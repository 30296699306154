<span (click)="uploadDocuments.show()" role="button" class="action-button success-link">
  {{ buttonName }}
</span>

<app-modal-drag-and-drop #uploadDocuments class="popup">
  <app-drag-and-drop-group
    (onFilesChange)="uploadFile($event); uploadDocuments.hide()"
    [additionalDescription]="'please_upload_copy'"
    [prefix]="'pages.manage_users.profile.'"
    [description]="'kyc_profile_upload_scanned_photo'"
    [extension]="acceptMimeTypesImage"
    [maxFilesCount]="1"
    [maxSize]="10 * 1024 * 1024"
    [title]="'please_drag_here_or'">
  </app-drag-and-drop-group>
</app-modal-drag-and-drop>
