import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { SvgIconComponent } from './svg-icon/svg-icon.component';

@NgModule({
  declarations: [SvgIconComponent],
  imports: [CommonModule, InlineSVGModule],
  exports: [SvgIconComponent],
})
export class SvgIconModule {}
