import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  constructor(private spinner: NgxSpinnerService) {}

  public makeObservableWithSpinner<T>(request: Observable<T>): Observable<T> {
    this.spinner.show();
    return request.pipe(finalize(() => this.spinner.hide()));
  }
}
