import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { IAsideMenuGroup } from '@default-application-app/components/layouts/aside-menu/aside-menu-group/aside-menu-group.component';
import { AsideMenuGroupService } from '@services/aside-menu/aside-menu-group.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { OptionsValuesNames } from '@constants/optionsPrefixes';
import { MenuGroupLabel } from '@layouts/aside-menu/menu-groupe-label';
import { AppOptionsService } from '@services/appOptions.service';
import { select, Store } from '@ngrx/store';
import * as FromApp from '@default-application-app/reducers/app.reducer';
import { ArabicLanguage } from '@constants/languages';

@Component({
  selector: 'app-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrls: ['./aside-menu.component.scss'],
  providers: [AsideMenuGroupService],
})
export class AsideMenuComponent implements OnDestroy {
  @Output() public getMenuOpened = new EventEmitter<boolean>();

  public groups: IAsideMenuGroup[];

  public isOpened = true;

  public activeGroup: IAsideMenuGroup | null = null;

  public isRtl: boolean = false;

  private unsubscribeSubject: Subject<void> = new Subject<void>();

  constructor(
    private asideMenuGroupService: AsideMenuGroupService,
    private router: Router,
    private appOptionService: AppOptionsService,
    private store: Store,
  ) {
    this.groups = this.asideMenuGroupService.getGroups();
    this.activeGroup = this.asideMenuGroupService.getActiveGroups();

    this.updateActiveItem();
    this.subscribeOnLanguage();

    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        takeUntil(this.unsubscribeSubject),
      )
      .subscribe(() => {
        const group = this.groups.find((value: IAsideMenuGroup) => this.router.url.includes(value.route));
        this.toggleSubItems(group);
      });

    // show card module if module enabled
    this.appOptionService
      .getOptionsValuePipe(OptionsValuesNames.CARD_MODULE)
      .pipe(
        filter((value: string): boolean => !!value),
        map((value: string): boolean => value === 'enable'),
        tap((enabled: boolean) => {
          const item = this.groups.find((group: IAsideMenuGroup) => group.label === MenuGroupLabel.Cards);

          if (item) {
            item.isHidden = !enabled;
          }
        }),
        takeUntil(this.unsubscribeSubject),
      )
      .subscribe();
  }

  private subscribeOnLanguage(): void {
    this.store
      .pipe(select(FromApp.getSiteLanguage))
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((currentLanguage) => {
        this.isRtl = currentLanguage === ArabicLanguage;
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

  public toggleOpen(): void {
    this.isOpened = !this.isOpened;
    this.menuIsOpened(this.isOpened);

    if (this.isOpened === false) {
      this.activeGroup = null;
    }
  }

  public toggleSubItems(activeGroup: IAsideMenuGroup) {
    this.activeGroup = activeGroup;

    this.updateActiveItem();
  }

  public close(): void {
    this.menuIsOpened(this.isOpened);
  }

  public menuIsOpened(isOpened: boolean) {
    this.getMenuOpened.emit(isOpened);
  }

  private updateActiveItem(): void {
    this.groups = this.groups.map((group) => ({
      ...group,
      isActive: group === this.activeGroup,
    }));
  }
}
