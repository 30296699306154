import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[SortHeader]',
})
export class SortHeaderDirective implements AfterViewInit, OnInit, OnDestroy {
  @Input() SortHeader: string;

  @Input() form: UntypedFormGroup;

  private upElement: HTMLElement;

  private downElement: HTMLElement;

  private arrowUpElement: HTMLElement;

  private arrowDownElement: HTMLElement;

  private subscription: Subscription;

  private control: UntypedFormControl;

  constructor(private elementRef: ElementRef, private _renderer: Renderer2) {}

  ngOnInit(): void {
    this.control = this.form.get('sort') as UntypedFormControl;
    this.checkValue();
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.classList.add('clickable');
    this.initHtmlElements();
    this.setElementsClasses();
  }

  private initHtmlElements(): void {
    const spanWithName: HTMLSpanElement = this._renderer.createElement('span');
    const spanWithCarets: HTMLSpanElement = this._renderer.createElement('span');
    this.upElement = this._renderer.createElement('i');
    this.downElement = this._renderer.createElement('i');
    this.arrowUpElement = this._renderer.createElement('i');
    this.arrowDownElement = this._renderer.createElement('i');
    this.appendElements(spanWithName, spanWithCarets);
    this.setupHtmlElementsClasses(spanWithCarets);
  }

  private setupHtmlElementsClasses(htmlEl: HTMLElement): void {
    htmlEl.classList.add('arrows', 'carets');
    this.upElement.classList.add('fas', 'fa-caret-up');
    this.downElement.classList.add('fas', 'fa-caret-down');
    this.arrowUpElement.classList.add('fas', 'fa-arrow-up');
    this.arrowDownElement.classList.add('fas', 'fa-arrow-down');
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private appendElements(spanWithName: HTMLElement, spanWithCarets: HTMLElement): void {
    this._renderer.appendChild(this.elementRef.nativeElement, spanWithName);
    this._renderer.appendChild(this.elementRef.nativeElement, spanWithCarets);
    this._renderer.appendChild(spanWithCarets, this.upElement);
    this._renderer.appendChild(spanWithCarets, this.downElement);
    this._renderer.appendChild(spanWithCarets, this.arrowUpElement);
    this._renderer.appendChild(spanWithCarets, this.arrowDownElement);
  }

  @HostListener('click', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onClick(event: KeyboardEvent) {
    if (this.isSortedByField()) {
      this.control.setValue(this.isSortOrderAsc() ? `-${this.SortHeader}` : this.SortHeader);
      this.elementRef.nativeElement.classList.add('sort-header-primary');
    } else {
      this.control.setValue(`-${this.SortHeader}`);
      this.elementRef.nativeElement.classList.add('sort-header-primary');
    }
  }

  private checkValue(): void {
    this.subscription = this.control.valueChanges.subscribe((controlName: string) => {
      if (controlName === this.SortHeader) {
        this.elementRef.nativeElement.classList.add('sort-header-primary');
      } else {
        this.elementRef.nativeElement.classList.remove('sort-header-primary');
      }
      this.setElementsClasses();
    });
  }

  private isSortedByField(): boolean {
    return this.SortHeader === this.control.value || `-${this.SortHeader}` === this.control.value;
  }

  private isSortOrderAsc(): boolean {
    return this.control.value === this.SortHeader;
  }

  private setElementsClasses() {
    if (this.isSortedByField()) {
      this.elementRef.nativeElement.classList.add('sort-header-primary');
      if (this.isSortOrderAsc()) {
        this.elementRef.nativeElement.classList.add('arrow-up');
        this.elementRef.nativeElement.classList.remove('arrow-down');
        this.upElement.classList.add('link-color');
        this.downElement.classList.remove('link-color');
      } else {
        this.elementRef.nativeElement.classList.add('arrow-down');
        this.elementRef.nativeElement.classList.remove('arrow-up');
        this.downElement.classList.add('link-color');
        this.upElement.classList.remove('link-color');
      }
    } else {
      this.downElement.classList.remove('link-color');
      this.upElement.classList.remove('link-color');
      this.elementRef.nativeElement.classList.remove('arrow-up');
      this.elementRef.nativeElement.classList.remove('arrow-down');
    }
  }
}
