import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PaginationService } from '@services/pagination/pagination.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-page-size',
  templateUrl: './page-size.component.html',
})
export class PageSizeComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  public itemsCount = [10, 25, 50, 100];

  private subscription: Subscription;

  @Input() form: UntypedFormGroup;

  ngOnInit(): void {
    this.form.addControl('size', new UntypedFormControl(PaginationService.defaultLimit));
    this.subscription = this.form.get('size').valueChanges.subscribe((value: string) => {
      if (!value || !this.itemsCount.includes(parseInt(value, 10))) {
        this.form.patchValue({ size: PaginationService.defaultLimit });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
