import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsModule } from '@default-application-app/modules/components/buttons/buttons.module';
import { TranslateModule } from '@ngx-translate/core';
import { PopupAutologoutComponent } from './popup-autologout/popup-autologout.component';

@NgModule({
  imports: [CommonModule, ButtonsModule, TranslateModule],
  declarations: [PopupAutologoutComponent],
  exports: [PopupAutologoutComponent],
})
export class PopupAutologoutModule {}
