import { SelectItemInterface } from '@default-application-app/core/interfaces/selectItemInterface';

export const KYC_STATUSES = {
  PENDING: 'pending',
  NOT_FILLED: 'not-filled',
  NOT_AVAILABLE: 'not-available',
  CANCELED: 'canceled',
  APPROVED: 'approved',
  WAITING: 'waiting',
  SENT_TO_CRM: 'sent_to_crm',
  SENT_TO_COMPLIANCE: 'sent_to_compliance',
  REJECT: 'rejected',
};

export const KYC_REQUIREMENTS_VIEW_STATUSES = {
  [KYC_STATUSES.APPROVED]: 'completed',
  [KYC_STATUSES.CANCELED]: 'declined',
  [KYC_STATUSES.PENDING]: 'waiting',
  [KYC_STATUSES.WAITING]: 'waiting',
  [KYC_STATUSES.NOT_AVAILABLE]: 'in_progress',
  [KYC_STATUSES.NOT_FILLED]: 'in_progress',
  [KYC_STATUSES.SENT_TO_CRM]: 'sent_to_crm',
  [KYC_STATUSES.REJECT]: 'rejected',
};

export const kycStatusSelectItems: SelectItemInterface[] = [
  { key: '', value: 'all_statuses' },
  { key: KYC_STATUSES.APPROVED, value: KYC_STATUSES.APPROVED },
  { key: KYC_STATUSES.PENDING, value: KYC_STATUSES.PENDING },
  { key: KYC_STATUSES.CANCELED, value: KYC_STATUSES.CANCELED },
];

export const getKycViewStatus = (statusCode: string): string =>
  KYC_REQUIREMENTS_VIEW_STATUSES[statusCode] || statusCode;

export const kycTransformStatus = (statusCode: string): string => statusCode.replace('-', '_');
