import { RequestDataProcessor } from '@default-application-app/core/services/transfer/request-data/request-data-processor';
import { CurrencyAmount } from '@default-application-app/core/models/currency-amount';
import { IP_INCOMING_PURPOSE, IP_OUTGOING_PURPOSE } from '@default-application-app/core/constants/transaction-purposes';
import { AdditionalDetail } from '@default-application-app/core/models/transfers/transfer-request-data';
import { CommonRequestProcessor } from '@default-application-app/core/services/transfer/request-data/common-request-processor';

export class FeeRequestDataProcessor implements RequestDataProcessor {
  private commonRequestProcessor: CommonRequestProcessor;

  constructor(private fullData: any) {
    this.commonRequestProcessor = new CommonRequestProcessor(fullData);
  }

  public getRequestAmount(): CurrencyAmount {
    return new CurrencyAmount(this.getCurrencyCode(), this.fullData.request.request.amount);
  }

  public getCurrencyCode(): string {
    return (
      this.commonRequestProcessor.getCurrencyCodeByPurpose(IP_INCOMING_PURPOSE) ||
      this.commonRequestProcessor.getCurrencyCodeByPurpose(IP_OUTGOING_PURPOSE)
    );
  }

  public getRate(): CurrencyAmount {
    const currencyCode =
      this.commonRequestProcessor.getCurrencyCodeByPurpose(IP_INCOMING_PURPOSE) ||
      this.commonRequestProcessor.getCurrencyCodeByPurpose(IP_OUTGOING_PURPOSE);
    return new CurrencyAmount(currencyCode, this.fullData.request.request.rate);
  }

  public getAdditionalDetails(): AdditionalDetail[] {
    return [];
  }
}
