import { AbstractControl } from '@angular/forms';
import { ErrorCodes } from '@default-application-app/core/constants/errorCodes';

const ACCOUNT_NUMBER_MIN_LENGTH = 8;

export function accountNumberValidator() {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const { value } = control;

    if (!value || value.length >= ACCOUNT_NUMBER_MIN_LENGTH) {
      return null;
    }

    return { [ErrorCodes.ACCOUNT_NUMBER_MIN_LENGTH]: true };
  };
}
