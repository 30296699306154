<div class="per-page-select">
  <span class="mobile-hide">{{ 'layout.system.show' | translate }}</span>
  <div class="page-select-container">
    <ng-select
      class="page-select"
      [title]="'layout.system.pages' | translate"
      [ngModel]="perPage"
      [searchable]="false"
      [clearable]="false"
      (change)="selectCount($event)"
      [items]="itemsCount">
    </ng-select>
  </div>
  <span class="mobile-hide">{{ 'layout.system.per_page' | translate }}</span>
</div>
