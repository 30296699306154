import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders as Headers } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SettingsTanCurrentDataInterface } from '@interfaces/settings-tan-interface/settings-tan-current-data.interface';
import { SettingsToPostInterface } from '@interfaces/settings-tan-interface/settings-tan-to-post.interface';
import { ApiCallerService } from '@services/api-caller.service';
import { ConfigService } from '@default-application-app/config.service';
import { SettingsTanObjectInterface } from '@interfaces/settings-tan-interface/settings-tan-object.interface';
import { CallResponceInterface } from '@interfaces/callResponce.interface';
import { SettingsTanDataInterface } from '@interfaces/settings-tan-interface/settings-tan-data.interface';

@Injectable()
export class SettingsRequestsTanService {
  public constructor(
    private http: HttpClient,
    private apiCallerService: ApiCallerService,
    private configService: ConfigService,
  ) {}

  public convertSettingsToPost(saveSettingData: SettingsTanCurrentDataInterface): SettingsToPostInterface {
    const postData: any = {
      keyValuePairs: [],
    };
    Object.keys(saveSettingData).forEach((key) => {
      const value = saveSettingData[key];
      postData.keyValuePairs.push({ key, value });
    });
    return postData;
  }

  public convertSettingsFromPost(
    formValue: SettingsTanCurrentDataInterface,
    dataFromServer: SettingsTanDataInterface[],
  ): SettingsTanCurrentDataInterface {
    const formData: any = {};
    Object.keys(formValue).forEach((fieldName) => {
      dataFromServer.forEach((rowObject) => {
        if (fieldName === rowObject.name) {
          formData[fieldName] = rowObject.value;
        }
      });
    });
    return formData;
  }

  public apiPostSettings(postData: SettingsToPostInterface) {
    const headers = new Headers();
    return this.apiCallerService.call(
      () =>
        this.http.post(this.configService.config.api.account.tanSettings, postData, { headers }).pipe(
          map((data) => {
            if (data) {
              return data;
            }
            return null;
          }),
        ),
      'apiPostSettings',
    );
  }

  public apiGetSettings() {
    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.account.tanSettings).pipe(
          map((data: SettingsTanObjectInterface) => {
            if (data.error) {
              return null;
            }
            return data;
          }),
        ),
      'apiGetSettings',
    );
  }

  public apiGetTansSetting(settingValue: string) {
    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.account.tanSettingForTransfer(settingValue)).pipe(
          map((data: SettingsTanObjectInterface) => {
            if (data.error) {
              return null;
            }
            return data;
          }),
        ),
      'apiGetTansSetting',
    );
  }

  public apiGetMyTansCount(): Observable<number | null> {
    return this.apiCallerService
      .call(() => this.http.get(this.configService.config.api.account.myTansCount), 'apiGetMyTansCount')
      .pipe(
        map(({ error, data }: CallResponceInterface) => {
          if (error) {
            return null;
          }
          return (<any>data).quantity;
        }),
      );
  }

  public apiGetTansCountByUserId(id: string): Observable<number | null> {
    return this.apiCallerService
      .call(() => this.http.get(this.configService.config.api.account.tansCountByUserId(id)), 'apiGetTansCountByUserId')
      .pipe(
        map(({ error, data }: CallResponceInterface) => {
          if (error) {
            return null;
          }
          return (<any>data).quantity;
        }),
      );
  }

  public apiGenerateTansByUserId(
    id: string,
    quantity: number,
    cancelOld: boolean,
    notificationMethod: string | null,
  ): Observable<{ success: boolean; quantity: number; cancelOld: boolean; notificationMethod: string | null }> {
    return this.apiCallerService
      .call(
        () =>
          this.http.post(this.configService.config.api.account.generateTansByUserId(id), {
            quantity,
            cancelOld,
            notificationMethod,
          }),
        'apiGenerateTansByUserId',
      )
      .pipe(
        map(({ error }: CallResponceInterface) => ({
          success: !error,
          quantity,
          cancelOld,
          notificationMethod,
        })),
      );
  }
}
