import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { asyncScheduler, Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  exhaustMap,
  mergeMap,
  map,
  skip,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { ErrorHandlerService } from '@services/error-handler.service';
import { UserGroupApiService } from '@services/userGroup/user-group-api.service';
import { FilterUserGroupInterface } from '@interfaces/filter-user-group.interface';
import { UserGroup } from '@models/user-group-model';
import { PaginationPageLimitInterface } from '@interfaces/pagination-page-limit.interface';
import { PaginationService } from '@services/pagination/pagination.service';
import * as FromProfiles from '@default-application-app/components/profiles/reducers';
import { UserGroupActions } from '@default-application-app/components/profiles/actions';
import { CallResponceInterface } from '@interfaces/callResponce.interface';
import { ApiError } from '@models/api-error.model';

@Injectable()
export class UserGroupEffects {
  @Effect()
  searchUserGroups$ = ({ debounce = 300, scheduler = asyncScheduler } = {}): Observable<Action> =>
    this.actions$.pipe(
      ofType<UserGroupActions.SearchUserGroups>(UserGroupActions.UserGroupTypes.SEARCH_USER_GROUPS),
      debounceTime(debounce, scheduler),
      withLatestFrom(this.store.pipe(select(FromProfiles.getUserGroupFilter))),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(([action, filter]): FilterUserGroupInterface => filter),
      switchMap((filter: FilterUserGroupInterface) => {
        const nextSearch$ = this.actions$.pipe(ofType(UserGroupActions.UserGroupTypes.SEARCH_USER_GROUPS), skip(1));
        return this.apiService.apiLoadUserGroups(filter).pipe(
          takeUntil(nextSearch$),
          map(({ data, error }: CallResponceInterface) => {
            if (error) {
              return new UserGroupActions.SearchUserGroupsFailure(<ApiError[]>data);
            }
            const userGroups: UserGroup[] = (<{ items: any[] }>data).items.map((item) => new UserGroup(item));
            const pagination: PaginationPageLimitInterface = PaginationService.buildPaginationPageLimit(
              data as {
                total_page: number;
                total_record: number;
                page: number;
                limit: number;
              },
            );
            return new UserGroupActions.SearchUserGroupsSuccess(userGroups, pagination);
          }),
          catchError((err) =>
            of(new UserGroupActions.SearchUserGroupsFailure(ErrorHandlerService.generateApiErrors(err))),
          ),
        );
      }),
    );

  @Effect()
  loadUserGroup$ = (): Observable<Action> =>
    this.actions$.pipe(
      ofType<UserGroupActions.LoadUserGroup>(UserGroupActions.UserGroupTypes.LOAD_USER_GROUP),
      map((action): number | undefined => action.id),
      withLatestFrom(this.store.pipe(select(FromProfiles.getCurrentUserGroupId))),
      mergeMap(([loadId, userId]: [number | undefined, number]) =>
        this.apiService.apiLoadUserGroup(loadId || userId).pipe(
          map(({ data, error }: CallResponceInterface) => {
            if (error) {
              return new UserGroupActions.LoadUserGroupFailure(<ApiError[]>data);
            }
            return new UserGroupActions.LoadUserGroupSuccess(new UserGroup(data));
          }),
          catchError((err) =>
            of(new UserGroupActions.LoadUserGroupFailure(ErrorHandlerService.generateApiErrors(err))),
          ),
        ),
      ),
    );

  @Effect()
  createUserGroup$ = (): Observable<Action> =>
    this.actions$.pipe(
      ofType<UserGroupActions.CreateUserGroup>(UserGroupActions.UserGroupTypes.CREATE_USER_GROUP),
      map((action) => action.userGroup),
      exhaustMap((userGroup: UserGroup) =>
        this.apiService.apiCreateUserGroup(userGroup).pipe(
          take(1),
          map(({ data, error }: CallResponceInterface) => {
            if (error) {
              return new UserGroupActions.SaveUserGroupFailure(<ApiError[]>data);
            }
            return new UserGroupActions.CreateUserGroupSuccess(new UserGroup(data));
          }),
          catchError((err) =>
            of(new UserGroupActions.SaveUserGroupFailure(ErrorHandlerService.generateApiErrors(err))),
          ),
        ),
      ),
    );

  @Effect()
  updateUserGroup$ = (): Observable<Action> =>
    this.actions$.pipe(
      ofType<UserGroupActions.SaveUserGroup>(UserGroupActions.UserGroupTypes.SAVE_USER_GROUP),
      map((action) => action.userGroup),
      exhaustMap((userGroup: UserGroup) =>
        this.apiService.apiUpdateUserGroup(userGroup).pipe(
          take(1),
          map(({ data, error }: CallResponceInterface) => {
            if (error) {
              return new UserGroupActions.SaveUserGroupFailure(<ApiError[]>data);
            }
            return new UserGroupActions.SaveUserGroupSuccess(new UserGroup(data));
          }),
          catchError((err) =>
            of(new UserGroupActions.SaveUserGroupFailure(ErrorHandlerService.generateApiErrors(err))),
          ),
        ),
      ),
    );

  @Effect()
  deleteUserGroup$ = (): Observable<Action> =>
    this.actions$.pipe(
      ofType<UserGroupActions.DeleteUserGroup>(UserGroupActions.UserGroupTypes.DELETE_USER_GROUP),
      map((action) => action.id),
      exhaustMap((id: number) =>
        this.apiService.apiDeleteUserGroup(id).pipe(
          take(1),
          map(({ data, error }: CallResponceInterface) => {
            if (error) {
              return new UserGroupActions.SaveUserGroupFailure(<ApiError[]>data);
            }
            return new UserGroupActions.DeleteUserGroupSuccess(id);
          }),
          catchError((err) =>
            of(new UserGroupActions.SaveUserGroupFailure(ErrorHandlerService.generateApiErrors(err))),
          ),
        ),
      ),
    );

  constructor(
    private actions$: Actions,
    private store: Store<FromProfiles.State>,
    private apiService: UserGroupApiService,
  ) {}
}
