<div class="dropdown-menu" (clickOutside)="closeMenu()">
  <div (click)="isOpenMenu = !isOpenMenu">
    <ng-content></ng-content>
  </div>

  <ul class="dropdown-menu-list" *ngIf="isOpenMenu && menuItems.length">
    <li
      *ngFor="let item of menuItems"
      (click)="onItemClick(item)"
      [title]="item.title | translate"
      class="dropdown-menu-item">
      <app-svg-icon
        class="item-icon"
        *ngIf="item.icon"
        [iconPath]="item.icon"
        [ngClass]="{ large: largeIcon }"></app-svg-icon>

      <span>{{ item.title | translate }}</span>
    </li>
  </ul>
</div>
