import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RefreshTokenService } from '@default-application-app/core/services/refreshToken.service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@default-application-app/core/services/auth/auth.service';
import { ConfigService } from '@default-application-app/config.service';
import { catchError, mergeMap } from 'rxjs/operators';
import * as UserPermissionsActions from '@default-application/src/app/actions/permission.actions';
import { Store } from '@ngrx/store';
import * as FromApp from '@default-application/src/app/reducers/app.reducer';
import { Router } from '@angular/router';

@Injectable()
export class RefreshTokenInterceptorService {
  constructor(
    public jwtHelper: JwtHelperService,
    private tokenService: RefreshTokenService,
    private configService: ConfigService,
    private auth: AuthService,
    private permissionsStore: Store<FromApp.UserPermissionsState>,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string | null = this.auth.getAccessToken();
    const tokenIsExpired = token ? this.jwtHelper.isTokenExpired(token) : true;

    const refreshToken: string | null = this.auth.getRefreshToken();
    const refreshTokenIsExpired = refreshToken ? this.jwtHelper.isTokenExpired(refreshToken) : true;
    if (this.auth.isAuthenticated() && refreshTokenIsExpired) {
      if (this.auth.isBusinessUser){
        this.auth.logOut();
        this.router.navigate(['/sign-in/business']);
        } else {
          this.auth.logOut();
        this.router.navigate(['/sign-in/individual']);
        }
      return;
    }

    if (
      this.auth.isAuthenticated() &&
      tokenIsExpired &&
      !this.tokenService.isProcessing() &&
      request.url !== this.configService.config.api.auth.refreshToken
    ) {
      this.tokenService.setProcessing(true);
      if (this.auth.isAdminOrRootUser()) {
        this.permissionsStore.dispatch(new UserPermissionsActions.LoadUserPermissions());
      }
      // eslint-disable-next-line consistent-return
      return this.auth.refreshAccessToken().pipe(
        mergeMap((res: any) => {
          if (!this.auth.saveToken(res)) {
            this.auth.logOut();
          }
          this.tokenService.publish(res);
          this.tokenService.setProcessing(false);
          return next.handle(request);
        }),
        catchError((error) => {
          this.tokenService.publish({});
          this.tokenService.setProcessing(false);

          if (error instanceof HttpErrorResponse && error.error instanceof ProgressEvent && error.status === 0) {
            // skip because of connection error
            return next.handle(request);
          }

          this.auth.logOut();
          return next.handle(request);
        }),
      );
    }
    if (request.url === this.configService.config.api.auth.refreshToken) {
      // eslint-disable-next-line consistent-return
      const modifiedRequest = request.clone({
        setHeaders: {
          'X-Platform': "web",
        },
      });
      return next.handle(modifiedRequest);
    }

    if (this.tokenService.isProcessing()) {
      // eslint-disable-next-line consistent-return
      return this.tokenService.storage.pipe(mergeMap(() => next.handle(request)));
    }
    // eslint-disable-next-line consistent-return
    return next.handle(request);
  }
}
