import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CallResponceInterface } from '@interfaces/callResponce.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiCallerService } from '@services/api-caller.service';
import { ConfigService } from '@default-application-app/config.service';
import { KycInitiateInterface } from '@interfaces/kyc/kyc-initiate.interface';
import { TokenService } from '@services/token-service';
import { LegalEntityInterface } from '@interfaces/kyc/legal-entity.interface';
import { EmploymentInfoInterface } from '@interfaces/kyc/employment-info.interface';

@Injectable()
export class KycApiService {
  constructor(
    private http: HttpClient,
    private apiCallerService: ApiCallerService,
    private configService: ConfigService,
    private tokenService: TokenService,
  ) {}

  public apiInitiateKyc(data: KycInitiateInterface): Observable<CallResponceInterface> {
    const headers = new HttpHeaders({
      'X-Tmp-Auth': this.tokenService.getTempAuthToken() || this.tokenService.getToken()?.accessToken,
    });

    return this.apiCallerService.call(
      () => this.http.post(this.configService.config.api.kyc.initiate, data, { headers }),
      'apiInitiateKyc',
    );
  }

  public apiDeleteKycRequestById(id: string): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () => this.http.delete(this.configService.config.api.kyc.deleteRequestById(id)),
      'apiDeleteKycRequestById',
    );
  }

  public apiCreateLegalEntity(data: LegalEntityInterface): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () => this.http.post(this.configService.config.api.kyc.legalEntity, data),
      'apiCreateLegalEntity',
    );
  }

  public apiUpdateLegalEntity(data: LegalEntityInterface, id: number): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () => this.http.put(this.configService.config.api.kyc.legalEntityById(id), data),
      'apiUpdateLegalEntity',
    );
  }

  public apiUpdateTierLevel(tierId: number): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () => this.http.post(this.configService.config.api.kyc.updateLevel, { tierId }),
      'apiUpdateTierLevel',
    );
  }

  public apiDeleteLegalEntity(id: number): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () => this.http.delete(this.configService.config.api.kyc.legalEntityById(id)),
      'apiDeleteLegalEntity',
    );
  }

  public apiLoadLegalEntityForAdminById(id: string): Observable<any> {
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.kyc.legalEntityForAdminById(id)),
      'apiLoadLegalEntityForAdminById',
    );
  }

  public apiLoadLegalEntityById(id: number): Observable<any> {
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.kyc.legalEntityById(id)),
      'apiLoadLegalEntityById',
    );
  }

  public apiLoadLegalEntities(params): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.kyc.legalEntity, { params }),
      'apiLoadLegalEntities',
    );
  }

  public apiLoadLegalEntitiesFromAdminForUser(params): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.kyc.legalEntityFromAdminForUser, { params }),
      'apiLoadLegalEntitiesFromAdminForUser',
    );
  }

  public apiUpdateEmploymentInfo(data: EmploymentInfoInterface, id: string, otp: string): Observable<CallResponceInterface> {
    const headers: {} = {};

    headers['X-OTP'] = otp;

    return this.apiCallerService.call(
      () => this.http.patch(this.configService.config.api.kyc.employment(id), data, { headers }),
      'apiUpdateEmploymentInfo',
    );
  }

  public apiGetKycTiers(): Observable<CallResponceInterface> {
    return this.apiCallerService.call(() => this.http.get(this.configService.config.api.kyc.tiers), 'apiGetKycTiers');
  }

  public apiGetCurrentKycTier(): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.kyc.currentTier),
      'apiGetCurrentKycTier',
    );
  }

  public apiUpdateKyc(id: string, data: Array<{ index: string; value: string }>): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () => this.http.put(this.configService.config.api.kyc.updateKyc(id), { values: data }),
      'apiUpdateKyc',
    );
  }

  public apiGetKyc(id: string): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.kyc.getTierById(id)),
      'apiUpdateKyc',
    );
  }

  public apiGetLimits(user_id: string, currency_type: string): Observable<any> {
    const headers = new Headers();
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.limits.getLimitsForUser(user_id, currency_type)),
      'apiGetLimits',
    )
  };
}
