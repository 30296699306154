import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateParser } from '@ngx-translate/core';

@Injectable()
export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  private allNotTranslated = {};

  constructor(private parser: TranslateParser) {}

  handle(params: MissingTranslationHandlerParams): string {
    // TODO remove when translate will be done
    const keys = params.key.split(/\.(?=[\w{<])/);
    const lastKey = keys[keys.length - 1];
    const prefix = keys.slice(0, keys.length - 1).join('.');
    if (this.allNotTranslated[prefix]) {
      if (!this.allNotTranslated[prefix].includes(lastKey)) {
        this.allNotTranslated[prefix].push(lastKey);
        // eslint-disable-next-line no-console
        console.log('All Not Translated = ', this.allNotTranslated);
      }
    } else {
      this.allNotTranslated[prefix] = [lastKey];
      // eslint-disable-next-line no-console
      console.log('All Not Translated = ', this.allNotTranslated);
    }
    return `(NOT TRANSLATED)${this.parser.interpolate(keys[keys.length - 1], params.interpolateParams)}`;
  }
}
