import { CurrenciesPrecision } from '@default-application-app/core/constants/currencies';
import Decimal from 'decimal.js';

export class BalanceHelper {
  public static valueToCurrencyPrecision(value: number | string, currencyCode: string): string {
    return BalanceHelper.valueToPrecision(value, BalanceHelper.getCurrencyPrecision(currencyCode));
  }

  public static valueToPrecision(value: number | string, precision: number = 2): string {
    const amountWithoutComma: Decimal = new Decimal(
      BalanceHelper.amountWithoutComma((value && value.toString()) || '0'),
    );
    return amountWithoutComma.toFixed(precision);
  }

  public static getCurrencyPrecision(currencyCode: string): number {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return CurrenciesPrecision.has(currencyCode) ? CurrenciesPrecision.get(currencyCode) : 2;
  }

  public static valueToCurrencyPrecisionWithCommas(value: number | string, currencyCode: string): string {
    return BalanceHelper.convertToAmountWithCommas(
      BalanceHelper.valueToPrecision(value, BalanceHelper.getCurrencyPrecision(currencyCode)),
    );
  }

  public static convertToAmountWithCommas(value: number | string) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  private static amountWithoutComma(amount: string): string {
    return amount.replace(/,/g, '');
  }
}
