<div class="input-field">
  <ng-select
    #search
    (change)="emitChange($event)"
    [bindValue]="bindValue"
    [class.searchable]="searchable"
    [clearable]="false"
    [hideSelected]="true"
    [items]="users$ | async"
    [loading]="isUsersLoading"
    [placeholder]="placeholder || ('pages.transfers.select_a_user' | translate)"
    [searchable]="searchable"
    [typeahead]="usersInputSubject$"
    class="simple searchable ng-select native-select"
    [notFoundText]="'layout.system.no_users_found' | translate">
  </ng-select>
</div>
