import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-pagination',
  templateUrl: './form-pagination.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormPaginationComponent implements OnInit {
  @Input() form: UntypedFormGroup;

  private control: UntypedFormControl;

  ngOnInit(): void {
    this.control = new UntypedFormControl('1');
    this.form.addControl('page', this.control);
  }

  pageChange(page: number) {
    this.control.setValue(page);
  }
}
