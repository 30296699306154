import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.scss'],
})
export class AddButtonComponent {
  @Input()
  public text: string;

  @Input()
  public modifyText: string;

  @Input()
  public isDisabled: boolean = false;


}


