import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appDragDrop]',
})
export class DragAndDropDirective {
  @Input()
  private disabled: boolean;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onFileDropped = new EventEmitter<any>();

  @HostListener('dragover', ['$event'])
  public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('drop', ['$event'])
  public ondrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    if (this.disabled) {
      return;
    }

    const { files } = evt.dataTransfer;
    if (files.length) {
      this.onFileDropped.emit(files);
    }
  }
}
