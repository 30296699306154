import { SelectItemInterface } from '@interfaces/selectItemInterface';

export const COUNTRIES = [
  {
    name: 'Afghanistan',
    dial_code: '+93',
    code: 'AF',
    image: 'af.svg',
  },
  {
    name: 'Aland Islands',
    dial_code: '+358',
    code: 'AX',
    image: 'ax.svg',
  },
  {
    name: 'Albania',
    dial_code: '+355',
    code: 'AL',
    image: 'al.svg',
  },
  {
    name: 'Algeria',
    dial_code: '+213',
    code: 'DZ',
    image: 'dz.svg',
  },
  {
    name: 'AmericanSamoa',
    dial_code: '+1684',
    code: 'AS',
    image: 'as.svg',
  },
  {
    name: 'Andorra',
    dial_code: '+376',
    code: 'AD',
    image: 'ad.svg',
  },
  {
    name: 'Angola',
    dial_code: '+244',
    code: 'AO',
    image: 'ao.svg',
  },
  {
    name: 'Anguilla',
    dial_code: '+1264',
    code: 'AI',
    image: 'ai.svg',
  },
  {
    name: 'Antarctica',
    dial_code: '+672',
    code: 'AQ',
    image: 'aq.svg',
  },
  {
    name: 'Antigua and Barbuda',
    dial_code: '+1268',
    code: 'AG',
    image: 'ag.svg',
  },
  {
    name: 'Argentina',
    dial_code: '+54',
    code: 'AR',
    image: 'ar.svg',
  },
  {
    name: 'Armenia',
    dial_code: '+374',
    code: 'AM',
    image: 'am.svg',
  },
  {
    name: 'Aruba',
    dial_code: '+297',
    code: 'AW',
    image: 'aw.svg',
  },
  {
    name: 'Australia',
    dial_code: '+61',
    code: 'AU',
    image: 'au.svg',
  },
  {
    name: 'Austria',
    dial_code: '+43',
    code: 'AT',
    image: 'at.svg',
  },
  {
    name: 'Azerbaijan',
    dial_code: '+994',
    code: 'AZ',
    image: 'az.svg',
  },
  {
    name: 'Bahamas',
    dial_code: '+1242',
    code: 'BS',
    image: 'bs.svg',
  },
  {
    name: 'Bahrain',
    dial_code: '+973',
    code: 'BH',
    image: 'bh.svg',
  },
  {
    name: 'Bangladesh',
    dial_code: '+880',
    code: 'BD',
    image: 'bd.svg',
  },
  {
    name: 'Barbados',
    dial_code: '+1246',
    code: 'BB',
    image: 'bb.svg',
  },
  {
    name: 'Belarus',
    dial_code: '+375',
    code: 'BY',
    image: 'by.svg',
  },
  {
    name: 'Belgium',
    dial_code: '+32',
    code: 'BE',
    image: 'be.svg',
  },
  {
    name: 'Belize',
    dial_code: '+501',
    code: 'BZ',
    image: 'bz.svg',
  },
  {
    name: 'Benin',
    dial_code: '+229',
    code: 'BJ',
    image: 'bj.svg',
  },
  {
    name: 'Bermuda',
    dial_code: '+1441',
    code: 'BM',
    image: 'bm.svg',
  },
  {
    name: 'Bhutan',
    dial_code: '+975',
    code: 'BT',
    image: 'bt.svg',
  },
  {
    name: 'Bolivia, Plurinational State of',
    dial_code: '+591',
    code: 'BO',
    image: 'bo.svg',
  },
  {
    name: 'Bosnia and Herzegovina',
    dial_code: '+387',
    code: 'BA',
    image: 'ba.svg',
  },
  {
    name: 'Botswana',
    dial_code: '+267',
    code: 'BW',
    image: 'bw.svg',
  },
  {
    name: 'Brazil',
    dial_code: '+55',
    code: 'BR',
    image: 'br.svg',
  },
  {
    name: 'British Indian Ocean Territory',
    dial_code: '+246',
    code: 'IO',
    image: 'io.svg',
  },
  {
    name: 'Brunei Darussalam',
    dial_code: '+673',
    code: 'BN',
    image: 'bn.svg',
  },
  {
    name: 'Bulgaria',
    dial_code: '+359',
    code: 'BG',
    image: 'bg.svg',
  },
  {
    name: 'Burkina Faso',
    dial_code: '+226',
    code: 'BF',
    image: 'bf.svg',
  },
  {
    name: 'Burundi',
    dial_code: '+257',
    code: 'BI',
    image: 'bi.svg',
  },
  {
    name: 'Cambodia',
    dial_code: '+855',
    code: 'KH',
    image: 'kh.svg',
  },
  {
    name: 'Cameroon',
    dial_code: '+237',
    code: 'CM',
    image: 'cm.svg',
  },
  {
    name: 'Canada',
    dial_code: '+1',
    code: 'CA',
    image: 'ca.svg',
  },
  {
    name: 'Cape Verde',
    dial_code: '+238',
    code: 'CV',
    image: 'cv.svg',
  },
  {
    name: 'Cayman Islands',
    dial_code: '+ 345',
    code: 'KY',
    image: 'ky.svg',
  },
  {
    name: 'Central African Republic',
    dial_code: '+236',
    code: 'CF',
    image: 'cf.svg',
  },
  {
    name: 'Chad',
    dial_code: '+235',
    code: 'TD',
    image: 'td.svg',
  },
  {
    name: 'Chile',
    dial_code: '+56',
    code: 'CL',
    image: 'cl.svg',
  },
  {
    name: 'China',
    dial_code: '+86',
    code: 'CN',
    image: 'cn.svg',
  },
  {
    name: 'Christmas Island',
    dial_code: '+61',
    code: 'CX',
    image: 'cx.svg',
  },
  {
    name: 'Cocos (Keeling) Islands',
    dial_code: '+61',
    code: 'CC',
    image: 'cc.svg',
  },
  {
    name: 'Colombia',
    dial_code: '+57',
    code: 'CO',
    image: 'co.svg',
  },
  {
    name: 'Comoros',
    dial_code: '+269',
    code: 'KM',
    image: 'km.svg',
  },
  {
    name: 'Congo',
    dial_code: '+242',
    code: 'CG',
    image: 'cg.svg',
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    dial_code: '+243',
    code: 'CD',
    image: 'cd.svg',
  },
  {
    name: 'Cook Islands',
    dial_code: '+682',
    code: 'CK',
    image: 'ck.svg',
  },
  {
    name: 'Costa Rica',
    dial_code: '+506',
    code: 'CR',
    image: 'cr.svg',
  },
  {
    name: "Cote d'Ivoire",
    dial_code: '+225',
    code: 'CI',
    image: 'ci.svg',
  },
  {
    name: 'Croatia',
    dial_code: '+385',
    code: 'HR',
    image: 'hr.svg',
  },
  {
    name: 'Cuba',
    dial_code: '+53',
    code: 'CU',
    image: 'cu.svg',
  },
  {
    name: 'Cyprus',
    dial_code: '+357',
    code: 'CY',
    image: 'cy.svg',
  },
  {
    name: 'Czech Republic',
    dial_code: '+420',
    code: 'CZ',
    image: 'cz.svg',
  },
  {
    name: 'Denmark',
    dial_code: '+45',
    code: 'DK',
    image: 'dk.svg',
  },
  {
    name: 'Djibouti',
    dial_code: '+253',
    code: 'DJ',
    image: 'dj.svg',
  },
  {
    name: 'Dominica',
    dial_code: '+1767',
    code: 'DM',
    image: 'dm.svg',
  },
  {
    name: 'Dominican Republic',
    dial_code: '+1849',
    code: 'DO',
    image: 'do.svg',
  },
  {
    name: 'Ecuador',
    dial_code: '+593',
    code: 'EC',
    image: 'ec.svg',
  },
  {
    name: 'Egypt',
    dial_code: '+20',
    code: 'EG',
    image: 'eg.svg',
  },
  {
    name: 'El Salvador',
    dial_code: '+503',
    code: 'SV',
    image: 'sv.svg',
  },
  {
    name: 'Equatorial Guinea',
    dial_code: '+240',
    code: 'GQ',
    image: 'gq.svg',
  },
  {
    name: 'Eritrea',
    dial_code: '+291',
    code: 'ER',
    image: 'er.svg',
  },
  {
    name: 'Estonia',
    dial_code: '+372',
    code: 'EE',
    image: 'ee.svg',
  },
  {
    name: 'Ethiopia',
    dial_code: '+251',
    code: 'ET',
    image: 'et.svg',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    dial_code: '+500',
    code: 'FK',
    image: 'fk.svg',
  },
  {
    name: 'Faroe Islands',
    dial_code: '+298',
    code: 'FO',
    image: 'fo.svg',
  },
  {
    name: 'Fiji',
    dial_code: '+679',
    code: 'FJ',
    image: 'fj.svg',
  },
  {
    name: 'Finland',
    dial_code: '+358',
    code: 'FI',
    image: 'fi.svg',
  },
  {
    name: 'France',
    dial_code: '+33',
    code: 'FR',
    image: 'fr.svg',
  },
  {
    name: 'French Guiana',
    dial_code: '+594',
    code: 'GF',
    image: 'gf.svg',
  },
  {
    name: 'French Polynesia',
    dial_code: '+689',
    code: 'PF',
    image: 'pf.svg',
  },
  {
    name: 'Gabon',
    dial_code: '+241',
    code: 'GA',
    image: 'ga.svg',
  },
  {
    name: 'Gambia',
    dial_code: '+220',
    code: 'GM',
    image: 'gm.svg',
  },
  {
    name: 'Georgia',
    dial_code: '+995',
    code: 'GE',
    image: 'ge.svg',
  },
  {
    name: 'Germany',
    dial_code: '+49',
    code: 'DE',
    image: 'de.svg',
  },
  {
    name: 'Ghana',
    dial_code: '+233',
    code: 'GH',
    image: 'gh.svg',
  },
  {
    name: 'Gibraltar',
    dial_code: '+350',
    code: 'GI',
    image: 'gi.svg',
  },
  {
    name: 'Greece',
    dial_code: '+30',
    code: 'GR',
    image: 'gr.svg',
  },
  {
    name: 'Greenland',
    dial_code: '+299',
    code: 'GL',
    image: 'gl.svg',
  },
  {
    name: 'Grenada',
    dial_code: '+1473',
    code: 'GD',
    image: 'gd.svg',
  },
  {
    name: 'Guadeloupe',
    dial_code: '+590',
    code: 'GP',
    image: 'gp.svg',
  },
  {
    name: 'Guam',
    dial_code: '+1671',
    code: 'GU',
    image: 'gu.svg',
  },
  {
    name: 'Guatemala',
    dial_code: '+502',
    code: 'GT',
    image: 'gt.svg',
  },
  {
    name: 'Guernsey',
    dial_code: '+44',
    code: 'GG',
    image: 'gg.svg',
  },
  {
    name: 'Guinea',
    dial_code: '+224',
    code: 'GN',
    image: 'gn.svg',
  },
  {
    name: 'Guinea-Bissau',
    dial_code: '+245',
    code: 'GW',
    image: 'gw.svg',
  },
  {
    name: 'Guyana',
    dial_code: '+595',
    code: 'GY',
    image: 'gy.svg',
  },
  {
    name: 'Haiti',
    dial_code: '+509',
    code: 'HT',
    image: 'ht.svg',
  },
  {
    name: 'Holy See (Vatican City State)',
    dial_code: '+379',
    code: 'VA',
    image: 'va.svg',
  },
  {
    name: 'Honduras',
    dial_code: '+504',
    code: 'HN',
    image: 'hn.svg',
  },
  {
    name: 'Hong Kong',
    dial_code: '+852',
    code: 'HK',
    image: 'hk.svg',
  },
  {
    name: 'Hungary',
    dial_code: '+36',
    code: 'HU',
    image: 'hu.svg',
  },
  {
    name: 'Iceland',
    dial_code: '+354',
    code: 'IS',
    image: 'is.svg',
  },
  {
    name: 'India',
    dial_code: '+91',
    code: 'IN',
    image: 'in.svg',
  },
  {
    name: 'Indonesia',
    dial_code: '+62',
    code: 'ID',
    image: 'id.svg',
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    dial_code: '+98',
    code: 'IR',
    image: 'ir.svg',
  },
  {
    name: 'Iraq',
    dial_code: '+964',
    code: 'IQ',
    image: 'iq.svg',
  },
  {
    name: 'Ireland',
    dial_code: '+353',
    code: 'IE',
    image: 'ie.svg',
  },
  {
    name: 'Isle of Man',
    dial_code: '+44',
    code: 'IM',
    image: 'im.svg',
  },
  {
    name: 'Israel',
    dial_code: '+972',
    code: 'IL',
    image: 'il.svg',
  },
  {
    name: 'Italy',
    dial_code: '+39',
    code: 'IT',
    image: 'it.svg',
  },
  {
    name: 'Jamaica',
    dial_code: '+1876',
    code: 'JM',
    image: 'jm.svg',
  },
  {
    name: 'Japan',
    dial_code: '+81',
    code: 'JP',
    image: 'jp.svg',
  },
  {
    name: 'Jersey',
    dial_code: '+44',
    code: 'JE',
    image: 'je.svg',
  },
  {
    name: 'Jordan',
    dial_code: '+962',
    code: 'JO',
    image: 'jo.svg',
  },
  {
    name: 'Kazakhstan',
    dial_code: '+77',
    code: 'KZ',
    image: 'kz.svg',
  },
  {
    name: 'Kenya',
    dial_code: '+254',
    code: 'KE',
    image: 'ke.svg',
  },
  {
    name: 'Kiribati',
    dial_code: '+686',
    code: 'KI',
    image: 'ki.svg',
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: '+850',
    code: 'KP',
    image: 'kp.svg',
  },
  {
    name: 'Korea, Republic of South Korea',
    dial_code: '+82',
    code: 'KR',
    image: 'kr.svg',
  },
  {
    name: 'Kuwait',
    dial_code: '+965',
    code: 'KW',
    image: 'kw.svg',
  },
  {
    name: 'Kyrgyzstan',
    dial_code: '+996',
    code: 'KG',
    image: 'kg.svg',
  },
  {
    name: 'Laos',
    dial_code: '+856',
    code: 'LA',
    image: 'la.svg',
  },
  {
    name: 'Latvia',
    dial_code: '+371',
    code: 'LV',
    image: 'lv.svg',
  },
  {
    name: 'Lebanon',
    dial_code: '+961',
    code: 'LB',
    image: 'lb.svg',
  },
  {
    name: 'Lesotho',
    dial_code: '+266',
    code: 'LS',
    image: 'ls.svg',
  },
  {
    name: 'Liberia',
    dial_code: '+231',
    code: 'LR',
    image: 'lr.svg',
  },
  {
    name: 'Libyan Arab Jamahiriya',
    dial_code: '+218',
    code: 'LY',
    image: 'ly.svg',
  },
  {
    name: 'Liechtenstein',
    dial_code: '+423',
    code: 'LI',
    image: 'li.svg',
  },
  {
    name: 'Lithuania',
    dial_code: '+370',
    code: 'LT',
    image: 'lt.svg',
  },
  {
    name: 'Luxembourg',
    dial_code: '+352',
    code: 'LU',
    image: 'lu.svg',
  },
  {
    name: 'Macao',
    dial_code: '+853',
    code: 'MO',
    image: 'mo.svg',
  },
  {
    name: 'Macedonia',
    dial_code: '+389',
    code: 'MK',
    image: 'mk.svg',
  },
  {
    name: 'Madagascar',
    dial_code: '+261',
    code: 'MG',
    image: 'mg.svg',
  },
  {
    name: 'Malawi',
    dial_code: '+265',
    code: 'MW',
    image: 'mw.svg',
  },
  {
    name: 'Malaysia',
    dial_code: '+60',
    code: 'MY',
    image: 'my.svg',
  },
  {
    name: 'Maldives',
    dial_code: '+960',
    code: 'MV',
    image: 'mv.svg',
  },
  {
    name: 'Mali',
    dial_code: '+223',
    code: 'ML',
    image: 'ml.svg',
  },
  {
    name: 'Malta',
    dial_code: '+356',
    code: 'MT',
    image: 'mt.svg',
  },
  {
    name: 'Marshall Islands',
    dial_code: '+692',
    code: 'MH',
    image: 'mh.svg',
  },
  {
    name: 'Martinique',
    dial_code: '+596',
    code: 'MQ',
    image: 'mq.svg',
  },
  {
    name: 'Mauritania',
    dial_code: '+222',
    code: 'MR',
    image: 'mr.svg',
  },
  {
    name: 'Mauritius',
    dial_code: '+230',
    code: 'MU',
    image: 'mu.svg',
  },
  {
    name: 'Mayotte',
    dial_code: '+262',
    code: 'YT',
    image: 'yt.svg',
  },
  {
    name: 'Mexico',
    dial_code: '+52',
    code: 'MX',
    image: 'mx.svg',
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    dial_code: '+691',
    code: 'FM',
    image: 'fm.svg',
  },
  {
    name: 'Moldova',
    dial_code: '+373',
    code: 'MD',
    image: 'md.svg',
  },
  {
    name: 'Monaco',
    dial_code: '+377',
    code: 'MC',
    image: 'mc.svg',
  },
  {
    name: 'Mongolia',
    dial_code: '+976',
    code: 'MN',
    image: 'mn.svg',
  },
  {
    name: 'Montenegro',
    dial_code: '+382',
    code: 'ME',
    image: 'me.svg',
  },
  {
    name: 'Montserrat',
    dial_code: '+1664',
    code: 'MS',
    image: 'ms.svg',
  },
  {
    name: 'Morocco',
    dial_code: '+212',
    code: 'MA',
    image: 'ma.svg',
  },
  {
    name: 'Mozambique',
    dial_code: '+258',
    code: 'MZ',
    image: 'mz.svg',
  },
  {
    name: 'Myanmar',
    dial_code: '+95',
    code: 'MM',
    image: 'mm.svg',
  },
  {
    name: 'Namibia',
    dial_code: '+264',
    code: 'NA',
    image: 'na.svg',
  },
  {
    name: 'Nauru',
    dial_code: '+674',
    code: 'NR',
    image: 'nr.svg',
  },
  {
    name: 'Nepal',
    dial_code: '+977',
    code: 'NP',
    image: 'np.svg',
  },
  {
    name: 'Netherlands',
    dial_code: '+31',
    code: 'NL',
    image: 'nl.svg',
  },
  {
    name: 'Netherlands Antilles',
    dial_code: '+599',
    code: 'AN',
    image: 'an.svg',
  },
  {
    name: 'New Caledonia',
    dial_code: '+687',
    code: 'NC',
    image: 'nc.svg',
  },
  {
    name: 'New Zealand',
    dial_code: '+64',
    code: 'NZ',
    image: 'nz.svg',
  },
  {
    name: 'Nicaragua',
    dial_code: '+505',
    code: 'NI',
    image: 'ni.svg',
  },
  {
    name: 'Niger',
    dial_code: '+227',
    code: 'NE',
    image: 'ne.svg',
  },
  {
    name: 'Nigeria',
    dial_code: '+234',
    code: 'NG',
    image: 'ng.svg',
  },
  {
    name: 'Niue',
    dial_code: '+683',
    code: 'NU',
    image: 'nu.svg',
  },
  {
    name: 'Norfolk Island',
    dial_code: '+672',
    code: 'NF',
    image: 'nf.svg',
  },
  {
    name: 'Northern Mariana Islands',
    dial_code: '+1670',
    code: 'MP',
    image: 'mp.svg',
  },
  {
    name: 'Norway',
    dial_code: '+47',
    code: 'NO',
    image: 'no.svg',
  },
  {
    name: 'Oman',
    dial_code: '+968',
    code: 'OM',
    image: 'om.svg',
  },
  {
    name: 'Pakistan',
    dial_code: '+92',
    code: 'PK',
    image: 'pk.svg',
  },
  {
    name: 'Palau',
    dial_code: '+680',
    code: 'PW',
    image: 'pw.svg',
  },
  {
    name: 'Palestinian Territory, Occupied',
    dial_code: '+970',
    code: 'PS',
    image: 'ps.svg',
  },
  {
    name: 'Panama',
    dial_code: '+507',
    code: 'PA',
    image: 'pa.svg',
  },
  {
    name: 'Papua New Guinea',
    dial_code: '+675',
    code: 'PG',
    image: 'pg.svg',
  },
  {
    name: 'Paraguay',
    dial_code: '+595',
    code: 'PY',
    image: 'py.svg',
  },
  {
    name: 'Peru',
    dial_code: '+51',
    code: 'PE',
    image: 'pe.svg',
  },
  {
    name: 'Philippines',
    dial_code: '+63',
    code: 'PH',
    image: 'ph.svg',
  },
  {
    name: 'Pitcairn',
    dial_code: '+872',
    code: 'PN',
    image: 'pn.svg',
  },
  {
    name: 'Poland',
    dial_code: '+48',
    code: 'PL',
    image: 'pl.svg',
  },
  {
    name: 'Portugal',
    dial_code: '+351',
    code: 'PT',
    image: 'pt.svg',
  },
  {
    name: 'Puerto Rico',
    dial_code: '+1939',
    code: 'PR',
    image: 'pr.svg',
  },
  {
    name: 'Qatar',
    dial_code: '+974',
    code: 'QA',
    image: 'qa.svg',
  },
  {
    name: 'Romania',
    dial_code: '+40',
    code: 'RO',
    image: 'ro.svg',
  },
  {
    name: 'Russia',
    dial_code: '+7',
    code: 'RU',
    image: 'ru.svg',
  },
  {
    name: 'Rwanda',
    dial_code: '+250',
    code: 'RW',
    image: 'rw.svg',
  },
  {
    name: 'Reunion',
    dial_code: '+262',
    code: 'RE',
    image: 're.svg',
  },
  {
    name: 'Saint Barthelemy',
    dial_code: '+590',
    code: 'BL',
    image: 'bl.svg',
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    dial_code: '+290',
    code: 'SH',
    image: 'sh.svg',
  },
  {
    name: 'Saint Kitts and Nevis',
    dial_code: '+1869',
    code: 'KN',
    image: 'kn.svg',
  },
  {
    name: 'Saint Lucia',
    dial_code: '+1758',
    code: 'LC',
    image: 'lc.svg',
  },
  {
    name: 'Saint Martin',
    dial_code: '+590',
    code: 'MF',
    image: 'mf.svg',
  },
  {
    name: 'Saint Pierre and Miquelon',
    dial_code: '+508',
    code: 'PM',
    image: 'pm.svg',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    dial_code: '+1784',
    code: 'VC',
    image: 'vc.svg',
  },
  {
    name: 'Samoa',
    dial_code: '+685',
    code: 'WS',
    image: 'ws.svg',
  },
  {
    name: 'San Marino',
    dial_code: '+378',
    code: 'SM',
    image: 'sm.svg',
  },
  {
    name: 'Sao Tome and Principe',
    dial_code: '+239',
    code: 'ST',
    image: 'st.svg',
  },
  {
    name: 'Saudi Arabia',
    dial_code: '+966',
    code: 'SA',
    image: 'sa.svg',
  },
  {
    name: 'Senegal',
    dial_code: '+221',
    code: 'SN',
    image: 'sn.svg',
  },
  {
    name: 'Serbia',
    dial_code: '+381',
    code: 'RS',
    image: 'rs.svg',
  },
  {
    name: 'Seychelles',
    dial_code: '+248',
    code: 'SC',
    image: 'sc.svg',
  },
  {
    name: 'Sierra Leone',
    dial_code: '+232',
    code: 'SL',
    image: 'sl.svg',
  },
  {
    name: 'Singapore',
    dial_code: '+65',
    code: 'SG',
    image: 'sg.svg',
  },
  {
    name: 'Slovakia',
    dial_code: '+421',
    code: 'SK',
    image: 'sk.svg',
  },
  {
    name: 'Slovenia',
    dial_code: '+386',
    code: 'SI',
    image: 'si.svg',
  },
  {
    name: 'Solomon Islands',
    dial_code: '+677',
    code: 'SB',
    image: 'sb.svg',
  },
  {
    name: 'Somalia',
    dial_code: '+252',
    code: 'SO',
    image: 'so.svg',
  },
  {
    name: 'South Africa',
    dial_code: '+27',
    code: 'ZA',
    image: 'za.svg',
  },
  {
    name: 'South Sudan',
    dial_code: '+211',
    code: 'SS',
    image: 'ss.svg',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    dial_code: '+500',
    code: 'GS',
    image: 'gs.svg',
  },
  {
    name: 'Spain',
    dial_code: '+34',
    code: 'ES',
    image: 'es.svg',
  },
  {
    name: 'Sri Lanka',
    dial_code: '+94',
    code: 'LK',
    image: 'lk.svg',
  },
  {
    name: 'Sudan',
    dial_code: '+249',
    code: 'SD',
    image: 'sd.svg',
  },
  {
    name: 'Suriname',
    dial_code: '+597',
    code: 'SR',
    image: 'sr.svg',
  },
  {
    name: 'Svalbard and Jan Mayen',
    dial_code: '+47',
    code: 'SJ',
    image: 'sj.svg',
  },
  {
    name: 'Swaziland',
    dial_code: '+268',
    code: 'SZ',
    image: 'sz.svg',
  },
  {
    name: 'Sweden',
    dial_code: '+46',
    code: 'SE',
    image: 'se.svg',
  },
  {
    name: 'Switzerland',
    dial_code: '+41',
    code: 'CH',
    image: 'ch.svg',
  },
  {
    name: 'Syrian Arab Republic',
    dial_code: '+963',
    code: 'SY',
    image: 'sy.svg',
  },
  {
    name: 'Taiwan',
    dial_code: '+886',
    code: 'TW',
    image: 'tw.svg',
  },
  {
    name: 'Tajikistan',
    dial_code: '+992',
    code: 'TJ',
    image: 'tj.svg',
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    dial_code: '+255',
    code: 'TZ',
    image: 'tz.svg',
  },
  {
    name: 'Thailand',
    dial_code: '+66',
    code: 'TH',
    image: 'th.svg',
  },
  {
    name: 'Timor-Leste',
    dial_code: '+670',
    code: 'TL',
    image: 'tl.svg',
  },
  {
    name: 'Togo',
    dial_code: '+228',
    code: 'TG',
    image: 'tg.svg',
  },
  {
    name: 'Tokelau',
    dial_code: '+690',
    code: 'TK',
    image: 'tk.svg',
  },
  {
    name: 'Tonga',
    dial_code: '+676',
    code: 'TO',
    image: 'to.svg',
  },
  {
    name: 'Trinidad and Tobago',
    dial_code: '+1868',
    code: 'TT',
    image: 'tt.svg',
  },
  {
    name: 'Tunisia',
    dial_code: '+216',
    code: 'TN',
    image: 'tn.svg',
  },
  {
    name: 'Turkey',
    dial_code: '+90',
    code: 'TR',
    image: 'tr.svg',
  },
  {
    name: 'Turkmenistan',
    dial_code: '+993',
    code: 'TM',
    image: 'tm.svg',
  },
  {
    name: 'Turks and Caicos Islands',
    dial_code: '+1649',
    code: 'TC',
    image: 'tc.svg',
  },
  {
    name: 'Tuvalu',
    dial_code: '+688',
    code: 'TV',
    image: 'tv.svg',
  },
  {
    name: 'Uganda',
    dial_code: '+256',
    code: 'UG',
    image: 'ug.svg',
  },
  {
    name: 'Ukraine',
    dial_code: '+380',
    code: 'UA',
    image: 'ua.svg',
  },
  {
    name: 'United Arab Emirates',
    dial_code: '+971',
    code: 'AE',
    image: 'ae.svg',
  },
  {
    name: 'United Kingdom',
    dial_code: '+44',
    code: 'GB',
    image: 'gb.svg',
  },
  {
    name: 'United States',
    dial_code: '+1',
    code: 'US',
    image: 'us.svg',
  },
  {
    name: 'Uruguay',
    dial_code: '+598',
    code: 'UY',
    image: 'uy.svg',
  },
  {
    name: 'Uzbekistan',
    dial_code: '+998',
    code: 'UZ',
    image: 'uz.svg',
  },
  {
    name: 'Vanuatu',
    dial_code: '+678',
    code: 'VU',
    image: 'vu.svg',
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    dial_code: '+58',
    code: 'VE',
    image: 've.svg',
  },
  {
    name: 'Vietnam',
    dial_code: '+84',
    code: 'VN',
    image: 'vn.svg',
  },
  {
    name: 'Virgin Islands, British',
    dial_code: '+1284',
    code: 'VG',
    image: 'vg.svg',
  },
  {
    name: 'Virgin Islands, U.S.',
    dial_code: '+1340',
    code: 'VI',
    image: 'vi.svg',
  },
  {
    name: 'Wallis and Futuna',
    dial_code: '+681',
    code: 'WF',
    image: 'wf.svg',
  },
  {
    name: 'Yemen',
    dial_code: '+967',
    code: 'YE',
    image: 'ye.svg',
  },
  {
    name: 'Zambia',
    dial_code: '+260',
    code: 'ZM',
    image: 'zm.svg',
  },
  {
    name: 'Zimbabwe',
    dial_code: '+263',
    code: 'ZW',
    image: 'zw.svg',
  },
];

export const COUNTRIES_FOR_SELECT: SelectItemInterface[] = COUNTRIES.map((item) => ({
  key: item.code,
  value: item.name,
}));

export const countryByCode = (code: string) => COUNTRIES.find((country) => country.code === code);
