import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '@services/auth/auth.service';
import * as LogoutActions from '@default-application/src/app/actions/logout.actions';
import { select, Store } from '@ngrx/store';
import * as FromLogout from '@default-application/src/app/reducers/app.reducer';
import { AutoLogoutPopupService } from '@services/auto-logout-popup.service';
import { fromEvent } from 'rxjs';
import { UnsubscribeDestroyHelper } from '@helpers/unsubscribe-destroy.helper';
import { takeUntil } from 'rxjs/operators';
import * as FromApp from '@default-application-app/reducers/app.reducer';
import { ArabicLanguage } from '@constants/languages';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent extends UnsubscribeDestroyHelper implements OnInit {
  public isAdmin = false;

  public isRtl: boolean = false;

  private scrollTime: number;

  constructor(
    protected router: Router,
    protected spinner: NgxSpinnerService,
    protected authService: AuthService,
    private store: Store<FromLogout.LogoutState>,
    private autoLogoutPopupService: AutoLogoutPopupService,
  ) {
    super();
    this.subscribeOnLanguage();
  }

  private subscribeOnLanguage(): void {
    this.store
      .pipe(select(FromApp.getSiteLanguage))
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((currentLanguage) => {
        this.isRtl = currentLanguage === ArabicLanguage;
      });
  }

  ngOnInit() {
    this.isAdmin = this.authService.isAdminOrRootUser();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.spinner.show();
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.spinner.hide();
      }
    });

    this.initAutoLogout();
  }

  private initAutoLogout(): void {
    this.scrollTime = Date.now();

    this.store.dispatch(new LogoutActions.ExtendLogoutTimer());
    fromEvent(document.body, 'click')
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(() => this.documentClickListener());

    fromEvent(window, 'scroll')
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(() => this.windowScrollListener());

    fromEvent(window, 'storage')
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((event) => this.windowStorageListener(event));
  }

  private documentClickListener(): void {
    if (!this.autoLogoutPopupService.isPopupShown()) {
      this.store.dispatch(new LogoutActions.ExtendLogoutTimer());
    }
  }

  private windowScrollListener(): void {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (Date.now() - this.scrollTime > 1000) {
      if (!this.autoLogoutPopupService.isPopupShown()) {
        this.scrollTime = Date.now();
        this.store.dispatch(new LogoutActions.ExtendLogoutTimer());
      }
    }
  }

  private windowStorageListener(e): void {
    if (e.key === null && e.newValue === null) {
      document.location.reload();
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    } else if (e.key === 'lastActivity' && parseInt(e.newValue, 10) - parseInt(e.oldValue, 10) > 100) {
      this.store.dispatch(new LogoutActions.ExtendLogoutTimer());
    } else if (e.key === 'autoLogoutPopup' && e.oldValue === 'active' && e.newValue === null) {
      this.autoLogoutPopupService.hidePopup();
    }
  }
}
