<div
  (click)="open()"
  class="group"
  [routerLinkActive]="route ? 'active' : ''"
  [routerLink]="route"
  [routerLinkActiveOptions]="{ exact: false }">
  <div class="group-icon-container" [title]="title">
    <app-svg-icon [iconPath]="iconName" class="group-icon"></app-svg-icon>
  </div>
</div>
