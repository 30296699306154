import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { asyncScheduler, Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  exhaustMap,
  map,
  skip,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';

import { ProfileApiService } from '@services/profile/profile-api.service';
import { ProfileModel } from '@models/profile-model';
import { ErrorHandlerService } from '@services/error-handler.service';
import { CallResponceInterface } from '@interfaces/callResponce.interface';
import { ApiError } from '@models/api-error.model';
import * as FromProfiles from '@default-application-app/components/profiles/reducers';
import { FilterUserGroupInterface } from '@interfaces/filter-user-group.interface';
import { PaginationPageLimitInterface } from '@interfaces/pagination-page-limit.interface';
import { PaginationService } from '@services/pagination/pagination.service';
import { NotificationsServiceWithTranslate } from '@services/translate/notificationsServiceWithTranslate';
import * as ProfileActions from '../actions/profile.actions';

@Injectable()
export class ProfileEffects {
  @Effect()
  searchProfiles$ = ({ debounce = 300, scheduler = asyncScheduler } = {}): Observable<Action> =>
    this.actions$.pipe(
      ofType<ProfileActions.LoadProfiles>(ProfileActions.ProfileActionTypes.LOAD_PROFILES),
      debounceTime(debounce, scheduler),
      withLatestFrom(this.store.pipe(select(FromProfiles.getProfilesFilter))),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(([action, filter]): FilterUserGroupInterface => filter),
      switchMap((filter: FilterUserGroupInterface) => {
        const nextSearch$ = this.actions$.pipe(ofType(ProfileActions.ProfileActionTypes.LOAD_PROFILES), skip(1));

        return this.apiService.apiLoadBlockedProfiles(filter).pipe(
          takeUntil(nextSearch$),
          map(({ data, error }: CallResponceInterface) => {
            if (error) {
              return new ProfileActions.LoadProfilesFailure(<ApiError[]>data);
            }
            const profiles: ProfileModel[] = (<{ items: any[] }>data).items.map((item) => new ProfileModel(item));
            const pagination: PaginationPageLimitInterface = PaginationService.buildPaginationPageLimit(
              data as {
                total_page: number;
                total_record: number;
                page: number;
                limit: number;
              },
            );
            return new ProfileActions.LoadProfilesSuccess(profiles, pagination);
          }),
          catchError((err) => of(new ProfileActions.LoadProfilesFailure(ErrorHandlerService.generateApiErrors(err)))),
        );
      }),
    );

  @Effect()
  createProfile$ = (): Observable<Action> =>
    this.actions$.pipe(
      ofType<ProfileActions.CreateProfile>(ProfileActions.ProfileActionTypes.CREATE_PROFILE),
      map((action) => action.profile),
      exhaustMap((profile) =>
        this.apiService.apiCreateProfile(profile).pipe(
          take(1),
          map(({ data, error }: CallResponceInterface) => {
            if (error) {
              return new ProfileActions.CreateProfileFailure(<ApiError[]>data);
            }
            return new ProfileActions.CreateProfileSuccess(new ProfileModel(data));
          }),
          catchError((err) => of(new ProfileActions.CreateProfileFailure([err]))),
        ),
      ),
    );

  @Effect()
  updateProfile$ = (): Observable<Action> =>
    this.actions$.pipe(
      ofType<ProfileActions.SaveProfile>(ProfileActions.ProfileActionTypes.SAVE_PROFILE),
      map((action) => action.profile),
      exhaustMap((profile) =>
        this.apiService.apiUpdateProfile(profile).pipe(
          take(1),
          map(({ data, error }: CallResponceInterface) => {
            if (error) {
              return new ProfileActions.SaveProfileFailure(<ApiError[]>data);
            }
            return new ProfileActions.SaveProfileSuccess(new ProfileModel(profile));
          }),
          catchError((err) => of(new ProfileActions.SaveProfileFailure([err]))),
        ),
      ),
    );

  @Effect()
  unblockProfiles$ = (): Observable<Action> =>
    this.actions$.pipe(
      ofType<ProfileActions.UnblockProfiles>(ProfileActions.ProfileActionTypes.UNBLOCK_PROFILES),
      map((action): { uid: string }[] => action.profiles),
      exhaustMap((profiles: { uid: string }[]) =>
        this.apiService.apiUnblockProfiles(profiles).pipe(
          take(1),
          map(({ data, error }: CallResponceInterface) => {
            if (error) {
              this.notificationService.error('common.notifications', 'blocked_user_profiles', 'something_went_wrong');

              return new ProfileActions.UnblockProfilesFailure(<ApiError[]>data);
            }
            this.notificationService.success('common.notifications', 'blocked_user_profiles', 'successfully_unblocked');
            return new ProfileActions.LoadProfiles();
          }),
          catchError((err) => of(new ProfileActions.UnblockProfilesFailure([err]))),
        ),
      ),
    );

  constructor(
    private actions$: Actions,
    private store: Store<FromProfiles.State>,
    private apiService: ProfileApiService,
    private notificationService: NotificationsServiceWithTranslate,
  ) {}
}
