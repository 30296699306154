import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-filters-button',
  templateUrl: './filters-button.component.html',
  styleUrls: ['./filters-button.component.scss'],
})
export class FiltersButtonComponent {
  @Input()
  public isActive: boolean = false;
}
