export const VALIDATION_PROPERTY = {
  PASSWORD_MIN_LENGTH: 8,
  PASSWORD_MAX_LENGTH: 64,
  LOCAL_PART_EMAIL_MAX_LENGTH: 64,
  DOMAIN_EMAIL_MAX_LENGTH: 255,
  IBAN_MAX_LENGTH: 64,
  PHONE_NUMBER_MIN_LENGTH: 8,
  PHONE_NUMBER_MAX_LENGTH: 15,
  TRANSFER_DESCRIPTIONS_LENGTH: 255,
  MAX_DESCRIPTIONS_LENGTH: 255,
  MAX_MESSAGE_LENGTH: 60000,
  DIGITAL_BEFORE_COMMA: 18,
  DIGITAL_AFTER_COMMA: 18,
  MIN_INPUT_LENGTH: 2,
  MAX_INPUT_LENGTH: 255,
  FILE_SIZE_LIMIT_MB: 1048576, // 1024 * 1024,
};
