import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { BreadcrumbsService } from '@services/breadcrums/breadcrumbs.service';
import { UnsubscribeDestroyHelper } from '@helpers/unsubscribe-destroy.helper';

interface ICrumb {
  label: string;
  params: any;
  url: string;
}

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent extends UnsubscribeDestroyHelper implements OnInit {
  public anotherBreadcrumb: string = '';

  public hideBreadcrumb: boolean = false;

  private crumbs: ICrumb[];

  constructor(private router: Router, private route: ActivatedRoute, private breadcrumbsService: BreadcrumbsService) {
    super();
  }

  ngOnInit(): void {
    this.crumbs = this.getBreadcrumbs(this.route.root);

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const { root } = this.route;
      this.crumbs = this.getBreadcrumbs(root);
    });

    this.breadcrumbsService
      .getBreadcrumb()
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((data: string) => {
        this.anotherBreadcrumb = data;

        this.hideBreadcrumb = typeof data === 'string' && data.length === 0;
      });
  }

  public get breadcrumbs(): ICrumb[] {
    if (this.crumbs && this.crumbs.length === 1 && !this.crumbs[0].url) {
      return [{ ...this.crumbs[0], url: '../' }];
    }

    return this.crumbs ? this.crumbs.filter((crumb: ICrumb) => crumb.label) : [];
  }

  // eslint-disable-next-line consistent-return
  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: ICrumb[] = []): ICrumb[] {
    const { children } = route;

    if (children.length === 0) {
      return breadcrumbs;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const child of children) {
      if (child.outlet !== PRIMARY_OUTLET) {
        // eslint-disable-next-line no-continue
        continue;
      }

      // eslint-disable-next-line no-prototype-builtins
      if (!child.snapshot.data.hasOwnProperty('crumbs')) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');

      if (routeURL) {
        url += `/${routeURL}`;
      }

      breadcrumbs[0] = {
        label: child.snapshot.data.crumbs,
        params: child.snapshot.params,
        url,
      };

      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
  }
}
