import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import { ProfileService } from '@services/profile/profile.service';
import { AcceptDocumentsModalComponent } from '@default-application-app/modules/auth/components/accept-documents-modal/accept-documents-modal.component';
import { CallResponceInterface } from '@interfaces/callResponce.interface';
import { LegalDocumentsApiService } from '@services/legal-documents/legal-documents-api.service';

@Injectable({
  providedIn: 'root',
})
export class LegalDocumentsService {
  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private profileService: ProfileService,
    private api: LegalDocumentsApiService,
  ) {}

  private showAcceptDocumentsModal(): void {
    this.dialog.open(AcceptDocumentsModalComponent);
  }

  public checkAcceptingLegalDocument(): void {
    if (
      (this.authService.isIndividualUser() || this.authService.isBusinessUser()) &&
      !this.profileService.profile.isLegalDocsAccepted
    ) {
      this.showAcceptDocumentsModal();
    }
  }

  public acceptTaC(uid: string): Observable<CallResponceInterface> {
    return this.api.apiAcceptTaC(uid);
  }

  public acceptPrivacyPolicy(uid: string): Observable<CallResponceInterface> {
    return this.api.apiAcceptPrivacyPolicy(uid);
  }
}
