import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as DOMPurify from 'dompurify';

@Pipe({
  name: 'sanitizeHtml',
})
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  transform(value: string | null): SafeHtml | null {
    return this.domSanitizer.bypassSecurityTrustHtml(DOMPurify.sanitize(value, { USE_PROFILES: { html: true } }));
  }
}
