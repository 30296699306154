import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { AuthService } from '@default-application-app/core/services/auth/auth.service';
import packageInfo from '../../../../../../package.json';

@Injectable()
export class EndpointInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.authService.deviceId) {
      this.authService.setDeviceUuid();
    }
    if (!this.authService.sessionId) {
      this.authService.setWebSessionId();
    }

    const headers = req.headers
      .set('V-Client-Request-Id', uuidv4())
      .set('V-Client-Version-Name', packageInfo.version)
      .set('V-Client-Name', packageInfo.name)
      .set('V-Client-Session-Id', this.authService.sessionId)
      .set('V-Client-Device-Id', this.authService.deviceId);

    const updatedReq = req.clone({ headers });

    return next.handle(updatedReq);
  }
}
