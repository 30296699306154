import { Component, Input } from '@angular/core';
import { PrintTableInterface, PrintTablesInterface } from '@shared-modules/print/interfaces';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
})
export class PrintComponent {
  @Input() table: PrintTableInterface;

  @Input() tables: PrintTablesInterface;

  @Input() blocks: PrintTablesInterface;

  @Input() header: string;

  public logoLink: string;

  public constructor(private sanitizer: DomSanitizer) {
    this.logoLink = `${window.location.protocol}//${window.location.host}/assets/images/logo/logo-full.svg`;
  }

  public sanitizeStyle(unsafeStyle: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(unsafeStyle);
  }
}
