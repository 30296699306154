import { Injectable } from '@angular/core';
import { TokenInterface } from '@default-application-app/core/interfaces/token-interface';
import { StorageService } from '@default-application-app/core/services/storage.service';
import { TEMP_AUTH_HEADER_NAME } from '@default-application-app/core/constants/signUp';
import { TEMP_TOKEN_NAME, TOKEN_NAME } from '@default-application-app/core/constants/token-name';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Roles } from '@enums/roles.enum';

@Injectable()
export class TokenService {
  constructor(private storage: StorageService, private helper: JwtHelperService) {}

  public saveTokenFromResponse(response: { data: { accessToken: string; refreshToken: string } }): void {
    if (!response.data || !response.data.accessToken || !response.data.refreshToken) {
      throw new Error('Access or refresh token is not found in the response');
    }
    const token: TokenInterface = {
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken,
    };
    this.saveToken(token);
  }

  public saveToken(token: { accessToken: string; refreshToken?: string; temporaryAccessToken?: string }) {
    if (token?.temporaryAccessToken) {
      this.storage.setItem(TOKEN_NAME, JSON.stringify({ accessToken: token.temporaryAccessToken }));
      return;
    }

    if (!token.accessToken) {
      throw new Error('Access token is not found');
    }

    this.storage.setItem(TOKEN_NAME, JSON.stringify(token));
  }

  public saveTempAuthToken(token: string): void {
    if (!token) {
      throw new Error(`${TEMP_AUTH_HEADER_NAME}token is not found`);
    }
    this.storage.setItem(TEMP_TOKEN_NAME, JSON.stringify(token));
  }

  public getTempAuthToken(): string | null {
    return this.isTempAuthTokenStored() ? JSON.parse(this.storage.getItem(TEMP_TOKEN_NAME)) : null;
  }

  public removeTempAuthToken(): void {
    this.storage.removeItem(TEMP_TOKEN_NAME);
  }

  public getUserRoleFromTempAuthToken(): Roles {
    const decodedToken = this.decodeToken(this.getTempAuthToken());
    return decodedToken?.roleName as Roles;
  }

  public isTempAuthTokenStored(): boolean {
    const token = this.storage.getItem(TEMP_TOKEN_NAME);
    return !!JSON.parse(token);
  }

  public removeToken(): void {
    const language = this.storage.getItem('language');
    this.storage.clear();

    if (language) {
      this.storage.setItem('language', language);
    }
  }

  public getToken(): TokenInterface | null {
    return this.isTokenStored() ? JSON.parse(this.storage.getItem(TOKEN_NAME)) : null;
  }

  public isTokenStored(): boolean {
    const token = this.storage.getItem(TOKEN_NAME);
    return !!token && JSON.parse(token).accessToken && JSON.parse(token).refreshToken;
  }

  public isAccessTokenStored(): boolean {
    const token = this.storage.getItem(TOKEN_NAME);
    return !!token && JSON.parse(token).accessToken;
  }

  private decodeToken(token: string): { [key: string]: string } {
    return this.helper.decodeToken(token);
  }
}
