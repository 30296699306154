import { Action } from '@ngrx/store';
import { PaginationPageLimitInterface } from '@interfaces/pagination-page-limit.interface';
import { CreateProfileRequest } from '@request-models/create-profile-request';
import { ApiError } from '@models/api-error.model';
import { ProfileFilter } from '@interfaces/profile-filter-interface';
import { ProfileModel } from '@models/profile-model';

export enum ProfileActionTypes {
  CREATE_PROFILE = '[User profiles] Create profile',
  SAVE_PROFILE = '[User profiles] Save profile',
  CREATE_PROFILE_SUCCESS = '[User profiles/API] create Success',
  SAVE_PROFILE_SUCCESS = '[User profiles/API] save Success',
  CREATE_PROFILE_FAILURE = '[User profiles/API] create Failure',
  SAVE_PROFILE_FAILURE = '[User profiles/API] save Failure',
  RESET_SAVED_STATE = '[User profiles] reset saved',
  LOAD_PROFILES = '[User profiles] Load profiles',
  LOAD_PROFILES_SUCCESS = '[User profiles/API] load profiles Success',
  LOAD_PROFILES_FAILURE = '[User profiles/API] load profiles Failure',
  UNBLOCK_PROFILES = '[User profiles] unblock profiles',
  UNBLOCK_PROFILES_FAILURE = '[User profiles/API] unblock Failure',
  TOGGLE_CHECKED = '[User profiles] toggle checked',
}

export class CreateProfile implements Action {
  readonly type = ProfileActionTypes.CREATE_PROFILE;

  constructor(public profile: ProfileModel | CreateProfileRequest) {}
}

export class SaveProfile implements Action {
  readonly type = ProfileActionTypes.SAVE_PROFILE;

  constructor(public profile: ProfileModel | CreateProfileRequest) {}
}

export class CreateProfileSuccess implements Action {
  readonly type = ProfileActionTypes.CREATE_PROFILE_SUCCESS;

  constructor(public profile: ProfileModel) {}
}

export class SaveProfileSuccess implements Action {
  readonly type = ProfileActionTypes.SAVE_PROFILE_SUCCESS;

  constructor(public profile: ProfileModel) {}
}

export class CreateProfileFailure implements Action {
  readonly type = ProfileActionTypes.CREATE_PROFILE_FAILURE;

  constructor(public errors: any[]) {}
}

export class SaveProfileFailure implements Action {
  readonly type = ProfileActionTypes.SAVE_PROFILE_FAILURE;

  constructor(public errors: any[]) {}
}

export class ResetSavedState implements Action {
  readonly type = ProfileActionTypes.RESET_SAVED_STATE;
}

export class LoadProfiles implements Action {
  readonly type = ProfileActionTypes.LOAD_PROFILES;

  constructor(public filter?: ProfileFilter) {}
}

export class LoadProfilesSuccess implements Action {
  readonly type = ProfileActionTypes.LOAD_PROFILES_SUCCESS;

  constructor(public profiles: ProfileModel[], public pagination?: PaginationPageLimitInterface) {}
}

export class LoadProfilesFailure implements Action {
  readonly type = ProfileActionTypes.LOAD_PROFILES_FAILURE;

  constructor(public errors: ApiError[]) {}
}

export class UnblockProfiles implements Action {
  readonly type = ProfileActionTypes.UNBLOCK_PROFILES;

  constructor(public profiles: { uid: string }[]) {}
}

export class UnblockProfilesFailure implements Action {
  readonly type = ProfileActionTypes.UNBLOCK_PROFILES_FAILURE;

  constructor(public errors: ApiError[]) {}
}

export class ToggleChecked implements Action {
  readonly type = ProfileActionTypes.TOGGLE_CHECKED;

  constructor(public profile: ProfileModel, public checked: boolean) {}
}

export type ProfileActionsUnion =
  | CreateProfile
  | SaveProfile
  | CreateProfileSuccess
  | SaveProfileSuccess
  | CreateProfileFailure
  | SaveProfileFailure
  | ResetSavedState
  | LoadProfiles
  | LoadProfilesSuccess
  | LoadProfilesFailure
  | UnblockProfiles
  | UnblockProfilesFailure
  | ToggleChecked;
