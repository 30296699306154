import { Pipe, PipeTransform } from '@angular/core';
import { UserForHumanId } from '@default-application-app/core/interfaces/user-interface';
import { getHumanId } from '@default-application-app/core/helpers/get-human-user-id.helper';

@Pipe({
  name: 'returnHumanId',
})
export class ReturnHumanIdPipe implements PipeTransform {
  transform(user: UserForHumanId): string {
    return getHumanId(user);
  }
}
