<div class="currency-container">
  <ng-container [formGroup]="form">
    <ng-select
      (ngModelChange)="change.emit($event)"
      [bindLabel]="bindLabel"
      [bindValue]="bindValue"
      [clearable]="clearable"
      [formControlName]="controlName"
      [groupBy]="groupBy"
      [items]="items"
      [loading]="loading"
      [placeholder]="placeholder"
      [searchable]="searchable"
      [title]="title"
      [typeahead]="typeahead"
      class="sort-select material-icons select-currency"
      [class.gray-color-select-value]="isAllCurrenciesTitle()">
      <ng-template let-item="item" ng-optgroup-tmp> &#8213; </ng-template>

      <ng-container>
        <ng-template ng-label-tmp let-item="item">
          {{ item?.key ? item.value : (item.value | translate) }}
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          {{ item?.key ? item.value : (item.value | translate) }}
        </ng-template>
      </ng-container>
    </ng-select>
  </ng-container>
</div>
