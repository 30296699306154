import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropDownMenuComponent } from '@default-application-app/modules/components/drop-down-menu/drop-down-menu.component';
import { SharedComponentsModule } from '@default-application-app/modules/shared-components/shared-components.module';
import { ButtonsModule } from '@default-application-app/modules/components/buttons/buttons.module';
import { SvgIconModule } from '@default-application-app/modules/components/svg-icon/svg-icon.module';
import { TableDropDownMenuComponent } from './table-drop-down-menu/table-drop-down-menu.component';

@NgModule({
  declarations: [DropDownMenuComponent, TableDropDownMenuComponent],
  imports: [CommonModule, SharedComponentsModule, ButtonsModule, SvgIconModule],
  exports: [DropDownMenuComponent, TableDropDownMenuComponent],
})
export class DropDownMenuModule {}
