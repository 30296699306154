<app-modal-wrapper
  [title]="'pages.group_transfers.upload_sub_transfer' | translate"
  [titleIconName]="'representative'">
  <form [formGroup]="uploadTransferForm">
    <div class="modal-content">
      <div class="line">
        <!-- Upload Button Container -->
        <div class="upload-container">
          <input
            type="file"
            id="csvUpload"
            accept=".csv"
            (change)="onFileSelected($event)"
            hidden />
          <app-button
            class="button"
            [appearance]="'primary'"
            (btnClick)="triggerFileInput()"
            [buttonText]="selectedFile ? selectedFile.name : ('common.buttons.choose_file' | translate)">
          </app-button>
        </div>

        <!-- Download Button Container (aligned to the right) -->
        <div class="download-container">
          <app-csv-template></app-csv-template>
        </div>
      </div>
    </div>

    <div class="footer">
      <app-button
        class="button"
        [appearance]="'primary-outline'"
        (btnClick)="closeDialog()"
        [buttonText]="'common.buttons.cancel' | translate">
      </app-button>
      <app-button
        class="button"
        [type]="'submit'"
        (btnClick)="uploadSubTransfer()"
        [isDisabled]="!selectedFile"
        [buttonText]="'common.buttons.save' | translate">
      </app-button>
    </div>
  </form>
</app-modal-wrapper>
