import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateSelectItems',
})
export class TranslateSelectItemsPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(items: any, prefix = 'select-items', key = 'key', value = 'value', param = 'param'): any {
    const translatedItems = JSON.parse(JSON.stringify(items));
    if (!items || !items.length) {
      return translatedItems;
    }
    if (typeof prefix !== 'string') {
      prefix = 'select-items';
    }
    if (prefix === '') {
      return translatedItems.map((item) => {
        item[value] = this.translate.instant(item[value]);
        return item;
      });
    }

    return translatedItems.map((item) => {
      item[value] = this.translate.instant(`${prefix}.${item[value]}`, item[param]);
      return item;
    });
  }
}
