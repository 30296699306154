import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseErrorInterface } from '@default-application-app/core/interfaces/baseError.interface';
import { combineLatest, from, Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import {
  MustShowDetailsErrorCodesWithoutTranslate,
  MustShowTitleErrorCodes,
} from '@default-application-app/core/constants/errorCodes';

@Injectable()
export class ErrorMessageTranslationService {
  private static translateMessage({
    code,
  }: {
    code: string;
    target?: string;
    source?: string;
    title?: string;
    details?: string;
  }): string {
    // Return default error message by error code
    return `errors.${code}`;
  }

  constructor(private translate: TranslateService) {}

  public translateErrors(error: BaseErrorInterface[]): Observable<string> {
    return combineLatest(error.map((err): Observable<string> => this.translateError(err))).pipe(
      first(),
      map((values: string[]) => values.join('<br>')),
    );
  }

  public handleError(error: BaseErrorInterface): Observable<string> {
    if (!MustShowDetailsErrorCodesWithoutTranslate.includes(error.code)) {
      return this.translateError(error);
    }
    return of(error.details);
  }

  private translateError(error: BaseErrorInterface): Observable<string> {
    if (MustShowTitleErrorCodes.includes(error.code)) {
      return from([error.title]);
    }
    return this.translate.get(ErrorMessageTranslationService.translateMessage(error), error.meta).pipe(first());
  }

  public translateText(phrase: string): string {
    return this.translate.instant(phrase);
  }
}
