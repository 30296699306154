import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent implements OnInit {
  @Output() public search: EventEmitter<any> = new EventEmitter<any>();

  @Input() form: UntypedFormGroup;

  @Input() searchable = false;

  @Input() isMultiple = false;

  @Input() clearable = false;

  @Input() placeholder = '';

  @Input() label = '';

  @Input() items: any[];

  @Input() bindValue: string;

  @Input() bindLabel: string;

  @Input() controlName: string;

  @Input() formControlName: string;

  @Input() title: string = '';

  @Input() loading: boolean;

  @Input() requiredIf: boolean;

  @Input() typeahead: Subject<any>;

  @Input() groupBy: string;

  @Input() translatePrefix: string;

  @Input() isNgSelectClass: boolean;

  @Input() isSelectMonthClass: boolean;

  @Input() isNativeSelectClass: boolean;

  @Input() isSearchableClass: boolean;

  @Input() isSearchableAddressClass: boolean;

  @Input() tooltipMessage: string;

  @Input() readonly: boolean;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter();

  ngOnInit(): void {
    this.form.addControl(this.controlName, new UntypedFormControl(null));
  }

  public getTranslateParams(item: any): { [key: string]: string } {
    if (item?.param) {
      const params = {};

      Object.keys(item.param).forEach((field: string) => {
        params[field] = item.param[field];
      });

      return params;
    }

    return {};
  }

  public isSelected(value): boolean {
    if (value && this.form.get(this.controlName).value && this.form.get(this.controlName).value.length) {
      return this.form.get(this.controlName).value.find((item) => item === value);
    }

    return false;
  }

  public isLastElement(key): boolean {
    if (key && this.form.get(this.controlName).value && this.form.get(this.controlName).value.length) {
      const len: number = this.form.get(this.controlName).value.length;
      return this.form.get(this.controlName).value[len - 1] === key;
    }

    return false;
  }
}
