<div class="table-content">
  <div *ngIf="!showRemoveBtn || (showRemoveBtn && !file)" class="drag-cub">
    <ngx-file-drop (onFileDrop)="dropped($event)" contentClassName="ngx-file-drop">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <div [class.need-line]="file" class="header-cub">
          <div (click)="upload.click(); onInputBlur()" class="line sub-heading">
            {{ prefix + title | translate }}

            <span class="success main_color">{{ 'common.drag_and_drop.browse' | translate }}</span>

            {{ 'common.drag_and_drop.to_upload' | translate }}
          </div>

          <input
            #upload
            (change)="uploadFile($event); upload.value = null"
            [accept]="inputAccept"
            hidden
            [multiple]="multipleFiles"
            type="file" />
          <div class="other-text">
            {{ description }}
          </div>
          <div class="other-text">
            {{
              'common.drag_and_drop.allowed_file_description'
                | translate: { extensions: extensionView.toUpperCase(), maxSize: formatBytes(maxSize) }
            }}
          </div>
        </div>
      </ng-template>
    </ngx-file-drop>
  </div>

  <div *ngIf="file && uploaded" class="footer-cub">
    <span class="icon success background_color-main_color"></span>

    <span [innerHTML]="'common.drag_and_drop.file_has_been_uploaded' | translate: { fileName: file.name }"> </span>

    <div *ngIf="showRemoveBtn" class="btn-container margin-left">
      <div (click)="removeFile()" class="basket" [title]="'common.drag_and_drop.delete_uploaded_file' | translate">
        <i class="zmdi zmdi-delete"></i>
      </div>
    </div>
  </div>

  <ng-container *ngIf="drawPreview">
    <div [class.hidden-block]="!uploaded" class="upload-table">
      <table class="table-gallery">
        <div class="dnd-gallery" id="gallery"></div>
      </table>
    </div>
  </ng-container>
</div>
