import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppOptionsService } from '@default-application-app/core/services/appOptions.service';
import { OptionsValuesNames } from '@default-application-app/core/constants/optionsPrefixes';
import { filter, map, tap } from 'rxjs/operators';

@Injectable()
export class AccountsCardsGuard implements CanActivate {
  constructor(private appOptionService: AppOptionsService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.appOptionService.getOptionsValuePipe(OptionsValuesNames.CARD_MODULE).pipe(
      filter((value: string): boolean => !!value),
      map((value: string): boolean => value === 'enable'),
      tap((enabled: boolean) => {
        if (!enabled) {
          this.router.navigate(['not-found']);
        }
      }),
    );
  }
}
