import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationsService } from 'angular2-notifications';
import { ErrorHandlerService } from '@default-application-app/core/services/error-handler.service';
import { ErrorTargets } from '@default-application-app/core/constants/errorTargets';
import { ApiError } from '@default-application-app/core/models/api-error.model';
import { ErrorMessageTranslationService } from '@default-application-app/core/services/translate/errorMessageTranslation.service';
import { filter, first, mergeMap, map } from 'rxjs/operators';
import { ErrorCodes, SkipNotificationErrorCodes } from '@default-application-app/core/constants/errorCodes';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ConfigService } from '@default-application-app/config.service';

@Injectable()
export class ApiErrorService implements OnDestroy {
  public errorsFormSubject: Subject<boolean> = new Subject<boolean>();

  private errorsSubject: Subject<ApiError[]> = new Subject<ApiError[]>();

  constructor(
    private notificationService: NotificationsService,
    private translateService: ErrorMessageTranslationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private configService: ConfigService,
  ) {
    this.showCommonErrors();
  }

  public get errorsPipe(): Observable<ApiError[]> {
    return this.errorsSubject.asObservable();
  }

  ngOnDestroy() {
    this.errorsSubject.complete();
    this.errorsFormSubject.complete();
  }

  public handleErrorResponse(res: HttpErrorResponse): void {
    let errors: ApiError[] = [];
    this.spinner.hide();
    if (res && res.error && res.error.issues) {
      const ibanError = res.error.issues['iban-number'] && res.error.issues['iban-number'][1] === ErrorCodes.INVALID_IBAN;
      if (ibanError) {
        // convert IBAN error to api error
        const invalidIbanError = new ApiError({
          title: 'Invalid IBAN',
          details: this.translateService.translateText('errors.invalid_iban'),
          source: 'iban-number',
          code: ErrorCodes.INVALID_IBAN,
          target: ErrorTargets.COMMON,
          httpResponse: res,
        });

        errors.push(invalidIbanError);
      } else {
        errors = ErrorHandlerService.generateApiErrors(res.error.issues, res);
      }

      this.errorsSubject.next(errors);

    } else if (res && res.error && res.error.errors) {
      errors = ErrorHandlerService.generateApiErrors(res.error.errors, res);
      this.errorsSubject.next(errors);
      if (errors.some((error: ApiError) => error.target === ErrorTargets.FIELD)) {
        this.notificationService.error(
          this.translateService.translateText('errors.error'),
          this.translateService.translateText('errors.form_contains_errors'),
        );
        this.errorsFormSubject.next(true);
      }
    }
    else if (res && res.status >= 500) {
      this.notificationService.error(
        this.translateService.translateText('errors.error'),
        this.translateService.translateText('errors.page_is_unavailable')
      );
    } else {
      this.notificationService.error(
        this.translateService.translateText('errors.error'),
        this.translateService.translateText('errors.something_went_wrong')
      );
    }
  }


  public handleSuccessResponse(): void {
    this.errorsSubject.next([]);
  }

  private showCommonErrors(): void {
    this.errorsPipe
      .pipe(
        map((errors: ApiError[]): ApiError[] =>
          errors
            .filter(
              (error: ApiError): boolean =>
                error.target === ErrorTargets.COMMON && !SkipNotificationErrorCodes.includes(error.code),
            )
            .filter((error: ApiError): boolean => this.skipErrorNotification(error)),
        ),
        filter((errors: ApiError[]): boolean => !!errors.length),
        mergeMap(
          (errors: ApiError[]): Observable<string[]> =>
            combineLatest(errors.map((error) => this.translateService.handleError(error))).pipe(first()),
        ),
      )
      .subscribe((errorMessages: string[]): void => {
        errorMessages
          .filter((message: string) => message !== 'errors.')
          .forEach((message: string) =>
            this.notificationService.error(this.translateService.translateText('errors.error'), message),
          );
      });
  }

  private skipErrorNotification(error: ApiError): boolean {
    return !(
      (error?.httpResponse?.url?.includes(this.configService.config.api.kyc.updateLevel) &&
        [ErrorCodes.REQUEST_IS_APPROVED, ErrorCodes.REQUEST_IS_PENDING, ErrorCodes.REQUIREMENTS_FILLED].includes(
          error.code as ErrorCodes,
        )) ||
      (error?.httpResponse?.url?.includes(this.configService.config.api.cash.cashIn) &&
        ErrorCodes.RECIPIENT_LIMIT_EXCEEDED === (error.code as ErrorCodes)) ||
      (error?.httpResponse?.url?.includes(this.configService.config.api.auth.resetPassword) &&
        ErrorCodes.INVALID_CONFIRMATION_CODE === (error.code as ErrorCodes)) ||
      (error?.httpResponse?.url?.includes(this.configService.config.api.account.tbuRequest) &&
        ErrorCodes.OTP_IS_INVALID === (error.code as ErrorCodes)) ||
      (error?.httpResponse?.url?.includes(this.configService.config.api.account.tbuRequest) &&
        ErrorCodes.OTP_IS_INVALID === (error.code as ErrorCodes)) ||
      ErrorCodes.OTP_TOO_MANY_ATTEMPTS === (error.code as ErrorCodes)
    );
  }
}
