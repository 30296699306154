import {
  KycTierInterface,
  KycTierRequirementsInterface,
  LimitationInterface,
  RequestInterface,
} from '@interfaces/kyc/kyc-tier.interface';
import { KycStatus } from '@enums/kyc-statuses';

export class KycTierModel implements KycTierInterface {
  public id: number;

  public level: number;

  public name: string;

  public currentLvl?: number;

  public status: KycStatus;

  public role?: string;

  public requestId: string;

  public requests?: RequestInterface[];

  public limitations?: LimitationInterface[];

  public limits?: LimitationInterface[];

  public requirements?: KycTierRequirementsInterface[];

  constructor(data: KycTierInterface) {
    Object.assign(this, data);
  }
}
