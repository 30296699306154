import { Observable } from 'rxjs';

export function once<T>(next?: (x: T) => void) {
  let init = false;
  return function mySimpleOperatorImplementation(source) {
    return new Observable((subscriber) =>
      source.subscribe(
        (value) => {
          if (!init) {
            next(value);
            init = true;
          }
          subscriber.next(value);
        },
        (err) => subscriber.error(err),
        () => subscriber.complete(),
      ),
    );
  };
}
