import { adminGroups, businessUserGroups, corporateUserGroups, ibanAdminGroups, individualsUserGroups } from '@layouts/aside-menu/group';
import { Injectable } from '@angular/core';
import { AuthService } from '@default-application-app/core/services/auth/auth.service';
import { IAsideMenuGroup } from '@layouts/aside-menu/aside-menu-group/aside-menu-group.component';
import { Router } from '@angular/router';
import { MenuGroupLabel } from '@layouts/aside-menu/menu-groupe-label';
import { Roles } from '@enums/roles.enum';

@Injectable()
export class AsideMenuGroupService {
  public groups: IAsideMenuGroup[];

  constructor(private auth: AuthService, private router: Router) {
    this.groups = null;

    switch (this.auth.getUserRole()) {
      case Roles.Root:
      case Roles.Admin:
        this.groups = [...adminGroups];
        break;
      case Roles.IbanAdmin:
        this.groups = [...ibanAdminGroups];
      case Roles.BusinessCorporate:
        this.groups = [...corporateUserGroups];
        break;
      case Roles.BusinessEnterprise:
        this.groups = [...businessUserGroups];
        break;
      case Roles.Client:
        this.groups = [...individualsUserGroups];
        break;
    }

    if (this.auth.isAdminOrRootUser()) {
      this.groups = null;
      this.groups = JSON.parse(JSON.stringify([...adminGroups]));

      const extraItems = [
        { title: 'security', route: '/settings/security' },
        { title: 'system_config', route: '/settings/system-config' },
        { title: 'system_emails', route: '/settings/system-email' },
        { title: 'system_sms', route: '/settings/system-sms' },
        { title: 'push_notifications', route: '/settings/push-notifications' },
        { title: 'root', route: '/settings/root/config' },
      ];

      this.groups
        .find((group: IAsideMenuGroup) => group.label === MenuGroupLabel.Settings)
        .subItems.push(...extraItems);
    }

    if (this.auth.isIbanAdmin()) {
      this.groups = null;
      this.groups = JSON.parse(JSON.stringify([...ibanAdminGroups]));
    }
  }

  public getGroups(): IAsideMenuGroup[] {
    return this.groups;
  }

  public getActiveGroups(): IAsideMenuGroup {
    return this.groups.find((group: IAsideMenuGroup) => this.router.url.includes(group.route));
  }
}
