import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DROP_DOWN_MENU_CONFIG } from '@default-application-app/modules/components/constants';
import { DropdownItem } from '@default-application-app/modules/components/interfaces/dropdown-item';

@Component({
  selector: 'app-drop-down-menu',
  templateUrl: './drop-down-menu.component.html',
  styleUrls: ['./drop-down-menu.component.scss'],
})
export class DropDownMenuComponent {
  @Output()
  public emitItemClick: EventEmitter<DropdownItem> = new EventEmitter();

  @Input()
  public menuItems: DropdownItem[] = [DROP_DOWN_MENU_CONFIG.print, DROP_DOWN_MENU_CONFIG.download];

  @Input()
  public tableView: boolean = false;

  @Input()
  public largeIcon: boolean = false;

  public isOpenMenu: boolean = false;

  public closeMenu(): void {
    this.isOpenMenu = false;
  }

  public onItemClick(item: DropdownItem): void {
    this.emitItemClick.emit(item);
    this.isOpenMenu = false;
  }
}
