import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { UserGroupActions } from '@default-application-app/components/profiles/actions';
import { UserGroup } from '@models/user-group-model';
import { FilterUserGroupInterface } from '@interfaces/filter-user-group.interface';
import { UserGroupTypes } from '@default-application-app/components/profiles/actions/user-group.actions';
import { PaginationPageLimitInterface } from '@interfaces/pagination-page-limit.interface';
import { PaginationService } from '@services/pagination/pagination.service';
import { ApiError } from '@models/api-error.model';

export interface State extends EntityState<UserGroup> {
  loading: boolean;
  saved: boolean;
  pagination: PaginationPageLimitInterface;
  filter: FilterUserGroupInterface;
  currentUserGroupId: number | null;
  errors: ApiError[];
  deleted?: boolean;
}

export const adapter: EntityAdapter<UserGroup> = createEntityAdapter<UserGroup>({});

export const defaultUserGroupFilter: FilterUserGroupInterface = {
  page: '1',
  limit: PaginationService.defaultLimit.toString(),
  sort: 'name',
};

export const initialState: State = adapter.getInitialState({
  loading: false,
  saved: false,
  pagination: PaginationService.defaultPaginationPageLimit,
  filter: { ...defaultUserGroupFilter },
  currentUserGroupId: null,
  errors: [],
});
// eslint-disable-next-line @typescript-eslint/default-param-last
export function reducer(state = initialState, action: UserGroupActions.UserGroupsActionsUnion): State {
  switch (action.type) {
    case UserGroupTypes.SELECT_USER_GROUP:
      return {
        ...state,
        currentUserGroupId: action.id,
        loading: false,
      };
    case UserGroupTypes.LOAD_USER_GROUP:
      return {
        ...state,
        loading: true,
        errors: [],
      };
    case UserGroupTypes.SEARCH_USER_GROUPS:
      return {
        ...state,
        loading: true,
        filter: action.filter ? { ...action.filter } : { ...defaultUserGroupFilter },
      };
    case UserGroupTypes.SEARCH_USER_SUCCESS:
      return {
        ...adapter.setAll(action.userGroups, state),
        pagination: { ...(action.pagination || PaginationService.defaultPaginationPageLimit) },
        loading: false,
      };
    case UserGroupTypes.LOAD_USER_GROUP_SUCCESS:
      return {
        ...adapter.upsertOne(action.userGroup, state),
        loading: false,
      };
    case UserGroupTypes.LOAD_USER_GROUP_FAILURE:
    case UserGroupTypes.SEARCH_USER_FAILURE:
      return {
        ...state,
        pagination: { ...PaginationService.defaultPaginationPageLimit },
        errors: action.errors,
        loading: false,
        currentUserGroupId: null,
      };
    case UserGroupTypes.CREATE_USER_GROUP:
    case UserGroupTypes.SAVE_USER_GROUP:
      return {
        ...state,
        loading: true,
        saved: false,
        deleted: false,
        errors: [],
      };
    case UserGroupTypes.CREATE_USER_GROUP_SUCCESS:
      return {
        ...adapter.addOne(action.userGroup, state),
        loading: false,
        saved: true,
        deleted: false,
      };
    case UserGroupTypes.SAVE_USER_GROUP_SUCCESS:
      return {
        ...adapter.updateOne(
          {
            id: action.userGroup.id,
            changes: action.userGroup,
          },
          state,
        ),
        loading: false,
        saved: true,
        deleted: false,
      };
    case UserGroupTypes.SAVE_USER_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        saved: false,
        deleted: false,
        errors: action.errors,
      };
    case UserGroupTypes.RESET_SAVED_STATE:
      return {
        ...state,
        saved: false,
        deleted: false,
        errors: [],
      };
    case UserGroupTypes.DELETE_USER_GROUP:
      return {
        ...state,
        loading: true,
        saved: false,
        deleted: false,
        errors: [],
      };
    case UserGroupTypes.DELETE_USER_GROUP_SUCCESS:
      return {
        ...adapter.removeOne(action.id, state),
        loading: false,
        saved: false,
        deleted: true,
        errors: [],
      };
    default: {
      return state;
    }
  }
}

const { selectAll, selectEntities } = adapter.getSelectors();

export const selectAllUserGroups = selectAll;

export const selectUserGroupEntities = selectEntities;

export const getUserGroupLoading = (state: State) => state.loading;

export const getUserGroupFilter = (state: State) => state.filter;

export const getUserGroupPagination = (state: State) => state.pagination;

export const getCurrentUserGroupId = (state: State) => state.currentUserGroupId;

export const getUserGroupSaved = (state: State) => state.saved;

export const getUserGroupDeleted = (state: State) => state.deleted;

export const getUserGroupErrors = (state: State) => state.errors;
