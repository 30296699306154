import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-items-per-page',
  templateUrl: './items-per-page.component.html',
})
export class ItemsPerPageComponent {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  @Input() public perPage = 10;

  @Output() changePerPage: EventEmitter<number> = new EventEmitter<number>();

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  public itemsCount = [10, 25, 50, 100];

  public selectCount(count: number): void {
    this.changePerPage.emit(count);
  }
}
