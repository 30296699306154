import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiError } from '@models/api-error.model';
import { FileModel } from '@models/file-model';
import { CallResponceInterface } from '@interfaces/callResponce.interface';
import { ProfileSettingsFileApiService } from '@default-application-app/modules/shared/profile-form/services/profile-settings-file-api.service';
import { FileService } from '@default-application-app/modules/shared/file-service/services/file.service';
import { queryParamsStringify } from '@helpers/queryParamsHelpers';
import { NotificationsServiceWithTranslate } from '@services/translate/notificationsServiceWithTranslate';
import { SpinnerService } from '@services/spinner.service';

@Injectable()
export class ProfileSettingsFilesService {
  public constructor(
    private apiService: ProfileSettingsFileApiService,
    private notification: NotificationsServiceWithTranslate,
    private fileService: FileService,
    private spinner: SpinnerService,
  ) { }

  public loadProfileFilesList(uid: string, filters?: any): Observable<FileModel[] | null> {
    const params = {
      sort: '-createdAt',
    };
    if (filters && filters.sort) {
      params.sort = filters.sort;
    }
    return this.apiService.apiLoadProfileFilesList(uid, queryParamsStringify(params, false)).pipe(
      // eslint-disable-next-line consistent-return
      map(({ data, error }: CallResponceInterface): FileModel[] | null => {
        if (error) {
          return null;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty('items')) {
          // @ts-ignore
          return (<{ data: any[] }>data).items.map((item) => new FileModel(item, this.fileService));
        }
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty('data')) {
          return (<{ data: any[] }>data).data.map((item) => new FileModel(item, this.fileService));
        }
      }),
    );
  }

  public getFilesByRequestAndCategory(requestId: string, category: string): Observable<any> {

    return this.apiService.apiGetFilesByRequestAndCategory(requestId, category).pipe(
      // eslint-disable-next-line consistent-return
      map(({ data, error }: CallResponceInterface): any => {
        if (error) {
          return null;
        }

        return data;
      }),
    );
  }

  public uploadFileToProfile(
    userId: string,
    formData: FormData,
    options: { isAdminOnly?: boolean; isPrivate?: boolean, category?: string, requestId?: string } = {},
  ): Observable<{ success: boolean; errors?: ApiError[]; data?: FileModel }> {
    return this.spinner
      .makeObservableWithSpinner(this.apiService.apiUploadFileToProfile(userId, formData, options))
      .pipe(
        map(({ data, error }: CallResponceInterface): { success: boolean; errors?: ApiError[]; data?: FileModel } => {
          if (!error) {
            return { success: true, data: new FileModel(data, this.fileService) };
          }
          return { success: false, errors: <ApiError[]>data };
        }),
      );
  }

  public deleteFileFromProfile(fileId: number): Observable<{ success: boolean; errors?: ApiError[]; fileId?: number }> {
    return this.apiService.apiDeleteFileFromProfile(fileId).pipe(
      map(({ data, error }: CallResponceInterface): { success: boolean; errors?: ApiError[]; fileId?: number } => {
        if (!error) {
          this.notification.success('common.notifications', 'File', 'successfully_deleted');
          return { success: true, fileId };
        }
        return { success: false, errors: <ApiError[]>data };
      }),
    );
  }

  public uploadFilesToProfile(
    userId: string,
    files: File[],
    options: { isAdminOnly?: boolean; isPrivate?: boolean, category?: string, requestId?: string } = {},
  ): Observable<{ success: boolean; errors?: ApiError[]; data?: FileModel[] }> {
    return this.spinner
      .makeObservableWithSpinner(this.apiService.apiUploadFilesToProfile(userId, files, options))
      .pipe(
        map((responses: CallResponceInterface[]): { success: boolean; errors?: ApiError[]; data?: FileModel[] } => {
          const errors: ApiError[] = [];
          const fileModels: FileModel[] = [];
          responses.forEach(({ data, error }: CallResponceInterface) => {
            if (!error) {
              fileModels.push(new FileModel(data, this.fileService));
            } else {
              errors.push(...(<ApiError[]>data));
            }
          });

          if (errors.length === 0) {
            return { success: true, data: fileModels };
          } else {
            return { success: false, errors };
          }
        }),
      );
  }

}
