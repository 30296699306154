import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppOptionsService } from '@default-application-app/core/services/appOptions.service';
import { Subscription } from 'rxjs';
import { OptionsValuesNames } from '@default-application-app/core/constants/optionsPrefixes';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[activeModuleHideBlock]',
})
export class ActiveModuleHideBlockDirective implements OnDestroy {
  private hasView = false;

  private subscription: Subscription;

  @Input() set activeModuleHideBlock(key: OptionsValuesNames) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.optionsService
      .getOptionsValuePipe(key)
      .pipe(map((value: string) => value === 'enable'))
      .subscribe((isAllowed: boolean) => {
        if (!isAllowed && !this.hasView) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        } else if (isAllowed && this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
        }
      });
  }

  constructor(
    private optionsService: AppOptionsService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
