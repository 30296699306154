<div class="date-term-container__datepicker">
  <app-date-picker-buttons [controlName]="'dateFrom'" [dateElement]="dateFromElement" [form]="form">
  </app-date-picker-buttons>

  <span class="own-datepicker__label">{{ 'common.date_picker.date_from' | translate }}</span>

  <ngx-datepicker
    #dateFrom
    (dateChange)="setDateFrom($event)"
    [class.is-selected]="from"
    [ngModel]="from | stringToDate"
    [options]="dateFromOptions"
    class="own-datepicker own-datepicker__from line-filter-datepicker"
    datepickerInZone></ngx-datepicker>

  <app-icon *ngIf="from" [iconName]="'cross'" class="own-datepicker__reset" (click)="dateFromReset()"></app-icon>
</div>

<div class="date-term-container__datepicker">
  <app-date-picker-buttons [controlName]="'dateTo'" [dateElement]="dateToElement" [form]="form">
  </app-date-picker-buttons>

  <span class="own-datepicker__label" [ngClass]="from ? 'date-label' : ''">{{
    'common.date_picker.date_to' | translate
  }}</span>

  <ngx-datepicker
    #dateTo
    (dateChange)="setDateTo($event)"
    [class.is-selected]="to"
    [ngModel]="to | stringToDate"
    [options]="dateToOptions"
    class="own-datepicker own-datepicker__to line-filter-datepicker"
    datepickerInZone></ngx-datepicker>

  <app-icon *ngIf="to" [iconName]="'cross'" class="own-datepicker__reset" (click)="dateToReset()"></app-icon>
</div>
