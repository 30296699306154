import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-block-wrapper',
  templateUrl: './content-wrapper.component.html',
  styleUrls: ['./content-wrapper.component.scss'],
})
export class ContentWrapperComponent {
  @Input()
  public backgroundColor: string = 'var(--app-color-white)';
}
