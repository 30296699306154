import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Papa, ParseResult } from 'ngx-papaparse';
import { NotificationsServiceWithTranslate } from '@services/translate/notificationsServiceWithTranslate';
import { IFileWrapper } from '@default-application-app/modules/drag-and-drop/models/file-wrapper';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-drag-and-drop-group',
  templateUrl: './drag-and-drop-group.component.html',
  styleUrls: ['./drag-and-drop-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DragAndDropGroupComponent),
    },
  ],
})
export class DragAndDropGroupComponent implements OnInit, ControlValueAccessor {
  @Input() extension: string;

  @Input() maxSize: number;

  @Input() drawPreview: boolean;

  @Input() uploaded = true;

  @Input() showRemoveBtn = false;

  @Input() prefix = '';

  @Input() extensionsForView;

  @Input() title: string;

  @Input() additionalDescription?: string;

  @Input() description: string;

  @Input() controlName: string;

  @Input()
  public maxFilesCount = 1;

  @Input()
  public disabled: boolean;

  @Input()
  public previews: string[];

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onFilesChange: EventEmitter<File[]> = new EventEmitter<File[]>();

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onParseCsv: EventEmitter<ParseResult> = new EventEmitter<ParseResult>();

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  public focusout = new EventEmitter();

  public fileWrappers: IFileWrapper[] = [{ uploaded: false, preview: null } as IFileWrapper];

  public file: File;

  constructor(private papa: Papa, private notificationsService: NotificationsServiceWithTranslate, private changeDetectorRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    if (this.previews) {
      this.previews.forEach((preview: string) => this.addPreview(preview));
    }
    this.changeDetectorRef.detectChanges();
  }

  public onInputBlur() {
    this.onTouched();
    this.focusout.emit();
  }

  public async uploadFiles(files: FileList, event?: any): Promise<void> {
    const filled = this.fileWrappers.every((wrapper: IFileWrapper) => !!wrapper.preview);
    if (filled) {
      return;
    }

    let error = false;

    // eslint-disable-next-line consistent-return
    Array.prototype.map.call(files, (file: File) => {
      if (file.size > this.maxSize) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        event ? (event.target.value = '') : (files = null);
        // eslint-disable-next-line no-return-assign
        return (error = true);
      }
    });

    if (error) {
      this.notificationsService.errorWithParams('common.drag_and_drop', 'cant_upload_file', null, 'file_size', {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        maxSize: this.maxSize / 1024 / 1024,
      });
      return;
    }

    // eslint-disable-next-line consistent-return
    const promises: Promise<{ preview: string; file: File }>[] = Array.prototype.map.call(files, (file: File) => {
      if (!this.extension || this.extension.includes(file.type)) {
        return new Promise((resolve: Function) => {
          const reader = new FileReader();
          reader.readAsText(file);
          reader.onload = (e: ProgressEvent) => {
            if (file.type === 'text/csv' || file.type === 'text/plain' || file.type === 'application/vnd.ms-excel') {
              this.papa.parse((e.target as FileReader).result as FormDataEntryValue, {
                skipEmptyLines: false,
                header: false,
                complete: (parseResult) => {
                  resolve({
                    preview: (e.target as FileReader).result as string,
                    file,
                  });
                  this.onParseCsv.emit(parseResult);
                },
              });
            } else {
              resolve({
                preview: (e.target as FileReader).result as string,
                file,
              });
            }
          };
        });
      }
    });

    const previews = await Promise.all(promises);

    previews
      .filter((_, i: number) => i <= this.maxFilesCount - this.fileWrappers.length)
      .forEach((file: { preview: string; file: File; results: ParseResult }) =>
        this.addPreview(file.preview, file.file),
      );

    this.emitFiles();
  }

  public deleteFile(fileWrapper: IFileWrapper): void {
    const index: number = this.fileWrappers.indexOf(fileWrapper);

    if (this.fileWrappers.length === this.maxFilesCount) {
      this.addPreview();
    }
    this.fileWrappers.splice(index, 1);
    this.emitFiles();
  }

  writeValue(obj: any): void {
    this.file = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private onTouched: Function = () => {};

  private onChange: Function = () => {};

  private addPreview(preview?: string, file?: File): void {
    const index: number = this.fileWrappers.length - 1 >= 0 ? this.fileWrappers.length - 1 : 0;
    if (this.fileWrappers[index] && !this.fileWrappers[index].preview) {
      this.fileWrappers.splice(index, 1);
    }
    this.fileWrappers.push({
      uploaded: !!preview,
      file,
      preview: preview || null,
    } as IFileWrapper);

    if (this.fileWrappers.length < this.maxFilesCount) {
      this.fileWrappers.push({ preview: '', uploaded: false } as IFileWrapper);
    }
  }

  private emitFiles(): void {
    const files: File[] = this.fileWrappers.map((wrapper: IFileWrapper) => wrapper.file).filter((file: File) => !!file);
    this.onFilesChange.emit(files);
  }
}
