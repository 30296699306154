import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// RESOLVERS
// COMPONENTS:LAYOUT
// COMPONENTS:OTHER
import { AlreadyAuthenticatedGuard } from '@guards/alreadyAuthenticated.guard';
import { UserRoleGuard } from '@guards/user-role.guard';
import { MainLayoutComponent } from '@layouts/main-layout/main-layout.component';
import { AdminRoleGuard } from '@guards/admin-role.guard';
import { NotFoundComponent } from '@layouts/error/not-found/not-found.component';
import { AccountsCardsGuard } from '@guards/accounts/cards.guard';
import { AuthGuard } from '@guards/auth.guard';
import { CheckMaintenanceGuard } from '@guards/maintenance-guards/check-maintenance.guard';
import { MaintenanceMessageComponent } from '@default-application-app/components/maintenance-message/maintenance-message.component';
import { MaintenanceDisableGuard } from '@guards/maintenance-guards/maintenance-disable.guard';
import { ComingSoonComponent } from '@default-application-app/modules/shared-components/coming-soon/coming-soon.component';
import { WelcomePageComponent } from '@components/welcome-page/welcome-page.component';
import { KycGuard } from '@default-application-app/modules/kyc/guards/kyc.guard';
import { KycGuardEnum } from '@default-application-app/modules/kyc/enums/kyc-guard.enum';
import { NoPermissionComponent } from '@layouts/error/no-permission/no-permission.component';
import { RolesGuard } from '@guards/roles.guard';
import { Roles } from '@enums/roles.enum';
import { IbanAdminRoleGuard } from '@guards/iban-admin-role.guard';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'sign-in/business',
  },

  {
    path: 'unavailable-message',
    canActivate: [MaintenanceDisableGuard],
    component: MaintenanceMessageComponent,
  },

  // TODO remove?
  {
    path: 'welcome',
    component: WelcomePageComponent,
  },

  // Auth
  {
    path: '',
    canActivate: [AlreadyAuthenticatedGuard],
    loadChildren: () => import('@default-routers/auth/default-auth.module').then((m) => m.DefaultAuthModule),
  },

  // KYC
  {
    path: 'verification',
    loadChildren: () => import('@default-routers/kyc/default-kyc.module').then((m) => m.DefaultKycModule),
  },

  // JUMIO
  {
    path: 'jumio',
    loadChildren: () => import('@default-routers/jumio/default-jumio.module').then((m) => m.DefaultJumioModule),
  },

  // Application
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [CheckMaintenanceGuard, AuthGuard],
    data: {
      step: KycGuardEnum.Dashboard,
    },
    children: [
      // Admin
      {
        path: 'admin',
        canActivate: [AdminRoleGuard],
        data: {
          crumbs: null,
        },
        children: [
          // Import-CSV
          {
            path: '',
            loadChildren: () =>
              import('@default-routers/import-csv/default-import-csv.module').then((m) => m.DefaultImportCsvModule),
          },

          // Requests
          {
            path: 'requests',
            loadChildren: () =>
              import('@default-routers/admin-requests/default-admin-requests.module').then(
                (m) => m.DefaultAdminRequestsModule,
              ),
          },

          // Accounts
          {
            path: 'accounts',
            loadChildren: () =>
              import('@default-routers/admin-accounts/default-admin-accounts.module').then(
                (m) => m.DefaultAdminAccountsModule,
              ),
          },

          // Cards
          // {
          //   path: 'cards',
          //   loadChildren: () => import('@default-routers/cards/cards.module').then((m) => m.CardsModule),
          // },

          // Currency Exchange
          // {
          //   path: 'exchange/currency',
          //   loadChildren: () =>
          //     import('@default-routers/currency-exchange/currency-exchange.module').then(
          //       (m) => m.CurrencyExchangeModule,
          //     ),
          // },

          // Crypto Exchange
          // {
          //   path: 'exchange/crypto',
          //   loadChildren: () =>
          //     import('@default-routers/crypto-exchange/crypto-exchange.module').then((m) => m.CryptoExchangeModule),
          // },

          // Transfers
          {
            path: 'transfers',
            loadChildren: () =>
              import('@default-routers/admin-transfers/default-admin-transfers.module').then(
                (m) => m.DefaultAdminTransferModule,
              ),
          },

          // Profiles
          {
            path: 'profiles',
            loadChildren: () =>
              import('@default-routers/profiles/default-profile.module').then((m) => m.DefaultProfileModule),
          },

          // Admin news
          {
            path: 'documents',
            canActivate: [AdminRoleGuard],
            loadChildren: () =>
              import('@default-routers/admin-news/default-admin-news.module').then((m) => m.DefaultAdminNewsModule),
          },

          // System log
          {
            path: 'system-log',
            loadChildren: () =>
              import('@default-routers/system-log/default-system-log.module').then((m) => m.DefaultSystemLogModule),
          },
          {
            path: 'reconciliation',
            canActivate: [AdminRoleGuard],
            loadChildren: () =>
              import('@default-routers/reconciliation/default-reconciliation.module').then((m) => m.DefaultReconciliationModule),
          },
        ],
      },

      // IBAN Admin
      {
        path: 'iban-admin',
        canActivate: [IbanAdminRoleGuard],
        data: {
          crumbs: null,
        },
        children: [
          // IBAN Verify requests
          {
            path: 'verify-requests',
            canActivate: [IbanAdminRoleGuard],
            loadChildren: () =>
              import('@default-routers/iban-admin-verify-requests/default-iban-admin-verify-requests.module').then((m) => m.DefaultIbanAdminVerifyRequestsModule),
          },
          // IBAN Transfer requests
          {
            path: 'iban-clients',
            canActivate: [IbanAdminRoleGuard],
            loadChildren: () =>
              import('@default-routers/iban-admin-clients/default-iban-admin-clients.module').then((m) => m.DefaultIbanAdminClientsModule),
          },
          {
            path: 'iban-cycles',
            canActivate: [IbanAdminRoleGuard],
            loadChildren: () =>
              import('@default-routers/iban-admin-cycles/default-iban-admin-cycles.module').then((m) => m.DefaultIbanAdminCyclesModule),
          },
          {
            path: 'transfer-requests',
            canActivate: [IbanAdminRoleGuard],
            loadChildren: () =>
              import('@default-routers/iban-admin-transfer-requests/default-iban-admin-transfer-requests.module').then((m) => m.DefaultIbanAdminTransferRequestsModule),
          },
        ],
      },

      // User dashboard
      {
        path: 'dashboard',
        canActivate: [KycGuard, RolesGuard],
        data: {
          step: KycGuardEnum.Dashboard,
          roles: [Roles.Client, Roles.BusinessEnterprise, Roles.BusinessCorporate],
        },
        loadChildren: () =>
          import('@default-routers/dashboard/default-dashboard.module').then((m) => m.DefaultDashboardModule),
      },

      // Invoices
      {
        path: 'invoices',
        canActivate: [KycGuard, RolesGuard],
        data: {
          step: KycGuardEnum.Dashboard,
          roles: [Roles.Client, Roles.BusinessEnterprise, Roles.BusinessCorporate],
        },
        loadChildren: () =>
          import('@default-routers/user-invoices/default-user-invoices.module').then(
            (m) => m.DefaultUserInvoicesModule,
          ),
      },

      // cash
      {
        path: 'cash',
        canActivate: [KycGuard, RolesGuard],
        data: {
          step: KycGuardEnum.Dashboard,
          roles: [Roles.Client, Roles.BusinessEnterprise, Roles.BusinessCorporate],
        },
        loadChildren: () =>
          import('@default-routers/user-cash/default-user-cash.module').then((m) => m.DefaultUserCashModule),
      },

      // Request Notifications
      {
        path: 'notifications',
        canActivate: [KycGuard, RolesGuard],
        data: {
          step: KycGuardEnum.Dashboard,
          roles: [Roles.Client, Roles.BusinessEnterprise, Roles.BusinessCorporate],
        },
        loadChildren: () =>
          import('@default-routers/user-notifications/default-user-notifications.module').then(
            (m) => m.DefaultUserNotificationsModule,
          ),
      },

      // Messages
      {
        path: 'helpdesk',
        data: {
          crumbs: 'helpdesk',
          step: KycGuardEnum.Dashboard,
          roles: [Roles.Client, Roles.BusinessEnterprise, Roles.BusinessCorporate],
        },
        canActivate: [KycGuard],
        loadChildren: () =>
          import('@default-routers/messages/default-messages.module').then((m) => m.DefaultMessagesModule),
      },

      // Messages
      {
        path: 'api',
        canActivate: [KycGuard],
        data: {
          crumbs: 'api',
          step: KycGuardEnum.Dashboard,
        },
        component: ComingSoonComponent,
      },

      // TRANSFER

      {
        path: 'transfers',
        canActivate: [KycGuard],
        data: {
          step: KycGuardEnum.Dashboard,
        },
        loadChildren: () =>
          import('@default-routers/user-transfers/default-user-transfers.module').then(
            (m) => m.DefaultUserTransfersModule,
          ),
      },
      {
        path: 'group-transfers',
        canActivate: [KycGuard],
        data: {
          step: KycGuardEnum.Dashboard,
        },
        loadChildren: () =>
          import('@default-routers/user-group-transfers/default-user-group-transfers.module').then(
            (m) => m.DefaultUserGroupTransfersModule,
          ),
      },

      {
        path: 'my-accounts',
        canActivate: [UserRoleGuard, KycGuard],
        data: {
          step: KycGuardEnum.Dashboard,
        },
        loadChildren: () =>
          import('@default-routers/user-account/default-user-account.module').then((m) => m.DefaultUserAccountModule),
      },

      {
        path: 'profiles',
        canActivate: [KycGuard],
        data: {
          step: KycGuardEnum.Dashboard,
        },
        loadChildren: () =>
          import('@default-routers/profiles/default-profile.module').then((m) => m.DefaultProfileModule),
      },
      {
        path: 'my-cards',
        canActivate: [UserRoleGuard, AccountsCardsGuard, KycGuard],
        data: {
          step: KycGuardEnum.Dashboard,
        },
        loadChildren: () =>
          import('@default-routers/user-cards/default-user-cards.module').then((m) => m.DefaultUserCardsModule),
      },
      {
        path: 'my-profile',
        canActivate: [KycGuard],
        data: {
          step: KycGuardEnum.Dashboard,
        },
        loadChildren: () =>
          import('@default-routers/my-profile/default-my-profile.module').then((m) => m.DefaultMyProfileModule),
      },

      {
        path: 'user-news',
        canActivate: [UserRoleGuard, KycGuard],
        data: {
          step: KycGuardEnum.Dashboard,
        },
        loadChildren: () =>
          import('@default-routers/user-news/default-user-news.module').then((m) => m.DefaultUserNewsModule),
      },
      {
        path: 'user-reports',
        canActivate: [UserRoleGuard, KycGuard],
        data: {
          step: KycGuardEnum.Dashboard,
        },
        loadChildren: () =>
          import('@default-routers/user-reports/default-user-reports.module').then((m) => m.DefaultUserReportsModule),
      },

      {
        path: 'reports',
        canActivate: [AdminRoleGuard],
        data: {
          step: KycGuardEnum.Dashboard,
        },
        loadChildren: () =>
          import('@default-routers/admin-reports/default-admin-reports.module').then(
            (m) => m.DefaultAdminReportsModule,
          ),
      },

      // Settings
      {
        path: 'settings',
        canActivate: [AdminRoleGuard],
        data: {
          step: KycGuardEnum.Dashboard,
        },
        loadChildren: () =>
          import('@default-routers/settings-main/default-settings-main.module').then(
            (m) => m.DefaultSettingsMainModule,
          ),
      },

      // OTHER
      {
        path: 'not-found',
        canActivate: [KycGuard],
        data: {
          step: KycGuardEnum.Dashboard,
        },
        component: NotFoundComponent,
      },

      // No permission
      {
        path: 'no-permission',
        canActivate: [KycGuard],
        data: {
          step: KycGuardEnum.Dashboard,
        },
        component: NoPermissionComponent,
      },
    ],
  },
];

routes.push({
  path: '**',
  redirectTo: 'not-found',
});

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy', enableTracing: false })],
  exports: [RouterModule],
})
export class DefaultAppRoutingModule { }
