import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input()
  public buttonText: string = '';

  @Input()
  public color: string = '';

  @Input()
  public link: string = '';

  @Input()
  public type: 'button' | 'submit' = 'submit';

  @Input()
  public appearance: 'primary' | 'primary-outline' | 'secondary' | 'danger' | 'danger-outline' | 'gray' = 'primary';

  @Input()
  public size: 'default' | 'smaller' = 'default';

  @Input()
  public isDisabled = false;

  @Input()
  public isRowReverse = false;

  @Input()
  public isBigFontSize = false;

  @Input()
  public iconPath: string;

  @Input()
  public title: string = '';

  @Output()
  public btnClick = new EventEmitter();

  public onClick(): void {
    this.btnClick.emit();
  }
}
