import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[elementHeight]',
})
export class ElementHeightDirective {
  @Input()
  public elementsCount: number;

  @Input()
  public elementsOpen: boolean;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  public elementHeightRem: number = 4;

  @HostBinding('style.max-height')
  get maxHeight(): string {
    return this.elementsOpen ? `${this.elementsCount * this.elementHeightRem}rem` : '0';
  }
}
