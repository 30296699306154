import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { SelectItemInterface } from '@interfaces/selectItemInterface';

@Component({
  selector: 'app-filter-currency-select',
  templateUrl: './filter-currency-select.component.html',
  styleUrls: ['./filter-currency-select.component.scss', '../sort-select/sort-select.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterCurrencySelectComponent implements OnInit {
  @Input() form: UntypedFormGroup;

  @Input() searchable = false;

  @Input() clearable = false;

  @Input() placeholder: string;

  @Input() items: SelectItemInterface[];

  @Input() bindValue: string;

  @Input() bindLabel: string;

  @Input() controlName: string;

  @Input() title: string;

  @Input() loading: boolean;

  @Input() typeahead: Subject<any>;

  @Input() groupBy: string;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter();

  ngOnInit(): void {
    this.form.addControl(this.controlName, new UntypedFormControl(null));
  }

  public isAllCurrenciesTitle(): boolean {
    return !this.form.value[this.controlName];
  }
}
