import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { UserService } from '@services/user/user.service';
import { IUser } from '@interfaces/user-interface';
import { DEFAULT_DEBOUNCE_TIME } from '@constants/forms';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-user-search-field',
  templateUrl: './user-search-field.component.html',
  styleUrls: ['./user-search-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => UserSearchFieldComponent),
    },
  ],
})
export class UserSearchFieldComponent implements AfterViewInit, OnDestroy, AfterContentInit, ControlValueAccessor {
  @Input() public searchable = true;

  @Input() public clearable = true;

  @Input() public bindValue = '';

  @Input() public placeholder: string;

  @Input() public roleName: string[] = ['client'];

  @ViewChild('search', { static: true }) search: NgSelectComponent;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter();

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  public users$: Observable<IUser[]>;

  public usersInputSubject$ = new Subject<string>();

  public isUsersLoading = false;

  private unsubscribeSubject: Subject<void> = new Subject<void>();

  constructor(private userService: UserService) {}

  ngAfterViewInit(): void {
    this.users$ = this.usersInputSubject$.pipe(
      debounceTime(DEFAULT_DEBOUNCE_TIME),
      distinctUntilChanged(),
      startWith(''),
      tap(() => {
        this.isUsersLoading = true;
      }),
      switchMap((term) =>
        this.userService.loadUsersForSelect({ username: term, roleName: this.roleName }).pipe(
          tap(() => {
            this.isUsersLoading = false;
          }),
          catchError(() => of([])),
        ),
      ),
      takeUntil(this.unsubscribeSubject),
    );
  }

  @Input() set isPending(value: boolean) {
    this.search.setDisabledState(value);
  }

  writeValue(obj: any): void {
    this.search.writeValue(obj);
  }

  registerOnChange(fn: any): void {
    this.search.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.search.registerOnTouched(fn);
  }

  ngAfterContentInit(): void {
    this.search.blurEvent.pipe(takeUntil(this.unsubscribeSubject)).subscribe((value: any) => this.blur.emit(value));
  }

  ngOnDestroy() {
    this.usersInputSubject$.complete();
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

  public emitChange($event: any) {
    this.change.emit($event);
  }
}
