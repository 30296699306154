<div (onFileDropped)="uploadFiles($event)" [disabled]="disabled" appDragDrop class="drag-and-drop-group">
  <app-preview
    (onDeleteFile)="deleteFile($event)"
    [additionalDescription]="additionalDescription"
    [description]="description"
    [disabled]="disabled"
    [extension]="extension"
    [files]="fileWrappers"
    [maxFilesCount]="maxFilesCount"
    [maxSize]="maxSize"
    [prefix]="prefix"
    [title]="title"
    class="drag-and-drop"></app-preview>
  <label *ngIf="!disabled" class="label">
    <input
      (change)="uploadFiles($event.target['files'], $event); onInputBlur()"
      [accept]="extension"
      hidden
      multiple
      type="file" />
  </label>
</div>
