import { Injectable } from '@angular/core';
import { KycInitiateInterface } from '@interfaces/kyc/kyc-initiate.interface';
import { Observable } from 'rxjs';
import { CallResponceInterface } from '@interfaces/callResponce.interface';
import { KycApiService } from '@services/kyc/kyc-api.service';
import { map } from 'rxjs/operators';
import { KycCurrentTierModel } from '@models/kyc/kyc-tier.model';
import { KycCurrentTierInterface, KycTierInterface } from '@interfaces/kyc/kyc-tier.interface';
import { KycTierModel } from '@models/kyc/kyc-tiers.model';
import { snakeToCamelCase } from '@helpers/stringHelpers';
import { LegalEntityInterface } from '@interfaces/kyc/legal-entity.interface';
import { LegalEntityModel } from '@models/kyc/legal-entity.model';
import { queryParamsStringify } from '@helpers/queryParamsHelpers';
import { JUMIO_KYC_INDEX } from '@constants/jumio';
import { EmploymentInfoInterface } from '@interfaces/kyc/employment-info.interface';

@Injectable()
export class KycService {
  constructor(private kycApiService: KycApiService) {}

  public initiateKyc(data: KycInitiateInterface): Observable<CallResponceInterface> {
    return this.kycApiService.apiInitiateKyc(data);
  }

  public deleteKycRequestById(id: string): Observable<CallResponceInterface> {
    return this.kycApiService.apiDeleteKycRequestById(id);
  }

  public createLegalEntity(data: LegalEntityInterface): Observable<CallResponceInterface> {
    return this.kycApiService.apiCreateLegalEntity(data);
  }

  public updateLegalEntity(data: LegalEntityInterface, id: number): Observable<CallResponceInterface> {
    return this.kycApiService.apiUpdateLegalEntity(data, id);
  }

  public updateTierLevel(tierId: number): Observable<CallResponceInterface> {
    return this.kycApiService.apiUpdateTierLevel(tierId);
  }

  public deleteLegalEntity(id: number): Observable<CallResponceInterface> {
    return this.kycApiService.apiDeleteLegalEntity(id);
  }

  public loadLegalEntityById(id: number): Observable<LegalEntityModel> {
    return this.kycApiService.apiLoadLegalEntityById(id).pipe(
      map((data: any) => {
        return data.error ? null : new LegalEntityModel(data.data as LegalEntityInterface);
      }),
    );
  }

  public loadLegalEntityForAdminById(id: string): Observable<LegalEntityModel> {
    return this.kycApiService.apiLoadLegalEntityForAdminById(id).pipe(
      map((data: any) => {
        return data.error ? null : new LegalEntityModel(data.data as LegalEntityInterface);
      }),
    );
  }

  private static transformLegalEntitiesParams(queryParams: { [key: string]: string }): { [key: string]: string } {
    const params = {};
    const filter = {};

    // @ts-ignore
    params.page = { size: '1000' };

    if (queryParams.legalEntityType) {
      filter['legalEntityType'] = queryParams.legalEntityType;
    }

    if (queryParams.userId) {
      filter['userId'] = queryParams.userId;
    }

    if (Object.keys(filter).length) {
      params['filter'] = filter;
    }

    return queryParamsStringify(params, false);
  }

  public loadLegalEntities(params): Observable<LegalEntityModel[]> {
    return this.kycApiService
      .apiLoadLegalEntities(KycService.transformLegalEntitiesParams(params))
      .pipe(
        map((data: CallResponceInterface) =>
          (data.data as LegalEntityInterface[]).map((item: LegalEntityInterface) => new LegalEntityModel(item)),
        ),
      );
  }

  public loadLegalEntitiesFromAdminForUser(params): Observable<LegalEntityModel[]> {
    return this.kycApiService
      .apiLoadLegalEntitiesFromAdminForUser(KycService.transformLegalEntitiesParams(params))
      .pipe(
        map((data: CallResponceInterface) =>
          (data.data as LegalEntityInterface[]).map((item: LegalEntityInterface) => new LegalEntityModel(item)),
        ),
      );
  }

  public loadLegalEntitiesByAdmin(params): Observable<LegalEntityModel[]> {
    return this.kycApiService
      .apiLoadLegalEntities(KycService.transformLegalEntitiesParams(params))
      .pipe(
        map((data: CallResponceInterface) =>
          (data.data as LegalEntityInterface[]).map((item: LegalEntityInterface) => new LegalEntityModel(item)),
        ),
      );
  }

  public updateEmploymentInfo(data: EmploymentInfoInterface, id: string, otp?: string): Observable<CallResponceInterface> {
    const otpToSend = otp || '';
    return this.kycApiService.apiUpdateEmploymentInfo(data, id, otpToSend);
  }

  public kycTiers(): Observable<KycTierModel[]> {
    return this.kycApiService
      .apiGetKycTiers()
      .pipe(
        map((data: CallResponceInterface) =>
          (data.data as KycTierInterface[]).map((item: KycTierInterface) => new KycTierModel(item)),
        ),
      );
  }

  public kycCurrentTier(): Observable<KycCurrentTierModel> {
    return this.kycApiService
      .apiGetCurrentKycTier()
      .pipe(map((data: CallResponceInterface) => new KycCurrentTierModel(data.data as KycCurrentTierInterface)));
  }

  public updateKyc(id: string, data: Array<{ index: string; value: string }>): Observable<CallResponceInterface> {
    return this.kycApiService.apiUpdateKyc(id, data);
  }

  public transformKycTierData(data: KycTierModel) {
    const values = {};

    data.requirements.forEach((requirement) => {
      if (requirement.elements && requirement.index !== JUMIO_KYC_INDEX && requirement.elements[0].value) {
        values[snakeToCamelCase(requirement.index)] = requirement.elements[0].value;
        if (requirement.elements[1] && requirement.elements[1].value) {
          values[snakeToCamelCase(requirement.elements[1].index)] = requirement.elements[1].value;
        }
      }
    });

    return values;
  }

  public getKycTier(id: string): Observable<KycTierModel> {
    return this.kycApiService.apiGetKyc(id).pipe(
      map((data: CallResponceInterface) => {
        if (!data.error) {
          return new KycTierModel(data.data as KycTierInterface);
        }

        return null;
      }),
    );
  }

  public getLimits(user_id: string, currency_type: string): Observable<any> {
    return this.kycApiService.apiGetLimits(user_id, currency_type);
  }
}
