/**
 * @license Use of this source code is governed by an CC BY-NC-ND 4.0 license that can be found in the LICENSE file at
 *   https://creativecommons.org/licenses/by-nc-nd/4.0
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders as Headers } from '@angular/common/http';
import { ApiCallerService } from '@services/api-caller.service';
import { ConfigService } from '@default-application-app/config.service';

@Injectable()
export class FileApiService {
  /**
   * CurrencyApiService constructor.
   *
   * @param {HttpClient} http
   * @param {ApiCallerService} apiCallerService
   * @param configService
   */
  public constructor(
    private http: HttpClient,
    private apiCallerService: ApiCallerService,
    private configService: ConfigService,
  ) {}

  /**
   * Upload file
   *
   * @returns {Observable<any>}
   */
  public apiUploadPublicFile(userId: string, uploadData: FormData) {
    const headers = new Headers();

    return this.apiCallerService.call(
      () => this.http.post(this.configService.config.api.file.uploadPublic(userId), uploadData, { headers }),
      'apiUploadPublicFile',
    );
  }

  /**
   * Upload file
   *
   * @returns {Observable<any>}
   */
  public apiUploadPrivateFile(uploadData: FormData) {
    const headers = new Headers();

    return this.apiCallerService.call(
      () => this.http.post(this.configService.config.api.file.uploadPrivate, uploadData, { headers }),
      'apiUploadPublicFile',
    );
  }

  /**
   * Upload file
   *
   * @returns {Observable<any>}
   */
  public apiUploadAdminOnlyFile(uploadData: FormData) {
    const headers = new Headers();

    return this.apiCallerService.call(
      () => this.http.post(this.configService.config.api.file.uploadAdminOnly, uploadData, { headers }),
      'apiUploadPublicFile',
    );
  }

  /**
   * Load file
   *
   * @returns {Observable<any>}
   */
  public apiLoadFile(id: number) {
    const headers = new Headers();
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.file.fileById(id), { headers }),
      'apiLoadFile',
    );
  }

  public apiFileBinaryById(id: number) {
    const headers = new Headers();
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.file.filePrivateBinaryById(id), { headers }),
      'apiFileBinaryById',
    );
  }

  public downloadFileBinary(id: number) {
    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.file.fileBinaryById(id), {
          responseType: 'arraybuffer',
        }),
      'downloadFileBinary',
    );
  }
}
