import { ChangeDetectorRef, Component, Inject, OnInit, Output} from '@angular/core';
import { FormGroup, UntypedFormBuilder ,ValidatorFn,Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VALIDATION_PROPERTY } from '@constants/validation-property';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import {
  IAdminTbuRequest,
    IAmountDetails,
    IIncomingAmount,
    ITbuRequest,
  } from '@interfaces/transfer-request-interface';
import { TransferService, TransferTypes } from '@services/transfer/transfer.service';
import { ApiError } from '@models/api-error.model';
import { ErrorCodes } from '@default-application-app/core/constants/errorCodes';
import { AmountDetailsMixin } from '../../transfer/components/shared/amountDetails.mixin';
import { AccountInterface } from '@interfaces/account-interface';
import { sufficientAmountValidatorFn } from '@validators/sufficientAmount.validator';
import { accountNumberValidator } from '@validators/account-number.validator';
import { numberValidator } from '@validators/number-validator';
import { ActivatedRoute } from '@angular/router';
import { UserGroupTransfersService } from '../services/user-group-transfers.services';
import { UserGroupTransfersApiService } from '../services/user-group-transfers-api.services';
import { CreateGroupTransfer } from '../pages/create-group-transfer/create-group-transfer.component';

enum CurrencyMinAmountValidationLimit {
  LBP = '500000',
  default = '5',
}

const LBP_CURRENCY_CODE = 'LBP';

@Component({
  selector: 'app-add-sub-transfer',
  templateUrl: './add-sub-transfer.component.html',
  styleUrls: ['./add-sub-transfer.component.scss'],
})
export class AddSubbTransferComponent extends AmountDetailsMixin(class {}) implements OnInit{

    public addTransferForm: FormGroup;
    public incomingCurrencyCode: string = null;
    protected totalOutgoingAmount: number;
    protected onInitDetails: Subject<any> = new Subject<any>();
    protected unsubscribeSubject = new Subject();
    public readonly defaultCurrencyCode = 'LBP';
    private minimumAmount: CurrencyMinAmountValidationLimit = CurrencyMinAmountValidationLimit.LBP;
    public errorCodes = ErrorCodes;
    public recipientDetails: string;
    public get accountFrom(): AccountInterface {
        return this.addTransferForm.get('accountFrom').value;
      }

  constructor(
    private dialogRef: MatDialogRef<AddSubbTransferComponent>,
    protected fb: UntypedFormBuilder,
    protected transferService: TransferService,
    private activatedRoute: ActivatedRoute,
    private UserGroupTransfersService: UserGroupTransfersService,
    protected changeDetector: ChangeDetectorRef,


    @Inject(MAT_DIALOG_DATA)
    data: {
        accountFrom: AccountInterface
    },
  ) {
      super();
    this.createForm()
    this.subscribeToLoadIncomingAmount();
    this.addTransferForm.patchValue({accountFrom: data.accountFrom})
    this.addTransferForm.setValidators([sufficientAmountValidatorFn()]);
    this.onChangeAccountFrom();
    
  }

  public ngOnInit() {
    this.addTransferForm.get('accountNumberTo').setValidators([accountNumberValidator(), numberValidator()]);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  createForm() {
    this.addTransferForm = this.fb.group({
      title: [null, Validators.required],
      accountNumberTo: ['', Validators.required],
      outgoingAmount: [null, [Validators.required]],
      accountFrom: [null],
      description: [null],
      helpText: [null],
      incomingAmount: [''],
    });
  }

  private initDetails(data: any): void {
    this.addTransferForm.get('incomingAmount').setValue((<IIncomingAmount>data).incomingAmount);
    this.details = (<IIncomingAmount>data).details || null;
    this.recipientDetails = this.transformRecipientInfo(data.recipient);
    this.addTransferForm.get('helpText').setValue(this.recipientDetails);
    this.incomingCurrencyCode = (<IIncomingAmount>data).incomingCurrencyCode || null;
    this.totalOutgoingAmount = data?.totalOutgoingAmount ? Math.abs(Number(data?.totalOutgoingAmount)) : null;
    this.onInitDetails.next();
  }

  private subscribeToLoadIncomingAmount() {
    this.transferService.onLoadIncomingAmount$
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(({ data, error }: { data: IIncomingAmount | ApiError[]; error: boolean }) => {
        if (!error) {
          this.initDetails(data);
        } else {
          this.details = null;
        }
        this.changeDetector.detectChanges();
      });
  }

  protected calculateFee(): void {
    this.details = null;
    if (this.addTransferForm.get('accountNumberTo').valid && this.addTransferForm.get('outgoingAmount').valid) {
      this.evaluateRequest();
    }
  }

  public onChangeAccount(): void {
    this.addTransferForm.get('accountNumberTo').updateValueAndValidity();
  }
  protected evaluateRequest(): void {
    this.transferService.loadIncomingAmount.next({
      type: TransferTypes.USER_TBU,
      data: { body: this.getRequestData() },
    });
  }


  public onChangeAccountFrom(): void {
    this.addTransferForm
      .get('accountFrom')
      .valueChanges.pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((data: AccountInterface) => {
        this.updateAmountControlValidators(data.type.currencyCode);
      });
  }

  protected getRequestData(): IAdminTbuRequest | ITbuRequest {
    const { accountFrom, accountTo, userFrom, userTo, userName, accountNumberTo, ...data } = this.addTransferForm.value;
    return {
      ...data,
      accountIdFrom: accountFrom.id,
      accountNumberTo: accountNumberTo || accountTo?.number,
    };
  }

  public addSubTransfer(): void {
    if (this.addTransferForm.valid) {
      const data:{} = {
        ...this.addTransferForm.value,
        groupId: this.activatedRoute.snapshot.queryParamMap.get('groupId')
      }
     
      this.UserGroupTransfersService.addSubTransfer(data).subscribe(() => {
        this.dialogRef.close();
 
      }
    )
      
    }
  }


  private getAmountValidators(): ValidatorFn[] {
    return [Validators.required, Validators.min(Number(this.minimumAmount))];
  }

  private updateAmountControlValidators(currencyCode: string): void {
    this.minimumAmount =
      currencyCode === LBP_CURRENCY_CODE
        ? CurrencyMinAmountValidationLimit.LBP
        : CurrencyMinAmountValidationLimit.default;

    this.addTransferForm.controls.outgoingAmount.clearValidators();
    this.addTransferForm.controls.outgoingAmount.addValidators(this.getAmountValidators());
    this.addTransferForm.controls.outgoingAmount.updateValueAndValidity();
  }

  transformRecipientInfo(data: { firstName: string, lastName: string, phoneNumber: string }): string {
    let { firstName, lastName, phoneNumber } = data;
    let transformedFirstName = firstName[0] + '.';
    let transformedPhoneNumber = phoneNumber.slice(0, 6) + "XXX" + phoneNumber.slice(9);
    let output = `${transformedFirstName} ${lastName} ${transformedPhoneNumber}`;

    return output;
  }
}
