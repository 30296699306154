import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent {
  @Input()
  public iconPath: string = '';

  @Input()
  public icon: string = '';

  @Input()
  public fallbackSVG: string = '';

  public getIconPath(icon): string {
    if (!icon) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return `ic_${icon}.svg`;
  }
}
