import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

const ROWS = 4;

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent implements ControlValueAccessor {
  @Input()
  public placeholder = '';

  @Input()
  public label = '';

  @Input()
  public isDisabled = false;

  @Input()
  public isNoResize = true;

  @Input()
  public formControlName: string;

  @Input()
  public rows: number = ROWS;

  @Input()
  public name: string;

  @Output()
  public blur: EventEmitter<any> = new EventEmitter<any>();

  constructor(public control: NgControl) {
    this.control.valueAccessor = this;
  }

  public model: string;

  public onChange: any = () => {};

  public onTouched: any = () => {};

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(obj: any): void {
    this.model = obj;
  }

  public onBlur(): void {
    this.blur.next();
    this.onTouched();
  }
}
