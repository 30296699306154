import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CurrenciesApiService } from '@default-application-app/core/services/currencies/currencies-api.service';
import { Currency, CurrencySettings } from '@default-application-app/core/models/currency-model';
import { FilterOptions } from '@default-application-app/core/shared/filter-options';
import { map, switchMap } from 'rxjs/operators';
import { CurrencyInterface } from '@default-application-app/core/interfaces/currency-interface';
import { NotificationsServiceWithTranslate } from '@default-application-app/core/services/translate/notificationsServiceWithTranslate';

@Injectable()
export class CurrenciesService {
  public constructor(
    private currenciesApiService: CurrenciesApiService,
    private notificationService: NotificationsServiceWithTranslate,
  ) {}

  private onUpdateSettingsSubject$: Subject<CurrencySettings> = new Subject<CurrencySettings>();

  public onUpdateSettings$: Observable<CurrencySettings> = this.onUpdateSettingsSubject$.asObservable().pipe(
    switchMap((settings: CurrencySettings) => this.currenciesApiService.apiUpdateSettings(settings)),
    map(({ data, error }: { data: any; error: boolean }) => {
      if (error) {
        this.notificationService.error(
          'pages.settings.account',
          'error',
          'an_error_occurred_during_switching_auto_updates_for_exchange_rates',
        );
      }
      return error ? null : data;
    }),
  );

  private onUpdateCurrenciesSubject$: Subject<Set<Currency>> = new Subject<Set<Currency>>();

  public onUpdateCurrencies$: Observable<boolean> = this.onUpdateCurrenciesSubject$.asObservable().pipe(
    switchMap((currencies: Set<Currency>) => this.currenciesApiService.apiUpdateCurrencies(currencies)),
    map(({ error }: { error: boolean }) => !error),
  );

  private onLoadCurrenciesSubject$: Subject<Currency[]> = new Subject<Currency[]>();

  public onLoadCurrencies$: Observable<Currency[]> = this.onLoadCurrenciesSubject$.asObservable();

  public updateSettings(settings: CurrencySettings): void {
    this.onUpdateSettingsSubject$.next(settings);
  }

  public updateCurrencies(currencies: Set<Currency>): void {
    this.onUpdateCurrenciesSubject$.next(currencies);
  }

  public getAllUserCurrencies(filters: FilterOptions = new FilterOptions()): Observable<Currency[]> {
    const params = filters.getParams();
    params['page[size]'] = '0';

    return this.currenciesApiService
      .apiGetUserCurrencies(params)
      .pipe(map(({ data, error }: { data: any; error: boolean }) => (error ? [] : data)));
  }

  public getAllCurrencies(filters: FilterOptions = new FilterOptions()): Observable<Currency[]> {
    const params = filters.getParams();
    params['page[size]'] = '0';

    this.currenciesApiService.apiGetCurrencies(params).subscribe(({ data, error }: { data: any; error: boolean }) => {
      if (error) {
        this.onLoadCurrenciesSubject$.next([]);
      } else {
        this.onLoadCurrenciesSubject$.next(data);
      }
    });
    return this.onLoadCurrencies$;
  }

  public getSettings(): Observable<CurrencySettings> {
    return this.currenciesApiService
      .apiGetSettings()
      .pipe(map(({ data, error }: { data: any; error: boolean }) => (error ? null : data)));
  }

  public createNewCurrency(currency: CurrencyInterface): Observable<boolean> {
    return this.currenciesApiService
      .apiCreateNewCurrency(currency)
      .pipe(map(({ error }: { data: any; error: boolean }) => !error));
  }
}
