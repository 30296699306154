import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppOptionsService } from '@default-application-app/core/services/appOptions.service';
import { OptionsValuesNames } from '@default-application-app/core/constants/optionsPrefixes';
import { filter, map, tap } from 'rxjs/operators';
import { environment } from '@default-application/src/environments/environment';

@Injectable()
export class SignUpResolver implements CanActivate {
  constructor(private appOptionService: AppOptionsService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.appOptionService.getOptionsValuePipe(OptionsValuesNames.USER_REGISTER).pipe(
      filter((value: string) => value !== undefined),
      map((value: string): boolean => value === 'enable'),
      // eslint-disable-next-line consistent-return
      tap((enabled: boolean) => {
        if (environment.envName === 'demo') {
          return true;
        }
        if (!enabled) {
          this.router.navigate(['not-found']);
        }
      }),
    );
  }
}
