/**
 * @license Copyright iKantam LLC. All Rights Reserved.
 *
 *   Use of this source code is governed by an CC BY-NC-ND 4.0 license that can be found in the LICENSE file at
 *   https://creativecommons.org/licenses/by-nc-nd/4.0
 */
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { FileModel } from '@models/file-model';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@default-application-app/config.service';
import { environment } from '@default-application/src/environments/environment';
import { exhaustMap, map, mergeMap, take, toArray } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import * as FileSaver from 'file-saver';
import { ViewFileComponent } from '@shared-modules/popups/popups/view-file/view-file.component';
import { MatDialog } from '@angular/material/dialog';
import { FileApiService } from './file-api.service';

const COUNT_REQUESTS_IN_ONE_TIME = 1;

@Injectable()
export class FileService {
  public constructor(
    private fileApiService: FileApiService,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private configService: ConfigService,
    private dialog: MatDialog,
  ) {}

  /** @returns {Observable<FileModel>} */
  public uploadPublicFile(userId: string, uploadData: FormData): Observable<FileModel> {
    return this.fileApiService
      .apiUploadPublicFile(userId, uploadData)
      .pipe(map(({ data, error }: { data: any; error: boolean }) => (error ? null : new FileModel(data, this))));
  }

  /** @returns {Observable<FileModel>} */
  public loadFile(id: number): Observable<FileModel> {
    return this.fileApiService
      .apiLoadFile(id)
      .pipe(map(({ data, error }: { data: any; error: boolean }) => (error ? null : new FileModel(data, this))));
  }

  public loadFiles(ids: Array<number>): Observable<FileModel[]> {
    return from(ids).pipe(
      mergeMap((id: number) => {
        if (id) {
          return this.loadFile(Number(id));
        }

        return of(null);
      }, COUNT_REQUESTS_IN_ONE_TIME),
      toArray(),
    );
  }

  public fileBinaryById(id: number): Observable<FileModel> {
    return this.fileApiService.apiFileBinaryById(id);
  }

  public localFileUrl(id: number): Observable<ArrayBuffer> {
    return this.http.get(
      // this.configService.config.api.file.fileBinaryById(id),
      this.configService.config.api.file.filePrivateBinaryById(id),
      {
        responseType: 'arraybuffer',
      },
    );
  }

  public getPublicFileUrl(id: number): string {
    return `${environment.apiHost}${this.configService.config.api.file.fileBinaryById(id)}`;
  }

  public openFileInNewWindow(id: string): void {
    this.spinner.show();

    this.loadFile(Number(id))
      .pipe(
        exhaustMap((file: FileModel) => file.fileLocation(true)),
        take(1),
      )
      .subscribe((url: string) => {
        this.spinner.hide();
        this.dialog.open(ViewFileComponent, { data: url });
      });
  }

  public downloadFile(id: string): void {
    this.spinner.show();
    let currentFile: FileModel;

    this.loadFile(Number(id))
      .pipe(
        exhaustMap((file: FileModel) => {
          currentFile = file;
          return file.getBlobFile();
        }),
        take(1),
      )
      .subscribe((blob: Blob) => {
        FileSaver.saveAs(blob, currentFile.filename);
        this.spinner.hide();
      });
  }
}
