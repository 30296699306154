import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormatBytes } from '@helpers/format-bytes.helper';
import { IFileWrapper } from '@default-application-app/modules/drag-and-drop/models/file-wrapper';

@Component({
  selector: 'app-drag-and-drop-input-preview',
  templateUrl: './drag-and-drop-input-preview.component.html',
  styleUrls: ['./drag-and-drop-input-preview.component.scss'],
})
export class DragAndDropInputPreviewComponent implements OnInit {
  @Input()
  public files: IFileWrapper[];

  @Input()
  public disabled: boolean;

  @Input()
  public isFileName: boolean;

  @Input()
  public prefix = '';

  @Input()
  public additionalDescription?: string;

  @Input()
  public description: string;

  @Input()
  public descriptionForUploadedFile: string;

  @Input()
  public maxSize: number;

  @Input()
  public extension = '';

  @Input()
  public fileUrl = '';

  @Input()
  public extensionsForView: string;

  @Output()
  public deleteFile: EventEmitter<any> = new EventEmitter<any>();

  public extensionView: string;

  public formatBytes = FormatBytes;

  get file(): IFileWrapper {
    if (this.files?.length) {
      return this.files?.[0];
    }

    return { uploaded: false, preview: null } as IFileWrapper;
  }

  public isPdfImage(path: any): boolean {
    if (typeof path === 'string') {
      return path?.includes('assets/icons/v2/ic_pdf-icon.svg');
    }

    return path?.changingThisBreaksApplicationSecurity?.includes('assets/icons/v2/ic_pdf-icon.svg');
  }

  public ngOnInit(): void {
    this.convertExtension();
  }

  private convertExtension(): void {
    if (this.extensionsForView) {
      this.extensionView = this.extensionsForView;
      return;
    }

    if (this.extension) {
      this.extensionView = this.extension.replace(/[a-z]*\//gi, ' ').replace(/plain/g, 'txt');
    }
  }
}
