import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormatBytes } from '@helpers/format-bytes.helper';
import { IFileWrapper } from '@default-application-app/modules/drag-and-drop/models/file-wrapper';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent implements OnInit {
  @Input()
  public files: IFileWrapper[];

  @Input()
  public disabled: boolean;

  @Input()
  public prefix = '';

  @Input()
  public title: string;

  @Input()
  public additionalDescription?: string;

  @Input()
  public maxFilesCount: number;

  @Input()
  public description: string;

  @Input()
  public maxSize: number;

  @Input()
  public extension = '';

  @Input()
  public extensionsForView: string;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onDeleteFile: EventEmitter<any> = new EventEmitter<any>();

  public extensionView: string;

  public formatBytes = FormatBytes;

  public ngOnInit(): void {
    this.convertExtension();
  }

  public deleteFile(value): void {
    this.onDeleteFile.emit(value);
  }

  private convertExtension(): void {
    if (this.extensionsForView) {
      this.extensionView = this.extensionsForView;
      return;
    }

    if (this.extension) {
      this.extensionView = this.extension.replace(/[a-z]*\//gi, ' ').replace(/plain/g, 'txt');
    }
  }
}
