import { AccountInterface } from '@default-application-app/core/interfaces/account-interface';

export class Transaction {
  public id: number;

  public description: string;

  public amount: string;

  public status: string;

  public createdAt: string;

  public statusChangedAt: string;

  public accountId: number;

  public currencyCode: string;

  public account: AccountInterface;

  public accTo?: AccountInterface;

  public accountFrom?: AccountInterface;

  public requestId: string;

  public isRefund: boolean;

  public balanceSnapshot?: number | string;

  public requestSubject?: string;

  public purpose?: string;

  public availableBalanceSnapshot?: number;

  public currentBalanceSnapshot?: number;

  public totals?: {
    amountToReceiveFromCashAgent?: string;
    amountToHandleToCashAgent?: string;
    requestAmount?: string;
    totalOutgoingAmount?: string;
    walletFee?: string;
  };

  public constructor(params: any) {
    Object.assign(this, params);
  }
}
