import { Action } from '@ngrx/store';
import {
  initialSiteTextState,
  reducer,
  siteTextAdapter,
  State,
} from '@default-application-app/actions/siteText.actions';

// eslint-disable-next-line @typescript-eslint/default-param-last
export function siteTextsReducer(state: State = initialSiteTextState, action: Action): State {
  return reducer(state, action);
}

const { selectEntities, selectAll } = siteTextAdapter.getSelectors();

export const selectAllSiteTextSelector = selectAll;

export const selectSiteTextEntitiesSelector = selectEntities;

export const getLoading = (state: State) => state.loading;

export const getErrors = (state: State) => state.errors;
