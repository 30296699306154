import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-popup-autologout',
  templateUrl: './popup-autologout.component.html',
  styleUrls: ['./popup-autologout.component.scss'],
})
export class PopupAutologoutComponent implements OnInit {
  @Input() public show = false;

  @Input() public message = '';

  @Input() public autoLogoutPadding = '';

  @Input() public headingMessage: string = null;

  @Input() public buttonYesName: string = null;

  @Input() public buttonNoName: string = null;

  @Output() userConfirm = new EventEmitter<boolean>();

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  public width = 100;

  public timer: number;

  public hours = 0;

  public minutes = 0;

  public seconds = 0;

  public refreshInterval: NodeJS.Timer;

  ngOnInit() {
    /* eslint-disable */
    if (this.autoLogoutPadding) {
      this.timer = +this.autoLogoutPadding;

      this.refreshInterval = setInterval(() => {
        --this.timer;
        this.width = Math.round((this.timer * 1000) / +this.autoLogoutPadding) / 10;
        this.hours = Math.floor(this.timer / 3600);
        this.minutes = Math.floor((this.timer % 3600) / 60);
        this.seconds = Math.floor(this.timer % 60);

        if (this.timer < 0) {
          this.show = false;
          clearInterval(this.refreshInterval);
        }
      }, 1000);
    }
    /* eslint-enable */
  }

  confirm(confirmed: boolean) {
    this.show = false;
    this.userConfirm.emit(confirmed);
    clearInterval(this.refreshInterval);
  }
}
