import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@default-application-app/core/services/auth/auth.service';

@Injectable()
export class UserRoleGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const isUser = !this.authService.isAdminOrRootUser() 
    && !this.authService.isIbanAdmin();
    if (!isUser) {
      this.router.navigate(['not-found']);
    }
    return isUser;
  }
}
