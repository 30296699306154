import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { phoneWithoutCountryCodeHelper } from '@helpers/phone-without-country-code.helper';

@Directive({
  selector: '[phoneMask]',
})
export class PhoneMaskDirective {
  @Input()
  public country: string;

  constructor(public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.ngControl.valueAccessor.writeValue(phoneWithoutCountryCodeHelper(event, this.country));
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.ngControl.valueAccessor.writeValue(phoneWithoutCountryCodeHelper(event.target.value, this.country));
  }
}
