import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { AppOptionsService } from '@default-application-app/core/services/appOptions.service';
import { UserPermissions } from '@default-application-app/core/constants/userPermissions';
import { map } from 'rxjs/operators';
import { ErrorDictionary } from '@default-application-app/core/constants/error.dictionary';
import { NotificationsServiceWithTranslate } from '@services/translate/notificationsServiceWithTranslate';

@Injectable()
export class UserPermissionGuard implements CanActivate {
  private errorDictionary = ErrorDictionary;

  constructor(
    private notificationService: NotificationsServiceWithTranslate,
    private appOptionService: AppOptionsService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const permissionsArray: string[] = route.data.permissions;
    const permissionCode: string = permissionsArray[0];

    return combineLatest(
      permissionsArray.map((key: UserPermissions) => this.appOptionService.getUserPermissionForKey(key)),
    ).pipe(
      map((permissions: boolean[]): boolean => {
        const isAllowed = permissions.includes(true);
        if (!isAllowed) {
          this.notificationService.errorWithParams(
            'common.notifications.permissions',
            'error',
            null,
            this.errorDictionary.has(permissionCode) ? 'no_permissions_to' : 'no_permissions_to_access_page',
            this.errorDictionary.has(permissionCode)
              ? { permission: this.errorDictionary.get(permissionCode) }
              : { permission: permissionCode },
          );
          this.router.navigate([route.data.redirectTo || 'no-permission']);
        }
        return isAllowed;
      }),
    );
  }
}
