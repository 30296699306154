<div
  class="date-term-container__datepicker right-align-datepicker"
  [ngClass]="{ 'date-term-container__datepicker__small': isSmall }">
  <app-date-picker-buttons [controlName]="'dateTo'" [dateElement]="dateToElement" [form]="form">
  </app-date-picker-buttons>

  <span class="own-datepicker__label">{{ title }}</span>

  <ngx-datepicker
    #dateTo
    (dateChange)="setDateTo($event)"
    [class.is-selected]="to"
    [ngModel]="to | stringToDate"
    [options]="dateToOptions"
    class="own-datepicker own-datepicker__to line-filter-datepicker"
    datepickerInZone>
  </ngx-datepicker>

  <app-icon *ngIf="to" [iconName]="'cross'" [iconName]="'cross'" class="own-datepicker__reset" (click)="dateToReset()">
  </app-icon>
</div>
