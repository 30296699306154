<div class="input-header">
  <label *ngIf="label" class="input-header__label">
    {{ label }}
  </label>
</div>

<div class="custom-container">
  <div class="form-control">
    <input
      #input
      [(ngModel)]="value"
      (ngModelChange)="onChange($event)"
      [ngClass]="{ 'has-error': control?.touched && control?.invalid }"
      (blur)="onBlur()"
      [class.filed]="isFilledClass"
      [autocomplete]="isAutocompleteDisabled ? 'disable' : true"
      class="form-control__input"
      user-options-field-disabled
      [optionName]="optionName"
      [placeholder]="placeholder"
      [options]="options"
      currencyMask
      makeActiveInput
      [requiredIf]="isRequiredIf"
      [attr.disabled]="isFormControlDisabled || isDisabled ? true : null"
      [type]="type"
      [readOnly]="isReadonly"
      [title]="title || label" />
  </div>

  <span *ngIf="currencyCode" class="currency-code-badge" [ngClass]="{ disable: isFormControlDisabled || isDisabled }">{{
    currencyCode
  }}</span>
</div>
