import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NotificationsServiceWithTranslate } from '@services/translate/notificationsServiceWithTranslate';
import { IFileWrapper } from '@default-application-app/modules/drag-and-drop/models/file-wrapper';
import heic2any from 'heic2any';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileService } from '@shared-modules/file-service/services/file.service';
import { UnsubscribeDestroyHelper } from '@helpers/unsubscribe-destroy.helper';

@Component({
  selector: 'app-drag-and-drop-input',
  templateUrl: './drag-and-drop-input.component.html',
  styleUrls: ['./drag-and-drop-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DragAndDropInputComponent),
    },
  ],
})
export class DragAndDropInputComponent extends UnsubscribeDestroyHelper implements OnInit, ControlValueAccessor {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  @Input()
  public extension: string;

  @Input()
  public maxSize: number;

  @Input()
  public label: string;

  @Input()
  public fileId: number;

  @Input()
  public isOptional: boolean;

  @Input()
  public isFileName: boolean;

  @Input() isMessageFile = false;

  @Input()
  public description: string;

  @Input()
  public bottomDescription: string;

  @Input()
  public descriptionForUploadedFile: string;

  @Input()
  public disabled: boolean;

  @Input()
  public tooltipMessage: string;

  @Input()
  public isRightPosition = false;

  @Input()
  public previews: string[];

  @Output()
  public filesChange: EventEmitter<File[]> = new EventEmitter<File[]>();

  @Output()
  public removeFile: EventEmitter<File> = new EventEmitter<File>();

  @Input()
  public isRemoveFile: boolean = false;

  @Output()
  public focusout = new EventEmitter();

  @Input()
  public fileUrl = '';

  public fileWrappers: IFileWrapper[] = [{ uploaded: false, preview: null } as IFileWrapper];

  public file: File;

  public isBigSizeError: boolean = false;

  private maxFilesCount: number = 1;

  private onTouched: Function = () => {};

  private onChange: Function = () => {};

  constructor(
    private notificationsService: NotificationsServiceWithTranslate,
    private spinner: NgxSpinnerService,
    private fileService: FileService,
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.previews) {
      this.previews.forEach((preview: string) => this.addPreview(preview));
    }
  }

  public onInputBlur(): void {
    this.onTouched();
    this.focusout.emit();
  }

  public remove(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.removeFile.emit(this.file);
  }

  public resetData(): void {
    this.previews = [];
    this.fileWrappers = [{ uploaded: false, preview: null } as IFileWrapper];
    this.fileUrl = null;
    this.file = null;
  }

  public get maxSizeLabel(): string {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return `${this.maxSize / 1024 / 1024} MB`;
  }

  public openFileInNewWindow(): void {
    this.fileService.openFileInNewWindow(this.fileId.toString());
  }

  public async uploadFiles(files: FileList): Promise<void> {
    this.spinner.show();
    let error = false;

    Array.prototype.map.call(files, (file: File) => {
      if (file.size > this.maxSize) {
        error = true;
      }
    });

    if (error) {
      this.spinner.hide();

      this.notificationsService.errorWithParams('common.drag_and_drop', 'cant_upload_file', null, 'file_size', {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        maxSize: this.maxSize / 1024 / 1024,
      });

      this.isBigSizeError = true;
      return;
    }
    this.isBigSizeError = false;

    if (files?.length) {
      this.clearFirstFile();
    }

    const filled = this.fileWrappers.every((wrapper: IFileWrapper) => !!wrapper.preview);
    if (filled) {
      this.spinner.hide();
      return;
    }

    // eslint-disable-next-line consistent-return
    const promises: Promise<{ preview: string; file: File }>[] = Array.prototype.map.call(files, (file: File) => {
      if (!this.extension || (this.extension.includes(file.type) && file.type)) {
        return new Promise((resolve: Function) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve({
              preview: file.type === 'application/pdf' ? 'assets/icons/v2/ic_pdf-icon.svg' : (reader.result as string),
              file,
            });
          };
          reader.readAsDataURL(file);
        });
      }

      if (!file.type && (file.name.includes('heic') || file.name.includes('HEIC'))) {
        return new Promise((resolve: Function) => {
          heic2any({
            blob: file,
            toType: 'image/png',
          }).then((convertHeicTopng: any) => {
            const reader = new FileReader();

            reader.readAsDataURL(convertHeicTopng);
            reader.onloadend = () => {
              const base64data = reader.result;
              reader.onload = () => {
                resolve({
                  preview: base64data as string,
                  file,
                });
              };
              reader.readAsDataURL(file);
            };
          });
        });
      }
    });

    const previews = await Promise.all(promises);

    previews
      .filter((_, i: number) => i <= this.maxFilesCount - this.fileWrappers.length)
      .forEach((file: { preview: string; file: File }) => this.addPreview(file.preview, file.file));

    this.emitFiles();
  }

  public deleteFile(fileWrapper: IFileWrapper): void {
    const index: number = this.fileWrappers.indexOf(fileWrapper);

    if (this.fileWrappers.length === this.maxFilesCount) {
      this.addPreview();
    }
    this.fileWrappers.splice(index, 1);
    this.emitFiles();
  }

  public clearFirstFile(): void {
    const index: number = 0;
    this.fileWrappers[index] = { uploaded: false, preview: null } as IFileWrapper;
  }

  public writeValue(obj: any): void {
    this.file = obj;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private addPreview(preview?: string, file?: File): void {
    const index: number = this.fileWrappers.length - 1 >= 0 ? this.fileWrappers.length - 1 : 0;
    if (this.fileWrappers[index] && !this.fileWrappers[index].preview) {
      this.fileWrappers.splice(index, 1);
    }
    this.fileWrappers.push({
      uploaded: !!preview,
      file,
      preview: preview || null,
    } as IFileWrapper);

    if (this.fileWrappers.length < this.maxFilesCount) {
      this.fileWrappers.push({ preview: '', uploaded: false } as IFileWrapper);
    }
  }

  private emitFiles(): void {
    this.spinner.hide();
    const files: File[] = this.fileWrappers.map((wrapper: IFileWrapper) => wrapper.file).filter((file: File) => !!file);
    this.filesChange.emit(files);

    if (this.isMessageFile) {
      this.fileWrappers = [{ uploaded: false, preview: null } as IFileWrapper];
      this.file = null;
      this.fileInput.nativeElement.value = '';
    }
  }
}
