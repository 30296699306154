import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MenuItem } from '@models/menu-item.model';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent implements OnInit {
  @Input() items: MenuItem[];

  @Input() main: MenuItem;

  @Input() icon: string;

  public isMenuOpened = false;

  public currentUrl: string;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.currentUrl = this.router.routerState.snapshot.url;
    this.changeToggle();
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.currentUrl = event.url;
      this.changeToggle();
    });
  }

  changeToggle() {
    this.isMenuOpened = !!(this.currentUrl.indexOf(this.main.rout) + 1);
  }

  toggleMenu() {
    if (!this.items.length) {
      if (this.main.rout !== 'null') {
        this.router.navigate([this.main.rout]);
      }
      return;
    }
    this.isMenuOpened = !this.isMenuOpened;
  }
}
