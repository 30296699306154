import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconModule } from '@default-application-app/modules/shared/icon/icon.module';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from '@default-application-app/modules/components/buttons/button.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { SvgIconModule } from '@default-application-app/modules/components/svg-icon/svg-icon.module';
import { FiltersButtonComponent } from './filters-button/filters-button.component';
import { MoreButtonComponent } from './more-button/more-button.component';
import { AddButtonComponent } from './add-button/add-button.component';

@NgModule({
  declarations: [ButtonComponent, FiltersButtonComponent, MoreButtonComponent, AddButtonComponent],
  imports: [CommonModule, IconModule, RouterModule, InlineSVGModule, SvgIconModule],
  exports: [ButtonComponent, MoreButtonComponent, FiltersButtonComponent, AddButtonComponent],
})
export class ButtonsModule {}
