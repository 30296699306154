<div class="group" [ngClass]="{ rtl: isRtl }">
  <div (click)="open($event)" class="header" [class.active]="isActive">
    <app-svg-icon [iconPath]="iconName" class="header-icon"></app-svg-icon>

    <span class="header-title" [title]="'layout.aside_menu.' + title | translate">
      {{ 'layout.aside_menu.' + title | translate }}
    </span>

    <app-svg-icon
      *ngIf="subItems"
      class="header-arrow"
      [class.active]="isActive"
      iconPath="menu/ic_arrow.svg"></app-svg-icon>
  </div>

  <div
    *ngIf="subItems"
    class="items"
    [class.open]="isActive"
    elementHeight
    [elementsCount]="subItems?.length"
    [elementsOpen]="isActive">
    <ng-container *ngFor="let item of subItems">
      <ng-container *ngIf="item.permissions && item.permissions.length">
        <div
          class="item"
          [routerLink]="item.route"
          *userPermissions="item.permissions"
          routerLinkActive="active"
          (click)="open($event, item)">
          <app-svg-icon class="item-icon" iconPath="menu/ic_dot.svg"></app-svg-icon>

          <span class="item-title" [title]="'layout.aside_menu.' + item.title | translate">
            {{ 'layout.aside_menu.' + item.title | translate }}
          </span>
        </div>
      </ng-container>

      <ng-container *ngIf="!item.permissions">
        <div class="item" [routerLink]="item.route" routerLinkActive="active" (click)="open($event, item)">
          <app-svg-icon class="item-icon" iconPath="menu/ic_dot.svg"></app-svg-icon>

          <span class="item-title" [title]="'layout.aside_menu.' + item.title | translate">
            {{ 'layout.aside_menu.' + item.title | translate }}
          </span>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
