import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input()
  public isRadio = false;

  @Input()
  public isCustomIcon = false;

  @Input()
  public text: string;

  @Input()
  public isRightPosition = false;

  public isShow = false;

  public onFocus() {
    this.isShow = !this.isShow;
  }

  public onBlur() {
    this.isShow = false;
  }
}
