import { RequestDataProcessor } from '@default-application-app/core/services/transfer/request-data/request-data-processor';
import { CurrencyAmount } from '@default-application-app/core/models/currency-amount';
import { OWT_OUTGOING_PURPOSE } from '@default-application-app/core/constants/transaction-purposes';
import {
  AdditionalDetail,
  AdditionalDetailField,
} from '@default-application-app/core/models/transfers/transfer-request-data';
import { CommonRequestProcessor } from '@default-application-app/core/services/transfer/request-data/common-request-processor';

export class OwtRequestDataProcessor implements RequestDataProcessor {
  private commonRequestProcessor: CommonRequestProcessor;

  constructor(private fullData: any) {
    this.commonRequestProcessor = new CommonRequestProcessor(fullData);
  }

  public getRequestAmount(): CurrencyAmount {
    const amount = (parseFloat(this.fullData.request.request.amount) / parseFloat(this.getRate().amount)).toString();
    return new CurrencyAmount(this.fullData.request.request.referenceCurrencyCode, amount);
  }

  public getCurrencyCode(): string {
    // return this.commonRequestProcessor.getCurrencyCodeByPurpose(OWT_OUTGOING_PURPOSE);
    return this.fullData.request.request.referenceCurrencyCode;
  }

  public getRate(): CurrencyAmount {
    return new CurrencyAmount(this.fullData.request.request.baseCurrencyCode, this.fullData.request.request.rate);
  }

  public getAdditionalDetails(): AdditionalDetail[] {
    const additionalData = this.fullData.data;
    return [
      new AdditionalDetail('debit_from_account', [
        new AdditionalDetailField('account_number', this.getAccountFromNumber()),
      ]),
      new AdditionalDetail('beneficiary_bank', [
        new AdditionalDetailField('swift_bic', additionalData.bankDetails.swiftCode),
        new AdditionalDetailField('name', additionalData.bankDetails.bankName),
        new AdditionalDetailField('address', additionalData.bankDetails.address),
        new AdditionalDetailField('location', additionalData.bankDetails.location),
        new AdditionalDetailField('country', additionalData.bankDetails.country.code),
        new AdditionalDetailField('acc_iban', additionalData.bankDetails.iban),
        new AdditionalDetailField('aba_rtn', additionalData.bankDetails.abaNumber),
      ]),
      new AdditionalDetail(
        'intermediary_bank',
        additionalData.intermediaryBankDetails
          ? [
              new AdditionalDetailField('swift_bic', additionalData.intermediaryBankDetails.swiftCode),
              new AdditionalDetailField('name', additionalData.intermediaryBankDetails.bankName),
              new AdditionalDetailField('address', additionalData.intermediaryBankDetails.address),
              new AdditionalDetailField('location', additionalData.intermediaryBankDetails.location),
              new AdditionalDetailField('country', additionalData.intermediaryBankDetails.country.code),
              new AdditionalDetailField('acc_iban', additionalData.intermediaryBankDetails.iban),
              new AdditionalDetailField('aba_rtn', additionalData.intermediaryBankDetails.abaNumber),
            ]
          : [],
      ),
      new AdditionalDetail('beneficiary_customer', [
        new AdditionalDetailField('name', additionalData.beneficiaryCustomer.accountName),
        new AdditionalDetailField('address', additionalData.beneficiaryCustomer.address),
        new AdditionalDetailField('acc_iban', additionalData.beneficiaryCustomer.iban),
      ]),
      new AdditionalDetail('additional_information', [
        new AdditionalDetailField('ref_message', additionalData.refMessage),
      ]),
    ];
  }

  private getAccountFromNumber(): string {
    return this.commonRequestProcessor.getAccountNumberByPurpose(OWT_OUTGOING_PURPOSE);
  }
}
