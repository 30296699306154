import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDeleteDirective } from '@default-application-app/modules/shared/confirm-delete-popup/confirm-delete.directive';
import { ConfirmDeletePopupComponent } from '@default-application-app/modules/shared/confirm-delete-popup/components/confirm-delete-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@default-application-app/modules/components/buttons/buttons.module';

@NgModule({
  imports: [CommonModule, TranslateModule, ButtonsModule],
  declarations: [ConfirmDeletePopupComponent, ConfirmDeleteDirective],
  exports: [ConfirmDeletePopupComponent, ConfirmDeleteDirective],
  providers: [],
})
export class ConfirmDeletePopupModule {}
