import { Component, OnInit } from '@angular/core';
import { OptionsPrefixes, OptionsValuesNames } from '@constants/optionsPrefixes';
import { map } from 'rxjs/operators';
import { OptionsApiService } from '@services/options/options-api.service';
import { OptionsHelper } from '@helpers/options.helper';
import { AppOptionsService } from '@services/appOptions.service';
import { environment } from '@default-application/src/environments/environment';

@Component({
  selector: 'app-maintenance-message',
  templateUrl: './maintenance-message.component.html',
  styleUrls: [
    './maintenance-message.component.scss',
    '../../modules/shared/pages/components/simple-header/simple-header.component.scss',
  ],
})
export class MaintenanceMessageComponent implements OnInit {
  public messageText = 'This system is temporarily unavailable.';

  public isSeparateText = false;

  public environment = environment;

  private optionsHelper: OptionsHelper;

  constructor(private optionsApiService: OptionsApiService, private appOptionService: AppOptionsService) {
    this.optionsHelper = new OptionsHelper(OptionsPrefixes.SYSTEM_CONFIG_GENERAL);
  }

  ngOnInit() {
    this.subscribeToOptions();
  }

  private subscribeToOptions(): void {
    this.appOptionService
      .getOptionsValuePipe(OptionsValuesNames.MAINTENANCE_TEXT)
      .pipe(map((value: string): string => value))
      .subscribe((value: string) => {
        this.messageText = value;
      });
  }
}
