import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertCreditCard',
})
export class ConvertCreditCardNumberPipe implements PipeTransform {
  private readonly CARD_NUMBER_VISIBLE_LENGTH = 8;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(cardNumber: string, numberFormat: string): string {
    return cardNumber
      .slice(-this.CARD_NUMBER_VISIBLE_LENGTH)
      .replace(/\s+/g, '')
      .replace(/(\d{4})/g, '$1 ')
      .trim()
      .replace(/(\d{4})\s/g, '**** ');
  }
}
