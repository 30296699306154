import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Message } from '@models/messages-model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ConfigService } from '@default-application-app/config.service';
import { ApiCallerService } from '@services/api-caller.service';
import { PaginationPageLimitInterface } from '@interfaces/pagination-page-limit.interface';
import { PaginationService } from '@services/pagination/pagination.service';
import { MessagesFilterInterface } from '@interfaces/messagesFilter.interface';
import { debounceTime, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { CallResponceInterface } from '@interfaces/callResponce.interface';
import { AuthService } from '@services/auth/auth.service';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data.min';
import { FileDownloadModel } from '@models/file-download.model';
import { Router } from '@angular/router';
import { StorageService } from '@services/storage.service';

export const UPDATE_UNREAD_MESSAGES_COUNT_TIMEOUT_MS = 60000;
export const CHECK_UNREAD_MESSAGES_COUNT_TIMEOUT_MS = 55000;

@Injectable()
export class MessagingApiService {
  /** @type {Subject<any>} */
  private onGetUnreadCountSubject$: Subject<number> = new Subject<number>();

  /** @type {Observable<any>} */
  public onGetUnreadCount$: Observable<number> = this.onGetUnreadCountSubject$.asObservable();

  /** @type {Subject<any>} */
  private defaultFilter: MessagesFilterInterface = {
    dateFrom: '',
    dateTo: '',
    searchField: 'All',
    searchQuery: '',
    sortField: 'last_message_created_at',
    sortDir: 'desc',
    page: '1',
    limit: PaginationService.defaultLimit.toString(),
    type: '',
    isUnread: '',
  };

  private onFilterChangeSubject$: BehaviorSubject<MessagesFilterInterface> =
    new BehaviorSubject<MessagesFilterInterface>({ ...this.defaultFilter });

  public messages$: Observable<{ messages: Message[]; pagination: PaginationPageLimitInterface }> =
    this.onFilterChangeSubject$.asObservable().pipe(
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      debounceTime(250),
      map((filterData: MessagesFilterInterface): object => this.clearBlankFilters(filterData)),
      switchMap((data: { [key: string]: string }): Observable<CallResponceInterface> => {
        if (this.auth.isAdminOrRootUser() && data.type === 'incoming') {
          return this.apiGetUnassignedAndIncomingForAdmin(data);
        }
        return this.apiGetMessagesWithFilters(data);
      }),
      map(({ data, error, links }: CallResponceInterface) => {
        if (error) {
          return { messages: [], pagination: { ...PaginationService.defaultPaginationPageLimit } };
        }
        const messages = (<any[]>data).map((message) => new Message(message));
        const pagination = PaginationService.buildPaginationOffsetLimit(links);
        return { messages, pagination };
      }),
      startWith({ messages: [], pagination: { ...PaginationService.defaultPaginationPageLimit } }),
      shareReplay(1),
    );

  private $filter: MessagesFilterInterface = { ...this.defaultFilter };

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private apiCallerService: ApiCallerService,
    private router: Router,
    private auth: AuthService,
    private storage: StorageService,
  ) {}

  /** Get filter */
  public filter(): Observable<MessagesFilterInterface> {
    return this.onFilterChangeSubject$.asObservable();
  }

  public getFilter(): MessagesFilterInterface {
    return this.$filter;
  }

  /**
   * Set filter, or replace an existing one
   *
   * @param {string | any} name Parameter name
   * @param {any} value Parameter value
   */
  public setFilter(name: string | any, value: any = null) {
    if (typeof name === 'string') {
      this.$filter[name] = value;
    } else {
      this.$filter = {
        ...this.$filter,
        ...name,
      };
    }

    this.onFilterChangeSubject$.next(this.$filter);
  }

  /**
   * Returns messages list for particular conversation by parent message id
   *
   * @param {number} messageId
   * @returns {Observable<any>}
   */
  public getMessageTree(messageId: number): Observable<any> {
    return this.http.get(this.configService.config.api.message.messageById(messageId));
  }

  /** @returns {Observable<number>} */
  public updateUnreadMessagesCount(forceUpdate: boolean = false): void {
    if (!forceUpdate) {
      const countUpdatedAt = parseInt(this.storage.getItem('unreadMessagesCountUpdatedAt'), 10);
      if (countUpdatedAt > Date.now() - CHECK_UNREAD_MESSAGES_COUNT_TIMEOUT_MS) {
        this.onGetUnreadCountSubject$.next(parseInt(this.storage.getItem('unreadMessagesCount'), 10));
        return;
      }
    }

    this.apiGetUnreadMessagesCount().subscribe(({ data, error }: { data: any; error: boolean }) => {
      if (error) {
        this.onGetUnreadCountSubject$.next(0);
        return;
      }
      this.storage.setItem('unreadMessagesCount', data);
      this.storage.setItem('unreadMessagesCountUpdatedAt', Date.now().toString());
      this.onGetUnreadCountSubject$.next(typeof data === 'number' ? data : 0);
    });
  }

  /**
   * Load single accounts
   *
   * @returns {Observable<any>}
   */
  public apiGetUnreadMessagesCount() {
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.message.unread),
      'apiGetUnreadMessagesCount',
    );
  }

  /**
   * Load single accounts
   *
   * @returns {Observable<any>}
   */
  public apiGetMessagesWithFilters(params: { [key: string]: string }) {
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.message.messages, { params: this.setDatesWithZone(params) }),
      'apiGetMessagesWithFilters',
    );
  }

  /**
   * Load single accounts
   *
   * @returns {Observable<any>}
   */
  public apiGetUnassignedAndIncomingForAdmin(params: { [key: string]: string }) {
    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.message.adminUnassignedAndIncomingMessages, {
          params: this.setDatesWithZone(params),
        }),
      'apiGetUnassignedAndIncomingForAdmin',
    );
  }

  /** @returns {Observable<any>} */
  public apiGetCsvMessagesWithFilters(params: any) {
    return this.http
      .get(this.configService.config.api.message.exportCsv.messages, {
        params: this.setDatesWithZone(params),
        responseType: 'arraybuffer',
        observe: 'response',
      })
      .pipe(
        map(
          (res: HttpResponse<ArrayBuffer>): FileDownloadModel =>
            new FileDownloadModel(
              new Blob([res.body], { type: 'text/csv' }),
              res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1],
            ),
        ),
      );
  }

  /** @returns {Observable<any>} */
  public apiGetCsvUnassignedAndIncomingForAdmin(params: any) {
    return this.http
      .get(this.configService.config.api.message.exportCsv.adminUnassignedAndIncomingMessages, {
        params: this.setDatesWithZone(params),
        responseType: 'arraybuffer',
        observe: 'response',
      })
      .pipe(
        map(
          (res: HttpResponse<ArrayBuffer>): FileDownloadModel =>
            new FileDownloadModel(
              new Blob([res.body], { type: 'text/csv' }),
              res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1],
            ),
        ),
      );
  }

  /**
   * Send message from user or admin
   *
   * @param {{}} form
   * @returns {Observable<any>}
   */
  public sendMessage(form: object): Observable<any> {
    return this.apiCallerService.call(
      () => this.http.post(this.configService.config.api.message.messages, form),
      'apiSendMessage',
    );
  }

  /**
   * Send message from user or admin
   *
   * @param {{}} form
   * @returns {Observable<any>}
   */
  public sendMessageToAll(form: object): Observable<any> {
    return this.http.post(this.configService.config.api.message.sendToAll, form);
  }

  /**
   * Send message from user or admin
   *
   * @param {{}} form
   * @returns {Observable<any>}
   */
  public sendMessageToSpecificGroup(form: object): Observable<any> {
    return this.http.post(this.configService.config.api.message.sendToSpecificGroup, form);
  }

  /**
   * Send message from user or admin
   *
   * @param {{}} form
   * @returns {Observable<any>}
   */
  public sendMessageToSpecificUsers(form: object): Observable<any> {
    return this.http.post(this.configService.config.api.message.sendToSpecificUsers, form);
  }

  public updateMessageField(messageId: number, fieldName: string, value: any): Observable<any> {
    const body = {};
    body[fieldName] = value;
    return this.http.patch(this.configService.config.api.message.messageById(messageId), body);
  }

  /**
   * Update the whole message
   *
   * @param messageId
   * @param message
   * @returns {Observable<any>}
   */
  public updateMessage(messageId: number, message: object): Observable<any> {
    return this.http.put(this.configService.config.api.message.messageById(messageId), message);
  }

  /**
   * Mark message as deleted at server
   *
   * @param {number} messageId
   * @param {boolean} forAll
   * @returns {Observable<any>}
   */
  public deleteMessage(messageId: number, forAll: boolean): Observable<any> {
    if (forAll) {
      return this.http.delete(this.configService.config.api.message.deleteForAll(messageId));
    }
    return this.http.delete(this.configService.config.api.message.deleteForMe(messageId));
  }

  public resetFilter(type: string) {
    this.$filter = { ...this.defaultFilter, type };
    this.onFilterChangeSubject$.next(this.$filter);
  }

  public clearBlankFilters(filterData: MessagesFilterInterface): object {
    const params = {};

    Object.keys(filterData).forEach((key: string): void => {
      if (filterData[key]) {
        if (key === 'page') {
          // @ts-ignore
          params.offset = parseInt(filterData.limit, 10) * (parseInt(filterData.page, 10) - 1);
        } else {
          params[key] = filterData[key];
        }
      }
    });
    return params;
  }

  private setDatesWithZone(userFilter: { [key: string]: string }): { [key: string]: string } {
    if (userFilter.dateFrom) {
      userFilter.dateFrom = moment(userFilter.dateFrom).startOf('day').utc().format();
    }
    if (userFilter.dateTo) {
      userFilter.dateTo = moment(userFilter.dateTo).endOf('day').utc().format();
    }
    return userFilter;
  }
}
