import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: 'icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input() iconName: string;

  @Input() maskSize: string;

  @Input() color?: string;

  get iconPath(): string {
    return `url(/assets/icons/${this.iconName}.svg`;
  }
}
