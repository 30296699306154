import { AfterViewInit, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormGroup,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
} from '@angular/forms';
import { UserOptionNames } from '@constants/optionsPrefixes';

@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPasswordComponent),
      multi: true,
    },
  ],
})
export class InputPasswordComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('input') input: ElementRef;

  @Input() placeholder: string = '';

  @Input() items: any[];

  @Input() controlName: string;

  @Input() label: string;

  @Input() title: string;

  @Input() type: string = 'text';

  @Input() loading: boolean;

  @Input() optionName: UserOptionNames;

  @Input() formControlName: string;

  @Input() mask;

  @Input() isRequiredIf: boolean;

  @Input() isDisabled: boolean = false;

  @Input() isRequiredLabel: boolean = false;

  @Input() isAutocompleteDisabled: boolean = false;

  @Input() value;

  @Input() isReadonly: boolean = false;

  @Input() isOnlyFloat: boolean = false;

  @Input() cardInputClass: boolean = false;

  @Input() isLockFieldClass: boolean = false;

  @Input() isFilledClass: boolean = false;

  public isShowPassword: boolean = false;

  constructor(private controlContainer: ControlContainer) {}

  public onChange: any = () => {};

  public onTouched: any = () => {};

  public ngAfterViewInit() {
    if (this.value) {
      this.input.nativeElement.classList.add('filled');
    }
  }

  public writeValue(value): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private get form(): UntypedFormGroup {
    return this.controlContainer.formDirective
      ? ((this.controlContainer.formDirective as FormGroupDirective).form as FormGroup)
      : null;
  }

  public get isFormControlDisabled(): boolean {
    if (this.form.get(this.formControlName)) {
      return this.form.get(this.formControlName).disabled;
    }

    return false;
  }

  public showHidePassword(): void {
    this.isShowPassword = !this.isShowPassword;
  }
}
