import { Component } from '@angular/core';
import { DropDownMenuComponent } from '@default-application-app/modules/components/drop-down-menu/drop-down-menu.component';

@Component({
  selector: 'app-table-drop-down-menu',
  templateUrl: './table-drop-down-menu.component.html',
  styleUrls: ['./table-drop-down-menu.component.scss'],
})
export class TableDropDownMenuComponent extends DropDownMenuComponent {
  constructor() {
    super();
  }
}
