import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-drag-and-drop',
  templateUrl: './modal-drag-and-drop.component.html',
  styleUrls: ['./modal-drag-and-drop.component.scss'],
})
export class ModalDragAndDropComponent {
  public visible = false;

  public show(): void {
    this.visible = true;
    document.body.style.overflow = 'hidden';
  }

  public hide(): void {
    this.visible = false;
    document.body.style.overflow = 'auto';
  }
}
