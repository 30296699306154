import { RequestDataProcessor } from '@default-application-app/core/services/transfer/request-data/request-data-processor';
import { CurrencyAmount } from '@default-application-app/core/models/currency-amount';
import {
  IBAN_TBU_INCOMING_PURPOSE,
} from '@default-application-app/core/constants/transaction-purposes';
import {
  AdditionalDetail,
  AdditionalDetailField,
} from '@default-application-app/core/models/transfers/transfer-request-data';
import { CommonRequestProcessor } from '@default-application-app/core/services/transfer/request-data/common-request-processor';

export class IbanTbuIncomingRequestDataProcessor implements RequestDataProcessor {
  private commonRequestProcessor: CommonRequestProcessor;

  constructor(private fullData: any) {
    this.commonRequestProcessor = new CommonRequestProcessor(fullData);
  }

  public getRequestAmount(): CurrencyAmount {
    return new CurrencyAmount(this.getCurrencyCode(), this.fullData.request.request.amount);
  }

  public getCurrencyCode(): string {
    return this.commonRequestProcessor.getCurrencyCodeByPurpose(IBAN_TBU_INCOMING_PURPOSE);
  }

  public getRate(): CurrencyAmount {
    const currencyCode = this.commonRequestProcessor.getCurrencyCodeByPurpose(IBAN_TBU_INCOMING_PURPOSE);
    return new CurrencyAmount(currencyCode, this.fullData.request.request.rate);
  }

  public getAdditionalDetails(): AdditionalDetail[] {
    return [
      new AdditionalDetail('credit_to_account', [
        new AdditionalDetailField('account_number', this.getAccountToNumber()),
      ]),
    ];
  }

  private getAccountToNumber(): string {
    return this.commonRequestProcessor.getAccountNumberByPurpose(IBAN_TBU_INCOMING_PURPOSE);
  }
}
