export const Languages = [
  {
    code: 'en',
    default: true,
    name: 'English',
  },
  {
    code: 'ar',
    name: 'عرب',
    default: false,
  },
];

export const DefaultLanguage = 'en';
export const ArabicLanguage = 'ar';
