import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { queryParamsStringify } from '@default-application-app/core/helpers/queryParamsHelpers';
import { CallResponceInterface } from '@interfaces/callResponce.interface';
import { DelegateInterface } from '@interfaces/delegate-interface';
import { DelegateModel } from '@models/delegate.model';
import { SelectedDelegateInterface } from '@default-application-app/modules/user-cash/interfaces/selected-delegate.interface';
import { DelegateApiService } from './delegate-api.service';

@Injectable()
export class DelegateService {
  public closeSelectDelegateDialog$: BehaviorSubject<SelectedDelegateInterface> =
    new BehaviorSubject<SelectedDelegateInterface>(null);

  constructor(private delegateApiService: DelegateApiService) {}

  public deleteDelegateById(id: number): Observable<CallResponceInterface> {
    return this.delegateApiService.apiDeleteDelegateById(id);
  }

  public createDelegate(data: DelegateInterface): Observable<CallResponceInterface> {
    return this.delegateApiService.apiCreateDelegate(data);
  }

  private static transformLegalEntitiesParams(queryParams: { [key: string]: string }): { [key: string]: string } {
    const params = {};
    const filter = {};

    // @ts-ignore
    params.page = { size: '1000' };

    if (queryParams.legalEntityType) {
      filter['legalEntityType'] = queryParams.legalEntityType;
    }

    if (Object.keys(filter).length) {
      params['filter'] = filter;
    }

    return queryParamsStringify(params, false);
  }

  public loadDelegates(): Observable<DelegateModel[]> {
    return this.delegateApiService.apiLoadDelegates().pipe(
      map((data: CallResponceInterface) =>
        // @ts-ignore
        (data.data.items as DelegateInterface[]).map((item: DelegateInterface) => new DelegateModel(item)),
      ),
    );
  }

  public loadAdminDelegatesForUser(id: string): Observable<DelegateModel[]> {
    return this.delegateApiService.apiLoadAdminDelegatesForUser(id).pipe(
      map((data: CallResponceInterface) =>
        // @ts-ignore
        (data.data.items as DelegateInterface[]).map((item: DelegateInterface) => new DelegateModel(item)),
      ),
    );
  }
}
