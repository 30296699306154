import { FileInterface } from '@default-application-app/core/interfaces/file-interface';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FileService } from '@default-application/src/app/modules/shared/file-service/services/file.service';
import heic2any from 'heic2any';

export class FileModel implements FileInterface {
  id: number;

  fileId?: number;

  contentType: string;

  documentType?: string;

  filename: string;

  size: number;

  userId: string;

  isAdminOnly: boolean;

  isPrivate: boolean;

  createdAt: string;

  updatedAt: string;

  private $fileLocation: string;

  private blobFile: Blob;

  public constructor(params: any, private fileService: FileService) {
    Object.assign(this, params);
  }

  public fileLocation(isOpenFile: boolean = false): Observable<string> {
    if (this.$fileLocation) {
      return of(this.$fileLocation);
    }
    return this.loadFileData().pipe(
      switchMap((blob: Blob) => {
        this.blobFile = blob;
        const URL = window.URL || (window as any).webkitURL;

        if (blob.type === 'application/octet-stream') {
          return from(
            heic2any({
              blob,
              toType: 'image/png',
            }),
          ).pipe(
            map((data) => {
              this.$fileLocation = URL.createObjectURL(data);
              return this.$fileLocation;
            }),
          );
        }

        if (blob.type === 'application/pdf' && !isOpenFile) {
          this.$fileLocation = 'assets/icons/v2/ic_pdf-icon.svg';
          return of(this.$fileLocation);
        }

        this.$fileLocation = URL.createObjectURL(blob);
        return of(this.$fileLocation);
      }),
    );
  }

  public getBlobFile(): Observable<Blob> {
    if (this.blobFile) {
      return of(this.blobFile);
    }
    return this.loadFileData().pipe(
      map((blob: Blob): Blob => {
        this.blobFile = blob;
        const URL = window.URL || (window as any).webkitURL;
        this.$fileLocation = URL.createObjectURL(blob);
        return blob;
      }),
    );
  }

  public getPublicFileUrl(): string {
    return this.fileService.getPublicFileUrl(this.id);
  }

  private loadFileData(): Observable<Blob> {
    return this.fileService
      .localFileUrl(this.id)
      .pipe(map((binary: ArrayBuffer): Blob => new Blob([binary], { type: this.contentType })));
  }
}
