import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
  CreateTransactionRequest,
  DataResponceConvertParams,
  IAdminTbaRequest,
  IAdminTbaRequestPreview,
  IAdminTbuRequest,
  IAdminTbuRequestPreview,
  ICftRequest,
  ICftRequestPreview,
  IIncomingAmount,
  IOwtRequest,
  IOwtRequestPreview,
  ISuccessfulTransfer,
  ITbaRequest,
  ITbaRequestPreview,
  ITbuRequest,
  ITbuRequestPreview,
  ITotalOutgoingAmount,
  PreviewConvertParams,
  PreviewTransactionRequest,
} from '@default-application-app/core/interfaces/transfer-request-interface';
import { TransferApiService } from '@default-application-app/core/services/transfer/transfer-api.service';
import { UserGroupTransfersApiService } from '@default-application/src/app/modules/group-transfer/services/user-group-transfers-api.services';
import { SuccessfulTransferModel } from '@default-application-app/core/models/successful-transfer-model';
import { TransferRequest } from '@default-application-app/core/models/transfers/transfer-request';
import { PaginationService } from '@default-application-app/core/services/pagination/pagination.service';
import { TransferRequestData } from '@default-application-app/core/models/transfers/transfer-request-data';
import { TransferRequestDataBuilder } from '@default-application-app/core/services/transfer/request-data/transfer-request-data-builder';
import { ApiError } from '@default-application-app/core/models/api-error.model';
import { exhaustMap, map, shareReplay, switchMap } from 'rxjs/operators';
import { CallResponceInterface } from '@default-application-app/core/interfaces/callResponce.interface';
import * as FileSaver from 'file-saver';
import { FileDownloadModel } from '@default-application-app/core/models/file-download.model';
import { QueryFieldInterface } from '@default-application-app/core/interfaces/sort-bar/queryField.interface';
import { DateToFromFieldInterface } from '@default-application-app/core/interfaces/sort-bar/dateToFromField.interface';
import { SortFieldInterface } from '@default-application-app/core/interfaces/sort-bar/sortField.interface';
import { PaginationFieldInterface } from '@default-application-app/core/interfaces/sort-bar/paginationField.interface';
import { queryParamsStringify } from '@default-application-app/core/helpers/queryParamsHelpers';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data.min';
import { Transaction } from '@default-application-app/core/models/transaction';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserRequestModel } from '@models/userRequest.model';
import { UserRequestsData } from '@default-application-app/modules/user-account/services/transaction.service';

export enum TransferTypes {
  USER_TBA = 'userTBA',
  ADMIN_TBA = 'adminTBA',
  USER_TBU = 'userTBU',
  ADMIN_TBU = 'adminTBU',
  USER_CFT = 'userCFT',
  ADMIN_OWT = 'adminOWT',
  USER_OWT = 'userOWT',
}

export type TransferRequestFilter = QueryFieldInterface &
  DateToFromFieldInterface &
  SortFieldInterface &
  PaginationFieldInterface & {
    subject: string;
    baseCurrencyCode?: string;
    status: string;
    accountId?: string;
    isForView?: string;
  };

export class TransferRequestsData {
  constructor(
    public transferRequests: TransferRequest[],
    public totalRecords: number,
    public pageSize: number,
    public page: number,
  ) {}
}

@Injectable()
export class TransferService {
  /** @type {Subject<TransferRequestsData>} */
  public getRequestsSubject$: Subject<TransferRequestFilter> = new Subject<TransferRequestFilter>();

  /** @type {Observable<TransferRequestsData>} */
  public onGetRequests$: Observable<TransferRequestsData | null> = this.getRequestsSubject$.asObservable().pipe(
    map((query: TransferRequestFilter) => TransferService.transformParams(query)),
    switchMap((params: { [key: string]: string }) => this.transferApiService.apiGetRequests(params)),
    map(({ data, error, links }: { data: any[]; error: boolean; links: any }) => {
      if (error) {
        return null;
      }
      const requests = data.map((item) => new TransferRequest(item));

      const pagination = PaginationService.buildPaginationSizeNumber(links);
      return new TransferRequestsData(requests, pagination.totalRecords, pagination.limit, pagination.currentPage);
    }),
  );

  /** @type {Subject<IIncomingAmount>} */
  private onExecuteRequestSubject$: Subject<string> = new Subject<string>();

  /** @type {Observable<IIncomingAmount>} */
  public onExecuteRequest$: Observable<boolean> = this.onExecuteRequestSubject$.asObservable().pipe(
    exhaustMap((id: string) => this.transferApiService.apiExecuteRequest(id)),
    map(({ error }: { error: boolean }) => !error),
  );

  /** @type {Subject<any>} */
  private onImportTransfersSubject$: Subject<any> = new Subject<any>();

  /** @type {Observable<any[]>} */
  public onImportTransfers$: Observable<any> = this.onImportTransfersSubject$.asObservable();

  /** @type {Subject<any>} */
  private onUpdateTransfersSubject$: Subject<any> = new Subject<any>();

  /** @type {Observable<any[]>} */
  public onUpdateTransfers$: Observable<any> = this.onUpdateTransfersSubject$.asObservable();

  public loadIncomingAmount: Subject<{ type: string; data: { body: PreviewTransactionRequest; userId?: string } }> =
    new Subject<{ type: string; data: { body: PreviewTransactionRequest; userId?: string } }>();

  public onLoadIncomingAmount$: Observable<{ data: IIncomingAmount | ApiError[]; error: boolean }> =
    this.loadIncomingAmount.asObservable().pipe(
      exhaustMap(
        // eslint-disable-next-line consistent-return
        ({ type, data }: { type: TransferTypes; data: { body: PreviewTransactionRequest; userId?: string } }) => {
          switch (type) {
            case TransferTypes.USER_TBA:
              return this.transferApiService.apiGetTbaRequestIncomingAmount(<ITbaRequestPreview>data.body);
            case TransferTypes.ADMIN_TBA:
              return this.transferApiService.apiGetTbaRequestIncomingAmountAsAdmin(
                data.userId,
                <IAdminTbaRequestPreview>data.body,
              );
            case TransferTypes.USER_TBU:
              return this.transferApiService.apiGetTbuRequestIncomingAmount(<ITbuRequestPreview>data.body);
            case TransferTypes.ADMIN_TBU:
              return this.transferApiService.apiGetTbuRequestIncomingAmountAsAdmin(
                data.userId,
                <IAdminTbuRequestPreview>data.body,
              );
            case TransferTypes.USER_CFT:
              return this.transferApiService.apiGetCftRequestIncomingAmount(<ICftRequestPreview>data.body);
          }
        },
      ),
    );

  public createTransaction: Subject<{ type: string; data: { body: CreateTransactionRequest; userId?: string } }> =
    new Subject<{ type: string; data: { body: CreateTransactionRequest; userId?: string } }>();

  public onCreateRequest$: Observable<{ data: ISuccessfulTransfer | ApiError[]; error: boolean }> =
    this.createTransaction.asObservable().pipe(
      // eslint-disable-next-line consistent-return
      exhaustMap(({ type, data }: { type: string; data: { body: CreateTransactionRequest; userId?: string } }) => {
        switch (type) {
          case TransferTypes.USER_TBA:
            return this.transferApiService.apiCreateTbaRequest(<ITbaRequest>data.body);
          case TransferTypes.ADMIN_TBA:
            return this.transferApiService.apiCreateTbaRequestAsAdmin(data.userId, <IAdminTbaRequest>data.body);
          case TransferTypes.USER_TBU:
            return this.transferApiService.apiCreateTbuRequest(<ITbuRequest>data.body);
          case TransferTypes.ADMIN_TBU:
            return this.transferApiService.apiCreateTbuRequestAsAdmin(data.userId, <IAdminTbuRequest>data.body);
          case TransferTypes.USER_CFT:
            return this.transferApiService.apiCreateCftRequest(<ICftRequest>data.body);
          case TransferTypes.USER_OWT:
            return this.transferApiService.apiCreateOwtRequest(<IOwtRequest>data.body);
          case TransferTypes.ADMIN_OWT:
            return this.transferApiService.apiCreateOwtRequestAsAdmin(data.userId, <IOwtRequest>data.body);
        }
      }),
      map(({ data, error }: { data: any; error: boolean }) => {
        if (error) {
          return { data, error };
        }
        return { data: new SuccessfulTransferModel(data), error };
      }),
    );

  public updateRequestBodySubject$: Subject<{ id: string; body: ISuccessfulTransfer }> = new Subject<{
    id: string;
    body: ISuccessfulTransfer;
  }>();

  public onUpdateRequestBody$: Observable<SuccessfulTransferModel | null> = this.updateRequestBodySubject$
    .asObservable()
    .pipe(
      switchMap(({ id, body }: { id: string; body: ISuccessfulTransfer }) =>
        this.transferApiService.apiUpdateRequestBodyByRequestId(id, body),
      ),
      map(({ data, error }: CallResponceInterface): SuccessfulTransferModel | null => {
        if (error) {
          return null;
        }
        return new SuccessfulTransferModel(data);
      }),
    );

  public LoadTotalAmountSubject$: Subject<{ type: string; data: { body: IOwtRequestPreview; userId?: string } }> =
    new Subject<{ type: string; data: { body: IOwtRequestPreview; userId?: string } }>();

  public onLoadTotalAmount$: Observable<{ data: ITotalOutgoingAmount | ApiError[]; error: boolean }> =
    this.LoadTotalAmountSubject$.asObservable().pipe(
      // eslint-disable-next-line consistent-return
      switchMap(({ type, data }: { type: string; data: { body: IOwtRequestPreview; userId?: string } }) => {
        switch (type) {
          case TransferTypes.USER_OWT:
            return this.transferApiService.apiGetOwtRequestTotalAmount(data.body);
          case TransferTypes.ADMIN_OWT:
            return this.transferApiService.apiGetOwtRequestTotalAmountAsAdmin(data.userId, data.body);
        }
      }),
    );

  /** @type {Subject<TransferRequestsData>} */
  public onGetRequestSubject$: BehaviorSubject<String> = new BehaviorSubject<String>(null);

  /** @type {Observable<TransferRequestsData>} */
  public onGetRequest$: Observable<TransferRequestData> = this.onGetRequestSubject$.asObservable().pipe(
    switchMap((id: string) => {
      if (id) {
        return this.transferApiService.apiGetRequest(id);
      }
      return of(null);
    }),
    map(({ data, error }: CallResponceInterface): TransferRequestData | null => {
      this.spinner.hide();
      return error ? null : new TransferRequestDataBuilder(data).call();
    }),
    shareReplay(1),
  );

  /** @type {Subject<TransferRequestsData>} */
  public onGetTransactionSubject$: BehaviorSubject<String> = new BehaviorSubject<String>(null);

  /** @type {Observable<TransferRequestsData>} */
  public onGetTransaction$: Observable<Transaction> = this.onGetTransactionSubject$.asObservable().pipe(
    switchMap((id: string) => this.transferApiService.apiGetTransaction(id)),
    map(({ data, error }: CallResponceInterface): Transaction | null => {
      this.spinner.hide();
      return error ? null : new Transaction(data);
    }),
    shareReplay(1),
  );

  /** @type {Subject<IIncomingAmount>} */
  public onCancelRequestSubject$: Subject<{ id: string; reason: string }> = new Subject<{
    id: string;
    reason: string;
  }>();

  /** @type {Observable<IIncomingAmount>} */
  public onCancelRequest$: Observable<{ data: ApiError[]; error: boolean }> = this.onCancelRequestSubject$
    .asObservable()
    .pipe(
      switchMap(({ id, reason }: { id: string; reason: string }) =>
        this.transferApiService.apiCancelRequest(id, reason),
      ),
    );

  /** RatesForMainCurrency */

  public onRatesForMainCurrencySubject$: Subject<PreviewConvertParams> = new Subject<PreviewConvertParams>();

  public onRatesForMainCurrency$: Observable<DataResponceConvertParams> = this.onRatesForMainCurrencySubject$
    .asObservable()
    .pipe(
      switchMap(
        (params: PreviewConvertParams): Observable<DataResponceConvertParams> =>
          this.transferApiService.apiRatesForMainCurrency(params),
      ),
    );

  private static transformParams(queryParams: TransferRequestFilter): { [key: string]: string } {
    const params = {
      page: { number: queryParams.page, size: queryParams.size },
      include: ['user', 'balanceSnapshots', 'balanceSnapshots.balanceType', 'balanceDifference'],
    };
    const filter = {};
    if (queryParams.sort) {
      // @ts-ignore
      params.sort = queryParams.sort;
    }
    if (queryParams.query) {
      // @ts-ignore
      filter.query = queryParams.query;
    }
    if (queryParams.subject) {
      // @ts-ignore
      filter.subject = queryParams.subject;
    }
    if (queryParams.dateTo) {
      filter['createdAt:lte'] = moment(queryParams.dateTo).endOf('day').utc().format();
    }
    if (queryParams.dateFrom) {
      filter['createdAt:gte'] = moment(queryParams.dateFrom).startOf('day').utc().format();
    }
    if (queryParams.subject) {
      // @ts-ignore
      filter.subject = queryParams.subject;
    }
    if (queryParams.baseCurrencyCode) {
      // @ts-ignore
      filter.baseCurrencyCode = queryParams.baseCurrencyCode;
    }
    if (queryParams.accountId) {
      // @ts-ignore
      filter.accountId = queryParams.accountId;
    }
    if (queryParams.isForView) {
      // @ts-ignore
      params.isForView = queryParams.isForView;
    }
    if (queryParams.status) {
      // @ts-ignore
      filter.status = queryParams.status;
    }
    if (Object.keys(filter).length) {
      // @ts-ignore
      params.filter = filter;
    }
    return queryParamsStringify(params, false);
  }

  constructor(private transferApiService: TransferApiService,
              private groupTransferApiService: UserGroupTransfersApiService,
              private spinner: NgxSpinnerService) {}

  /**
   * Execute request by id
   *
   * @param {string} id
   */
  public executeRequest(id: string): void {
    this.onExecuteRequestSubject$.next(id);
  }

  /** @returns {Observable<any>} */
  public updateTransfersFromCsv(csv: FormData): Observable<any> {
    this.transferApiService.apiUpdateTransfersFromCsv(csv).subscribe(({ data }: { data: any; error: boolean }) => {
      this.onUpdateTransfersSubject$.next(data);
    });

    return this.onUpdateTransfers$;
  }

  /** @returns {Observable<any>} */
  public importTransfersFromCsv(csv: FormData): Observable<any> {
    this.transferApiService.apiImportTransfersFromCsv(csv).subscribe(({ error }: { data: any; error: boolean }) => {
      if (error) {
        this.onImportTransfersSubject$.next(false);
      } else {
        this.onImportTransfersSubject$.next(true);
      }
    });

    return this.onImportTransfers$;
  }

  /** @param {{}} params */
  public exportTransfersToCsv(params: TransferRequestFilter) {
    const transformedParams = TransferService.transformParams(params);
    delete transformedParams.include;
    this.transferApiService.apiExportTransfersToCsv(transformedParams).subscribe((data: FileDownloadModel) => {
      FileSaver.saveAs(data.blob, data.filename);
    });
  }

  public loadRequestsAsAdmin(filter: TransferRequestFilter): Observable<UserRequestsData> {
    return this.transferApiService.apiGetRequests(TransferService.transformParams(filter)).pipe(
      map(({ data, error, links }: { data: any[]; error: boolean; links: any }) => {
        if (error) {
          return new UserRequestsData([], PaginationService.defaultPaginationPageLimit);
        }
        const requests = data.map(
          (item) =>
            new UserRequestModel(item, parseInt(TransferService.transformParams(filter)['filter[accountId]'], 10)),
        );

        const pagination = PaginationService.buildPaginationSizeNumber(links);
        return new UserRequestsData(requests, pagination);
      }),
    );
  }

  public sendSmsToPhone(): Observable<any> {
    return this.transferApiService.apiSendSmsToPhone();
  }

  public sendSmsToEmail(): Observable<any> {
    return this.transferApiService.apiSendSmsToEmail();
  }

  public executeTbuRequest(id: string, otp: string): Observable<CallResponceInterface> {
    return this.transferApiService.apiExecuteTbuRequest(id, otp);
  }

  public executeGroupTbuRequest (id: string, otp: string): Observable<CallResponceInterface>{
    return this.groupTransferApiService.apiSubmitGroupTransfer(id, otp);
  }

  public cancelTbuRequest(id: string, otp?: string): Observable<CallResponceInterface> {
    return this.transferApiService.apiCancelTbuRequest(id, otp);
  }
  public getComplianceRequests(): Observable<any> {
    return this.transferApiService.apiGetComplianceRequests();
  }
  public approveComplianceRequest(requestId: string): Observable<string> {
    return this.transferApiService.apiApproveComplianceRequest(requestId)
    .pipe(
      map((response: any) => response.data.status)
    );
  }

  public rejectComplianceRequest(requestId: string, reason: string): Observable<string> {
    return this.transferApiService.apiRejectComplianceRequest(requestId, reason)
    .pipe(
      map((response: any) => response.data.status)
    );
  }
  public getAccountByNumber(accountNumber: string): Observable<any> {
    return this.transferApiService.apiGetTbuByNumber(accountNumber);
  }
}


