<div class="menu" [class.full]="isOpened" [ngClass]="{ rtl: isRtl }">
  <div [class.full]="isOpened" class="menu__full">
    <div class="header">
      <img class="header__logo big" src="assets/images/logo/logo-full.svg" />

      <app-svg-icon (click)="toggleOpen()" class="toggle-icon" iconPath="menu/ic_menu_unfold.svg"></app-svg-icon>
    </div>

    <div class="groups">
      <ng-scrollbar visibility="hover">
        <ng-container *ngFor="let group of groups">
          <app-aside-menu-group
            *ngIf="!group.isHidden"
            (linkClick)="close()"
            [iconName]="group.iconName"
            [isActive]="group.isActive"
            [route]="group.route"
            [subItems]="group.subItems"
            [title]="group.title"></app-aside-menu-group>
        </ng-container>
      </ng-scrollbar>
    </div>
  </div>

  <div class="menu__compact">
    <div class="header">
      <app-svg-icon
        (click)="toggleOpen()"
        class="toggle-icon sidebar-closed"
        iconPath="menu/ic_menu_unfold.svg"></app-svg-icon>
    </div>

    <div class="groups">
      <ng-container *ngFor="let group of groups">
        <app-aside-menu-group-compact
          *ngIf="!group.isHidden"
          [iconName]="group.iconName"
          [title]="'layout.aside_menu.' + group.title | translate"
          [route]="group.route"></app-aside-menu-group-compact>
      </ng-container>
    </div>
  </div>
</div>
