<div *ngIf="show" class="popup-wrapper">
  <div class="content border_color-main_color">
    <div class="popup-header background_color-main_color" *ngIf="headingMessage">
      <h1 class="heading">
        {{ headingMessage }}
      </h1>
    </div>

    <div class="popup-main">
      <p class="message">
        {{ message }}
      </p>

      <div *ngIf="autoLogoutPadding" class="progress-bar">
        <div class="bar">
          <div id="progress" class="progress background_color-main_color" [style.width.%]="width"></div>
        </div>
      </div>

      <div *ngIf="autoLogoutPadding" class="timer">
        <span class="timer-item timer-hours"> {{ hours > 9 ? hours : '0' + hours }} </span>
        : <span class="timer-item timer-minutes"> {{ minutes > 9 ? minutes : '0' + minutes }} </span> :
        <span class="timer-item timer-seconds"> {{ seconds > 9 ? seconds : '0' + seconds }} </span>
      </div>

      <div class="bottom bottom_flex_end">
        <app-button class="cancel-button" appearance="secondary" (click)="confirm(false)">
          {{ buttonNoName ? buttonNoName : ('pages.accounts.accounts.no' | translate) }}
        </app-button>
        <app-button class="button" (click)="confirm(true)">{{
          buttonYesName ? buttonYesName : 'pages.accounts.accounts.yes'
        }}</app-button>
      </div>
    </div>
  </div>
</div>
