import { NgModule } from '@angular/core';
import { TooltipComponent } from '@default-application-app/modules/components/tooltip/tooltip.component';
import { CommonModule } from '@angular/common';
import { NgxFileDropModule } from 'ngx-file-drop';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '@shared-modules/icon/icon.module';
import { SvgIconModule } from '@default-application-app/modules/components/svg-icon/svg-icon.module';
import { ApplicationPipesModule } from '@default-application-app/modules/application-pipes/application-pipes.module';

@NgModule({
  declarations: [TooltipComponent],
  imports: [CommonModule, NgxFileDropModule, TranslateModule, IconModule, SvgIconModule, ApplicationPipesModule],
  exports: [TooltipComponent],
})
export class TooltipModule {}
