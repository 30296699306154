import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@default-application-app/core/services/auth/auth.service';

@Injectable()
export class AdminRoleGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const isAdmin = this.authService.isAdminOrRootUser();
    if (!isAdmin) {
      this.router.navigate(['not-found']);
    }
    return isAdmin;
  }
}
