import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-top-panel-action-icons',
  templateUrl: './top-panel-action-icons.component.html',
  styleUrls: ['./top-panel-action-icons.component.scss'],
})
export class TopPanelActionIconsComponent {
  @Output() printPage: EventEmitter<void> = new EventEmitter<void>();

  @Output() downloadCSV: EventEmitter<void> = new EventEmitter<void>();
}
