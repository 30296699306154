export enum ErrorCodes {
  /* Common validation error */
  UNIQUE = 'UNIQUE',
  REQUIRED = 'REQUIRED',
  CUSTOM_REQUIRED = 'CUSTOM_REQUIRED',
  MAX = 'MAX',
  MIN = 'MIN',
  EMAIL = 'EMAIL',
  LEN = 'LEN',
  DECIMAL_VALIDATION = 'DECIMAL_VALIDATION',
  DECIMAL_GT_VALIDATION = 'DECIMAL_GT_VALIDATION',
  FILE_NOT_FOUND = 'FILE_NOT_FOUND',
  FILE_MAX = 'FILE_MAX',
  MIMES = 'MIMES',
  INVALID_FORMAT = 'INVALID_FORMAT',
  NOT_POSITIVE_NUBER = 'NOT_POSITIVE_NUBER',
  NOT_UPPERCASE = 'NOT_UPPERCASE',
  NOT_UPPERCASE_OR_NUMBER = 'NOT_UPPERCASE_OR_NUMBER',

  /* accounts service */
  TAN_EMPTY = 'TAN_EMPTY',
  TAN_INVALID = 'TAN_INVALID',
  ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND',
  TRANSFER_TO_SELF_ACCOUNT = 'TRANSFER_TO_SELF_ACCOUNT',
  ACCOUNT_INACTIVE = 'ACCOUNT_INACTIVE',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_INVALID_STATUS = 'USER_INVALID_STATUS',
  DUPLICATE_TRANSFER_TEMPLATE = 'DUPLICATE_TRANSFER_TEMPLATE',
  UNCOVERED_TRANSFER_FEE = 'UNCOVERED_TRANSFER_FEE',
  WITHDRAWAL_NOT_ALLOWED = 'WITHDRAWAL_NOT_ALLOWED',
  DEPOSIT_NOT_ALLOWED = 'DEPOSIT_NOT_ALLOWED',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  FORBIDDEN = 'FORBIDDEN',
  DUPLICATE_TRANSFER_FEE = 'DUPLICATE_TRANSFER_FEE',
  FEE_PARAMS_NOT_FOUND = 'FEE_PARAMS_NOT_FOUND',
  FILE_INVALID = 'FILE_INVALID',
  FILE_EMPTY = 'FILE_EMPTY',
  REQUEST_NOT_FOUND = 'REQUEST_NOT_FOUND',
  UNKNOWN_REQUEST_STATUS = 'UNKNOWN_REQUEST_STATUS',
  UNKNOWN_EMAIL_OR_PHONE_NUMBER = 'UNKNOWN_EMAIL_OR_PHONE_NUMBER',
  NUMERIC = 'NUMERIC',
  UNKNOWN_REQUEST_SUBJECT = 'UNKNOWN_REQUEST_SUBJECT',
  ACCOUNT_TYPE_CONTAINS_ACCOUNTS = 'ACCOUNT_TYPE_CONTAINS_ACCOUNTS',
  ZERO_AMOUNT = 'ZERO_AMOUNT',
  TRANSACTION_NOT_FOUND = 'TRANSACTION_NOT_FOUND',
  UNEXPECTED_TRANSACTION_STATUS = 'UNEXPECTED_TRANSACTION_STATUS',
  DUPLICATE_ACCOUNT_NUMBER = 'DUPLICATE_ACCOUNT_NUMBER',
  ACCOUNT_TYPE_NOT_FOUND = 'ACCOUNT_TYPE_NOT_FOUND',
  INVALID_ACCOUNT_OWNER = 'INVALID_ACCOUNT_OWNER',
  RATES_DO_NOT_MATCH = 'RATES_DO_NOT_MATCH',
  TEMPLATE_NOT_FOUND = 'TEMPLATE_NOT_FOUND',
  CARD_NOT_FOUND = 'CARD_NOT_FOUND',
  INVALID_CARD_OWNER = 'INVALID_CARD_OWNER',
  INVALID_TEMPLATE = 'INVALID_TEMPLATE',
  CARD_TYPE_CATEGORY_NOT_FOUND = 'CARD_TYPE_CATEGORY_NOT_FOUND',
  CARD_TYPE_FORMAT_NOT_FOUND = 'CARD_TYPE_FORMAT_NOT_FOUND',
  INVALID_QUERY_PARAMETERS = 'INVALID_QUERY_PARAMETERS',
  CARD_TYPE_ASSOCIATED_WITH_CARDS = 'CARD_TYPE_ASSOCIATED_WITH_CARDS',
  CSV_FILE_INVALID_ROW = 'CSV_FILE_INVALID_ROW',
  LIMIT_EXCEEDED = 'LIMIT_EXCEEDED',
  REQUEST_ALREADY_EXISTS = 'REQUEST_ALREADY_EXISTS',
  GROUP_TRANSACTION_DETAILS_NOT_FOUND = 'GROUP_TRANSACTION_DETAILS_NOT_FOUND',

  /* permissions service */
  ADMIN_GROUP_CONSTRAINT = 'ADMIN_GROUP_CONSTRAINT',

  /* users service */
  USER_HAS_FILES = 'USER_HAS_FILES',
  USER_HAS_ACCOUNTS = 'USER_HAS_ACCOUNTS',
  USER_HAS_CARDS = 'USER_HAS_CARDS',
  USERS_IP_IS_BLOCKED = 'USERS_IP_IS_BLOCKED',
  USERS_USER_IS_BLOCKED = 'USERS_USER_IS_BLOCKED',
  USERS_USER_IS_DORMANT = 'USERS_USER_IS_DORMANT',
  USERS_AUTH_USER_IS_NOT_FOUND = 'USERS_AUTH_USER_IS_NOT_FOUND',
  USERS_REGISTRATION_IS_NOT_CONFIRMED = 'USERS_REGISTRATION_IS_NOT_CONFIRMED',
  USERS_USER_IS_LOCKED = 'USERS_USER_IS_LOCKED',
  USERS_USER_IS_NOT_ACTIVE = 'USERS_USER_IS_NOT_ACTIVE',
  USERS_USER_IS_PENDING = 'USERS_USER_IS_PENDING',
  USERS_AUTH_COMMON_ERROR = 'USERS_AUTH_COMMON_ERROR',
  USERS_CONFIRM_REGISTRATION_LINK = 'USERS_CONFIRM_REGISTRATION_LINK',
  USERS_INVALID_USERNAME_OR_PASSWORD = 'USERS_INVALID_USERNAME_OR_PASSWORD',
  USERS_INVALID_SECURITY_QUESTION_ANSWER = 'USERS_INVALID_SECURITY_QUESTION_ANSWER',
  CANNOT_RETRIEVE_COLLECTION = 'CANNOT_RETRIEVE_COLLECTION',
  CANNOT_RETRIEVE_COLLECTION_COUNT = 'CANNOT_RETRIEVE_COLLECTION_COUNT',
  CANNOT_CREATE_PAGINATION = 'CANNOT_CREATE_PAGINATION',
  REGISTRATION_REQUEST_NOT_FOUND = 'REGISTRATION_REQUEST_NOT_FOUND',
  INVALID_APPROVAL_STATUS = 'INVALID_APPROVAL_STATUS',
  INVALID_CANCELLATION_STATUS = 'INVALID_CANCELLATION_STATUS',
  INVALID_PREVIOUS_PASSWORD = 'INVALID_PREVIOUS_PASSWORD',
  INVALID_CONFIRMATION_CODE = 'INVALID_CONFIRMATION_CODE',
  INVALID_DATE_OF_BIRTH = 'INVALID_DATE_OF_BIRTH',
  DELEGATE_ALREADY_EXISTS = 'DELEGATE_ALREADY_EXISTS',
  CONFIRMATION_CODE_IS_INVALID = 'CONFIRMATION_CODE_IS_INVALID',
  INVALID_FIELD = 'INVALID_FIELD',
  CANNOT_APPROVE_USER = 'CANNOT_APPROVE_USER',
  CANNOT_CANCEL_USER = 'CANNOT_CANCEL_USER',
  CANNOT_UPDATE_USER = 'CANNOT_UPDATE_USER',
  MAINTENANCE_MODE = 'MAINTENANCE_MODE',
  RESET_PASSWORD_IS_NOT_ALLOWED = 'RESET_PASSWORD_IS_NOT_ALLOWED',
  ACTION_CANNOT_BE_PERFORMED = 'ACTION_CANNOT_BE_PERFORMED',
  USERS_INVALID_PASSWORD = 'USERS_INVALID_PASSWORD',
  RECIPIENT_LIMIT_EXCEEDED = 'RECIPIENT_LIMIT_EXCEEDED',
  GENERAL_LIMIT_EXCEEDED= 'GENERAL_LIMIT_EXCEEDED',
  PROVIDE_SOURCE_OF_FUNDS = 'PROVIDE_SOURCE_OF_FUNDS',

  OTP_IS_INVALID = 'OTP_IS_INVALID',
  OTP_TOO_MANY_ATTEMPTS = 'OTP_TOO_MANY_ATTEMPTS',

  UNAUTHORIZED = 'UNAUTHORIZED',
  UNCONFIRMED_PROFILE_DATA = 'UNCONFIRMED_PROFILE_DATA',
  CANNOT_FIND_USER_BY_EMAIL = 'CANNOT_FIND_USER_BY_EMAIL',
  CANNOT_DELETE_USER_GROUP = 'CANNOT_DELETE_USER_GROUP',
  USER_GROUP_ALREADY_EXISTS = 'USER_GROUP_ALREADY_EXISTS',
  GROUP_NAME_DUPLICATION = 'GROUP_NAME_DUPLICATION',
  EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS',
  PHONE_ALREADY_EXISTS = 'PHONE_ALREADY_EXISTS',
  NO_EMAIL_EXISTS = 'NO_EMAIL_EXISTS',
  NO_USERNAME_EXISTS = 'NO_USERNAME_EXISTS',

  USER_MUST_HAVE_ACTIVE_STATUS = 'USER_MUST_HAVE_ACTIVE_STATUS',

  /* currencies service */
  ACCESS_TOKEN_NOT_FOUND = 'ACCESS_TOKEN_NOT_FOUND',
  ACCESS_TOKEN_INVALID = 'ACCESS_TOKEN_INVALID',
  CURRENCY_NOT_FOUND = 'CURRENCY_NOT_FOUND',
  BAD_COLLECTION_PARAMS = 'BAD_COLLECTION_PARAMS',
  DEACTIVATING_MAIN_CURRENCY = 'DEACTIVATING_MAIN_CURRENCY',
  INVALID_EXCHANGE_RATE = 'INVALID_EXCHANGE_RATE',
  USED_CURRENCY = 'USED_CURRENCY',
  CURRENCY_IS_NOT_ACTIVE = 'CURRENCY_IS_NOT_ACTIVE',
  EMPTY_FEED = 'EMPTY_FEED',
  CANNOT_SET_INACTIVE_CURRENCY_AS_MAIN = 'CANNOT_SET_INACTIVE_CURRENCY_AS_MAIN',

  /* notification service */
  CANNOT_UPDATE_COLLECTION = 'CANNOT_UPDATE_COLLECTION',
  BAD_TEST_SMTP_PARAMS = 'BAD_TEST_SMTP_PARAMS',
  CANNOT_RETRIEVE_PROVIDER_DETAILS = 'CANNOT_RETRIEVE_PROVIDER_DETAILS',
  CANNOT_RETRIEVE_SETTING = 'CANNOT_RETRIEVE_SETTING',
  CANNOT_UPDATE_SETTINGS = 'CANNOT_UPDATE_SETTINGS',
  SETTINGS_NOT_FOUND = 'SETTINGS_NOT_FOUND',
  INVALID_API_KEYS = 'INVALID_API_KEYS',
  UNPROCESSABLE_ENTITY = 'UNPROCESSABLE_ENTITY',
  ERROR_SENDING_TEST_PUSH = 'ERROR_SENDING_TEST_PUSH',
  NOT_FOUND = 'NOT_FOUND',

  /* Errors list - messages service */
  MESSAGE_NOT_FOUND = 'MESSAGE_NOT_FOUND',
  INVALID_QUERY_PARAMS = 'INVALID_QUERY_PARAMS',

  /* reports service */
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',

  /* validation errors */
  IS_REQUIRED = 'required',
  REQUIRED_TRUE = 'requiredTrue',
  REQUIRED_IF = 'requiredIf',
  REQUIRED_EMPTY = 'REQUIRED_EMPTY',
  PATTERN = 'pattern',
  EMAIL_INNER = 'email',
  MIN_VALUE = 'MIN_VALUE',
  MAX_VALUE = 'MAX_VALUE',
  MIN_LENGTH = 'minlength',
  MAX_LENGTH = 'maxlength',
  MIN_CHARACTERS = 'MIN_CHARACTERS',
  MAX_CHARACTERS = 'MAX_CHARACTERS',
  NOT_SPACES = 'NOT_SPACES',
  DECIMAL_NOT_VALID = 'DECIMAL_NOT_VALID',
  MULTIPLE_NOT_VALID = 'MULTIPLE_NOT_VALID',
  NOT_NUMBER = 'notNumber',
  INVALID_URL = 'INVALID_URL',
  MONTH_AND_YEAR_INVALID = 'monthAndYearIsLessThanAllowed',
  MIN_AGE = 'MIN_AGE',
  NOT_ZERO = 'NOT_ZERO',
  NOT_ZERO_EXCHANGE = 'NOT_ZERO_EXCHANGE',
  GREATER_THAN = 'GREATER_THAN',
  INVALID_USERNAME = 'usernameValidator',
  INVALID_CHARS = 'INVALID_CHARS',
  INVALID_PHONE_NUMBER = 'phoneNumberValidator',
  MUST_START_WITH_PHONE_NUMBER = 'MUST_START_WITH_PHONE_NUMBER',
  SHOULD_INCLUDE_SHORT_NUMBER = 'SHOULD_INCLUDE_SHORT_NUMBER',
  SHOULD_INCLUDE_NUMBER = 'SHOULD_INCLUDE_NUMBER',
  DOCUMENT_LENGTH = 'DOCUMENT_LENGTH',
  ALLOWED_ONLY_ALPHANUMERICS = 'onlyAlphanumericsValidator',
  CONFIRM_FIELD_INVALID = 'CONFIRM_FIELD_INVALID',
  CONFIRM_PASSWORD_INVALID = 'CONFIRM_PASSWORD_INVALID',
  CONFIRM_EMAIL_INVALID = 'CONFIRM_EMAIL_INVALID',
  DATE_INVALID = 'DATE_INVALID',
  SIGNATURE_INVALID = 'SIGNATURE_INVALID',
  NOT_EMPTY = 'notEmpty',
  EMPTY_QUILL = 'EMPTY_QUILL',
  INVALID_FILE_EXTENSION = 'invalid_file_extension',
  INVALID_MIME_TYPE = 'invalid_file_mime_type',
  FILE_SIZE = 'FILE_SIZE',
  SERVER_PORT = 'serverPortPattern',
  FEE_NAME_PATTERN = 'feeNamePattern',
  MIN_LESS_THAN_MAX = 'minLessThanMax',
  TAN_STANDARD_LESS_LIMIT = 'TAN_STANDARD_LESS_LIMIT',
  TOKEN_REQUIRED = 'TOKEN_REQUIRED',
  SAME_ACCOUNTS = 'same_account_is_used',
  DIFFERENT_EMAILS = 'DIFFERENT_EMAILS',
  SAME_USERS = 'sameUsers',
  USER_GROUP_NAME_PATTERN = 'userGroupNamePattern',
  SPECIAL_CHARACTER_REQUIRED = 'SPECIAL_CHARACTER_REQUIRED',
  ONLY_UPPERCASE_NUMBER = 'ONLY_UPPERCASE_NUMBER',
  NUMBER_REQUIRED = 'NUMBER_REQUIRED',
  UPPERCASE_LETTER_REQUIRED = 'UPPERCASE_LETTER_REQUIRED',
  LOWERCASE_LETTER_REQUIRED = 'LOWERCASE_LETTER_REQUIRED',
  DATE_MORE_THAN_MAX_DATE = 'DATE_MORE_THAN_MAX_DATE',
  ACCOUNT_NUMBER_MIN_LENGTH = 'ACCOUNT_NUMBER_MIN_LENGTH',
  ACCOUNT_NUMBER_INVALID = 'ACCOUNT_NUMBER_INVALID',
  DELEGATE_YEARS = 'DELEGATE_YEARS',

  // files service
  NOT_ENOUGH_SPACE_IN_FILES_STORAGE = 'NOT_ENOUGH_SPACE_IN_FILES_STORAGE',
  FILE_TOO_LARGE = 'FILE_TOO_LARGE',

  /* customization service */
  CANNOT_SAVE_LOGO_AREA = 'CANNOT_SAVE_LOGO_AREA',
  CANNOT_EDIT_DEFAULT_SCHEME = 'CANNOT_EDIT_DEFAULT_SCHEME',
  CANNOT_REMOVE_ACTIVE_SCHEME = 'CANNOT_REMOVE_ACTIVE_SCHEME',
  CANNOT_REMOVE_DEFAULT_SCHEME = 'CANNOT_REMOVE_DEFAULT_SCHEME',

  /* notifications service */
  ERROR_SENDING_TEST_SMS = 'ERROR_SENDING_TEST_SMS',

  /* Custom Errors */
  INVALID_CAPTCHA = 'INVALID_CAPTCHA',

  /* KYC */
  REQUEST_IS_APPROVED = 'REQUEST_IS_APPROVED',
  REQUEST_IS_PENDING = 'REQUEST_IS_PENDING',
  REQUIREMENTS_FILLED = 'REQUIREMENTS_FILLED',

  /* IBAN */
  INVALID_IBAN = 'INVALID_IBAN'
  
}

export const SkipNotificationErrorCodes: string[] = [
  ErrorCodes.MAINTENANCE_MODE,
  ErrorCodes.CANNOT_FIND_USER_BY_EMAIL,
  ErrorCodes.ACCESS_TOKEN_NOT_FOUND,
  ErrorCodes.UNCONFIRMED_PROFILE_DATA,
];

export const MustShowTitleErrorCodes: string[] = [
  ErrorCodes.ERROR_SENDING_TEST_SMS,
  ErrorCodes.ERROR_SENDING_TEST_PUSH,
];

export const MustShowDetailsErrorCodesWithoutTranslate: string[] = [
  // Temp solution before translate service implement
  ErrorCodes.USERS_USER_IS_DORMANT,
  ErrorCodes.USERS_USER_IS_NOT_ACTIVE,
  ErrorCodes.USERS_USER_IS_PENDING,
];
