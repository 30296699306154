import { ApiError } from '@default-application-app/core/models/api-error.model';
import { ErrorTargets } from '@default-application-app/core/constants/errorTargets';
import { HttpErrorResponse } from '@angular/common/http';

export class ErrorHandlerService {
  public static generateApiErrors(errors: object | any[] | Error, res?: HttpErrorResponse): ApiError[] {
    try {
      if (errors instanceof Array) {
        return errors.map(
          (error): ApiError =>
            new ApiError({
              details: error.details || error.detail,
              source: error.source || '',
              target: error.target,
              title: error.title,
              code: error.code,
              meta: error.meta,
              httpResponse: res,
            }),
        );
      }
      if (errors instanceof Error) {
        return [new ApiError({ details: errors.message, target: ErrorTargets.COMMON, httpResponse: res })];
      }
      return Object.keys(errors).map(
        (key): ApiError => new ApiError({ details: errors[key], source: key, httpResponse: res }),
      );
    } catch (e) {
      return [];
    }
  }
}
