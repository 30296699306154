import {
  SystemDetails,
  TransferDetails,
  TransferRequestData,
} from '@default-application-app/core/models/transfers/transfer-request-data';
import { RequestDataProcessor } from '@default-application-app/core/services/transfer/request-data/request-data-processor';
import { CurrencyAmount } from '@default-application-app/core/models/currency-amount';
import {
  CFT_INCOMING_PURPOSE,
  FEE_PURPOSE,
  REVENUE_PURPOSES,
} from '@default-application-app/core/constants/transaction-purposes';
import {
  SUBJECT_CA,
  SUBJECT_CFT,
  SUBJECT_CI,
  SUBJECT_CO,
  SUBJECT_DA,
  SUBJECT_IBAN_TBU_INCOMING,
  SUBJECT_IP,
  SUBJECT_OWT,
  SUBJECT_TBA,
  SUBJECT_TBU,
} from '@default-application-app/core/constants/request-subjects';
import { TbaRequestDataProcessor } from '@default-application-app/core/services/transfer/request-data/tba-request-data-processor';
import { TbuRequestDataProcessor } from '@default-application-app/core/services/transfer/request-data/tbu-request-data-processor';
import { OwtRequestDataProcessor } from '@default-application-app/core/services/transfer/request-data/owt-request-data-processor';
import { CaRequestDataProcessor } from '@default-application-app/core/services/transfer/request-data/ca-request-data-processor';
import { DaRequestDataProcessor } from '@default-application-app/core/services/transfer/request-data/da-request-data-processor';
import { Transaction } from '@default-application-app/core/models/transaction';
import { CftRequestDataProcessor } from '@default-application-app/core/services/transfer/request-data/cft-request-data-processor';
import { CashInRequestDataProcessor } from '@services/transfer/request-data/cash-in-request-data-processor';
import { CashOutRequestDataProcessor } from '@services/transfer/request-data/cash-out-request-data-processor';
import { FeeRequestDataProcessor } from '@services/transfer/request-data/fee-request-data-processor';
import { IbanTbuIncomingRequestDataProcessor } from './iban-tbu-incoming-data-processor';

export class TransferRequestDataBuilder {
  private requestDataProcessor: RequestDataProcessor;

  private static getTransactionAccount(transaction: any): string {
    if (TransferRequestDataBuilder.isRevenueTransaction(transaction)) {
      return 'Revenue';
    }

    if (transaction.transaction.purpose === CFT_INCOMING_PURPOSE) {
      return `Card ${transaction.card.number}`;
    }

    return transaction.account && transaction.account.account.number;
  }

  private static isRevenueTransaction(transaction: any): boolean {
    return REVENUE_PURPOSES.includes(transaction.transaction.purpose);
  }

  private static getTransactionCurrencyCode(transaction: any): string {
    if (TransferRequestDataBuilder.isRevenueTransaction(transaction)) {
      return transaction.revenueAccount.revenueAccount.currencyCode;
    }

    if (transaction.transaction.purpose === CFT_INCOMING_PURPOSE) {
      return transaction.card.currencyCode;
    }

    return transaction.account && transaction.account.account.currencyCode;
  }

  constructor(private fullData: any) {
    this.requestDataProcessor = this.getDataProcessor();
  }

  public call(): TransferRequestData {
    const obj = new TransferRequestData();
    obj.systemDetails = this.getSystemDetails();
    obj.transferDetails = this.getTransferDetails();
    obj.additionalDetails = this.requestDataProcessor.getAdditionalDetails();
    obj.transactions = this.getTransactions();
    obj.feeTransaction = this.getFeeTransaction();
    return obj;
  }

  private getSystemDetails(): SystemDetails {
    const details = new SystemDetails();
    const { request } = this.fullData;
    const { sourceAccount } = this.fullData;
    const { destinationAccount } = this.fullData;
    const { data } = this.fullData;
 
    details.id = request.request.id;
    details.createdAt = request.request.createdAt;
    details.updatedAt = request.request.updatedAt;
    details.statusChangedAt = request.request.statusChangedAt;
    details.firstName = (sourceAccount && sourceAccount.user && sourceAccount.user.firstName) || '';
    details.lastName = (sourceAccount && sourceAccount.user && sourceAccount.user.lastName) || '';
    details.businessName = (sourceAccount && sourceAccount.user && sourceAccount.user?.businessName) || '';
    details.username = (sourceAccount && sourceAccount.user && sourceAccount.user.username) || '';
    details.email = (sourceAccount && sourceAccount.user && sourceAccount.user.email) || '';
    details.sourceAccountNumber = (sourceAccount && sourceAccount.number) || '';
    details.status = request.request.status;
    details.reason = request.request.cancellationReason;
    details.subject = request.request.subject;
    details.isInitiatedBySystem = request.request.isInitiatedBySystem;
    details.referenceNo = request.request?.referenceNo || null;
    details.referenceCurrencyCode = request.request?.referenceCurrencyCode || null;
    details.delegateType = request.request?.delegateType || null;
    details.delegateId = request.request?.delegateId || null;
    details.cancellationReason = request.request?.cancellationReason || null;
    details.rate = request.request?.rate || null;
    details.expiresAt = request.request?.expiresAt || this.fullData?.expiredAt;
    details.creationReason = data?.creationReason || null;
    details.creationReasonText = data?.creationReasonText || null;
    details.sourceOfFundsDocID = data?.sourceOfFundsDocID || null;
    details.extReferenceId = this.fullData?.extReferenceId || null;

    if (request && request?.totals) {
      details.totals = request?.totals;
    }

    if (request && request?.initiator) {
      details.initiator = request.initiator;
    }

    if (request && request?.executor) {
      details.executor = request?.executor;
    }

    if (request && request?.details) {
      details.details = request?.details;
    }

    if (destinationAccount) {
      details.destinationAccount = destinationAccount;

      if (destinationAccount?.number) {
        details.destinationAccountNumber = destinationAccount?.number || '';
      }
    }

    if (sourceAccount) {
      details.sourceAccount = sourceAccount;

      if (sourceAccount?.number) {
        details.sourceAccountNumber = sourceAccount?.number || '';
      }
    }

    return details;
  }

  private getTransferDetails(): TransferDetails {
    const details = new TransferDetails();

    details.amount = this.requestDataProcessor.getRequestAmount();
    details.baseCurrencyCode = this.requestDataProcessor.getCurrencyCode();
    details.description = this.fullData.request.request.description;
    details.fee = this.requestDataProcessor?.getFee ? this.requestDataProcessor?.getFee() : this.getFee();
    details.rate = this.requestDataProcessor.getRate();

    return details;
  }

  public getFee(): CurrencyAmount {
    const foundTransaction = this.fullData.transactions.find((e) => e.transaction.purpose === FEE_PURPOSE);
    if (foundTransaction) {
      const amount = Math.abs(foundTransaction.transaction.amount).toString();
      return new CurrencyAmount(foundTransaction.account.account.currencyCode, amount);
    }
    return null;
  }

  // eslint-disable-next-line consistent-return
  private getDataProcessor(): RequestDataProcessor {
    switch (this.fullData.request.request.subject) {
      case SUBJECT_TBA: {
        return new TbaRequestDataProcessor(this.fullData);
      }
      case SUBJECT_TBU: {
        return new TbuRequestDataProcessor(this.fullData);
      }
      case SUBJECT_OWT: {
        return new OwtRequestDataProcessor(this.fullData);
      }
      case SUBJECT_CA: {
        return new CaRequestDataProcessor(this.fullData);
      }
      case SUBJECT_DA: {
        return new DaRequestDataProcessor(this.fullData);
      }
      case SUBJECT_CFT: {
        return new CftRequestDataProcessor(this.fullData);
      }
      case SUBJECT_CO: {
        return new CashOutRequestDataProcessor(this.fullData);
      }
      case SUBJECT_CI: {
        return new CashInRequestDataProcessor(this.fullData);
      }
      case SUBJECT_IP: {
        return new FeeRequestDataProcessor(this.fullData);
      }
      case SUBJECT_IBAN_TBU_INCOMING: {
        return new IbanTbuIncomingRequestDataProcessor(this.fullData);
      }
    }
  }

  private getTransactions(): Transaction[] {
    return this.fullData.transactions.map(
      (e) =>
        new Transaction({
          id: e.transaction.id,
          description: e.transaction.description,
          amount: e.transaction.amount,
          status: e.transaction.status,
          createdAt: e.transaction.createdAt,
          purpose: e.transaction?.purpose,
          statusChangedAt: this.fullData.request.request.statusChangedAt,
          account: TransferRequestDataBuilder.getTransactionAccount(e),
          currencyCode: TransferRequestDataBuilder.getTransactionCurrencyCode(e),
        }),
    );
  }

  public getFeeTransaction(): Transaction {
    const feeTransaction: Transaction = this.getTransactions().find(
      (transaction: Transaction) => transaction.purpose === FEE_PURPOSE,
    );

    if (feeTransaction?.amount) {
      feeTransaction.amount = Math.abs(Number(feeTransaction.amount)).toString();
      return feeTransaction;
    }

    return null;
  }
}
