import { Pipe, PipeTransform } from '@angular/core';
import { AsYouType } from 'libphonenumber-js';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(phoneValue: number | string): string {
    try {
      const ayt = new AsYouType();
      ayt.input(String(phoneValue));
      return ayt.getNumber().format('INTERNATIONAL');
    } catch (error) {
      return String(phoneValue);
    }
  }
}
