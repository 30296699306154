import { Injectable } from '@angular/core';
import { ApiCallerService } from '@default-application-app/core/services/api-caller.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '@default-application-app/config.service';
import { Observable } from 'rxjs';
import { CallResponceInterface } from '@default-application-app/core/interfaces/callResponce.interface';
import {
  ChangePasswordInterface,
  ResetPasswordInterface,
} from '@default-application-app/core/interfaces/set-password.interface';
import { ProfileModel } from '@default-application-app/core/models/profile-model';
import { TokenService } from '@default-application-app/core/services/token-service';
import { Verification } from '@default-application-app/core/constants/verification';

@Injectable()
export class AuthApiService {
  constructor(
    private http: HttpClient,
    private apiCallerService: ApiCallerService,
    private configService: ConfigService,
    private tokenService: TokenService
  ) {}

  /**
   * Sign In
   *
   * @param {any} email
   * @param {any} password
   * @returns {Observable<
   *   IBaseResponse<AuthResponseInterface | HttpErrorResponse>
   * >}
   */
  public apiSignIn({ login, password }): Observable<CallResponceInterface> {
    const body = {
      login: login.trim(),
      password,
    };
    return this.apiCallerService.call(
      () => this.http.post(this.configService.config.api.auth.login, body),
      "apiSignIn"
    );
  }

  public apiRootSignInAsUser(uid: string): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () =>
        this.http.post(
          this.configService.config.api.auth.issueTokensForUserByUid(uid),
          {}
        ),
      "apiRootSignInAsUser"
    );
  }

  public apiSignUp(request): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      observe: "response" as "response",
    };

    return this.http.post(
      this.configService.config.api.auth.signup,
      request,
      httpOptions
    );
  }

  public apiGenerateSmsCode(): Observable<CallResponceInterface> {
    const headers = new HttpHeaders({
      "X-Tmp-Auth": this.tokenService.getTempAuthToken(),
    });

    return this.apiCallerService.call(
      () =>
        this.http.post(
          this.configService.config.api.auth.generateSmsCode,
          {},
          { headers }
        ),
      "apiGenerateSmsCode"
    );
  }

  public apiGenerateEmailCode(): Observable<CallResponceInterface> {
    const headers = new HttpHeaders({
      "X-Tmp-Auth": this.tokenService.getTempAuthToken(),
    });

    return this.apiCallerService.call(
      () =>
        this.http.post(
          this.configService.config.api.auth.generateEmailCode,
          {},
          { headers }
        ),
      "apiGenerateEmailCode"
    );
  }

  public apiSMSConfirmation(code: string): Observable<CallResponceInterface> {
    const headers = new HttpHeaders({
      "X-Tmp-Auth": this.tokenService.getTempAuthToken(),
    });

    return this.apiCallerService.call(
      () =>
        this.http.put(
          this.configService.config.api.auth.smsConfirmation,
          {
            code,
          },
          { headers }
        ),
      "apiSMSConfirmation"
    );
  }

  public apiEmailConfirmation(code: string): Observable<CallResponceInterface> {
    const headers = new HttpHeaders({
      "X-Tmp-Auth": this.tokenService.getTempAuthToken(),
    });

    return this.apiCallerService.call(
      () =>
        this.http.put(
          this.configService.config.api.auth.emailConfirmation,
          {
            code,
          },
          { headers }
        ),
      "apiEmailConfirmation"
    );
  }
  // ---

  public apiGenerateSignatureSmsCode(): Observable<CallResponceInterface> {
    const headers = new HttpHeaders({
      "X-Tmp-Auth": this.tokenService.getTempAuthToken(),
    });

    return this.apiCallerService.call(
      () =>
        this.http.post(
          this.configService.config.api.auth.generateSignatureSmsCode,
          {},
          { headers }
        ),
      "apiGenerateSignatureSmsCode"
    );
  }

  public apiGenerateSignatureEmailCode(): Observable<CallResponceInterface> {
    const headers = new HttpHeaders({
      "X-Tmp-Auth": this.tokenService.getTempAuthToken(),
    });

    return this.apiCallerService.call(
      () =>
        this.http.post(
          this.configService.config.api.auth.generateSignatureEmailCode,
          {},
          { headers }
        ),
      "apiGenerateSignatureEmailCode"
    );
  }

  public apiSMSSignatureConfirmation(
    code: string
  ): Observable<CallResponceInterface> {
    const headers = new HttpHeaders({
      "X-Tmp-Auth": this.tokenService.getTempAuthToken(),
    });

    return this.apiCallerService.call(
      () =>
        this.http.put(
          this.configService.config.api.auth.smsSignatureConfirmation,
          {
            code,
          },
          { headers }
        ),
      "apiSMSSignatureConfirmation"
    );
  }

  public apiEmailSignatureConfirmation(
    code: string
  ): Observable<CallResponceInterface> {
    const headers = new HttpHeaders({
      "X-Tmp-Auth": this.tokenService.getTempAuthToken(),
    });

    return this.apiCallerService.call(
      () =>
        this.http.put(
          this.configService.config.api.auth.emailSignatureConfirmation,
          {
            code,
          },
          { headers }
        ),
      "apiEmailSignatureConfirmation"
    );
  }

  public apiUpdateUnconfirmedPhoneNumber(phoneNumber: string) {
    return this.apiCallerService.call(
      () =>
        this.http.put(
          this.configService.config.api.auth.limited.changePhoneNumber,
          {
            phoneNumber,
          }
        ),
      "apiUpdateUnconfirmedPhoneNumber"
    );
  }

  public apiUpdateUnconfirmedEmail(email: string) {
    return this.apiCallerService.call(
      () =>
        this.http.put(this.configService.config.api.auth.limited.changeEmail, {
          email,
        }),
      "apiUpdateUnconfirmedEmail"
    );
  }

  public getInfoAboutCurrentUserByTempToken(): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () =>
        this.http.get(
          this.configService.config.api.auth.limited.userInfoByTempToken
        ),
      "getInfoAboutCurrentUserByTempToken"
    );
  }

  /**
   * Recover password for particular email
   *
   * @param {string} email
   * @returns {Observable<any>}
   */
  public apiRecoverPassword(email: string) {
    const body = {
      email: email.trim(),
    };

    return this.apiCallerService.call(
      () =>
        this.http.post(this.configService.config.api.auth.forgotPassword, body),
      "apiRecoverPassword"
    );
  }

  /**
   * Update user
   *
   * @returns {Observable<any>}
   */
  public apiPutUser(uid: string, profile: ProfileModel) {
    const headers = new HttpHeaders({
      "X-Tmp-Auth": this.tokenService.getTempAuthToken(),
    });

    return this.apiCallerService.call(
      () =>
        this.http.put(
          this.configService.config.api.auth.userById(uid),
          { data: profile },
          { headers }
        ),
      "apiPutUser"
    );
  }

  public verificationLimitedFileUser(
    fileId: string,
    verificationType: Verification
  ) {
    const headers = new HttpHeaders({
      "X-Tmp-Auth": this.tokenService.getTempAuthToken(),
    });

    return this.apiCallerService.call(
      () =>
        this.http.post(
          this.configService.config.api.auth.verification.authVerification,
          { fileId, verificationType },
          { headers }
        ),
      `verificationFileUser_${fileId}`
    );
  }

  public listVerificationFileUser() {
    const headers = new HttpHeaders({
      "X-Tmp-Auth": this.tokenService.getTempAuthToken(),
    });

    return this.apiCallerService.call(
      () =>
        this.http.get(
          this.configService.config.api.auth.verification.authVerification,
          { headers }
        ),
      "listVerificationFileUser"
    );
  }

  public apiChangePassword(data: ChangePasswordInterface) {
    return this.apiCallerService.call(
      () =>
        this.http.post(this.configService.config.api.auth.changePassword, {
          data,
        }),
      "apiChangePassword"
    );
  }

  public apiResetPassword(data: {
    code: string;
    password: string;
  }): Observable<CallResponceInterface> {
    const body = {
      confirmationCode: data.code,
      newPassword: data.password,
    };
    return this.apiCallerService.call(
      () =>
        this.http.post(this.configService.config.api.auth.resetPassword, body),
      "apiResetPassword"
    );
  }

  public apiResetPasswordById(
    data: ResetPasswordInterface
  ): Observable<CallResponceInterface> {
    const body = {
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
    };

    return this.apiCallerService.call(
      () =>
        this.http.put(
          this.configService.config.api.auth.resetPasswordById(data.uid),
          body
        ),
      "apiResetPasswordById"
    );
  }

  public apiConfirmationCode(link: string): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () =>
        this.http.get(
          this.configService.config.api.auth.confirmationCode(link)
        ),
      "apiConfirmationCode"
    );
  }

  /**
   * Refresh token
   *
   * @returns {Observable<any>}
   */
  public refreshAccessToken(token) {
    const headers = new HttpHeaders({
      "X-Refresh-Token": token,
    });
    return this.http.get(this.configService.config.api.auth.refreshToken, {
      headers,
    });
  }

  public logout() {
    return this.http.delete(this.configService.config.api.auth.logout);
  }

  public apiGetCurrentCountryFromExternal(): Observable<Object> {
    return this.http.get(
      this.configService.config.api.externalApis.currentCountry
    );
  }
}
