import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { OptionsValuesNames } from '@default-application-app/core/constants/optionsPrefixes';
import { AppOptionsService } from '@default-application-app/core/services/appOptions.service';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class MaintenanceDisableGuard implements CanActivate {
  constructor(private appOptionService: AppOptionsService, private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.appOptionService.getOptionsValuePipe(OptionsValuesNames.MAINTENANCE).pipe(
      filter((value: string) => value !== undefined),
      map((value: string): boolean => value === 'enable'),
      map((enabled: boolean) => {
        if (!enabled) {
          this.router.navigate(['sign-in']);
        }
        return true;
      }),
    );
  }
}
