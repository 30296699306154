/**
 * @license Use of this source code is governed by an CC BY-NC-ND 4.0 license that can be found in the LICENSE file at
 *   https://creativecommons.org/licenses/by-nc-nd/4.0
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiCallerService } from '@services/api-caller.service';
import { ConfigService } from '@default-application-app/config.service';

@Injectable()
export class CurrencyApiService {
  /**
   * CurrencyApiService constructor.
   *
   * @param {HttpClient} http
   * @param {ApiCallerService} apiCallerService
   * @param configService
   */
  public constructor(
    private http: HttpClient,
    private apiCallerService: ApiCallerService,
    private configService: ConfigService,
  ) {}

  /**
   * Load list of currencies
   *
   * @returns {Observable<any>}
   */
  public apiLoadCurrencies(type?: string) {
    const params = {};
    // @ts-ignore
    params.sort = 'type';
    if (type) {
      params['filter[type]'] = type;
    }

    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.currency.currencies, { params }),
      'apiLoadCurrencies',
    );
  }

  /**
   * Get main currency
   *
   * @returns {Observable<any>}
   */
  public apiGetMainCurrency() {
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.currency.mainCurrency),
      'apiGetMainCurrency',
    );
  }

  /**
   * Load exchange rates
   *
   * @returns {Observable<any>}
   */
  public apiLoadMainCurrencyExchangeRates() {
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.currency.mainCurrencyExchangeRates),
      'apiLoadMainCurrencyExchangeRates',
    );
  }

  /**
   * Load exchange rates
   *
   * @returns {Observable<any>}
   */
  public apiUpdateExchangeRates(exchangeRates: {}[]) {
    return this.apiCallerService.call(
      () =>
        this.http.put(this.configService.config.api.currency.updateRates, {
          data: exchangeRates,
        }),
      'apiUpdateExchangeRates',
    );
  }
}
