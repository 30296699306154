import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-delete-popup',
  templateUrl: './confirm-delete-popup.component.html',
  styleUrls: ['./confirm-delete-popup.component.scss'],
})
export class ConfirmDeletePopupComponent {
  @Input() public headingMessage: string;

  @Output() userConfirm = new EventEmitter<boolean>();

  public confirmAction(confirmed: boolean): void {
    this.userConfirm.emit(confirmed);
  }

  @HostListener('document:keyup.esc')
  private keyupEsc() {
    this.userConfirm.emit(false);
  }
}
