<app-svg-icon iconPath="auth/input-error.svg" hidden></app-svg-icon>

<div class="input-header">
  <label *ngIf="label" class="input-header__label">
    {{ label }}
    <span class="input-header__label-optional" *ngIf="isOptional">({{ 'common.labels.optional' | translate }})</span>

    <div class="input-header__tooltip">
      <app-tooltip *ngIf="tooltipMessage">{{ tooltipMessage }}</app-tooltip>
    </div>
  </label>
</div>

<div class="form-control">
  <div *ngIf="currencyCode" class="currency" [ngClass]="{ disable: isFormControlDisabled || isDisabled }">
    <span>{{ currencyCode }}</span>
  </div>

  <input
    class="form-control__input"
    [ngClass]="{ 'has-error': control.touched && control.invalid, 'no-disabled-styles': noDisabledStyles }"
    [type]="type"
    [placeholder]="placeholder"
    [(ngModel)]="model"
    (ngModelChange)="onChange($event)"
    onlyFloat
    [options]="options"
    currencyMask
    [requiredIf]="isRequiredIf"
    (blur)="onBlur()"
    [attr.disabled]="isFormControlDisabled || isDisabled ? true : null"
    [name]="name"
    [autocomplete]="autocomplete" />

  <div *ngIf="fee" class="fee">
    <span>{{ fee }}</span>
  </div>
</div>
