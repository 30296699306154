import {
  LogoutActions,
  SiteTextActions,
  UserPermissionsActions,
  SiteLanguageActions,
} from '@default-application-app/actions';
import { State as RootState } from '@default-application-app/reducers/index';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { getIsLoggedIn, logoutReducer } from '@default-application-app/reducers/logout.reducer';
import {
  selectUserPermissionsEntitiesSelector,
  userPermissionsReducer,
} from '@default-application-app/reducers/permission.reducer';
import * as FromSiteText from '@default-application-app/reducers/siteText.reducer';
import { siteLanguageReducer } from '@default-application-app/reducers/siteLanguage.reducer';

export interface LogoutState {
  logOut: LogoutActions.State;
}

export interface UserPermissionsState {
  userPermissions: UserPermissionsActions.State;
}

export interface SiteTextState {
  siteTexts: SiteTextActions.State;
}

export interface SiteLanguageState {
  siteLanguage: SiteLanguageActions.State;
}

export interface State extends RootState {
  logOut: LogoutState;
  userPermissions: UserPermissionsState;
  siteTexts: SiteTextState;
  siteLanguage: SiteLanguageState;
}

export const logoutReducers: ActionReducerMap<LogoutState, any> = {
  logOut: logoutReducer,
};

export const userPermissionsReducers: ActionReducerMap<UserPermissionsState, any> = {
  userPermissions: userPermissionsReducer,
};

export const siteTextsReducers: ActionReducerMap<SiteTextState, any> = {
  siteTexts: FromSiteText.siteTextsReducer,
};

export const siteLanguageReducers: ActionReducerMap<SiteLanguageState, any> = {
  siteLanguage: siteLanguageReducer,
};

export const getLogOutState = createFeatureSelector<State, LogoutState>('logOut');

export const getUserPermissionsFutureState = createFeatureSelector<State, UserPermissionsState>('userPermissions');

export const getSiteTextsFutureState = createFeatureSelector<State, SiteTextState>('siteTexts');

export const getSiteLanguageState = createFeatureSelector<State, SiteLanguageState>('siteLanguage');

export const getLogOutEntitiesState = createSelector(getLogOutState, (state) => state.logOut);

export const getLogOutIsLoggedIn: any = createSelector(getLogOutEntitiesState, getIsLoggedIn);

// User Permissions

export const getUserPermissionsState = createSelector(getUserPermissionsFutureState, (state) => state.userPermissions);

export const selectUserPermissionsEntities = createSelector(
  getUserPermissionsState,
  selectUserPermissionsEntitiesSelector,
);

export const getUserPermissionForKey = (key: string): any =>
  createSelector(
    selectUserPermissionsEntities,
    () => key,
    (entities, id) => entities[id],
  );

// site Texts

export const getSiteTextState = createSelector(getSiteTextsFutureState, (state) => state.siteTexts);

export const selectAllSiteTexts: any = createSelector(getSiteTextState, FromSiteText.selectAllSiteTextSelector);

export const selectSiteTextsLoading: any = createSelector(getSiteTextState, FromSiteText.getLoading);

export const selectSiteTextsErrors: any = createSelector(getSiteTextState, FromSiteText.getErrors);

// site Language

export const getSiteLanguage: any = createSelector(getSiteLanguageState, (state) => state.siteLanguage.language);

export const getSiteLanguages: any = createSelector(getSiteLanguageState, (state) => state.siteLanguage.languages);
