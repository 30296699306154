import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UserGroupTransfersApiService } from './user-group-transfers-api.services';

@Injectable()
export class UserGroupTransfersService {

  public constructor(protected UserGroupTransfersApiService: UserGroupTransfersApiService) { }
  /**
* Get Group Transfers For user
*/
  public getUserGroupTransfers(userId: string): Observable<any> {
    return this.UserGroupTransfersApiService
      .apiGetUserGroupTransfers(userId);
  }

  public getUserGroupTransfersById(groupId: string): Observable<any> {
    return this.UserGroupTransfersApiService
      .apiGetUserGroupTransfersById(groupId);
  }

  public createGroupTransfer(userId: string): Observable<any> {
    return this.UserGroupTransfersApiService
      .apiCreateGroupTransfer(userId);
  }

  public updateGroupTransfer(groupId: string, details: {}): Observable<any> {
    return this.UserGroupTransfersApiService
      .apiUpdateGroupTransfer(groupId, details);
  }

  public deleteGroupTransfer(groupId: string): Observable<any> {
    return this.UserGroupTransfersApiService
      .apiDeleteGroupTransfer(groupId);
  }

  public deleteSubGroupTransfer(subtransferId: string): Observable<any> {
    return this.UserGroupTransfersApiService
      .apiDeleteSubGroupTransfer(subtransferId);
  }

  public getGroupSubTransfers(groupId: string): Observable<any> {
    return this.UserGroupTransfersApiService
      .apiGetGroupSubTransfers(groupId);
  }

  public submitGroupTransfer(groupId: string, otp: string): Observable<any> {
    return this.UserGroupTransfersApiService
      .apiSubmitGroupTransfer(groupId, otp);
  }

  public updateGroupTransferStatus(groupId: string, status: string): Observable<any> {
    return this.UserGroupTransfersApiService
      .apiUpdateGroupTransferStatus(groupId, status);
  }

  public updateGroupTotalAmount(groupId: string, totalAmount: number): Observable<any> {
    return this.UserGroupTransfersApiService
      .apiUpdateGroupTotalAmount(groupId, totalAmount);
  }

  public addSubTransfer(data: any): Observable<any> {
    return this.UserGroupTransfersApiService
      .apiAddSubTransfer(data);
  }

  public uploadSubTransfer(data: { fileName: string; fileData: string; }): Observable<any> {
    return this.UserGroupTransfersApiService.apiUploadSubTransfer(data);
  }

  public getUserById(userId: string): Observable<any> {
    return this.UserGroupTransfersApiService
      .apiGetUserById(userId);
  }
  

}
