import { Action } from '@ngrx/store';
import {
  initialOptionsState,
  optionsAdapter,
  reducer,
  State,
} from '@default-application-app/components/profiles/actions/option.actions';

// eslint-disable-next-line @typescript-eslint/default-param-last
export function optionReducer(state: State = initialOptionsState, action: Action): State {
  return reducer(state, action);
}

const { selectEntities } = optionsAdapter.getSelectors();

export const selectOptionsEntities = selectEntities;

export const getOptionsLoading = (state: State) => state.loading;

export const getCurrentPath = (state: State) => state.currentPath;

export const getOptionsErrors = (state: State) => state.errors;
