/**
 * @license Copyright iKantam LLC. All Rights Reserved.
 *
 *   Use of this source code is governed by an CC BY-NC-ND 4.0 license that can be found in the LICENSE file at
 *   https://creativecommons.org/licenses/by-nc-nd/4.0
 */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { User } from '@default-application-app/core/models/user-model';
import { IUser } from '@default-application-app/core/interfaces/user-interface';
import { PaginationPageLimitInterface } from '@default-application-app/core/interfaces/pagination-page-limit.interface';
import { PaginationService } from '@default-application-app/core/services/pagination/pagination.service';
import { map, switchMap } from 'rxjs/operators';
import { queryParamsStringify } from '@default-application-app/core/helpers/queryParamsHelpers';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data.min';
import { NgxSpinnerService } from 'ngx-spinner';
import { KYCRequestInterface } from '@default-application-app/core/interfaces/kyc/kyc-request.interface';
import { KYC_STATUSES } from '@constants/kyc-statuses';
import { UserApiService } from './user-api.service';

@Injectable()
export class UserService {
  public constructor(private userApiService: UserApiService, private spinner: NgxSpinnerService) {}

  /** LoadKYCRequests */

  public loadKYCRequestsSubject$: Subject<{ [key: string]: string }> = new Subject<{ [key: string]: string }>();

  public onLoadKYCRequests$: Observable<{ requests: KYCRequestInterface[]; pagination: PaginationPageLimitInterface }> =
    this.loadKYCRequestsSubject$.asObservable().pipe(
      map((query: any) => UserService.transformKycParams(query as { [key: string]: string })),
      switchMap((params: { [key: string]: string }) => this.userApiService.apiLoadKYCRequests(params)),
      map((response: any) => {
        if (response.error) {
          return { requests: [], pagination: PaginationService.defaultPaginationPageLimit };
        }

        const requests = response.data as KYCRequestInterface[];

        const pagination = response.links
          ? PaginationService.buildPaginationPages(response.links)
          : PaginationService.defaultPaginationPageLimit;
        return { requests, pagination };
      }),
    );

  private static transformKycParams(queryParams: { [key: string]: string }): { [key: string]: string } {
    const params = {};

    // @ts-ignore
    params.page = { number: queryParams.page, size: queryParams.size };
    const filter = {};
    if (queryParams.dateTo) {
      filter['updatedAt:lte'] = moment(queryParams.dateTo).endOf('day').utc().format();
    }
    if (queryParams.dateFrom) {
      filter['updatedAt:gte'] = moment(queryParams.dateFrom).startOf('day').utc().format();
    }
    if (
      queryParams.status &&
      queryParams.status !== KYC_STATUSES.SENT_TO_CRM &&
      queryParams.status !== KYC_STATUSES.SENT_TO_COMPLIANCE
    ) {
      // @ts-ignore
      filter.status = queryParams.status;
    }
    if (queryParams.status && queryParams.status === KYC_STATUSES.SENT_TO_CRM) {
      // @ts-ignore
      filter.crmStatus = KYC_STATUSES.PENDING;
    }
    if (queryParams.status && queryParams.status === KYC_STATUSES.PENDING) {
      // @ts-ignore
      filter.complianceStatus = KYC_STATUSES.PENDING;
    }
    if (queryParams.query) {
      // @ts-ignore
      filter.query = queryParams.query;
    }
    if (queryParams.userRequirementValue) {
      // @ts-ignore
      filter.userRequirementValue = queryParams.userRequirementValue;
    }
    if (queryParams.requirementFormIndex) {
      // @ts-ignore
      filter.requirementFormIndex = queryParams.requirementFormIndex;
    }
    if (Object.keys(filter).length) {
      // @ts-ignore
      params.filter = filter;
    }

    return queryParamsStringify(params, false);
  }

  public loadUsersForSelect({ username, roleName }: { username: string; roleName?: string[] }): Observable<IUser[]> {
    const params: [[string, string]] = [['', '']];
    if (username) {
      params.push(
        Object.entries(
          queryParamsStringify({
            filter: {
              query: username,
            },
          }),
        )[0],
      );
    }

    params.push(
      Object.entries(
        queryParamsStringify({
          filter: {
            role_name: roleName.join(','),
          },
        }),
      )[0],
    );

    return this.userApiService.apiLoadUsers(params).pipe(
      map(({ data, error }: { data: any; error: boolean }) => {
        if (error) {
          return [];
        }
        return data.items.map((item) => new User(item));
      }),
    );
  }

  public loadUser(uid: string): Observable<IUser> {
    return this.userApiService.apiLoadUser(uid).pipe(
      map(({ data, error }: { data: any; error: boolean }) => {
        if (error) {
          return null;
        }
        return new User(data);
      }),
    );
  }
}
