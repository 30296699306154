import { Component, forwardRef, Input, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface IDate {
  dateOfBirthYear: number;
  dateOfBirthMonth: number;
  dateOfBirthDay: number;
}

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDateComponent),
      multi: true,
    },
  ],
})
export class InputDateComponent implements OnInit, ControlValueAccessor {
  @Input() public clearable = false;

  onChange: Function;

  onTouched: Function;

  disabled: boolean;

  public selectDate = {
    month: 12,
    date: 31,
    year: 100,
    currentYear: moment().year(),
  };

  public form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      dateOfBirthYear: [null],
      dateOfBirthMonth: [null],
      dateOfBirthDay: [null],
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: string | Date): void {
    if (!value) {
      return;
    }

    const date = typeof value === 'string' ? new Date(value) : value;

    this.form.controls.dateOfBirthYear.setValue(date.getFullYear());
    this.form.controls.dateOfBirthMonth.setValue(date.getMonth() + 1);
    this.form.controls.dateOfBirthDay.setValue(date.getDate());
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.form.get('dateOfBirthYear').disable();
      this.form.get('dateOfBirthMonth').disable();
      this.form.get('dateOfBirthDay').disable();
    } else {
      this.form.get('dateOfBirthYear').enable();
      this.form.get('dateOfBirthMonth').enable();
      this.form.get('dateOfBirthDay').enable();
    }
  }

  onDateChange(): void {
    const formIsEmpty =
      this.form.controls.dateOfBirthYear.value === null &&
      this.form.controls.dateOfBirthMonth.value === null &&
      this.form.controls.dateOfBirthDay.value === null;

    if (formIsEmpty) {
      this.onChange(null);
      return;
    }

    const date = moment({
      y: this.form.controls.dateOfBirthYear.value !== null ? this.form.controls.dateOfBirthYear.value : NaN,
      M: this.form.controls.dateOfBirthMonth.value !== null ? this.form.controls.dateOfBirthMonth.value - 1 : NaN,
      d: this.form.controls.dateOfBirthDay.value !== null ? this.form.controls.dateOfBirthDay.value : NaN,
    });

    this.onChange(date.format('YYYY-MM-DD'));

    if (
      this.form.controls.dateOfBirthYear.value !== null &&
      this.form.controls.dateOfBirthMonth.value !== null &&
      this.form.controls.dateOfBirthDay.value !== null
    ) {
      this.onTouched();
    }
  }
}
