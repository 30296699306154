import { Directive, Input, Renderer2, ElementRef, ViewChildren, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface CheckEmptyTableParamsInterface {
  dataExists: boolean | number;
  message?: string;
}

@Directive({
  selector: '[checkEmptyTable]',
})
export class CheckEmptyTableDirective implements OnDestroy {
  public message = 'layout.system.no_results_available';

  public translatedMessage = '';

  private unsubscribeSubject: Subject<void> = new Subject<void>();

  constructor(
    private elementRef: ElementRef,
    private _renderer: Renderer2,
    private translateService: TranslateService,
  ) {}

  public ngOnDestroy(): void {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

  @ViewChildren('.no-search-results') _elem: ElementRef;

  emptyElement = this._renderer.createElement('tr');

  @Input() set checkEmptyTable(params: CheckEmptyTableParamsInterface) {
    if (params.message) {
      this.message = params.message;
    }
    this.initSomething(params);
  }

  private initSomething(params: CheckEmptyTableParamsInterface): void {
    const parent = this.elementRef.nativeElement;

    if (!params.dataExists) {
      this.emptyElement.classList.add('no-search-results');
      this.emptyElement.innerHTML = `<td colspan="50"><div class="no-search-icon">${this.translateService.instant(
        this.message,
      )}</div></td>`;
      this._renderer.appendChild(parent, this.emptyElement);
      this.subscribeToLanguage();
    } else if (this.emptyElement) {
      this.emptyElement.innerHTML = '';
      this.emptyElement.classList.remove('no-search-results');
      this.unsubscribeSubject.next();
      this.unsubscribeSubject.complete();
    }
  }

  private subscribeToLanguage(): void {
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribeSubject)).subscribe(() => {
      this.translatedMessage = this.translateService.instant(this.message);
      this.emptyElement.innerHTML = `<td colspan="50"><div class="no-search-icon">${this.translatedMessage}</div></td>`;
    });
  }
}
