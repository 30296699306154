// export const MIME_TYPES_IMAGE = 'application/pdf,image/jpeg,image/jpg,image/png';

export const MIME_TYPES_IMAGE = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'image/heic'].join(',');

export const MIME_TYPES_DOCUMENT = ['image/jpeg', 'image/jpg', 'image/png', 'image/heic'].join(',');

// E.g. Excel changes 'text/csv' to 'application/vnd.ms-excel'
export const MIME_TYPES_CSV = [
  'text/comma-separated-values',
  'text/csv',
  'application/csv',
  'application/excel',
  'application/vnd.ms-excel',
  'application/vnd.msexcel',
  'text/anytext',
];

export const MIME_TYPES_DOCUMENTS = [
  'application/pdf',
  ...MIME_TYPES_CSV,
  'text/plain',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
].join(',');
