import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  private breadcrumb: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public setBreadcrumb(value: string): void {
    this.breadcrumb.next(value);
  }

  public getBreadcrumb(): Observable<string> {
    return this.breadcrumb as Observable<string>;
  }
}
