<div class="input-header">
  <label *ngIf="label" class="input-header__label" [class.required]="isRequiredLabel">
    {{ label }}
    <app-tooltip *ngIf="tooltipMessage">{{ tooltipMessage }}</app-tooltip>
  </label>
</div>

<span *ngIf="limitByKey">&nbsp;({{ limitByKey }})</span>

<input
  [(ngModel)]="model"
  [name]="name"
  (ngModelChange)="onChange($event)"
  (input)="onInput($event)"
  (blur)="onBlur()"
  [class.card-input]="cardInputClass"
  [class.lock-field]="isLockFieldClass"
  [class.filed]="isFilledClass"
  [autocomplete]="isAutocompleteDisabled ? 'disable' : true"
  class="native-input"
  user-options-field-disabled
  [optionName]="optionName"
  [placeholder]="placeholder"
  makeActiveInput
  [requiredIf]="isRequiredIf"
  [attr.disabled]="isDisabled ? true : null"
  [type]="type"
  [value]="value"
  [readOnly]="isReadonly"
  [textMask]="mask ? mask : { mask: false }"
  [title]="title || label" />

<label *ngIf="footnote" class="footnote">
  {{ footnote }}
</label>

<label *ngIf="otherText" class="other-text">{{ otherText }}</label>
