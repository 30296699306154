import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UserOptionNames } from '@default-application-app/core/constants/optionsPrefixes';
import { AppOptionsService } from '@default-application-app/core/services/appOptions.service';
import { take } from 'rxjs/operators';
import { AuthService } from '@default-application-app/core/services/auth/auth.service';

@Directive({
  selector: '[user-options-field-disabled]',
})
export class UserOptionsFieldDisabledDirective {
  @Input()
  set optionName(value: UserOptionNames) {
    if (!value) {
      return;
    }

    if (this.authService.isAdminOrRootUser()) {
      return;
    }
    this.appOptionsService
      .getUserOption(value)
      .pipe(take(1))
      .subscribe((enabled: boolean) => {
        if (!enabled) {
          this.control.control.disable();
        }
      });
  }

  constructor(
    private control: NgControl,
    private appOptionsService: AppOptionsService,
    private authService: AuthService,
  ) {}
}
