import { Component, EventEmitter, HostListener, Input, Output, ViewEncapsulation } from '@angular/core';
import { DatepickerComponent } from 'ng2-datepicker';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-date-picker-buttons',
  templateUrl: './date-picker-buttons.component.html',
  styleUrls: ['./date-picker-buttons.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DatePickerButtonsComponent {
  @Input() dateElement: DatepickerComponent;

  @Input() form: UntypedFormGroup;

  @Input() controlName: string;

  @Output() public clearDatepickerEmit = new EventEmitter<boolean>();

  @HostListener('document:keyup.delete')
  private keyupDelete() {
    if (this.dateElement.isOpened) {
      this.clearDatepicker();
    }
  }

  public openCalendar(): void {
    this.dateElement.toggle();
  }

  public clearDatepicker() {
    this.form.get(this.controlName).setValue(null);
    this.clearDatepickerEmit.emit(true);
  }
}
