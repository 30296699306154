import { Component, Input, OnInit } from '@angular/core';
import * as SiteLanguagesActions from '@default-application/src/app/actions/siteLanguage.actions';
import { select, Store } from '@ngrx/store';
// eslint-disable-next-line import/no-duplicates
import * as FromLanguage from '@default-application/src/app/reducers/app.reducer';
import { takeUntil } from 'rxjs/operators';
// eslint-disable-next-line import/no-duplicates
import * as FromApp from '@default-application/src/app/reducers/app.reducer';
import { UnsubscribeDestroyHelper } from '@helpers/unsubscribe-destroy.helper';
import { LanguageInterface } from '@interfaces/language.interface';
import { DropdownItem } from '@default-application-app/modules/components/interfaces/dropdown-item';
import { Languages } from '@constants/languages';

@Component({
  selector: 'app-header-language-menu',
  templateUrl: './header-language-menu.component.html',
  styleUrls: ['./header-language-menu.component.scss'],
})
export class HeaderLanguageMenuComponent extends UnsubscribeDestroyHelper implements OnInit {
  @Input() private authenticated = false;

  public languages: LanguageInterface[];

  public currentLanguage: string;

  public dropDownMenuItems: DropdownItem[] = [];

  constructor(private store: Store<FromLanguage.SiteLanguageState>) {
    super();
  }

  public ngOnInit(): void {
    this.store
      .pipe(select(FromApp.getSiteLanguage))
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((currentLanguage) => {
        this.currentLanguage = currentLanguage;
      });

    this.store
      .pipe(select(FromApp.getSiteLanguages))
      .pipe(takeUntil(this.unsubscribeSubject))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .subscribe((currentLanguage) => {
        this.languages = Languages;
        this.dropDownMenuItems = this.languages.map((value: LanguageInterface) => ({
          icon: `language/ic_${value.code}.svg`,
          title: `layout.languages.${value.code}`,
          key: value.code,
        }));
      });
  }

  public onMenuItemClick(item: DropdownItem): void {
    if (this.authenticated) {
      this.store.dispatch(new SiteLanguagesActions.SaveUserLanguage(item.key));
    } else {
      this.store.dispatch(new SiteLanguagesActions.SetSiteLanguage(item.key));
    }
  }
}
