import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DatepickerOptions, DatepickerComponent } from 'ng2-datepicker';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data.min';
import { DATEPICKER_BASE_OPTIONS, DATEPICKER_RESPONSE_FORMAT } from '@constants/datepickerBaseOptions';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { AppOptionsService } from '@services/appOptions.service';
import { Subject } from 'rxjs';
import { OptionsValuesNames } from '@constants/optionsPrefixes';

@Component({
  selector: 'app-date-to',
  templateUrl: './date-to.component.html',
  styleUrls: ['./date-to.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DateToComponent implements OnInit, OnDestroy {
  @ViewChild('dateTo', { static: true }) dateToElement: DatepickerComponent;

  @Input() form: UntypedFormGroup;

  @Input() isSmall = false;

  public to: string;

  public dateToOptions: DatepickerOptions;

  private unsubscribeSubject: Subject<void> = new Subject<void>();

  @Input() title = '';

  constructor(protected route: ActivatedRoute, private appOptionService: AppOptionsService) {
    this.createDatepickerOptions();
  }

  private static isValidateDate(date: string): boolean {
    return moment(date, DATEPICKER_RESPONSE_FORMAT, true).isValid();
  }

  ngOnInit(): void {
    this.appOptionService
      .getOptionsValuePipe(OptionsValuesNames.DEFAULT_DATE_FORMAT)
      .pipe(take(1))
      .subscribe((format: string) => {
        format = format.replace('DD', 'dd').replace('YYYY', 'yyyy');

        this.dateToOptions = {
          ...this.dateToOptions,
          format,
        };
      });

    this.form.addControl('dateTo', new UntypedFormControl(''));

    this.form
      .get('dateTo')
      .valueChanges.pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((value: string) => {
        if (value && !DateToComponent.isValidateDate(value)) {
          value = '';
        }
        this.to = value;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

  public setDateTo(dateTo: Date): void {
    const date = moment(dateTo).format(DATEPICKER_RESPONSE_FORMAT);
    this.form.patchValue({ dateTo: date });
  }

  public dateToReset() {
    this.form.get('dateTo').setValue(null);
    this.dateToElement.displayValue = null;
    this.dateToElement.innerValue = new Date();
  }

  private createDatepickerOptions(): void {
    this.dateToOptions = { ...DATEPICKER_BASE_OPTIONS };
  }
}
