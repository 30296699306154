<!--<div *ngIf="isRoot()" class="top-header-for-super">
  {{ 'layout.system.signed_as_the_root_user' | translate }}
</div>-->

<div class="header">
  <div class="crumbs">
    <app-breadcrumbs></app-breadcrumbs>
  </div>

  <div class="header-bar">
    <div
      *ngIf="isRoot()"
      [title]="'layout.aside_menu.transfer_requests' | translate"
      class="header-bar__item"
      routerLink="/admin/accounts/transfer-requests">
      <app-svg-icon iconPath="menu/ic_transfer_requests.svg" class="icon"></app-svg-icon>
    </div>

    <div class="header-bar__item" *ngIf="!isIbanAdmin">
      <div [title]="unreadMessagesTitle" class="header-bar__messages-notification" routerLink="/helpdesk">
        <app-svg-icon iconPath="menu/ic_messages.svg" class="icon"></app-svg-icon>

        <div *ngIf="unreadMessages" class="unread unread-message"></div>
      </div>
    </div>

    <div class="header-bar__item" *ngIf="!isAdmin && !isIbanAdmin">
      <div
        [title]="'layout.crumbs.notifications' | translate"
        class="header-bar__messages-notification"
        routerLink="/notifications">
        <app-svg-icon iconPath="header/ic_bell.svg" class="icon icon--bell"></app-svg-icon>

        <div *ngIf="isPendingNotifications" class="unread unread-message"></div>
      </div>
    </div>

    <!--    <div *ngIf="isRoot()" class="header-bar__item">-->
    <!--      <div-->
    <!--        [title]="'layout.aside_menu.expiring_documents' | translate"-->
    <!--        class="header-bar__messages-notification"-->
    <!--        routerLink="/admin/profiles/expiring-documents">-->
    <!--        <app-svg-icon iconPath="menu/ic_document.svg" class="icon"></app-svg-icon>-->

    <!--        <div *ngIf="false" class="unread unread-document"></div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="header-bar__item" *ngIf="!isAdmin && !isIbanAdmin">
      <app-header-language-menu [authenticated]="true"></app-header-language-menu>
    </div>

    <app-drop-down-menu
      class="header-bar__item drop-down"
      [menuItems]="dropDownMenuItems"
      (emitItemClick)="onMenuItemClick($event)">
      <app-svg-icon [iconPath]="avatarLink" class="avatar-photo"></app-svg-icon>
    </app-drop-down-menu>
  </div>
</div>

<app-popup-autologout
  (userConfirm)="onConfirm($event)"
  *ngIf="showPopupConfirmAutoLogout"
  [autoLogoutPadding]="autoLogoutPadding"
  [buttonNoName]="'layout.system.log_out' | translate"
  [buttonYesName]="'layout.system.continue_session' | translate"
  [headingMessage]="headingMessage"
  [message]="popupMessage"
  [show]="showPopupConfirmAutoLogout">
</app-popup-autologout>
