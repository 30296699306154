import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiCallerService } from '@default-application-app/core/services/api-caller.service';
import { ConfigService } from '@default-application-app/config.service';

@Injectable()
export class IbanApiService {
  constructor(
    private http: HttpClient,
    private apiCallerService: ApiCallerService,
    private configService: ConfigService,
  ) { }

  /**
   * Makes a GET request to fetch IBAN requests with optional parameters.
   * @param params Object containing optional query parameters for pagination and filtering
   */
  public apiGetIbanRequests(params: { [key: string]: any }) {
    const httpParams = new HttpParams({ fromObject: params });

    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.iban.getIbanRequest, { params: httpParams }),
      'apiGetIbanRequests',
    );
  }

  public apiApproveIbanRequest(requestId: string, ibanNumber: string) {
    return this.apiCallerService.call(
      () =>
        this.http.post(this.configService.config.api.iban.approveIbanRequest,
          {
            'request-id': requestId,
            'iban-number': ibanNumber
          }
        ),
      'apiApproveIbanRequest',
    );
  }

  public apiGetRequestById(requestId: string) {
    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.iban.getRequestById(requestId),
        ),
      'apiGetRequestById',
    );
  }
}
