export interface NoteInterface {
  createdAt: string;
  id: number;
  note: string;
  user: { firstName: string; lastName: string; uid: string };
}

export class NoteModel implements NoteInterface {
  public createdAt: string;

  public id: number;

  public note: string;

  public user: { firstName: string; lastName: string; uid: string };

  constructor(params: any) {
    Object.assign(this, params);
  }
}
