import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormGroup,
  FormGroupDirective,
  NgControl,
  UntypedFormGroup,
} from '@angular/forms';
import { CurrencyMaskConfig } from 'ng2-currency-mask';
import { CurrenciesPrecision, CustomCurrencyMaskConfig } from '@constants/currencies';

@Component({
  selector: 'app-input-amount',
  templateUrl: './input-amount.component.html',
  styleUrls: ['./input-amount.component.scss'],
})
export class InputAmountComponent implements ControlValueAccessor, OnInit {
  @Input()
  public placeholder = '';

  @Input()
  public label = '';

  @Input()
  public noDisabledStyles = false;

  @Input()
  public currencyCode: string;

  @Input()
  public fee: string;

  @Input()
  public type: 'text' | 'email' | 'number' = 'text';

  @Input()
  public isDisabled = false;

  @Input()
  public isOptional = false;

  @Input()
  public formControlName: string;

  @Input()
  public name: string;

  @Input()
  public autocomplete: string;

  @Input() public isRequiredIf: boolean;

  @Input()
  public tooltipMessage: string;

  @Input()
  options: CurrencyMaskConfig;

  @Output()
  public blur: EventEmitter<any> = new EventEmitter<any>();

  constructor(public control: NgControl, private controlContainer: ControlContainer) {
    this.control.valueAccessor = this;
  }

  public ngOnInit() {
    if (!this.options && this.currencyCode) {
      const precision: number = 2;

      this.options = {
        ...CustomCurrencyMaskConfig,
        precision: CurrenciesPrecision.has(this.currencyCode) ? CurrenciesPrecision.get(this.currencyCode) : precision,
      };
    }
  }

  public model: string;

  public onChange: any = () => {};

  public onTouched: any = () => {};

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(obj: any): void {
    this.model = obj;
  }

  public onBlur(): void {
    this.blur.next();
    this.onTouched();
  }

  private get form(): UntypedFormGroup {
    return this.controlContainer.formDirective
      ? ((this.controlContainer.formDirective as FormGroupDirective).form as FormGroup)
      : null;
  }

  public get isFormControlDisabled(): boolean {
    if (this.form.get(this.formControlName)) {
      return this.form.get(this.formControlName).disabled;
    }

    return false;
  }
}
