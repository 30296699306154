import { Pipe, PipeTransform } from '@angular/core';
import { AppOptionsService } from '@default-application-app/core/services/appOptions.service';
import { OptionsValuesNames } from '@default-application-app/core/constants/optionsPrefixes';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data.min';
import { UnsubscribeDestroyHelper } from '@default-application-app/core/helpers/unsubscribe-destroy.helper';
import { takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

const DATE_ONLY_WITHOUT_TIMEZONE = 'dateOnlyWithoutTimezone';
const DATE_ONLY = 'dateOnly';
const TIME_ONLY = 'timeOnly';

@Pipe({
  name: 'customDate',
})
export class CustomDatePipe extends UnsubscribeDestroyHelper implements PipeTransform {
  private dateFormat = 'DD/MM/YYYY';

  private timeFormat = 'HH:mm';

  constructor(private appOptionService: AppOptionsService) {
    super();
    this.subscribeToOptions();
  }

  transform(date: string, formatSize?: string): string {
    if (date && moment(date).isValid()) {
      return this.makeTransform(date, formatSize);
    }
    return '';
  }

  public returnTimeFormat(): string {
    return this.timeFormat;
  }

  private subscribeToOptions() {
    combineLatest([
      this.appOptionService.getOptionsValuePipe(OptionsValuesNames.DEFAULT_DATE_FORMAT),
      this.appOptionService.getOptionsValuePipe(OptionsValuesNames.DEFAULT_TIME_FORMAT),
    ])
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(([dateFormat, timeFormat]) => {
        this.dateFormat = (dateFormat as string) || this.dateFormat;
        this.timeFormat = (timeFormat as string) || this.timeFormat;
      });
  }

  private makeTransform(date: string, formatSize: string): string {
    switch (formatSize) {
      case DATE_ONLY_WITHOUT_TIMEZONE:
        return moment(date).format(this.dateFormat);
      case DATE_ONLY:
        return moment(date).local().format(this.dateFormat);
      case TIME_ONLY:
        return moment(date).local().format(this.timeFormat);
      default:
        return moment(date).local().format(`${this.dateFormat} ${this.timeFormat}`);
    }
  }
}
