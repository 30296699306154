import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderLanguageMenuComponent } from '@default-application-app/components/header-language-menu/header-language-menu.component';
import { SvgIconModule } from '@default-application-app/modules/components/svg-icon/svg-icon.module';
import { DropDownMenuModule } from '@default-application-app/modules/components/drop-down-menu/drop-down-menu.module';

@NgModule({
  declarations: [HeaderLanguageMenuComponent],
  imports: [CommonModule, TranslateModule, SvgIconModule, DropDownMenuModule],
  exports: [HeaderLanguageMenuComponent],
})
export class HeaderLanguageMenuModule {}
