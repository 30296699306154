<div class="header">
  <div class="header__logo-container">
    <a class="header__link" href="">
      <div class="header__logo-container__image"></div>
    </a>
  </div>
</div>

<div class="main-display">
  <div class="section__image">
    <svg
      xmlns="https://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="228"
      height="244"
      viewBox="0 0 228 244">
      <defs>
        <circle id="a" cx="39" cy="39" r="39" />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <path fill="#F5F5F5" d="M0 0h190v216a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V0z" />
        <path
          fill="#FFF"
          d="M11 29h28v178H11zM45 29h135v8H45zM45 70h135v8H45zM45 42h135v8H45zM45 83h135v8H45zM45 56h135v8H45zM45 97h135v8H45zM45 111h135v8H45zM45 125h135v82H45z" />
        <path fill="#FFF" stroke="#F5F5F5" d="M.5.5h189v19H.5z" />
        <path fill="#F7F7F7" d="M65 7h115v6H65z" />
        <path fill="#FF7658" d="M10 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
        <path fill="#FFDC36" d="M20 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
        <path fill="#00CC3A" d="M30 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
        <g transform="translate(122 138)">
          <circle cx="53" cy="53" r="47" fill="#80C21E" />
          <path
            fill="#80C21E"
            d="M50.23 0h5.54a2 2 0 0 1 1.997 1.894l2.23 42.22a2 2 0 0 1 .003.105v26.382a2 2 0 0 1-.004.131l-2.206 33.4A2 2 0 0 1 55.794 106h-5.588a2 2 0 0 1-1.996-1.868l-2.206-33.4a2 2 0 0 1-.004-.131V44.219a2 2 0 0 1 .003-.105l2.23-42.22A2 2 0 0 1 50.23 0z" />
          <path
            fill="#80C21E"
            d="M106 50.23v5.54a2 2 0 0 1-1.894 1.997l-42.22 2.23a2 2 0 0 1-.105.003H35.399a2 2 0 0 1-.131-.004l-33.4-2.206A2 2 0 0 1 0 55.794v-5.588a2 2 0 0 1 1.868-1.996l33.4-2.206a2 2 0 0 1 .131-.004h26.382a2 2 0 0 1 .105.003l42.22 2.23A2 2 0 0 1 106 50.23z" />
          <path
            fill="#80C21E"
            d="M92.435 88.518l-3.917 3.917a2 2 0 0 1-2.752.073L54.336 64.23a2 2 0 0 1-.077-.072L35.605 45.504a2 2 0 0 1-.09-.096L13.457 20.23a2 2 0 0 1 .09-2.732l3.952-3.952a2 2 0 0 1 2.732-.09l25.177 22.058a2 2 0 0 1 .096.09L64.16 54.259a2 2 0 0 1 .072.077l28.277 31.43a2 2 0 0 1-.073 2.752z" />
          <path
            fill="#80C21E"
            d="M88.518 13.565l3.917 3.917a2 2 0 0 1 .073 2.752L64.23 51.664a2 2 0 0 1-.072.077L45.504 70.395a2 2 0 0 1-.096.09L20.23 92.543a2 2 0 0 1-2.732-.09L13.547 88.5a2 2 0 0 1-.09-2.732l22.058-25.177a2 2 0 0 1 .09-.096L54.259 41.84a2 2 0 0 1 .077-.072l31.43-28.277a2 2 0 0 1 2.752.073z" />
          <g transform="translate(14 14)">
            <mask id="b" fill="#fff">
              <use xlink:href="#a" />
            </mask>
            <use fill="#FFF" xlink:href="#a" />
            <circle cx="39" cy="39" r="20" fill="#D2D2D2" mask="url(#b)" opacity=".161" />
            <path fill="#80C21E" d="M38-.368V39h43V4.914L51.492-5z" mask="url(#b)" opacity=".254" />
          </g>
        </g>
        <g fill="#80C21E">
          <path
            d="M173 154h2v6h-2zM173 222h2v6h-2zM200.456 164.13l1.415 1.414-4.243 4.243-1.414-1.414zM152.372 212.213l1.414 1.414-4.242 4.243-1.415-1.414zM212 190v2h-6v-2zM144 190v2h-6v-2zM201.87 216.456l-1.415 1.414-4.242-4.243 1.414-1.414zM153.787 168.373l-1.414 1.414-4.243-4.243 1.415-1.414z" />
        </g>
        <path fill="#5B5B5B" d="M174 190h27v2h-27z" />
        <path fill="#5B5B5B" d="M175 167v25h-2v-25z" />
      </g>
    </svg>
  </div>

  <div class="section__text">
    <p class="message-text gray-primary">
      {{ messageText }}
    </p>
  </div>
</div>
