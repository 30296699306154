import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StoreOptionsModel } from '@default-application-app/core/models/option.model';
import { select, Store } from '@ngrx/store';
import * as FromProfiles from '@default-application-app/components/profiles/reducers';
import { filter, map } from 'rxjs/operators';
import * as OptionActions from '@default-application-app/components/profiles/actions/option.actions';
import { OptionsHelper } from '@default-application-app/core/helpers/options.helper';
import {
  OptionsPaths,
  OptionsPrefixes,
  OptionsValuesNames,
  optionsValuesPaths,
  pathOptionsPrefixes,
  publicPaths,
  UserOptionNames,
} from '@default-application-app/core/constants/optionsPrefixes';
// eslint-disable-next-line import/no-duplicates
import * as FromApp from '@default-application/src/app/reducers/app.reducer';
// eslint-disable-next-line import/no-duplicates
import { getUserPermissionForKey } from '@default-application/src/app/reducers/app.reducer';
import { UserPermissionsActions } from '@default-application/src/app/actions';
import { UserPermissions } from '@default-application-app/core/constants/userPermissions';
import { UserPermissionModel } from '@default-application-app/core/models/userPermission.model';

const ENABLE_VALUE = 'enable';

@Injectable()
export class AppOptionsService {
  private static isLoadedPermissions: boolean;

  private optionsPipes: Map<OptionsPaths, Observable<{ [key: string]: boolean | string }>> = new Map();

  constructor(
    private store: Store<FromProfiles.OptionsState>,
    private permissionsStore: Store<FromApp.UserPermissionsState>,
  ) {}

  public clearOptions() {
    this.optionsPipes.clear();
  }

  public deleteOptions(path) {
    if (this.optionsPipes.has(path)) {
      this.optionsPipes.delete(path);
    }
  }

  public deleteOptionsValuePipe(valueName: OptionsValuesNames) {
    this.deleteOptions(optionsValuesPaths.get(valueName));
  }

  public getOptions(path: OptionsPaths): Observable<{ [key: string]: boolean | string }> {
    if (!this.optionsPipes.has(path)) {
      this.optionsPipes.set(path, this.getFromStoreOrAPI(path));
    }
    return this.optionsPipes.get(path);
  }

  public getOptionsValuePipe(valueName: OptionsValuesNames): Observable<boolean | string> {
    return this.getOptions(optionsValuesPaths.get(valueName)).pipe(
      map((data: { [key: string]: boolean | string }): string => <string>data[valueName]),
    );
  }

  public getUserOption(valueName: UserOptionNames): Observable<boolean> {
    return this.getOptions(OptionsPaths.USER_OPTIONS).pipe(
      filter((data: { [key: string]: string }) => Object.keys(data).length !== 0),
      map((data: { [key: string]: string }): boolean => {
        if (valueName === UserOptionNames.BENEFICIAL_OWNER) {
          return data[valueName] === ENABLE_VALUE;
        }
        const helper = new OptionsHelper(OptionsPrefixes.USER_OPTIONS_FIELD_USER);
        return <boolean>helper.parseValue(data[valueName]);
      }),
    );
  }

  public getUserPermissionForKey(key: UserPermissions): Observable<boolean> {
    if (!AppOptionsService.isLoadedPermissions) {
      this.loadPermissions();
    }
    return this.permissionsStore.pipe(select(getUserPermissionForKey(key))).pipe(
      filter((permission: UserPermissionModel): boolean => !!permission),
      map((permission: UserPermissionModel): boolean => permission.isAllowed),
    );
  }

  private getFromStoreOrAPI(path: OptionsPaths): Observable<{ [key: string]: boolean | string }> {
    this.store.dispatch(new OptionActions.LoadOptions({ path, publicPath: publicPaths.includes(path) }));
    const optionsHelper = new OptionsHelper(pathOptionsPrefixes.get(path));
    return this.store.pipe(
      select(FromProfiles.getPathOptions(path)),
      map((options: StoreOptionsModel | null) => options || { options: [] }),
      map((options: StoreOptionsModel): { [key: string]: boolean | string } =>
        optionsHelper.optionsArrayToPureObject(options.options),
      ),
    );
  }

  private loadPermissions() {
    this.permissionsStore.dispatch(new UserPermissionsActions.LoadUserPermissions());
    AppOptionsService.isLoadedPermissions = true;
  }
}
