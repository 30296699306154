import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { KycService } from '@services/kyc/kyc.service';
import { KycCurrentTierModel } from '@models/kyc/kyc-tier.model';

@Injectable()
export class KycTierResolver implements Resolve<KycCurrentTierModel> {
  constructor(private kycService: KycService) {}

  public resolve(): Observable<KycCurrentTierModel> {
    return this.kycService.kycCurrentTier();
  }
}
