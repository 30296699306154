<div style="position: relative; text-align: center; width: 100%; min-height: 30px">
  <img [src]="logoLink" height="50" alt="Logo" style="margin-bottom: 20px" />
</div>

<div>
  <a
    class="print-icon"
    style="position: absolute; right: 10px; top: 10px; font-family: sans-serif; color: #5f5d5d; cursor: pointer"
    onclick="
       const printIcon = document.querySelector('.print-icon');
       printIcon.style.display = 'none';
       window.print();
       printIcon.style.display = 'block';
    ">
    <i class="zmdi zmdi-print" style="font-size: 20px; color: #737373"></i>
    {{ 'common.print.print' | translate }}
  </a>
</div>
<h1
  *ngIf="header"
  style="
    text-align: center;
    border-collapse: collapse;
    background-color: #fff;
    font-family: sans-serif;
    margin-bottom: 30px;
  ">
  {{ header }}
</h1>

<div *ngIf="blocks" style="font-family: sans-serif; padding-top: 10px; border-top: 1px solid #eee">
  <h4 *ngIf="blocks.subTitle" style="margin-left: 10px">{{ blocks.subTitle }}</h4>

  <div *ngFor="let table of blocks.tables" style="float: left; margin-right: 10px">
    <ng-container *ngIf="table">
      <h3 class="text_color-main_color" style="margin-left: 10px">{{ table.title }}</h3>
      <table
        style="
          width: 500px;
          border-collapse: collapse;
          background-color: #fff;
          font-weight: lighter;
          font-family: sans-serif;
          font-size: 14px;
          margin-bottom: 30px;
        ">
        <thead>
          <th
            *ngFor="let value of table.columns"
            style="padding: 10px; background-color: lightgrey; text-align: left; font-weight: normal">
            {{ value }}
          </th>
        </thead>

        <tbody>
          <tr *ngFor="let values of table.rows" style="height: 68px">
            <td *ngFor="let value of values" style="padding: 10px; border: none">
              {{ value }}
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>
  <div *ngIf="blocks.notes" style="width: 500px; margin-left: 10px; font-weight: lighter">
    {{ blocks.notes }}
  </div>
</div>

<table
  *ngIf="table"
  style="
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    font-weight: lighter;
    font-family: sans-serif;
    font-size: 14px;
  ">
  <thead>
    <th
      *ngFor="let value of table.columns"
      style="padding: 10px; background-color: lightgrey; text-align: left; font-weight: normal">
      {{ value }}
    </th>
  </thead>

  <tbody>
    <tr *ngFor="let values of table.rows">
      <td *ngFor="let value of values" style="padding: 10px; border-top: 1px solid #000; border-bottom: 1px solid #000">
        {{ value }}
      </td>
    </tr>
  </tbody>
</table>

<div *ngIf="tables">
  <ng-container *ngFor="let table of tables.tables">
    <h2 *ngIf="table.titleForDraw" style="font-family: sans-serif; font-weight: lighter">
      {{ table.titleForDraw.toUpperCase() }}
    </h2>

    <table
      style="
        width: 100%;
        border-collapse: collapse;
        background-color: #fff;
        font-weight: lighter;
        font-size: 14px;
        margin-bottom: 30px;
      ">
      <thead>
        <th
          *ngFor="let value of table.columns"
          style="padding: 10px; background-color: lightgrey; text-align: left; font-weight: normal">
          {{ value }}
        </th>
      </thead>

      <tbody>
        <tr *ngFor="let values of table.rows">
          <td
            *ngFor="let value of values"
            style="padding: 10px; border-top: 1px solid #000; border-bottom: 1px solid #000">
            {{ value }}
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <div style="  position: fixed;
  bottom: 0px;
  right: 200px;
  text-align: center;
  width: auto;
  min-height: 30px;">
    <img [src]="stampLink" height="150" alt="Logo" style="margin-bottom: 20px" />
  </div>
</div>

<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/css/material-design-iconic-font.min.css" />

