import { Action } from '@ngrx/store';
import { ApiError } from '@models/api-error.model';
import { CustomActionInterface, staticImplementsDecorator } from '@interfaces/staticAction.interface';
import { buildReducer, checkToBeUniqueType } from '@helpers/redux-type-cashe';
import { OptionModel, StoreOptionsModel } from '@models/option.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface State extends EntityState<StoreOptionsModel> {
  loading: boolean;
  currentPath: string | null;
  errors: ApiError[];
}

export const optionsAdapter: EntityAdapter<StoreOptionsModel> = createEntityAdapter<StoreOptionsModel>({
  selectId: (options: StoreOptionsModel) => options.path,
});

export const initialOptionsState: State = optionsAdapter.getInitialState({
  loading: false,
  currentPath: null,
  errors: [],
});

export enum OptionActionsTypes {
  SET_OPTIONS_PATH = '[Options] set path',
  LOAD_OPTIONS = '[Options] load',
  LOAD_OPTIONS_SUCCESS = '[Options/API] load Success',
  LOAD_OPTIONS_FAILURE = '[Options/API] load Failure',
  UPDATE_OPTION = '[Options] update',
  UPDATE_OPTIONS_SUCCESS = '[Options/API] update Success',
  UPDATE_OPTIONS_FAILURE = '[Options/API] update Failure',
}

@staticImplementsDecorator<CustomActionInterface<SetOptionsPath, State>>()
export class SetOptionsPath implements Action {
  static type = checkToBeUniqueType(OptionActionsTypes.SET_OPTIONS_PATH);

  readonly type = OptionActionsTypes.SET_OPTIONS_PATH;

  constructor(public path: string) {}

  static reduce(state: State, action: SetOptionsPath): State {
    return {
      ...state,
      currentPath: action.path,
    };
  }
}

@staticImplementsDecorator<CustomActionInterface<LoadOptions, State>>()
export class LoadOptions implements Action {
  static type = checkToBeUniqueType(OptionActionsTypes.LOAD_OPTIONS);

  readonly type = OptionActionsTypes.LOAD_OPTIONS;

  constructor(public actionData?: { path: string; publicPath?: boolean }) {}

  static reduce(state: State): State {
    return {
      ...state,
      loading: true,
      errors: [],
    };
  }
}

@staticImplementsDecorator<CustomActionInterface<LoadOptionsSuccess, State>>()
export class LoadOptionsSuccess implements Action {
  static type = checkToBeUniqueType(OptionActionsTypes.LOAD_OPTIONS_SUCCESS);

  readonly type = OptionActionsTypes.LOAD_OPTIONS_SUCCESS;

  constructor(public options: StoreOptionsModel) {}

  static reduce(state: State, action: LoadOptionsSuccess): State {
    return {
      ...optionsAdapter.upsertOne(action.options, state),
      loading: false,
    };
  }
}

@staticImplementsDecorator<CustomActionInterface<LoadOptionsFailure, State>>()
export class LoadOptionsFailure implements Action {
  static type = checkToBeUniqueType(OptionActionsTypes.LOAD_OPTIONS_FAILURE);

  readonly type = OptionActionsTypes.LOAD_OPTIONS_FAILURE;

  constructor(public errors: ApiError[]) {}

  static reduce(state: State, action: LoadOptionsFailure): State {
    return {
      ...state,
      loading: false,
      currentPath: null,
      errors: action.errors,
    };
  }
}

@staticImplementsDecorator<CustomActionInterface<UpdateOptions, State>>()
export class UpdateOptions implements Action {
  static type = checkToBeUniqueType(OptionActionsTypes.UPDATE_OPTION);

  readonly type = OptionActionsTypes.UPDATE_OPTION;

  constructor(public options: StoreOptionsModel) {}

  static reduce(state: State): State {
    return {
      ...state,
      loading: true,
      errors: [],
    };
  }
}

@staticImplementsDecorator<CustomActionInterface<UpdateOptionsSuccess, State>>()
export class UpdateOptionsSuccess implements Action {
  static type = checkToBeUniqueType(OptionActionsTypes.UPDATE_OPTIONS_SUCCESS);

  readonly type = OptionActionsTypes.UPDATE_OPTIONS_SUCCESS;

  constructor(public options: StoreOptionsModel) {}

  static reduce(state: State, action: UpdateOptionsSuccess): State {
    const options: any = {};
    Object.assign(options, action.options);

    if (action && action.options) {
      let updatedStateOptions: OptionModel[] = [...state.entities[action.options.path].options];

      updatedStateOptions = updatedStateOptions.map((value: OptionModel) => new OptionModel(value));

      options.options.forEach((newOption: OptionModel) => {
        const option = updatedStateOptions.find((oldOption: OptionModel) => oldOption.path === newOption.path);

        if (!option) {
          updatedStateOptions.push(newOption);
        } else {
          option.value = newOption.value;
        }
      });

      options.options = updatedStateOptions;
    }

    return {
      ...optionsAdapter.upsertOne(options, state),
      loading: false,
    };
  }
}

@staticImplementsDecorator<CustomActionInterface<UpdateOptionsFailure, State>>()
export class UpdateOptionsFailure implements Action {
  static type = checkToBeUniqueType(OptionActionsTypes.UPDATE_OPTIONS_FAILURE);

  readonly type = OptionActionsTypes.UPDATE_OPTIONS_FAILURE;

  constructor(public errors: ApiError[]) {}

  static reduce(state: State, action: UpdateOptionsFailure): State {
    return {
      ...state,
      loading: false,
      errors: action.errors,
    };
  }
}

export const reducer = buildReducer(
  SetOptionsPath,
  LoadOptions,
  LoadOptionsSuccess,
  LoadOptionsFailure,
  UpdateOptions,
  UpdateOptionsSuccess,
  UpdateOptionsFailure,
);
