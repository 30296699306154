import { AccountInterface } from '@default-application-app/core/interfaces/account-interface';
import { AccountTypeInterface } from '@default-application-app/core/interfaces/account-type-interface';
import { IUser } from '@default-application-app/core/interfaces/user-interface';
import { getHumanId } from '@default-application-app/core/helpers/get-human-user-id.helper';
import { CountryCode, IBANBuilder } from 'ibankit';

export class AccountModel implements AccountInterface {
  id: number;

  createdAt: string;

  updatedAt: string;

  availableAmount: number;

  initialBalance: string;

  number: string;

  type: AccountTypeInterface;

  typeId: number;

  user: IUser;

  userId: string;

  description: string;

  isActive: boolean;

  balance: number;

  allowWithdrawals: boolean;

  allowDeposits: boolean;

  maturityDate: string;

  payoutDay: number;

  interestAccount: AccountInterface;

  interestAccountId: number;

  iban: string;

  public constructor(params: any) {
    Object.assign(this, params);

    if (this.user && !this.user.humanId) {
      this.user.humanId = () => getHumanId(this.user);
    }

    try {
      this.iban = new IBANBuilder()
        .countryCode(CountryCode.IT)
        .bankCode('1904343')
        .accountNumber(this.prepareAccountNumber())
        .build()
        .toString();
    } catch (error) {
      this.iban = this.number;
    }
  }

  public prepareAccountNumber(): string {
    const numberMaxLength = 10;
    const accountNumber = this.number.replace(/\D+/g, '');
    let result = accountNumber;
    const { length } = accountNumber;

    if (length > numberMaxLength) {
      result = result.substring(0, numberMaxLength);
    }

    if (length < numberMaxLength) {
      for (let i = 0; i < numberMaxLength - accountNumber.length; i++) {
        result += '0';
      }
    }

    return result;
  }
}
