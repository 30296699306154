<!--declaration svg-->
<app-svg-icon iconPath="ic_check.svg" hidden></app-svg-icon>

<div class="custom-checkbox-container">
  <input
    [disabled]="isDisabled"
    class="custom-checkbox-input"
    [id]="id"
    type="checkbox"
    [ngModel]="checked"
    (ngModelChange)="onModelChange($event)" />
  <label class="custom-checkbox" [for]="id">
    <span>
      <svg width="12px" height="9">
        <use xlink:href="#check"></use>
      </svg>
    </span>
    <span>
      <ng-content></ng-content>
    </span>
  </label>
</div>
