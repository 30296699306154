import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-query-search',
  templateUrl: './query-search.component.html',
})
export class QuerySearchComponent implements OnInit {
  @Input() form: UntypedFormGroup;

  @Input() placeholder: string;

  @Input() controlName: string = 'query';

  @Input() isSearchIcon: boolean = false;

  @Input() isWhiteBackground: boolean = false;

  @Output() focusEmitter = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.form.addControl(this.controlName, new UntypedFormControl(''));
  }

  public emitFocus(value: boolean): void {
    this.focusEmitter.emit(value);
  }
}
