import { DelegateInterface } from '@interfaces/delegate-interface';

export class DelegateModel implements DelegateInterface {
  public createdAt: string;

  public dateOfBirth: string;

  public documentPersonalId: string;

  public documentType: string;

  public firstName: string;

  public lastName: string;

  public parentId: string;

  public updatedAt: string;

  public id: number;

  public type: string;

  constructor(data: DelegateInterface) {
    Object.assign(this, data);

    this.type = 'delegate';
  }
}
