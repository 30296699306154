import { UserForHumanId } from '@default-application-app/core/interfaces/user-interface';

export function getHumanId(user: UserForHumanId): string {
  if (user?.firstName || user?.lastName) {
    let output = `${user?.firstName ? `${user?.firstName} ` : ''}${user?.lastName ? `${user?.lastName} ` : ''}`;
    // In messages module System & Administrator users don't have the email
    if (user?.email) {
      output += `(${user.email})`;
    }
    return output;
  }

  if (user?.businessName) {
    let output = user?.businessName;
    // In messages module System & Administrator users don't have the email
    if (user?.email) {
      output += `(${user.email})`;
    }
    return output;
  }

  if (user?.email) {
    return `${user.email}`;
  }

  return '';
}

/**
 * GetHumanIdForUser To avoid email sharing - display a user only "full name"
 *
 * @param user
 */
export function getHumanIdForUser(user: UserForHumanId): string {
  if (user?.firstName || user?.lastName) {
    return `${user?.firstName ? `${user?.firstName} ` : ''}${user?.lastName ? `${user?.lastName} ` : ''}`;
  }

  if (user?.businessName) {
    return user.businessName;
  }

  return '';
}
