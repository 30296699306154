import { snakeToCamelCase } from '@default-application-app/core/helpers/stringHelpers';
import { ErrorTargets } from '@default-application-app/core/constants/errorTargets';
import { BaseErrorInterface } from '@default-application-app/core/interfaces/baseError.interface';
import { HttpErrorResponse } from '@angular/common/http';

export class ApiError implements BaseErrorInterface {
  public title: string;

  public details: string;

  public source: string;

  public code: string;

  public meta?: any;

  public target: ErrorTargets;

  public httpResponse: HttpErrorResponse;

  constructor(data: {
    details?: string;
    source?: string;
    target?: string;
    title?: string;
    code?: string;
    meta?: { [key: string]: string };
    httpResponse?: HttpErrorResponse;
  }) {
    this.source = snakeToCamelCase(data.source || '');
    this.target = <ErrorTargets>data.target || (this.source ? ErrorTargets.FIELD : ErrorTargets.COMMON);
    this.details = data.details;
    this.code = data.code || data.details || data.title || '';
    this.title = data.title || 'Error';
    if (data.meta) {
      this.meta = data.meta;
    }
    this.httpResponse = data.httpResponse;
  }
}
