<div class="fields-container__field__container_row" [formGroup]="form">
  <div class="fields-container__field__container_row__padding month">
    <div
      [class.error-field-border]="form.get('dateOfBirthMonth').invalid"
      class="input-field select-container native-select__month margin-right">
      <ng-select
        [clearable]="clearable"
        [dropdownPosition]="'bottom'"
        [formControlName]="'dateOfBirthMonth'"
        [placeholder]="'common.selects.dob.month' | translate"
        [searchable]="true"
        (change)="onDateChange()"
        class="simple">
        <ng-option *ngFor="let item of selectDate.month | fillArray; let i = index" [value]="i + 1">
          {{ i | month }}
        </ng-option>
      </ng-select>
    </div>
  </div>
  <div class="fields-container__field__container_row__padding day">
    <div
      [class.error-field-border]="form.get('dateOfBirthDay').invalid"
      class="input-field select-container native-select__day_year margin-right">
      <ng-select
        [clearable]="clearable"
        [dropdownPosition]="'bottom'"
        [formControlName]="'dateOfBirthDay'"
        [placeholder]="'common.selects.dob.day' | translate"
        [searchable]="true"
        (change)="onDateChange()"
        class="simple">
        <ng-option *ngFor="let item of selectDate.date | fillArray; let i = index" [value]="i + 1">
          {{ (i + 1).toString().padStart(2, '0') }}
        </ng-option>
      </ng-select>
    </div>
  </div>
  <div class="fields-container__field__container_row__padding year">
    <ng-select
      [clearable]="clearable"
      [dropdownPosition]="'bottom'"
      [formControlName]="'dateOfBirthYear'"
      [placeholder]="'common.selects.dob.year' | translate"
      [searchable]="true"
      (change)="onDateChange()"
      class="simple">
      <ng-option *ngFor="let item of selectDate.year | fillArray; let i = index" [value]="selectDate.currentYear - i">
        {{ selectDate.currentYear - i }}
      </ng-option>
    </ng-select>
  </div>
</div>
