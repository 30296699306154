import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IbanApiService } from './iban-api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IbanService {
  constructor(protected apiService: IbanApiService) {}

  /**
   * Fetches IBAN requests with optional pagination and filtering parameters.
   * @param pageSize Number of records per page (optional)
   * @param currentPage Current page number (optional)
   * @param dateFrom Start date filter (optional)
   * @param dateTo End date filter (optional)
   */
  public getIbanRequest(
    pageSize?: number,
    currentPage?: number,
    dateFrom?: string,
    dateTo?: string
  ): Observable<any> {
    // Build the parameters object only if they are provided
    const params: any = {};
    if (pageSize) params['page[size]'] = pageSize;
    if (currentPage) params['page[number]'] = currentPage;
    if (dateFrom) params['filters[dateFrom]'] = dateFrom;
    if (dateTo) params['filters[dateTo]'] = dateTo;
    
    return this.apiService.apiGetIbanRequests(params);
    
  }

  public approveIbanRequest(requestId: string, ibanNumber: string): Observable<any> {
    return this.apiService
      .apiApproveIbanRequest(requestId, ibanNumber);
  }

  public getRequestById(requestId: string): Observable<any> {
    return this.apiService
      .apiGetRequestById(requestId);
  }
}
