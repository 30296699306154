import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppOptionsService } from '@services/appOptions.service';
import { OptionsValuesNames } from '@constants/optionsPrefixes';
import { AuthService } from '@services/auth/auth.service';
import { Store } from '@ngrx/store';
import * as FromLogout from '@default-application-app/reducers/app.reducer';
import * as LogoutActions from '@default-application-app/actions/logout.actions';
// eslint-disable-next-line import/no-duplicates
import * as LanguagesActions from '@default-application-app/actions/siteLanguage.actions';
// eslint-disable-next-line import/no-duplicates
import * as SiteLanguagesActions from '@default-application-app/actions/siteLanguage.actions';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data.min';
import { ConfigService } from '@default-application-app/config.service';
import { TranslateService } from '@ngx-translate/core';
import { VERSION } from '@default-application/src/environments/version'; // import the automatically generated file
import { DefaultLanguage } from '@constants/languages';
import { JUMIO_STATUS_STORAGE, JUMIO_SUCCESS_STATUS } from '@constants/jumio';
import { Router } from '@angular/router';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'app-root',
  template: `
    <div #spinner class="spinner">
      <ngx-spinner
        bdColor="rgba(255,255,255,0.8)"
        size="large"
        [color]="mainColor"
        type="ball-clip-rotate"></ngx-spinner>
    </div>
    <simple-notifications></simple-notifications>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('spinner') spinner: ElementRef;

  private faviconId = 'appFavicon';

  public mainColor: string;

  constructor(
    private titleService: Title,
    private appOptionService: AppOptionsService,
    private auth: AuthService,
    private store: Store<FromLogout.LogoutState>,
    private configService: ConfigService,
    private readonly translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private storageService: StorageService,
  ) {
    // eslint-disable-next-line no-console
    console.log(`Application version is: git-hash=${VERSION.hash}`);
    this.translate.setDefaultLang(DefaultLanguage);
  }

  ngOnInit() {
    this.checkJumioParam();
    this.store.dispatch(new SiteLanguagesActions.LoadSiteLanguages());

    if (this.auth.isAuthenticated()) {
      this.store.dispatch(new LogoutActions.LogIn());
      this.store.dispatch(new LanguagesActions.LoadUserLanguage());
    } else {
      this.store.dispatch(new LanguagesActions.SetSiteLanguageFromLocalStorage());
    }

    const userTZ = moment.tz.guess(true);
    if (userTZ) {
      moment.tz.setDefault(userTZ);
    } else {
      // Use application default TZ
      this.appOptionService.getOptionsValuePipe(OptionsValuesNames.DEFAULT_TIMEZONE).subscribe((timeZone: string) => {
        if (timeZone) {
          moment.tz.setDefault(timeZone);
        }
      });
    }

    // apply site title
    this.appOptionService.getOptionsValuePipe(OptionsValuesNames.WEB_PAGES_TITLE).subscribe((title: string) => {
      if (title) {
        this.titleService.setTitle(title);
      }
    });
  }

  ngAfterViewInit(): void {
    this.setMainColorForSpinner();
    this.cdr.detectChanges();
  }

  private setMainColorForSpinner() {
    this.mainColor = getComputedStyle(this.spinner.nativeElement).getPropertyValue('color');
  }

  private checkJumioParam(): void {
    const urlParams = new URLSearchParams(window.location.search);
    const acquisitionStatusValue: string = urlParams?.get('acquisitionStatus');

    if (acquisitionStatusValue === JUMIO_SUCCESS_STATUS) {
      this.storageService.setItem(JUMIO_STATUS_STORAGE, JUMIO_SUCCESS_STATUS);
      if(this.auth.isBusinessUser){
      this.router.navigate(['/sign-in/business']);
      } else {
        this.router.navigate(['/sign-in/individual']);
      }
    }
  }
}
