import { Component, Host, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxColorsTriggerDirective } from 'ngx-colors';

// source: https://ngx-colors.web.app/

@Component({
  selector: 'app-ngx-colors',
  templateUrl: './ngx-colors.component.html',
  styleUrls: ['./ngx-colors.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NgxColorsComponent implements OnInit {
  constructor(@Host() protected triggerDirective: NgxColorsTriggerDirective) {}

  // IO color
  color = '';

  ngOnInit(): void {
    this.color = this.triggerDirective.color;
    this.triggerDirective.change.subscribe((color: string) => {
      this.color = color;
    });
  }
}
