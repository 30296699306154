<label *ngIf="label" class="native-label native-label__admin" [class.required]="isRequiredLabel">
  {{ label }}
</label>

<div class="password">
  <div class="eye-button" (click)="showHidePassword()" [title]="'common.buttons.print' | translate">
    <img [src]="'assets/icons/close_eye.svg'" />
  </div>

  <input
    #input
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
    (blur)="onTouched()"
    [class.card-input]="cardInputClass"
    [class.lock-field]="isLockFieldClass"
    [class.filed]="isFilledClass"
    [autocomplete]="isAutocompleteDisabled ? 'disable' : true"
    class="native-input"
    user-options-field-disabled
    [optionName]="optionName"
    [placeholder]="placeholder"
    makeActiveInput
    [requiredIf]="isRequiredIf"
    [attr.disabled]="isFormControlDisabled || isDisabled ? true : null"
    appErrorHandle
    [type]="isShowPassword ? 'text' : 'password'"
    [value]="value"
    [readOnly]="isReadonly"
    [textMask]="mask ? mask : { mask: false }"
    [title]="title || label" />
</div>
