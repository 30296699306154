import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'httpLink',
})
export class HttpLinkPipe implements PipeTransform {
  transform(href: string): string {
    return href.startsWith('http://') || href.startsWith('https://') ? href : `//${href}`;
  }
}
