export class Talker {
  uid: string;

  firstName: string;

  businessName?: string;

  lastName: string;

  username: string;

  email: string;

  roleName: string;

  file?: {
    location: string;
  };

  public constructor(params: any) {
    Object.assign(this, params);
  }

  public isAdmin?(): boolean {
    return this.roleName === 'admin' || this.roleName === 'root';
  }

  public isSystem?(): boolean {
    return this.uid === '' || !this.uid;
  }

  public getFullName?(isCurrentUserAdmin: any): string {
    if (this.isAdmin() && this.username !== 'system' && !isCurrentUserAdmin) {
      return 'Administrator';
    }
    return this.firstName ? `${this.firstName} ${this.lastName}` : this.businessName;
  }
}

export class Message {
  id: number;

  parentId: any;

  children: null | Message[];

  subject: string;

  message: string;

  sender: Talker;

  senderId: string;

  recipient: Talker;

  recipientId: string;

  isSenderRead: boolean;

  isRecipientRead: boolean;

  createdAt: string;

  updatedAt: string;

  lastMessageCreatedAt: string;

  edited: boolean;

  isContextMenuShown = false;

  isEditMode = false;

  deleteAfterRead: boolean;

  cancelSubjectId?: string;

  fileName?: string;

  fileId?: number;

  constructor(message: Message) {
    this.id = message.id;
    this.parentId = message.parentId;
    this.children = message.children ? message.children.map((item) => new Message(item)) : null;
    this.subject = message.subject;
    this.message = message.message;
    this.sender = message.sender ? new Talker(message.sender) : null;
    this.recipient = message.recipient ? new Talker(message.recipient) : null;
    this.senderId = message.senderId;
    this.recipientId = message.recipientId;
    this.isSenderRead = message.isSenderRead;
    this.isRecipientRead = message.isRecipientRead;
    this.createdAt = message.createdAt;
    this.updatedAt = message.updatedAt;
    this.lastMessageCreatedAt = message.lastMessageCreatedAt;
    this.edited = message.edited;
    this.deleteAfterRead = message.deleteAfterRead;
    this.cancelSubjectId = message.cancelSubjectId;
    this.fileName = message.fileName;
    this.fileId = message.fileId;
  }
}
