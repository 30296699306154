import { AsYouType, CountryCode } from 'libphonenumber-js';

export const phoneWithoutCountryCodeHelper = (phoneValue: number | string, countryCode: string) => {
  const ayt = new AsYouType(countryCode as CountryCode);
  try {
    ayt.input(`${phoneValue}`);
    const positionShift = 2;
    const position = ayt.getNumber().countryCallingCode.length + positionShift;
    return ayt.getNumber().format('INTERNATIONAL').slice(position);
  } catch (error) {
    return String(phoneValue);
  }
};
