import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MIME_TYPES_IMAGE } from '@constants/mimeTypes';
import { take, takeUntil } from 'rxjs/operators';
import { ProfileSettingsFilesService } from '@shared-modules/profile-form/services/profile-settings-files.service';
import { UnsubscribeDestroyHelper } from '@helpers/unsubscribe-destroy.helper';
import { ApiError } from '@models/api-error.model';
import { FileModel } from '@models/file-model';

@Component({
  selector: 'app-upload-file-to-profile',
  templateUrl: './upload-file-to-profile.component.html',
  styleUrls: ['./upload-file-to-profile.component.scss'],
})
export class UploadFileToProfileComponent extends UnsubscribeDestroyHelper {
  @Input() buttonName: string;

  @Input() userId: string;

  @Output() fileIdEmit: EventEmitter<string> = new EventEmitter<string>();

  public acceptMimeTypesImage = MIME_TYPES_IMAGE;

  constructor(private fileService: ProfileSettingsFilesService) {
    super();
  }

  public uploadFile(data: File[]): void {
    const formData = new FormData();
    formData.append('file', data[0]);

    this.fileService
      .uploadFileToProfile(this.userId, formData, { isAdminOnly: false, isPrivate: true })
      .pipe(take(1), takeUntil(this.unsubscribeSubject))
      .subscribe((response: { success: boolean; errors?: ApiError[]; data?: FileModel }) => {
        if (response.success && response.data && response.data.id) {
          this.fileIdEmit.emit(String(response.data.id));
        }
      });
  }
}
