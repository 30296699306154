<pagination-template #p="paginationApi" (pageChange)="pageChange.emit($event)" [id]="paginationId" [maxSize]="5">
  <div [class.disabled]="p.isLastPage() && p.isFirstPage()" class="pagination main_color">
    <!--    <div class="pagination__arrow" [class.disabled]="p.isFirstPage()" (click)="p.previous()">-->
    <!--      <a *ngIf="!p.isFirstPage()"><i class="fas fa-angle-double-left"></i></a>-->
    <!--    </div>-->
    <div
      (click)="p.setCurrent(page.value)"
      *ngFor="let page of p.pages"
      [class.current]="p.getCurrent() === page.value"
      [class]="p.getCurrent() === page.value ? 'pagination__page active' : 'pagination__page'">
      <span>{{ page.label }}</span>
    </div>
    <!--    <div class="pagination__arrow" [class.disabled]="p.isLastPage()" (click)="p.next()">-->
    <!--      <a *ngIf="!p.isLastPage()"><i class="fas fa-angle-double-right"></i></a>-->
    <!--    </div>-->
  </div>
</pagination-template>
