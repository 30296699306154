<app-modal-wrapper [isCenterIcon]="true" [isCancelIcon]="false" [titleIconName]="'warning'" [title]="''">
  <form [formGroup]="form">
    <app-description
      [description]="'pages.auth.terms_and_conditions_privacy_policy_have_been_updated'"></app-description>

    <div class="agreement-container__agreement required">
      <app-checkbox formControlName="privacyConsent">
        <span>{{ 'pages.registration.consent_to_privacy' | translate }}</span>
      </app-checkbox>

      <app-svg-icon
        class="icon"
        (click)="showPrivacy()"
        [ngClass]="{ rotated: isShowPrivacy }"
        [iconPath]="'ic_arrow_bottom.svg'"></app-svg-icon>
    </div>

    <div class="reset-list-style" *ngIf="isShowPrivacy" [innerHTML]="privacyPolicy"></div>

    <div class="agreement-container__agreement required">
      <app-checkbox formControlName="tacConsent">
        <span>{{ 'pages.registration.i_agree_to_these_terms' | translate }}</span>
      </app-checkbox>

      <app-svg-icon
        class="icon"
        (click)="showTerms()"
        [ngClass]="{ rotated: isShowTerms }"
        [iconPath]="'ic_arrow_bottom.svg'"></app-svg-icon>
    </div>

    <div class="reset-list-style" *ngIf="isShowTerms" [innerHTML]="termsAndConditions"></div>

    <div class="footer">
      <app-button
        class="button"
        [appearance]="'primary-outline'"
        (btnClick)="logout()"
        [type]="'button'"
        [buttonText]="'layout.system.log_out' | translate"></app-button>

      <app-button
        class="button"
        (btnClick)="accept()"
        [isDisabled]="form.invalid"
        [type]="'button'"
        [buttonText]="'common.buttons.accept' | translate"></app-button>
    </div>
  </form>
</app-modal-wrapper>
