import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormGroup,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
  UntypedFormGroup,
} from '@angular/forms';
import { UserOptionNames } from '@constants/optionsPrefixes';
import { CurrencyMaskConfig } from 'ng2-currency-mask';

@Component({
  selector: 'app-input-with-currency-mask',
  templateUrl: './input-with-currency-mask.component.html',
  styleUrls: ['./input-with-currency-mask.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputWithCurrencyMaskComponent),
      multi: true,
    },
  ],
})
export class InputWithCurrencyMaskComponent implements ControlValueAccessor, AfterViewInit, OnInit {
  @ViewChild('input') input: ElementRef;

  @ViewChild(NgControl) control: NgControl;

  @Output()
  public blur: EventEmitter<any> = new EventEmitter<any>();

  @Input() placeholder: string = '';

  @Input() items: any[];

  @Input() formControlName: string;

  @Input() label: string;

  @Input() title: string;

  @Input() type: string = 'text';

  @Input() loading: boolean;

  @Input() optionName: UserOptionNames;

  @Input() value;

  @Input() isRequiredIf: boolean;

  @Input() isDisabled: boolean = false;

  @Input() isAutocompleteDisabled: boolean = false;

  @Input() isFilledClass: boolean = false;

  @Input() isReadonly: boolean = false;

  @Input() options: CurrencyMaskConfig;

  @Input() currencyCode: string;

  constructor(private controlContainer: ControlContainer) {}

  public ngOnInit(): void {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  public onChange: any = () => {};

  public onTouched: any = () => {};

  public ngAfterViewInit() {
    if (this.value) {
      this.input.nativeElement.classList.add('filled');
    }
  }

  public writeValue(value): void {
    this.value = value;
  }

  public onBlur(): void {
    this.blur.next();
    this.onTouched();
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private get form(): UntypedFormGroup {
    return this.controlContainer.formDirective
      ? ((this.controlContainer.formDirective as FormGroupDirective).form as FormGroup)
      : null;
  }

  public get isFormControlDisabled(): boolean {
    if (this.form.get(this.formControlName)) {
      return this.form.get(this.formControlName).disabled;
    }

    return false;
  }
}
