import { Component } from '@angular/core';

@Component({
  selector: 'app-csv-template',
  templateUrl:'./download-sub-transfer-csv.component.html',
})
export class CsvTemplateComponent {
  headers = ['Recipient_account', 'Recipient_phone_number' ,'Amount'];
  tableData = [
    ['Recipient_account','Recipient_phone_number' ,'Amount']
  ];

  downloadCsv() {
    const csvContent = this.tableData
      .map(row => row.join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'transfer_template.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
