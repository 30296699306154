import { Action } from '@ngrx/store';
import { initialAutoLogoutState, reducer, State } from '@default-application-app/actions/logout.actions';

// eslint-disable-next-line @typescript-eslint/default-param-last
export function logoutReducer(state: State = initialAutoLogoutState, action: Action): State {
  return reducer(state, action);
}

export const getIsLoggedIn = (state: State) => state.isLoggedIn;

export const getIsAutoLogout = (state: State) => state.isAutoLogout;
