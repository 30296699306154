import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';
import { PrintComponent } from '@default-application-app/components/print/print-component';
import { PrintTableInterface, PrintTablesInterface } from '@default-application-app/modules/shared/print/interfaces';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data.min';
import { AccountInterface } from '@interfaces/account-interface';
import { BalanceHelper } from '@helpers/balance.helper';
import { CustomDatePipe } from '@pipes/customDate.pipe';
import { TranslateService } from '@ngx-translate/core';
import { ProfileModel } from '@models/profile-model';

@Injectable()
export class PrintService {
  private printComponentRef: ComponentRef<PrintComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private datePipe: CustomDatePipe,
    private injector: Injector,
    private translateService: TranslateService,
  ) {}

  public printTable(data: PrintTableInterface, prefix = 'common.print', header?: string) {
    const translatedData = this.translatePrintTable(data, prefix);
    this.initPrintComponentInWindowBody(translatedData.title);
    this.printComponentRef.instance.table = translatedData;
    if (header) {
      this.printComponentRef.instance.header = this.translateService.instant(header);
    }
  }

  public printTables(data: PrintTablesInterface, prefix = 'common.print') {
    const translatedData = this.translatePrintTables(data, prefix);
    this.initPrintComponentInWindowBody(translatedData.title);
    this.printComponentRef.instance.tables = translatedData;
  }

  public printBlocks(data: PrintTablesInterface, prefix = 'common.print') {
    const translatedData = this.translatePrintTables(data, prefix);
    this.initPrintComponentInWindowBody(translatedData.title);
    this.printComponentRef.instance.blocks = translatedData;
  }

  public printBlocksAndTables(
    blocks: PrintTablesInterface,
    data: PrintTablesInterface,
    prefix = 'common.print',
    header?: string,
  ) {
    const translatedData = this.translatePrintTables(data, prefix);
    this.initPrintComponentInWindowBody(data.title);
    this.printComponentRef.instance.blocks = blocks;
    this.printComponentRef.instance.tables = translatedData;
    if (header) {
      this.printComponentRef.instance.header = this.translateService.instant(header);
    }
  }

  public getUserDetails(title: string, user: ProfileModel, dateFormat): PrintTableInterface {
    const userDetails = {
      title: this.translateService.instant(`common.print.${title}`),
      columns: [],
      rows: [
        [this.translateService.instant('common.print.company_name'), user.companyDetails.companyName],
        [
          this.translateService.instant('common.print.full_name'),
          `${user.firstName ? user.firstName : ''} ${user.lastName ? user.lastName : ''}`,
        ],
        [
          this.translateService.instant('common.print.profile_creation_date'),
          user.createdAt ? moment(user.createdAt).format(`${dateFormat} ${this.getTimeFormat()}`) : '',
        ],
      ],
    };

    if (user.isCorporate) {
      userDetails.rows.unshift([this.translateService.instant('common.print.company_name'), user.isCorporate]);
    }

    return userDetails;
  }

  public getAccountDetails(title: string, account: AccountInterface, dateFormat): PrintTableInterface {
    return {
      title: this.translateService.instant(`common.print.${title}`),
      columns: [],
      rows: [
        [
          this.translateService.instant('common.print.creation_date'),
          account.createdAt ? moment(account.createdAt).format(`${dateFormat} ${this.getTimeFormat()}`) : '',
        ],
        [this.translateService.instant('common.print.number'), account.number],
        [this.translateService.instant('common.print.type'), account.type ? account.type.name : ''],
        [this.translateService.instant('common.print.currency'), account.type ? account.type.currencyCode : ''],
        [
          this.translateService.instant('common.print.available_balance'),
          account.availableAmount && account.type
            ? BalanceHelper.valueToCurrencyPrecisionWithCommas(account.availableAmount, account.type.currencyCode)
            : '',
        ],
        [
          this.translateService.instant('common.print.current_balance'),
          account.balance && account.type
            ? BalanceHelper.valueToCurrencyPrecisionWithCommas(account.balance, account.type.currencyCode)
            : '',
        ],
      ],
    };
  }

  public convertDateForPrint(date, formatSize: string = null): string {
    return this.datePipe.transform(date, formatSize);
  }

  public getTimeFormat(): string {
    return this.datePipe.returnTimeFormat();
  }

  private initPrintComponentInWindowBody(title: string = 'Print') {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(PrintComponent);
    this.printComponentRef = componentFactory.create(this.injector);
    this.appRef.attachView(this.printComponentRef.hostView);

    const componentView = (this.printComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    const win = window.open('', '_blank', 'toolbar=0,location=0,menubar=0,modal=yes,alwaysRaised=ye');
    const doc = win.document;
    doc.title = title;
    doc.body.appendChild(componentView);
  }

  private translatePrintTable(data: PrintTableInterface, source: string) {
    return {
      ...data,
      columns: data.columns.map((column) => this.translateService.instant(`${source}.${column}`)),
      title: data.title ? this.translateService.instant(`${source}.${data.title}`, data.titleParams) : null,
      titleForDraw: data.titleForDraw ? this.translateService.instant(`${source}.${data.titleForDraw}`) : null,
    };
  }

  private translatePrintTables(data: PrintTablesInterface, source: string) {
    return {
      ...data,
      tables: data.tables.map((table) => this.translatePrintTable(table, source)),
      title: data.title ? this.translateService.instant(`${source}.${data.title}`) : null,
      subTitle: data.subTitle ? this.translateService.instant(`${source}.${data.subTitle}`, data.subTitleParams) : null,
    };
  }
}
