import { ErrorHandler, ModuleWithProviders, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '@default-application/src/environments/environment';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import {
  optionsReducers,
  profilesReducers,
  userGroupsReducers,
} from '@default-application-app/components/profiles/reducers';
import { currenciesReducers } from '@default-application-app/components/currencies/reducers';
import { UserGroupEffects } from '@default-application-app/components/profiles/effects/user-group.effects';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { OptionEffects } from '@default-application-app/components/profiles/effects/option.effects';
import {
  logoutReducers,
  siteLanguageReducers,
  siteTextsReducers,
  userPermissionsReducers,
} from '@default-application/src/app/reducers/app.reducer';
import { LogoutEffects } from '@default-application/src/app/effects/logout.effects';
import { SiteLanguageEffects } from '@default-application/src/app/effects/siteLanguage.effect';
import { PermissionEffects } from '@default-application/src/app/effects/permission.effects';
import { SharedComponentsModule } from '@default-application-app/modules/shared-components/shared-components.module';
import { DragAndDropModule } from '@default-application-app/modules/drag-and-drop/drag-and-drop.module';
import { ProfileEffects } from '@default-application-app/components/profiles/effects/profile.effects';
import { ApplicationPipesModule } from '@default-application-app/modules/application-pipes/application-pipes.module';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CustomTranslateLoader } from '@services/translate/customTranslateLoader';
import { PrintModule } from '@default-application-app/modules/shared/print/print.module';
import { HeaderComponent } from '@layouts/headers/header/header.component';
import { NotFoundComponent } from '@layouts/error/not-found/not-found.component';
import { MainLayoutComponent } from '@layouts/main-layout/main-layout.component';
import { UserGroupApiService } from '@services/userGroup/user-group-api.service';
import { metaReducers, reducers } from '@default-application/src/app/reducers';
import { ApiCallerService } from '@services/api-caller.service';
import { UserPermissionGuard } from '@guards/user-permission.guard';
import { AuthGuard } from '@guards/auth.guard';
import { DatepickerBaseOptionsResolver } from '@resolvers/datepickerBaseOptions.resolver';
import { AuthApiService } from '@services/auth/auth-api.service';
import { TempAuthTokenGuard } from '@guards/tempAuthToken.guard';
import { CurrenciesResolver } from '@resolvers/currency/currencies.resolver';
import { OptionsApiService } from '@services/options/options-api.service';
import { UserCurrenciesResolver } from '@default-application-app/modules/shared/currency/resolvers/user-currencies.resolver';
import { AccountService } from '@services/account/account.service';
import { CustomMissingTranslationHandler } from '@services/translate/customMissingTranslationHandler';
import { UserRoleGuard } from '@guards/user-role.guard';
import { RefreshTokenService } from '@services/refreshToken.service';
import { OptionsModulesGuard } from '@guards/options-modules.guard';
import { AppEffects } from '@default-application-app/app.effects';
import { AdminRoleGuard } from '@guards/admin-role.guard';
import { IbanAdminRoleGuard } from '@guards/iban-admin-role.guard';
import { CurrenciesApiService } from '@services/currencies/currencies-api.service';
import { ProfileApiService } from '@services/profile/profile-api.service';
import { SignUpResolver } from '@guards/signUp.resolver';
import { AccountsCardsGuard } from '@guards/accounts/cards.guard';
import { PermissionGroupApiService } from '@services/permission-group/permission-group-api.service';
import { TransferService } from '@services/transfer/transfer.service';
import { StorageService } from '@services/storage.service';
import { AuthService } from '@services/auth/auth.service';
import { TokenService } from '@services/token-service';
import { AccountsResolver } from '@resolvers/accounts.resolver';
import { ApiErrorInterceptor } from '@services/apiError.interceptor';
import { UserApiService } from '@services/user/user-api.service';
import { CurrenciesEffects } from '@default-application-app/components/currencies/effects/currencies.effects';
import { UserService } from '@services/user/user.service';
import { TransferApiService } from '@services/transfer/transfer-api.service';
import { IbanApiService } from '@services/iban/iban-api.service';
import { IbanService } from '@services/iban/iban.service';
import { UserGroupTransfersApiService } from '../group-transfer/services/user-group-transfers-api.services';
import { SettingsRequestsTanService } from '@default-application-app/modules/settings-tan/services/settings-requests-tan.service';
import { DataService } from '@services/data.service';
import { ApiErrorService } from '@services/apiError.service';
import { ProfileService } from '@services/profile/profile.service';
import { ConfigService } from '@default-application-app/config.service';
import { AuthHeaderInterceptor } from '@services/authHeader.interceptor';
import { CurrenciesService } from '@services/currencies/currencies.service';
import { RedirectIsEnabledModulesGuard } from '@guards/redirect-isEnabled-modules.guard';
import { AccountApiService } from '@services/account/account-api.service';
import { AppOptionsService } from '@services/appOptions.service';
import { AlreadyAuthenticatedGuard } from '@guards/alreadyAuthenticated.guard';
import { RefreshTokenInterceptorService } from '@services/refreshToken.interceptor';
import { ErrorMessageTranslationService } from '@services/translate/errorMessageTranslation.service';
import { RouterModule } from '@angular/router';
import { RootRoleGuard } from '@guards/root-role.guard';
import { CheckMaintenanceGuard } from '@guards/maintenance-guards/check-maintenance.guard';
import { MaintenanceMessageComponent } from '@default-application-app/components/maintenance-message/maintenance-message.component';
import { MaintenanceDisableGuard } from '@guards/maintenance-guards/maintenance-disable.guard';
import { PopupAutologoutModule } from '@default-application-app/modules/shared/popup-autologout/popup-autologout.module';
import { MaintenanceResolver } from '@resolvers/maintenance.resolver';
import { AutoLogoutPopupService } from '@services/auto-logout-popup.service';
import { AccountsWithIwtInstructionsAvailableResolver } from '@resolvers/accounts-with-iwt-instructions-available.resolver';
import { MessagingApiService } from '@default-application-app/modules/shared/messages-api/services/messaging-api.service';
import { CurrencyApiService } from '@default-application-app/modules/shared/currency/services/currency-api.service';
import { ErrorCatchService } from '@services/error-catch.service';
import { NoPermissionGuard } from '@guards/no-permission-guard.service';
import { SessionService } from '@services/session.service';
import { IconModule } from '@default-application-app/modules/shared/icon/icon.module';
import { AsideMenuComponent } from '@layouts/aside-menu/aside-menu.component';
import { AsideMenuGroupComponent } from '@layouts/aside-menu/aside-menu-group/aside-menu-group.component';
import { BreadcrumbsComponent } from '@default-application-app/components/breadcrumbs/breadcrumbs.component';
import { NotificationsServiceWithTranslate } from '@services/translate/notificationsServiceWithTranslate';
import { EndpointInterceptor } from '@services/endpoint.interceptor';
import { TOKEN_NAME } from '@constants/token-name';
import { ApiLanguageService } from '@services/apiLanguageService';
import { AsideMenuGroupCompactComponent } from '@layouts/aside-menu/aside-menu-group-compact/aside-menu-group-compact.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { SvgIconModule } from '@default-application-app/modules/components/svg-icon/svg-icon.module';
import { HeaderLanguageMenuModule } from '@components/header-language-menu/header-language-menu.module';
import { DropDownMenuModule } from '@default-application-app/modules/components/drop-down-menu/drop-down-menu.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DialogModule } from '@ngneat/dialog';
import { WelcomePageComponent } from '@components/welcome-page/welcome-page.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MaterialModule } from '@default-application-app/modules/material/material.module';
import { KycGuard } from '@default-application-app/modules/kyc/guards/kyc.guard';
import { KycTierResolver } from '@resolvers/kyc-tier.resolver';
import { KycService } from '@services/kyc/kyc.service';
import { KycApiService } from '@services/kyc/kyc-api.service';
import { KycTiersResolver } from '@resolvers/kyc-tiers.resolver';
import { RolesGuard } from '@guards/roles.guard';
import { DelegateService } from '@services/delegate/delegate.service';
import { DelegateApiService } from '@services/delegate/delegate-api.service';
import { UserGroupTransfersService } from '../group-transfer/services/user-group-transfers.services';
import { CreateGroupTransfer } from '../group-transfer/pages/create-group-transfer/create-group-transfer.component';


// import { SvgIconPreloaderModule } from '@default-application-app/modules/svg-icon-preloader/svg-icon-preloader.module';

// MODULES
export function tokenGetter() {
  const token = localStorage.getItem(TOKEN_NAME);
  return token && JSON.parse(token).accessToken;
}

export function HttpLoaderFactory(http: HttpClient) {
  return new CustomTranslateLoader(http, 'app');
}

@NgModule({
  declarations: [
    HeaderComponent,
    MainLayoutComponent,
    MaintenanceMessageComponent,
    NotFoundComponent,
    AsideMenuComponent,
    AsideMenuGroupComponent,
    AsideMenuGroupCompactComponent,
    BreadcrumbsComponent,
    WelcomePageComponent,
  ],
  imports: [
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    ApplicationPipesModule,
    SharedComponentsModule,
    DragAndDropModule,
    PrintModule,
    SimpleNotificationsModule,
    JwtModule,
    RouterModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    InlineSVGModule.forRoot({
      baseUrl: '/assets/icons/v2/',
    }),
    EffectsModule.forRoot([AppEffects]),
    StoreModule.forFeature('profiles', profilesReducers),
    StoreModule.forFeature('userGroups', userGroupsReducers),
    StoreModule.forFeature('options', optionsReducers),
    StoreModule.forFeature('logOut', logoutReducers),
    StoreModule.forFeature('userPermissions', userPermissionsReducers),
    StoreModule.forFeature('siteTexts', siteTextsReducers),
    StoreModule.forFeature('siteLanguage', siteLanguageReducers),
    StoreModule.forFeature('currencies', currenciesReducers),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 25 }) : [],
    EffectsModule.forFeature([
      ProfileEffects,
      UserGroupEffects,
      OptionEffects,
      LogoutEffects,
      PermissionEffects,
      CurrenciesEffects,
      SiteLanguageEffects,
    ]),
    TranslateModule,
    PopupAutologoutModule,
    IconModule,
    SvgIconModule,
    HeaderLanguageMenuModule,
    DropDownMenuModule,
    NgScrollbarModule,
    MaterialModule,
    DialogModule.forRoot({
      minHeight: 'auto',
      width: 'auto',
      enableClose: false,
      windowClass: 'custom-popup',
    }),
    // SvgIconPreloaderModule.forRoot({ configUrl: './assets/json/preload-icons.json' }),
  ],
  exports: [
    HeaderComponent,
    MainLayoutComponent,
    NotFoundComponent,
    SimpleNotificationsModule,
    JwtModule,
    StoreModule,
    MaintenanceMessageComponent,
    EffectsModule,
    AsideMenuComponent,
    AsideMenuGroupComponent,
    AsideMenuGroupCompactComponent,
    BreadcrumbsComponent,
    WelcomePageComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MainModule {
  static forRoot(): ModuleWithProviders<MainModule> {
    return {
      ngModule: MainModule,
      providers: [
        ProfileService,
        CreateGroupTransfer,
        ProfileApiService,
        UserGroupTransfersApiService,
        ConfigService,
        AdminRoleGuard,
        IbanAdminRoleGuard,
        IbanApiService,
        IbanService,
        UserRoleGuard,
        RolesGuard,
        MaintenanceDisableGuard,
        CheckMaintenanceGuard,
        KycGuard,
        RootRoleGuard,
        AuthGuard,
        ApiCallerService,
        StorageService,
        DataService,
        AuthService,
        TokenService,
        AuthApiService,
        UserService,
        UserApiService,
        AccountApiService,
        UserGroupTransfersService,
        AccountService,
        KycService,
        KycApiService,
        DelegateService,
        DelegateApiService,
        OptionsApiService,
        TransferService,
        TransferApiService,
        UserGroupApiService,
        PermissionGroupApiService,
        AccountsResolver,
        KycTierResolver,
        KycTiersResolver,
        MaintenanceResolver,
        CurrenciesResolver,
        ApiErrorService,
        UserCurrenciesResolver,
        CurrenciesApiService,
        CurrenciesService,
        MessagingApiService,
        AccountsCardsGuard,
        SettingsRequestsTanService,
        DatepickerBaseOptionsResolver,
        RefreshTokenService,
        AppOptionsService,
        AuthHeaderInterceptor,
        EndpointInterceptor,
        RefreshTokenInterceptorService,
        SignUpResolver,
        TempAuthTokenGuard,
        AlreadyAuthenticatedGuard,
        AutoLogoutPopupService,
        UserPermissionGuard,
        NoPermissionGuard,
        RedirectIsEnabledModulesGuard,
        ErrorMessageTranslationService,
        OptionsModulesGuard,
        CurrencyApiService,
        AccountsWithIwtInstructionsAvailableResolver,
        NotificationsServiceWithTranslate,
        SessionService,
        ApiLanguageService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RefreshTokenInterceptorService,
          multi: true,
        },
        {
          provide: ErrorHandler,
          useClass: ErrorCatchService,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthHeaderInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: EndpointInterceptor,
          multi: true,
        },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
        { provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true },
        ...SimpleNotificationsModule.forRoot({
          icons: {
            success: '<i class="icon-success"></i>',
            alert: '<i class="icon-alert"></i>',
            error: '<i class="icon-error"></i>',
            info: '<i class="icon-info"></i>',
            warn: '<i class="icon-warning"></i>',
          },
          timeOut: 6000,
          showProgressBar: true,
          pauseOnHover: true,
          clickToClose: true,
          clickIconToClose: true,
        }).providers,
        ...JwtModule.forRoot({
          config: {
            tokenGetter,
            whitelistedDomains: environment.whitelistedDomains,
          },
        }).providers,
        ...StoreModule.forRoot(reducers, { metaReducers }).providers,
        ...EffectsModule.forRoot([AppEffects]).providers,
        ...TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient],
          },
          missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler },
        }).providers,
      ],
    };
  }
}
