<pagination-template #p="paginationApi" [maxSize]="7" (pageChange)="pageChange($event)">
  <div class="pagination main_color" [class.disabled]="p.isLastPage() && p.isFirstPage()">
    <div
      *ngIf="p.pages.length && !p.isFirstPage()"
      (click)="p.previous()"
      class="pagination-previous pagination__page"
      [class.disabled]="p.isFirstPage()"></div>
    <div
      *ngFor="let page of p.pages"
      [class.current]="p.getCurrent() === page.value"
      [class]="p.getCurrent() === page.value ? 'pagination__page active' : 'pagination__page'"
      (click)="p.setCurrent(page.value)">
      <span>{{ page.label }}</span>
    </div>
    <div
      *ngIf="p.pages.length && !p.isLastPage()"
      (click)="p.next()"
      class="pagination-next pagination__page"
      [class.disabled]="p.isLastPage()"></div>
  </div>
</pagination-template>
