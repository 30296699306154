import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { UserOptionNames } from '@constants/optionsPrefixes';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @ViewChild('input') input: ElementRef;

  @ViewChild(NgControl) control: NgControl;

  @Output()
  public blur: EventEmitter<any> = new EventEmitter<any>();

  @Input() placeholder: string = '';

  @Input() items: any[];

  @Input() controlName: string;

  @Input() label: string;

  @Input() limitByKey: any;

  @Input() tooltipMessage: string;

  @Input() footnote: string;

  @Input() otherText: string;

  @Input() title: string;

  @Input() type: string = 'text';

  @Input() loading: boolean;

  @Input() optionName: UserOptionNames;

  @Input() formControlName: string;

  @Input() mask;

  @Input() isRequiredIf: boolean;

  @Input() isDisabled: boolean = false;

  @Input() isRequiredLabel: boolean = false;

  @Input() isAutocompleteDisabled: boolean = false;

  @Input() value: any;

  @Input() isReadonly: boolean = false;

  @Input() isOnlyFloat: boolean = false;

  @Input() cardInputClass: boolean = false;

  @Input() isLockFieldClass: boolean = false;

  @Input() isFilledClass: boolean = false;

  @Input()
  public name: string;

  public ngOnInit(): void {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  public ngAfterViewInit() {
    if (this.value) {
      this.input.nativeElement.classList.add('filled');
    }
  }

  public model: string;

  public onChange: any = () => {};

  public onTouched: any = () => {};

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(obj: any): void {
    this.model = obj;
  }

  public onBlur(): void {
    this.blur.next();
    this.onTouched();
  }
}
