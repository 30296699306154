import { Directive, HostListener } from '@angular/core';

const allowedKeys = ['Delete', 'Backspace', 'Escape', 'Tab', 'Enter', 'End', 'Home', 'ArrowLeft', 'ArrowRight', '.'];

const regExp = /^\d*$/;

@Directive({
  selector: '[onlyNumber]',
})
export class OnlyNumberDirective {
  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    if (
      allowedKeys.find((value) => value === e.key) ||
      // Allow: Ctrl+A
      (e.code === 'KeyA' && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.code === 'KeyC' && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.code === 'KeyV' && (e.ctrlKey || e.metaKey))
    ) {
      return;
    }

    if (regExp.test(e.key)) {
      return;
    }

    e.preventDefault();
  }
}
