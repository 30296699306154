import * as FromProfiles from '@default-application-app/components/profiles/reducers/profiles.reducer';
import * as FromUserGroup from '@default-application-app/components/profiles/reducers/user-group.reducer';
import * as FromOption from '@default-application-app/components/profiles/reducers/option.reducer';
import { State as RootState } from '@default-application/src/app/reducers';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as OptionActions from '@default-application-app/components/profiles/actions/option.actions';

export interface ProfileState {
  profiles: FromProfiles.State;
}

export interface UserGroupState {
  userGroups: FromUserGroup.State;
}

export interface OptionsState {
  options: OptionActions.State;
}

export interface State extends RootState {
  profiles: ProfileState;
  userGroups: UserGroupState;
  options: OptionsState;
}

export const profilesReducers: ActionReducerMap<ProfileState, any> = {
  profiles: FromProfiles.reducer,
};

export const userGroupsReducers: ActionReducerMap<UserGroupState, any> = {
  userGroups: FromUserGroup.reducer,
};

export const optionsReducers: ActionReducerMap<OptionsState, any> = {
  options: FromOption.optionReducer,
};

export const getProfilesState = createFeatureSelector<State, ProfileState>('profiles');
export const getUserGroupState = createFeatureSelector<State, UserGroupState>('userGroups');
export const getOptionState = createFeatureSelector<State, OptionsState>('options');

// Profiles

export const getProfileEntitiesState = createSelector(getProfilesState, (state) => state.profiles);

export const getUserProfiles: any = createSelector(getProfileEntitiesState, FromProfiles.selectAllProfiles);

export const getSelectedProfileId = createSelector(getProfileEntitiesState, FromProfiles.getSelectedId);

export const getSaveLoading: any = createSelector(getProfileEntitiesState, FromProfiles.getLoading);

export const getSaved = createSelector(getProfileEntitiesState, FromProfiles.getSaved);

export const getSaveErrors = createSelector(getProfileEntitiesState, FromProfiles.getError);

export const getProfilesFilter: any = createSelector(getProfileEntitiesState, FromProfiles.getProfilesFilter);

export const getProfilesPagination: any = createSelector(getProfileEntitiesState, FromProfiles.getProfilesPagination);

// UserGroups

export const getUserGroupsState = createSelector(getUserGroupState, (state: UserGroupState) => state.userGroups);

export const getUserGroups: any = createSelector(getUserGroupsState, FromUserGroup.selectAllUserGroups);

export const getUserGroupLoading: any = createSelector(getUserGroupsState, FromUserGroup.getUserGroupLoading);

export const getUserGroupSaved = createSelector(getUserGroupsState, FromUserGroup.getUserGroupSaved);

export const getUserGroupDeleted = createSelector(getUserGroupsState, FromUserGroup.getUserGroupDeleted);

export const getUserGroupErrors = createSelector(getUserGroupsState, FromUserGroup.getUserGroupErrors);

export const getUserGroupFilter: any = createSelector(getUserGroupsState, FromUserGroup.getUserGroupFilter);

export const getUserGroupPagination: any = createSelector(getUserGroupsState, FromUserGroup.getUserGroupPagination);

export const selectUserGroupEntities = createSelector(getUserGroupsState, FromUserGroup.selectUserGroupEntities);

export const getCurrentUserGroupId = createSelector(getUserGroupsState, FromUserGroup.getCurrentUserGroupId);

export const selectCurrentUserGroup = createSelector(selectUserGroupEntities, getCurrentUserGroupId, (entities, id) =>
  id === null ? null : entities[id],
);

export const getUserGroupById = (groupId: number) =>
  createSelector(
    selectUserGroupEntities,
    () => groupId,
    (entities, id) => entities[id],
  );

// Options

export const getOptionsState = createSelector(getOptionState, (state) => state.options);

export const selectOptionsEntities = createSelector(getOptionsState, FromOption.selectOptionsEntities);

export const getCurrentOptionsPath: any = createSelector(getOptionsState, FromOption.getCurrentPath);

export const selectCurrentOptions: any = createSelector(selectOptionsEntities, getCurrentOptionsPath, (entities, id) =>
  id === null ? null : entities[id as number],
);

export const getOptionsLoading: any = createSelector(getOptionsState, FromOption.getOptionsLoading);

export const getOptionsErrors: any = createSelector(getOptionsState, FromOption.getOptionsErrors);

export const getPathOptions = (path: string): any =>
  createSelector(
    selectOptionsEntities,
    () => path,
    (entities, id) => entities[id],
  );
