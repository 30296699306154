import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sort-select',
  templateUrl: './sort-select.component.html',
  styleUrls: ['./sort-select.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortSelectComponent implements OnInit {
  @Input() form: UntypedFormGroup;

  @Input() searchable = false;

  @Input() clearable = false;

  @Input() placeholder = '';

  @Input() items: any[];

  @Input() bindValue: string;

  @Input() bindLabel: string;

  @Input() controlName: string;

  @Input() title: string;

  @Input() loading: boolean;

  @Input() typeahead: Subject<any>;

  @Input() groupBy: string;

  @Input() translatePrefix: string;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter();

  ngOnInit(): void {
    this.form.addControl(this.controlName, new UntypedFormControl(null));
  }
}
