/**
 * @license Use of this source code is governed by an CC BY-NC-ND 4.0 license that can be found in the LICENSE file at
 *   https://creativecommons.org/licenses/by-nc-nd/4.0
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders as Headers } from '@angular/common/http';
import { ApiCallerService } from '@services/api-caller.service';
import { ConfigService } from '@default-application-app/config.service';
import { map } from 'rxjs/operators';

@Injectable()
export class TransactionApiService {
  /**
   * AccountApiService constructor.
   *
   * @param {HttpClient} http
   * @param {ApiCallerService} apiCallerService
   * @param configService
   */
  public constructor(
    private http: HttpClient,
    private apiCallerService: ApiCallerService,
    private configService: ConfigService,
  ) {}

  /**
   * Load accounts
   *
   * @param params
   * @returns {Observable<any>}
   */
  public apiLoadTransactions(params: {}) {
    const headers = new Headers();
    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.account.transactions.userList, {
          params,
          headers,
        }),
      'apiLoadTransactions',
    );
  }

  public apiLoadTransactionsHistory(params: {}) {
    const headers = new Headers();
    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.account.transactions.history, {
          params,
          headers,
        }),
      'apiLoadTransactionsHistory',
    );
  }

  public apiLoadSubTransactionsByGroupId(groupId: string) {
    const headers = new Headers();
    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.account.subtransactions.subtransactionsByGroupId(groupId), {
          headers,
        }),
      'apiLoadSubTransactionsByGroupId',
    );
  }

  public apiLoadPendingTransactions(params: {}) {
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.account.transactions.userList, { params }),
      'apiLoadPendingTransactions',
    );
  }

  public apiGetUserTransaction(id: string, params: { [key: string]: string }): any {
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.account.transactions.transactionById(id), { params }),
      'apiGetUserTransaction',
    );
  }

  public apiLoadRequests(params: {}): Observable<any> {
    const headers = new Headers();

    return this.apiCallerService.call(
      () =>
        this.http.get(this.configService.config.api.account.transactions.userRequests, {
          params,
          headers,
        }),
      'apiLoadRequests',
    );
  }

  /**
   * Load transactions csv
   *
   * @param params
   * @returns {Observable<any>}
   */
  public apiLoadTransactionsCsv(params: {}): Observable<any> {
    return this.http
      .get(this.configService.config.api.account.transactions.exportCsv, {
        params,
        responseType: 'arraybuffer',
      })
      .pipe(map((binary: ArrayBuffer): Blob => new Blob([binary], { type: 'text/csv' })));
  }
}
