<div class="dropdown-menu" (clickOutside)="closeMenu()">
  <app-more-button class="app-more-button" (click)="isOpenMenu = !isOpenMenu" [small]="true"></app-more-button>

  <ul class="dropdown-menu-list" *ngIf="isOpenMenu && menuItems.length">
    <li
      *ngFor="let item of menuItems"
      (click)="onItemClick(item)"
      [title]="item.title | translate"
      class="dropdown-menu-item">
      <app-svg-icon class="item-icon" *ngIf="item.icon" [iconPath]="item.icon"></app-svg-icon>

      <span>{{ item.title | translate }}</span>
    </li>
  </ul>
</div>
