import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[nameWithNumberSymbol]',
})
export class NameWithNumberSymbolDirective {
  private regexAllowedCharacters = /^[\u0600-\u06FF\u0660-\u0669A-Za-z0-9.,'\- ]+$/;

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = this.el.nativeElement.value + event.key;
    if (!this.regexAllowedCharacters.test(input)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData.getData('text');
    if (!this.regexAllowedCharacters.test(clipboardData)) {
      event.preventDefault();
    }
  }
}
