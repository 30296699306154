import { buildReducer, checkToBeUniqueType } from '@helpers/redux-type-cashe';
import { CustomActionInterface, staticImplementsDecorator } from '@interfaces/staticAction.interface';
import { Action } from '@ngrx/store';
import { DefaultLanguage, Languages } from '@constants/languages';
import { LanguageInterface } from '@interfaces/language.interface';

export interface State {
  // TODO add language type

  languages: LanguageInterface[];
  language: string;
  loading: boolean;
}

export const initialSiteLanguageState: State = {
  languages: Languages,
  language: DefaultLanguage,
  loading: false,
};

export enum SiteLanguageActionsTypes {
  SET_SITE_LANGUAGE = '[Language] set site language',
  SET_SITE_DEFAULT_LANGUAGE = '[Language] set site default language',
  LOAD_SITE_LANGUAGES = '[Language] load site Languages',
  LOAD_SITE_LANGUAGES_SUCCESS = '[Language] load site Languages success',
  LOAD_SITE_LANGUAGES_FAILURE = '[Language] load site Languages failure',
  SET_SITE_LANGUAGE_FROM_LOCAL_STORAGE = '[Language] set site language from local storage',
  SET_SITE_LANGUAGE_FROM_LOCAL_STORAGE_SUCCESS = '[Language] set site language from local storage success',
  SET_SITE_LANGUAGE_FROM_LOCAL_STORAGE_FAILURE = '[Language] set site language from local storage failure',
  LOAD_USER_LANGUAGE = '[Language] load user Language',
  LOAD_USER_LANGUAGE_SUCCESS = '[Language] load user Language success',
  LOAD_USER_LANGUAGE_FAILURE = '[Language] load user Language failure',
  SAVE_USER_LANGUAGE = '[Language] save user Language',
  SAVE_USER_LANGUAGE_SUCCESS = '[Language] save user Language success',
  SAVE_USER_LANGUAGE_FAILURE = '[Language] save user Language failure',
}

@staticImplementsDecorator<CustomActionInterface<SetSiteLanguage, State>>()
export class SetSiteLanguage implements Action {
  static type = checkToBeUniqueType(SiteLanguageActionsTypes.SET_SITE_LANGUAGE);

  readonly type = SiteLanguageActionsTypes.SET_SITE_LANGUAGE;

  constructor(public language: string) {}

  static reduce(state: State, action: SetSiteLanguage): State {
    return {
      ...state,
      language: action.language,
    };
  }
}

@staticImplementsDecorator<CustomActionInterface<SetSiteDefaultLanguage, State>>()
export class SetSiteDefaultLanguage implements Action {
  static type = checkToBeUniqueType(SiteLanguageActionsTypes.SET_SITE_DEFAULT_LANGUAGE);

  readonly type = SiteLanguageActionsTypes.SET_SITE_DEFAULT_LANGUAGE;

  static reduce(state: State): State {
    return {
      ...state,
      language: state.languages.find((item) => item.default).code || DefaultLanguage,
      loading: false,
    };
  }
}

@staticImplementsDecorator<CustomActionInterface<SetSiteLanguageFromLocalStorage, State>>()
export class SetSiteLanguageFromLocalStorage implements Action {
  static type = checkToBeUniqueType(SiteLanguageActionsTypes.SET_SITE_LANGUAGE_FROM_LOCAL_STORAGE);

  readonly type = SiteLanguageActionsTypes.SET_SITE_LANGUAGE_FROM_LOCAL_STORAGE;

  static reduce(state: State): State {
    return {
      ...state,
      loading: true,
    };
  }
}

@staticImplementsDecorator<CustomActionInterface<SetSiteFromLocalStorageLanguageSuccess, State>>()
export class SetSiteFromLocalStorageLanguageSuccess implements Action {
  static type = checkToBeUniqueType(SiteLanguageActionsTypes.SET_SITE_LANGUAGE_FROM_LOCAL_STORAGE_SUCCESS);

  readonly type = SiteLanguageActionsTypes.SET_SITE_LANGUAGE_FROM_LOCAL_STORAGE_SUCCESS;

  constructor(public language: string) {}

  static reduce(state: State, action: SetSiteFromLocalStorageLanguageSuccess): State {
    return {
      ...state,
      language: action.language,
      loading: false,
    };
  }
}

@staticImplementsDecorator<CustomActionInterface<SetSiteFromLocalStorageLanguageFailure, State>>()
export class SetSiteFromLocalStorageLanguageFailure implements Action {
  static type = checkToBeUniqueType(SiteLanguageActionsTypes.SET_SITE_LANGUAGE_FROM_LOCAL_STORAGE_FAILURE);

  readonly type = SiteLanguageActionsTypes.SET_SITE_LANGUAGE_FROM_LOCAL_STORAGE_FAILURE;

  static reduce(state: State): State {
    return {
      ...state,
      loading: false,
    };
  }
}

@staticImplementsDecorator<CustomActionInterface<LoadSiteLanguages, State>>()
export class LoadSiteLanguages implements Action {
  static type = checkToBeUniqueType(SiteLanguageActionsTypes.LOAD_SITE_LANGUAGES);

  readonly type = SiteLanguageActionsTypes.LOAD_SITE_LANGUAGES;

  static reduce(state: State): State {
    return {
      ...state,
      loading: true,
    };
  }
}

@staticImplementsDecorator<CustomActionInterface<LoadSiteLanguagesSuccess, State>>()
export class LoadSiteLanguagesSuccess implements Action {
  static type = checkToBeUniqueType(SiteLanguageActionsTypes.LOAD_SITE_LANGUAGES_SUCCESS);

  readonly type = SiteLanguageActionsTypes.LOAD_SITE_LANGUAGES_SUCCESS;
  // TODO add language type

  constructor(public languages: any[]) {}

  static reduce(state: State, action: LoadSiteLanguagesSuccess): State {
    return {
      ...state,
      languages: action.languages,
      loading: false,
    };
  }
}

@staticImplementsDecorator<CustomActionInterface<LoadSiteLanguagesFailure, State>>()
export class LoadSiteLanguagesFailure implements Action {
  static type = checkToBeUniqueType(SiteLanguageActionsTypes.LOAD_SITE_LANGUAGES_FAILURE);

  readonly type = SiteLanguageActionsTypes.LOAD_SITE_LANGUAGES_FAILURE;

  static reduce(state: State): State {
    return {
      ...state,
      loading: false,
    };
  }
}

@staticImplementsDecorator<CustomActionInterface<LoadUserLanguage, State>>()
export class SaveUserLanguage implements Action {
  static type = checkToBeUniqueType(SiteLanguageActionsTypes.SAVE_USER_LANGUAGE);

  readonly type = SiteLanguageActionsTypes.SAVE_USER_LANGUAGE;

  constructor(public language: string) {}

  static reduce(state: State): State {
    return {
      ...state,
      loading: true,
    };
  }
}

@staticImplementsDecorator<CustomActionInterface<LoadUserLanguageSuccess, State>>()
export class SaveUserLanguageSuccess implements Action {
  static type = checkToBeUniqueType(SiteLanguageActionsTypes.SAVE_USER_LANGUAGE_SUCCESS);

  readonly type = SiteLanguageActionsTypes.SAVE_USER_LANGUAGE_SUCCESS;

  constructor(public language: string) {}

  static reduce(state: State): State {
    return {
      ...state,
      loading: false,
    };
  }
}

@staticImplementsDecorator<CustomActionInterface<LoadUserLanguageFailure, State>>()
export class SaveUserLanguageFailure implements Action {
  static type = checkToBeUniqueType(SiteLanguageActionsTypes.SAVE_USER_LANGUAGE_FAILURE);

  readonly type = SiteLanguageActionsTypes.SAVE_USER_LANGUAGE_FAILURE;

  static reduce(state: State): State {
    return {
      ...state,
      loading: false,
    };
  }
}

@staticImplementsDecorator<CustomActionInterface<LoadUserLanguage, State>>()
export class LoadUserLanguage implements Action {
  static type = checkToBeUniqueType(SiteLanguageActionsTypes.LOAD_USER_LANGUAGE);

  readonly type = SiteLanguageActionsTypes.LOAD_USER_LANGUAGE;

  static reduce(state: State): State {
    return {
      ...state,
      loading: true,
    };
  }
}

@staticImplementsDecorator<CustomActionInterface<LoadUserLanguageSuccess, State>>()
export class LoadUserLanguageSuccess implements Action {
  static type = checkToBeUniqueType(SiteLanguageActionsTypes.LOAD_USER_LANGUAGE_SUCCESS);

  readonly type = SiteLanguageActionsTypes.LOAD_USER_LANGUAGE_SUCCESS;

  constructor(public language: string) {}

  static reduce(state: State, action: LoadUserLanguageSuccess): State {
    return {
      ...state,
      language: action.language,
      loading: false,
    };
  }
}

@staticImplementsDecorator<CustomActionInterface<LoadUserLanguageFailure, State>>()
export class LoadUserLanguageFailure implements Action {
  static type = checkToBeUniqueType(SiteLanguageActionsTypes.LOAD_USER_LANGUAGE_FAILURE);

  readonly type = SiteLanguageActionsTypes.LOAD_USER_LANGUAGE_FAILURE;

  static reduce(state: State): State {
    return {
      ...state,
      loading: false,
    };
  }
}

export const reducer = buildReducer(SetSiteLanguage, LoadSiteLanguages, LoadSiteLanguagesSuccess);
