import { Injectable } from '@angular/core';

import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { OptionsApiService } from '@default-application-app/core/services/options/options-api.service';
import { OptionsPaths } from '@default-application-app/core/constants/optionsPrefixes';

@Injectable()
export class MaintenanceResolver implements Resolve<any> {
  constructor(private optionsApiService: OptionsApiService) {}

  public resolve(): Observable<any> {
    return this.optionsApiService.apiLoadOptions(OptionsPaths.REGIONAL_GENERAL, true).pipe(first());
  }
}
