import { NgModule } from '@angular/core';
import { CreditCardNumberPipe } from '@pipes/credit-card-number.pipe';
import { CustomDatePipe } from '@pipes/customDate.pipe';
import { FillArrayPipe } from '@pipes/fill-array.pipe';
import { SanitizeHtmlPipe } from '@pipes/sanitizeHtml';
import { ConvertCreditCardNumberPipe } from '@pipes/convert-credit-card-number.pipe';
import { MonthPipe } from '@pipes/month.pipe';
import { CustomCurrencyPipe } from '@pipes/customCurrency.pipe';
import { VerificationTypePipe } from '@pipes/verification-type.pipe';
import { CountriesPipe } from '@pipes/countries.pipe';
import { ReturnHumanIdPipe } from '@pipes/return-human-id.pipe';
import { TranslateSelectItemsPipe } from '@pipes/translate-select-items.pipe';
import { HttpLinkPipe } from '@pipes/http-link.pipe';
import { StringToDatePipe } from '@pipes/string-to-date.pipe';
import { PhonePipe } from '@pipes/phone.pipe';
import { PhoneWithoutCountryCodePipe } from '@pipes/phone-without-country-code.pipe';

@NgModule({
  declarations: [
    CreditCardNumberPipe,
    ConvertCreditCardNumberPipe,
    CustomDatePipe,
    FillArrayPipe,
    SanitizeHtmlPipe,
    MonthPipe,
    VerificationTypePipe,
    CustomCurrencyPipe,
    CountriesPipe,
    ReturnHumanIdPipe,
    PhonePipe,
    PhoneWithoutCountryCodePipe,
    TranslateSelectItemsPipe,
    HttpLinkPipe,
    StringToDatePipe,
  ],
  exports: [
    CreditCardNumberPipe,
    ConvertCreditCardNumberPipe,
    CustomDatePipe,
    FillArrayPipe,
    SanitizeHtmlPipe,
    CountriesPipe,
    MonthPipe,
    CustomCurrencyPipe,
    VerificationTypePipe,
    ReturnHumanIdPipe,
    PhonePipe,
    TranslateSelectItemsPipe,
    PhoneWithoutCountryCodePipe,
    HttpLinkPipe,
    StringToDatePipe,
  ],
})
export class ApplicationPipesModule {}
