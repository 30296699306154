<app-svg-icon iconPath="auth/input-error.svg" hidden></app-svg-icon>

<div class="input-header">
  <label *ngIf="label" class="input-header__label">
    {{ label }}
  </label>
</div>

<div class="form-control">
  <textarea
    [rows]="rows"
    class="form-control__input"
    [ngClass]="{ 'has-error': control.touched && control.invalid, 'no-resize': isNoResize }"
    [placeholder]="placeholder"
    [(ngModel)]="model"
    (ngModelChange)="onChange($event)"
    (blur)="onBlur()"
    [name]="name"
    [attr.disabled]="isDisabled ? true : null"></textarea>
</div>
