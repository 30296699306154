<div class="group" [formGroup]="form">
  <input
    class="group-item"
    type="text"
    formControlName="0"
    [maxLength]="1"
    (focusin)="onFocus($event)"
    (keypress)="onKeyPress($event)"
    (keyup.backspace)="back($event)"
    autofocus
    #input />
  <input
    class="group-item"
    type="text"
    formControlName="1"
    [maxLength]="1"
    (focusin)="onFocus($event)"
    (keypress)="onKeyPress($event)"
    (keyup.backspace)="back($event)"
    #input />
  <input
    class="group-item"
    type="text"
    formControlName="2"
    [maxLength]="1"
    (focusin)="onFocus($event)"
    (keypress)="onKeyPress($event)"
    (keyup.backspace)="back($event)"
    #input />
  <input
    class="group-item"
    type="text"
    formControlName="3"
    [maxLength]="1"
    (focusin)="onFocus($event)"
    (keypress)="onKeyPress($event)"
    (keyup.backspace)="back($event)"
    #input />
  <input
    class="group-item"
    type="text"
    formControlName="4"
    [maxLength]="1"
    (focusin)="onFocus($event)"
    (keypress)="onKeyPress($event)"
    (keyup.backspace)="back($event)"
    #input />
  <input
    class="group-item"
    type="text"
    formControlName="5"
    [maxLength]="1"
    (focusin)="onFocus($event)"
    (keypress)="onKeyPress($event)"
    (keyup.backspace)="back($event)"
    #input />
</div>
