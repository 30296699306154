import { AccountInterface } from '@default-application-app/core/interfaces/account-interface';
import { TransactionStatus } from '@models/transactions/transaction-status';
import { InvoiceInterface } from '@models/invoices/invoice';
import { RefundInterface } from '@models/invoices/refund';

export class ViewTransactionModel {
  public id: number;

  public description: string;

  public amount: string;

  public purpose: string;

  public createdAt: string;

  public statusChangedAt: string;

  public updatedAt: string;

  public requestData: RequestDataInterface;

  public recipient: ViewAccountProfileInterface;

  public sender: ViewAccountProfileInterface;

  public requestSubject: string;

  public status: TransactionStatus;

  public accountId: number;

  public currencyCode: string;

  public account: AccountInterface;

  public requestId: string;

  public showRequestId: boolean;

  public isRefund: boolean;

  public balanceSnapshot: number;

  public type: string;

  public invoiceId: number;

  public invoice: InvoiceInterface;

  public refund: RefundInterface;

  public accTo?: AccountInterface;

  public accountFrom?: AccountInterface;

  public transactionType?: string;

  public groupId?: string;

  public totals?: {
    amountToReceiveFromCashAgent?: string;
    amountToHandleToCashAgent?: string;
    requestAmount?: string;
    totalOutgoingAmount?: string;
    walletFee?: string;
  };

  public constructor(params: any) {
    Object.assign(this, params);
  }
}

export interface ViewAccountProfileInterface {
  account: ViewTransactionAccount;
  card: ViewTransactionAccount;
  profile: ViewTransactionProfile;
}

export interface ViewTransactionAccount {
  currencyCode: string;
  number: string;
  typeName: string;
}

export interface ViewTransactionProfile {
  companyName: string;
  businessName: string;
  firstName: string;
  lastName: string;
}

export interface RequestDataInterface {
  refMessage: string;
  bankDetails: BankDetailsInterface;
  beneficiaryCustomer: BeneficiaryCustomerInterface;
  intermediaryBankDetails: BankDetailsInterface;
  destinationCurrencyCode: string;
  requestId: number;
  sourceAccountId: number;
}

export interface BeneficiaryCustomerInterface {
  accountName: string;
  address: string;
  createdAt: string;
  iban: string;
  id: number;
  updatedAt: string;
}

export interface BankDetailsInterface {
  abaNumber: string;
  address: string;
  bankName: string;
  country: {
    code: string;
    code3: string;
    codeNumeric: string;
    id: number;
    name: string;
  };
  countryId: number;
  createdAt: string;
  iban: string;
  id: number;
  location: string;
  swiftCode: string;
  updatedAt: string;
}
