import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[nameSymbol]',
})
export class NameSymbolDirective implements OnInit {
  private regexAllowedCharacters = /^[\u0600-\u06FF\u0660-\u0669A-Za-z]+$/;

  @Input() symbols: string = ".,'\\- ";

  constructor(private el: ElementRef) {}

  public ngOnInit(): void {
    this.regexAllowedCharacters = new RegExp(`^[\\u0600-\\u06FF\\u0660-\\u0669A-Za-z${this.symbols}]+$`);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = this.el.nativeElement.value + event.key;
    if (!this.regexAllowedCharacters.test(input)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData.getData('text');
    if (!this.regexAllowedCharacters.test(clipboardData)) {
      event.preventDefault();
    }
  }
}
