import { IAmountDetails } from '@interfaces/transfer-request-interface';
import { BalanceHelper } from '@helpers/balance.helper';

export type Constructor<T> = new (...args: any[]) => T;

export function AmountDetailsMixin<TBase extends Constructor<{}>>(Base: TBase) {
  return class extends Base {
    public details: IAmountDetails[] | null = null;

    public get hasAnyDetails(): boolean {
      return !!(this.details && this.details.length);
    }

    public typeFeeText(details: IAmountDetails[]): string {
      let fee: string = null;

      details.forEach((item: IAmountDetails) => {
        if (item.purpose === 'fee_default_transfer') {
          fee = `${BalanceHelper.valueToCurrencyPrecision(Math.abs(+item.amount), item.currencyCode)} ${
            item.currencyCode
          }`;
        }
      });

      return fee;
    }

    public prettyFeeText(detail: IAmountDetails): string {
      return `${this.prettyPurpose(detail.purpose)}: ${detail.amount} ${detail.currencyCode}`;
    }

    private prettyPurpose(purpose: string): string {
      return purpose
        .split('_')
        .join(' ')
        .replace(/(\w)/, (g) => g[0].toUpperCase());
    }
  };
}
