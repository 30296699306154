import { Action } from '@ngrx/store';
import { FilterUserGroupInterface } from '@interfaces/filter-user-group.interface';
import { UserGroup } from '@models/user-group-model';
import { PaginationPageLimitInterface } from '@interfaces/pagination-page-limit.interface';
import { ApiError } from '@models/api-error.model';
import { CreateUserGroupRequest } from '@request-models/create-user-group-request';

export enum UserGroupTypes {
  SEARCH_USER_GROUPS = '[User Group] load',
  SEARCH_USER_SUCCESS = '[User Group/API] load Success',
  SEARCH_USER_FAILURE = '[User Group/API] load Failure',
  SELECT_USER_GROUP = '[User Group] select user group',
  LOAD_USER_GROUP = '[User Group] load user group',
  LOAD_USER_GROUP_SUCCESS = '[User Group/API] load group Success',
  LOAD_USER_GROUP_FAILURE = '[User Group/API] load group Failure',
  CREATE_USER_GROUP = '[User Group] Create user group',
  SAVE_USER_GROUP = '[User Group] Save user group',
  CREATE_USER_GROUP_SUCCESS = '[User Group/API] create Success',
  SAVE_USER_GROUP_SUCCESS = '[User Group/API] save Success',
  SAVE_USER_GROUP_FAILURE = '[User Group/API] save Failure',
  RESET_SAVED_STATE = '[User Group] reset saved',
  DELETE_USER_GROUP = '[User Group] delete user group',
  DELETE_USER_GROUP_SUCCESS = '[User Group/API] delete Success',
}

export class SearchUserGroups implements Action {
  readonly type = UserGroupTypes.SEARCH_USER_GROUPS;

  constructor(public filter?: FilterUserGroupInterface) {}
}

export class SearchUserGroupsSuccess implements Action {
  readonly type = UserGroupTypes.SEARCH_USER_SUCCESS;

  constructor(public userGroups: UserGroup[], public pagination?: PaginationPageLimitInterface) {}
}

export class SearchUserGroupsFailure implements Action {
  readonly type = UserGroupTypes.SEARCH_USER_FAILURE;

  constructor(public errors: any[]) {}
}

export class SelectUserGroup implements Action {
  readonly type = UserGroupTypes.SELECT_USER_GROUP;

  constructor(public id: number) {}
}

export class LoadUserGroup implements Action {
  readonly type = UserGroupTypes.LOAD_USER_GROUP;

  constructor(public id?: number) {}
}

export class LoadUserGroupSuccess implements Action {
  readonly type = UserGroupTypes.LOAD_USER_GROUP_SUCCESS;

  constructor(public userGroup: UserGroup) {}
}

export class LoadUserGroupFailure implements Action {
  readonly type = UserGroupTypes.LOAD_USER_GROUP_FAILURE;

  constructor(public errors: ApiError[]) {}
}

export class CreateUserGroup implements Action {
  readonly type = UserGroupTypes.CREATE_USER_GROUP;

  constructor(public userGroup: CreateUserGroupRequest) {}
}

export class SaveUserGroup implements Action {
  readonly type = UserGroupTypes.SAVE_USER_GROUP;

  constructor(public userGroup: CreateUserGroupRequest) {}
}

export class CreateUserGroupSuccess implements Action {
  readonly type = UserGroupTypes.CREATE_USER_GROUP_SUCCESS;

  constructor(public userGroup: UserGroup) {}
}

export class SaveUserGroupSuccess implements Action {
  readonly type = UserGroupTypes.SAVE_USER_GROUP_SUCCESS;

  constructor(public userGroup: UserGroup) {}
}

export class SaveUserGroupFailure implements Action {
  readonly type = UserGroupTypes.SAVE_USER_GROUP_FAILURE;

  constructor(public errors: ApiError[]) {}
}

export class ResetSavedState implements Action {
  readonly type = UserGroupTypes.RESET_SAVED_STATE;
}

export class DeleteUserGroup implements Action {
  readonly type = UserGroupTypes.DELETE_USER_GROUP;

  constructor(public id: number) {}
}

export class DeleteUserGroupSuccess implements Action {
  readonly type = UserGroupTypes.DELETE_USER_GROUP_SUCCESS;

  constructor(public id: number) {}
}

export type UserGroupsActionsUnion =
  | SearchUserGroups
  | SearchUserGroupsSuccess
  | SearchUserGroupsFailure
  | SelectUserGroup
  | LoadUserGroupSuccess
  | LoadUserGroupFailure
  | LoadUserGroup
  | CreateUserGroup
  | SaveUserGroup
  | CreateUserGroupSuccess
  | SaveUserGroupSuccess
  | SaveUserGroupFailure
  | ResetSavedState
  | DeleteUserGroup
  | DeleteUserGroupSuccess;
