<app-modal-wrapper [isHideIcon]="true">
  <div class="modal-content">
    <img [src]="url" />
  </div>

  <div class="footer">
    <app-button
      class="button"
      type="button"
      [appearance]="'primary-outline'"
      (btnClick)="close()"
      [buttonText]="'common.buttons.close' | translate"></app-button>
  </div>
</app-modal-wrapper>
