<ng-container [formGroup]="form">
  <ng-select
    class="sort-select material-icons"
    [loading]="loading"
    [title]="title"
    [searchable]="searchable"
    [clearable]="clearable"
    [placeholder]="placeholder"
    [items]="items"
    [bindValue]="bindValue"
    [bindLabel]="bindLabel"
    [groupBy]="groupBy"
    [formControlName]="controlName"
    [typeahead]="typeahead"
    (ngModelChange)="change.emit($event)">
    <ng-template ng-optgroup-tmp let-item="item"> &#8213; </ng-template>

    <ng-container *ngIf="translatePrefix">
      <ng-template ng-label-tmp let-item="item">
        {{ translatePrefix + '.' + (item?.value ? item.value : 'select_item') | translate }}
      </ng-template>

      <ng-template ng-option-tmp let-item="item">
        {{ translatePrefix + '.' + item.value | translate }}
      </ng-template>
    </ng-container>

    <ng-container *ngIf="!translatePrefix">
      <ng-template ng-label-tmp let-item="item">
        {{ item?.key ? item.value : (item.value | translate) }}
      </ng-template>

      <ng-template ng-option-tmp let-item="item">
        {{ item?.key ? item.value : (item.value | translate) }}
      </ng-template>
    </ng-container>
  </ng-select>
</ng-container>
