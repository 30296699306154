<div class="popup-wrapper">
  <div class="content">
    <div class="popup-header">
      <h1 class="heading">
        <span class="heading-elem">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
            <g
              fill="none"
              fill-rule="evenodd"
              stroke="#FFF"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2">
              <path d="M4 7v18h18V7.397M1 6.5h24M18 6V1H8v5M10.5 11v8M15.18 11v8"></path>
            </g>
          </svg>
        </span>

        <span class="heading-elem" [title]="headingMessage">
          {{ 'layout.delete_popup.delete_message' | translate: { message: headingMessage } }}
        </span>
      </h1>
    </div>

    <div class="popup-main">
      <p class="warning-text">{{ 'layout.delete_popup.warning' | translate }}</p>
      <p class="message table-content">{{ 'layout.delete_popup.action_cannot_be_undone' | translate }}</p>
    </div>

    <div class="bottom bottom_flex_end">
      <app-button
        appearance="secondary"
        class="cancel-button"
        (btnClick)="confirmAction(false)"
        [buttonText]="'layout.delete_popup.cancel' | translate">
      </app-button>

      <app-button
        appearance="danger"
        (btnClick)="confirmAction(true)"
        [buttonText]="'layout.delete_popup.delete' | translate">
      </app-button>
    </div>
  </div>
</div>
