import { KycCurrentTierInterface, LimitationInterface } from '@interfaces/kyc/kyc-tier.interface';

export class KycCurrentTierModel implements KycCurrentTierInterface {
  public id: number;

  public level: number;

  public name: string;

  public role?: string;

  public countryCode?: string;

  public currencyCode?: string;

  public limits?: LimitationInterface[];

  constructor(data: KycCurrentTierInterface) {
    Object.assign(this, data);
  }
}
