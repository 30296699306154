<div class="success-container">
  <div class="success-container__content">
    <svg fill="none" height="74" viewBox="0 0 74 74" width="74" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4016 17.948C17.2528 10.1792 26.5782 5 37.0006 5C39.5605 5 42.1204 5.36994 44.4975 5.92485"
        stroke="#229932"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10" />
      <path
        d="M30.6 69C15.972 66.0405 5.00098 52.9076 5.00098 37.37C5.00098 32.9307 5.91523 28.6764 7.56088 24.792"
        stroke="#229932"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10" />
      <path
        d="M53.6396 64.9307C50.8968 66.5954 47.7884 67.8902 44.4971 68.815"
        stroke="#229932"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10" />
      <path
        d="M62.6004 17.9482C66.6231 23.3124 69.0002 30.1563 69.0002 37.3702C69.0002 46.2488 65.3432 54.3875 59.6748 60.3065"
        stroke="#229932"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10" />
      <circle cx="37" cy="37" fill="#229932" r="25" />
      <path
        d="M29 38.697L34.554 44L46 30"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2" />
    </svg>

    <div class="success-container__content__title">
      {{ 'pages.auth.thank_you' | translate }}
    </div>
    <div class="success-container__content__description">
      {{ 'pages.auth.request_is_being_processed' | translate }}
    </div>
  </div>
</div>
