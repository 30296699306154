<i
  *ngIf="!isCustomIcon"
  class="zmdi zmdi-help"
  [ngClass]="{ radio: isRadio }"
  (click)="onFocus()"
  (clickOutside)="onBlur()">
  <div class="question-popup" [ngClass]="{ right: isRightPosition }" [style.display]="isShow ? 'block' : 'none'">
    <ng-content></ng-content>
  </div>

  <i class="after-icon" [style.display]="isShow ? 'block' : 'none'"></i>
</i>

<div *ngIf="isCustomIcon" class="custom-content" (click)="onFocus()" (clickOutside)="onBlur()">
  <ng-content select="[tooltip-content]"></ng-content>
  <div class="question-popup" [ngClass]="{ right: isRightPosition }" [style.display]="isShow ? 'block' : 'none'">
    {{ text }}
  </div>

  <i class="after-icon" [style.display]="isShow ? 'block' : 'none'"></i>
</div>
