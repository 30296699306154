<div class="per-page-select" [formGroup]="form">
  <span class="mobile-hide">{{ 'layout.system.show' | translate }}</span>

  <div class="page-select-container">
    <ng-select
      class="page-select"
      [title]="'layout.system.pages' | translate"
      [searchable]="false"
      [clearable]="false"
      formControlName="size"
      dropdownPosition="top"
      (change)="form.get('page').setValue('1')"
      [items]="itemsCount">
    </ng-select>
  </div>

  <span class="mobile-hide">{{ 'layout.system.per_page' | translate }}</span>
</div>
