import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { AccountInterface } from '@interfaces/account-interface';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ControlValueAccessor, FormControlName, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-account-select-with-currency',
  templateUrl: './account-select-with-currency.component.html',
  styleUrls: ['./account-select-with-currency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AccountSelectWithCurrencyComponent),
    },
  ],
})
export class AccountSelectWithCurrencyComponent implements ControlValueAccessor, AfterContentInit {
  @ViewChild('NgSelectComponent', { static: true }) ngSelect: NgSelectComponent;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter();

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter();

  @Input() public selectLabel: string;

  @Input() public placeholderText: string;

  @Input() public bindValue: string;

  @Input() public isClearable: boolean;

  @Input() public readonly isSource = true;

  @Input() set isPending(value: boolean) {
    this.ngSelect.setDisabledState(value);
  }

  @Input() set accounts(accounts: AccountInterface[]) {
    this._accounts =
      accounts &&
      accounts.filter((account: AccountInterface): boolean => {
        if (this.isSource) {
          return account.allowWithdrawals;
        }
        return account.allowDeposits;
      });
  }

  public _accounts: AccountInterface[];

  @Output() public accountChange: EventEmitter<AccountInterface> = new EventEmitter<AccountInterface>();

  public control: FormControlName;

  private onTouched: Function = () => {};

  constructor(private injector: Injector) {}

  writeValue(obj: any): void {
    this.ngSelect.writeValue(obj);
  }

  registerOnChange(fn: any): void {
    this.ngSelect.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
    this.ngSelect.registerOnTouched(fn);
  }

  ngAfterContentInit(): void {
    this.control = this.injector.get<NgControl>(NgControl) as FormControlName;
    this.ngSelect.closeEvent.pipe(take(1)).subscribe((value: any) => {
      this.onTouched();
      this.blur.emit(value);
    });
  }

  emitChange(event: any) {
    this.change.emit(event);
  }
}
