import { IAsideMenuGroup } from '@default-application-app/components/layouts/aside-menu/aside-menu-group/aside-menu-group.component';
import { MenuGroupLabel } from '@default-application-app/components/layouts/aside-menu/menu-groupe-label';
import { UserPermissions } from '@constants/userPermissions';

export const adminGroups: IAsideMenuGroup[] = [
  {
    iconName: 'menu/ic_users.svg',
    title: 'users',
    route: '/admin/profiles',
    subItems: [
      { title: 'clients', route: '/admin/profiles/user-profiles/client' },
      {
        title: 'verif_requests',
        route: '/admin/profiles/requests',
        permissions: [UserPermissions.VIEW_KYC_REQUESTS, UserPermissions.VIEW_KYB_REQUESTS],
      },
      // { title: 'expiring_documents', route: '/admin/profiles/expiring-documents' },
      { title: 'administrators', route: '/admin/profiles/administrator-profiles' },
      { title: 'blocked_users', route: '/admin/profiles/blocking' },
    ],
  },
  {
    iconName: 'menu/ic_accounts.svg',
    title: 'accounts',
    route: '/admin/accounts',
    subItems: [
      { title: 'all_accounts', route: '/admin/accounts/accounts' },
      // { title: 'a2a_transfer', route: '/admin/accounts/transfers/transfer-between-accounts' },
      // { title: 'wire_transfer', route: '/admin/accounts/transfers/outgoing-wire-transfer' },
      { title: 'transfer_requests', route: '/admin/accounts/transfer-requests' },
      { title: 'revenue_accounts', route: '/admin/accounts/revenue' },
      { title: 'top_up', route: '/admin/accounts/top-up' },
      { title: 'withdraw', route: '/admin/accounts/withdraw' },
      { title: 'cash_source', route: '/admin/accounts/cash-source' },
    ],
  },
  /* {
    iconName: 'menu/ic_cards.svg',
    title: 'cards',
    route: '/admin/cards',
    label: MenuGroupLabel.Cards,
    subItems: [
      { title: 'all_cards', route: '/admin/cards/cards' },
      { title: 'card_holds', route: '/admin/cards/card-holds' },
      { title: 'card_transactions', route: '/admin/cards/card-transactions' },
      { title: 'clearing_logs', route: '/admin/cards/clearing-logs' },
      // { title: 'card_provider_config', route: '/admin/cards/provider-config' },
    ],
  }, */
  /* {
    iconName: 'menu/ic_exchange_fiat.svg',
    title: 'currency_exchange',
    route: '/admin/exchange/currency',
    subItems: [
      { title: 'currencies', route: '/admin/exchange/currency/currencies' },
      { title: 'rates', route: '/admin/exchange/currency/rates' },
      { title: 'fees', route: '/admin/exchange/currency/fees' },
      { title: 'orders', route: '/admin/exchange/currency/orders' },
    ],
  }, */
  /* {
    iconName: 'menu/ic_exchange_crypto.svg',
    title: 'crypto_exchange',
    route: '/admin/exchange/crypto',
    subItems: [
      { title: 'currencies', route: '/admin/exchange/crypto/currencies' },
      { title: 'rates', route: '/admin/exchange/crypto/rates' },
      { title: 'fees', route: '/admin/exchange/crypto/fees' },
      { title: 'orders', route: '/admin/exchange/crypto/orders' },
      // { title: 'custody_config', route: '/admin/exchange/crypto/custody-config' },
    ],
  }, */
  {
    iconName: 'menu/ic_reports.svg',
    title: 'reports',
    route: '/reports',
    subItems: [
      { title: 'specific_user_reports', route: '/reports/specific-user-reports' },
      { title: 'general_system_reports', route: '/reports/general-system-reports' },
    ],
  },
  {
    iconName: 'menu/ic_administration.svg',
    title: 'administration',
    route: '/settings',
    label: MenuGroupLabel.Settings,
    subItems: [
      { title: 'users', route: '/settings/profile' },
      { title: 'kyc_tiers', route: '/settings/kyc-tiers' },
      { title: 'accounts', route: '/settings/account' },
      // { title: 'transfer_fees', route: '/settings/fee' },
    ],
  },
  {
    iconName: 'menu/ic_news.svg',
    title: 'news_and_documents',
    route: '/admin/documents',
    subItems: [
      // { title: 'news', route: '/admin/documents/news' },
      { title: 'legal_updates', route: '/admin/documents/legal-updates' },
    ],
  },
  {
    iconName: 'menu/ic_audit.svg',
    title: 'system_logs',
    route: '/admin/system-log',
    subItems: [
      { title: 'transaction_log', route: '/admin/system-log/transaction-log' },
      { title: 'information_log', route: '/admin/system-log/information-log' },
      { title: 'scheduled_transaction_log', route: '/admin/system-log/scheduled-transaction-log' },
      { title: 'access_log', route: '/admin/system-log/access-log' },
    ],
  },
  {
    iconName: 'menu/ic_messages.svg',
    title: 'helpdesk',
    route: '/helpdesk',
  },
  // {
  //   iconName: 'menu/ic_api.svg',
  //   title: 'api',
  //   route: '/api',
  // },
];

export const ibanAdminGroups: IAsideMenuGroup[] = [
  {
    iconName: 'menu/ic_accounts.svg',
    title: 'iban',
    route: '/iban-admin',
    subItems: [
      { title: 'verify_requests', 
        route: '/iban-admin/verify-requests', 
        permissions: [UserPermissions.VIEW_IBAN_KYC_REQUESTS],
      },
      { title: 'view_iban_clients', 
        route: '/iban-admin/iban-clients', 
        permissions: [UserPermissions.VIEW_IBAN_KYC_REQUESTS],
      },
      { title: 'transfer_requests', 
        route: '/iban-admin/transfer-requests', 
        permissions: [UserPermissions.VIEW_IBAN_TRANSFER_REQUESTS],
      },
      { title: 'view_iban_transfers', 
        route: '/iban-admin/iban-transfers', 
        permissions: [UserPermissions.VIEW_IBAN_TRANSFERS],
      },
    ],
  },
];

export const businessUserGroups: IAsideMenuGroup[] = [
  {
    iconName: 'menu/ic_dashboard.svg',
    title: 'dashboard',
    route: '/dashboard',
  },
  {
    iconName: 'menu/ic_accounts.svg',
    title: 'accounts',
    route: '/my-accounts',
  },
  {
    iconName: 'menu/ic_transfers.svg',
    title: 'transfers',
    route: '/transfers',
    label: MenuGroupLabel.Transactions,
  },
  {
    iconName: 'menu/ic_users.svg',
    title: 'my_profile',
    route: '/my-profile',
  },
  {
    iconName: 'menu/ic_reports.svg',
    title: 'reports',
    route: '/user-reports',
    subItems: [
      { title: 'specific_account_statement', route: '/user-reports/specific-account-statement' },
      { title: 'all_accounts_transactions', route: '/user-reports/all-accounts-transactions' },
      { title: 'all_accounts_balances', route: '/user-reports/all-accounts-balances' },
    ],
  },
  {
    iconName: 'menu/ic_messages.svg',
    title: 'helpdesk',
    route: '/helpdesk',
  },
];

export const corporateUserGroups: IAsideMenuGroup[] = [
  {
    iconName: 'menu/ic_dashboard.svg',
    title: 'dashboard',
    route: '/dashboard',
  },
  {
    iconName: 'menu/ic_accounts.svg',
    title: 'accounts',
    route: '/my-accounts',
  },
  {
    iconName: 'menu/ic_transfers.svg',
    title: 'transfers',
    route: '/transfers',
    label: MenuGroupLabel.Transactions,
  },
  {
    iconName: 'menu/ic_transfers.svg',
    title: 'group_transfers',
    route: '/group-transfers',
  },
  {
    iconName: 'menu/ic_users.svg',
    title: 'my_profile',
    route: '/my-profile',
  },
  {
    iconName: 'menu/ic_reports.svg',
    title: 'reports',
    route: '/user-reports',
    subItems: [
      { title: 'specific_account_statement', route: '/user-reports/specific-account-statement' },
      { title: 'all_accounts_transactions', route: '/user-reports/all-accounts-transactions' },
      { title: 'all_accounts_balances', route: '/user-reports/all-accounts-balances' },
    ],
  },
  {
    iconName: 'menu/ic_messages.svg',
    title: 'helpdesk',
    route: '/helpdesk',
  },
];

export const individualsUserGroups: IAsideMenuGroup[] = [
  {
    iconName: 'menu/ic_dashboard.svg',
    title: 'dashboard',
    route: '/dashboard',
  },
  {
    iconName: 'menu/ic_accounts.svg',
    title: 'accounts',
    route: '/my-accounts',
  },
  {
    iconName: 'menu/ic_users.svg',
    title: 'my_profile',
    route: '/my-profile',
  },
  {
    iconName: 'menu/ic_reports.svg',
    title: 'reports',
    route: '/user-reports',
    subItems: [
      { title: 'specific_account_statement', route: '/user-reports/specific-account-statement' },
      { title: 'all_accounts_transactions', route: '/user-reports/all-accounts-transactions' },
      { title: 'all_accounts_balances', route: '/user-reports/all-accounts-balances' },
    ],
  },
  {
    iconName: 'menu/ic_messages.svg',
    title: 'helpdesk',
    route: '/helpdesk',
  },
];
