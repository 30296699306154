import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxFileDropModule } from 'ngx-file-drop';
import { TranslateModule } from '@ngx-translate/core';
import { DragAndDropGroupComponent } from '@default-application-app/modules/drag-and-drop/components/drag-and-drop-group/drag-and-drop-group.component';
import { PreviewComponent } from '@default-application-app/modules/drag-and-drop/components/drag-and-drop-group/preview/preview.component';
import { DragAndDropDirective } from '@default-application-app/modules/drag-and-drop/components/drag-and-drop-group/directive/drag-and-drop.directive';
import { IconModule } from '@default-application-app/modules/shared/icon/icon.module';
import { DragAndDropComponent } from '@default-application-app/modules/drag-and-drop/components/drag-and-drop/drag-and-drop.component';
import { DragAndDropInputComponent } from '@default-application-app/modules/drag-and-drop/components/drag-and-drop-input/drag-and-drop-input.component';
import { DragAndDropInputPreviewComponent } from '@default-application-app/modules/drag-and-drop/components/drag-and-drop-input/preview/drag-and-drop-input-preview.component';
import { SvgIconModule } from '@default-application-app/modules/components/svg-icon/svg-icon.module';
import { ApplicationPipesModule } from '@default-application-app/modules/application-pipes/application-pipes.module';
import { TooltipModule } from '@default-application-app/modules/components/tooltip/tooltip.module';

@NgModule({
  declarations: [
    DragAndDropComponent,
    DragAndDropGroupComponent,
    DragAndDropInputComponent,
    PreviewComponent,
    DragAndDropInputPreviewComponent,
    DragAndDropDirective,
  ],
  imports: [
    CommonModule,
    NgxFileDropModule,
    TranslateModule,
    IconModule,
    SvgIconModule,
    ApplicationPipesModule,
    TooltipModule,
  ],
  exports: [DragAndDropComponent, DragAndDropGroupComponent, DragAndDropInputComponent],
})
export class DragAndDropModule {}
