import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { BalanceHelper } from '@default-application-app/core/helpers/balance.helper';
import { Currency } from '@default-application-app/core/models/currency-model';
import { select, Store } from '@ngrx/store';
import { CurrenciesState } from '@default-application-app/components/currencies/reducers';
import { LoadCurrencies } from '@default-application-app/components/currencies/actions/currencies.actions';
import * as FromCurrencies from '@default-application-app/components/currencies/reducers';
import { first, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { once } from '@default-application-app/core/helpers/once.operator';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {
  private currencies: Observable<Currency[]>;

  constructor(@Inject(LOCALE_ID) _locale: string, private store: Store<CurrenciesState>) {
    // @ts-ignore
    this.currencies = this.store.pipe(
      select(FromCurrencies.getCurrencies),
      once((data: Currency[]) => {
        if (!data.length) {
          this.store.dispatch(new LoadCurrencies({ 'page[size]': '0' }));
        }
      }),
    );
  }

  public separateWithCommas(data: string): string {
    if (data === null) {
      data = '0';
    }

    if (data) {
      const parts = data.toString().split('.');

      let result = Intl.NumberFormat('en-US').format(Number(parts[0]));

      if (parts.length > 1) {
        result = `${result}.${parts[1]}`;
      }

      return result;
    }

    return data;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(value: string | number, currencyCode: string, decimalPlaces?: number): Observable<string> {
    return this.currencies.pipe(
      first((data: Currency[]) => !!data.length),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map((data: Currency[]) => {
        const res = data.find((currency) => currency.code === currencyCode);
        // eslint-disable-next-line no-nested-ternary,@typescript-eslint/no-magic-numbers
        const precision = decimalPlaces || (decimalPlaces === 0 ? decimalPlaces : res ? res.decimalPlaces : 2);
        return this.separateWithCommas(BalanceHelper.valueToPrecision(value, precision));
      }),
      take(1),
    );
  }
}
