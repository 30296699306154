<ng-container [formGroup]="form">
  <input
    type="text"
    (focus)="emitFocus(true)"
    (blur)="emitFocus(false)"
    class="search-container__input native-input first-elem"
    [ngClass]="{ 'search-icon-padding': isSearchIcon, 'white-background': isWhiteBackground }"
    [placeholder]="placeholder"
    [formControlName]="controlName" />

  <app-svg-icon *ngIf="isSearchIcon" [iconPath]="'ic_search-new.svg'" class="icon"></app-svg-icon>
</ng-container>
