import { LegalEntityDataInterface, LegalEntityInterface } from '@interfaces/kyc/legal-entity.interface';

export class LegalEntityDataModel implements LegalEntityDataInterface {
  public Id: number;

  ParentLegalEntityId: number;

  legalEntity: LegalEntityModel;

  constructor(data: LegalEntityDataInterface) {
    Object.assign(this, data);
  }
}

export class LegalEntityModel implements LegalEntityInterface {
  public backID: number;

  public certificateOfRegistration: number;

  public commercialCircularOfAuthorityMatrix: number;

  public countryOfResidence: string;

  public dateOfBirth: string;

  public delegateDocument: number;

  public documentNumber: string;

  public documentType: string;

  public entityName: string;

  public fathersName: string;

  public firstName: string;

  public frontID: number;

  public id: number;

  public generalAttestation: number;

  public lastName: string;

  public legalEntityType: string;

  public legalFirstName: string;

  public legalLastName: string;

  public legalForm: string;

  public minutesOfMeetingOfGeneralAssembly: number;

  public minutesOfMeetingOfTheBoardOfDirectors: number;

  public nationality: string;

  public legalByLaws: number;

  public passport: number;

  public percentage: number;

  public phoneNumber: string;

  public representatives: LegalEntityDataModel[];

  public shareHolders: LegalEntityDataModel[];

  public type: string;

  public refinitivCaseId?: string;

  public refinitivCaseSystemId?: string;

  public refinitivReviewRequired?: number;

  public refinitivTotalMatches?: number;

  public refinitivUnresolved?: number;

  public regirstryNumber?: string;
  
  public registryPlace?: string;

  constructor(data: LegalEntityInterface) {
    Object.assign(this, data);
  }
}
