import { HttpClient } from '@angular/common/http';
import { ApiCallerService } from '@default-application-app/core/services/api-caller.service';
import { ConfigService } from '@default-application-app/config.service';
import { Observable } from 'rxjs';
import { CallResponceInterface } from '@default-application-app/core/interfaces/callResponce.interface';
import { Injectable } from '@angular/core';

@Injectable()
export class ApiLanguageService {
  public constructor(
    private http: HttpClient,
    private apiCallerService: ApiCallerService,
    private configService: ConfigService,
  ) {}

  public apiLoadLanguages(): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.language.load),
      'apiLoadLanguage',
    );
  }

  public apiUpdateUserLanguages(language: string): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () => this.http.patch(this.configService.config.api.language.update, { language }),
      'apiSaveUserLanguages',
    );
  }
}
