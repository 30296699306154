import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { catchError, exhaustMap, map, take } from 'rxjs/operators';
import { SiteLanguageActions } from '@default-application-app/actions';
import { DATEPICKER_BASE_OPTIONS } from '@constants/datepickerBaseOptions';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data.min';
import { StorageService } from '@services/storage.service';
import { CallResponceInterface } from '@interfaces/callResponce.interface';
import { ProfileModel } from '@models/profile-model';
import { ApiLanguageService } from '@services/apiLanguageService';
import { ProfileApiService } from '@services/profile/profile-api.service';
import { LanguageInterface } from '@interfaces/language.interface';
import en from 'date-fns/locale/en-US';
import ar from 'date-fns/locale/ar';
import { ArabicLanguage } from '@constants/languages';
import { AuthService } from '@services/auth/auth.service';

export const DatePickerOptionLocales = {
  en,
  ar,
};

@Injectable()
export class SiteLanguageEffects {
  @Effect({ dispatch: false })
  setLanguage = (): Observable<void> =>
    this.actions$.pipe(
      ofType<SiteLanguageActions.SetSiteLanguage>(SiteLanguageActions.SiteLanguageActionsTypes.SET_SITE_LANGUAGE),
      map((action) => {
        this.translate.setDefaultLang(action.language);
        this.translate.use(action.language).subscribe();
        DATEPICKER_BASE_OPTIONS.locale = DatePickerOptionLocales[action.language];
        moment.locale(action.language);
        this.storage.setItem('language', action.language);

        if (action.language === ArabicLanguage && this.authService.isAuthenticated()) {
          const note = document.querySelector('body');
          note.style.direction = 'rtl';
          note.classList.add('rtl');
        } else {
          const note = document.querySelector('body');
          note.style.direction = null;
          note.classList.remove('rtl');
        }
      }),
    );

  @Effect()
  LoadSiteLanguages = (): Observable<Action> =>
    this.actions$.pipe(
      ofType<SiteLanguageActions.LoadSiteLanguages>(SiteLanguageActions.SiteLanguageActionsTypes.LOAD_SITE_LANGUAGES),
      exhaustMap(() =>
        this.apiLanguageService.apiLoadLanguages().pipe(
          take(1),
          map(({ data, error }: CallResponceInterface) => {
            if (error) {
              return new SiteLanguageActions.LoadSiteLanguagesFailure();
            }

            return new SiteLanguageActions.LoadSiteLanguagesSuccess(data as LanguageInterface[]);
          }),
          catchError(() => of(new SiteLanguageActions.LoadSiteLanguagesFailure())),
        ),
      ),
    );

  @Effect()
  SetSiteLanguageFromLocalStorage = (): Observable<Action> =>
    this.actions$.pipe(
      ofType<SiteLanguageActions.SetSiteLanguageFromLocalStorage>(
        SiteLanguageActions.SiteLanguageActionsTypes.SET_SITE_LANGUAGE_FROM_LOCAL_STORAGE,
      ),
      map(() => {
        const language = this.storage.getItem('language');
        if (language) {
          return new SiteLanguageActions.SetSiteFromLocalStorageLanguageSuccess(language);
        }
        return new SiteLanguageActions.SetSiteFromLocalStorageLanguageFailure();
      }),
    );

  @Effect()
  SetSiteFromLocalStorageLanguageSuccess = (): Observable<Action> =>
    this.actions$.pipe(
      ofType<SiteLanguageActions.SetSiteFromLocalStorageLanguageSuccess>(
        SiteLanguageActions.SiteLanguageActionsTypes.SET_SITE_LANGUAGE_FROM_LOCAL_STORAGE_SUCCESS,
      ),
      map((action) => new SiteLanguageActions.SetSiteLanguage(action.language)),
    );

  @Effect()
  LoadUserLanguage = (): Observable<Action> =>
    this.actions$.pipe(
      ofType<SiteLanguageActions.LoadUserLanguage>(SiteLanguageActions.SiteLanguageActionsTypes.LOAD_USER_LANGUAGE),
      exhaustMap(() =>
        this.profileApiService.apiLoadMyProfile().pipe(
          take(1),
          map(({ data, error }: CallResponceInterface) => {
            if (error) {
              return new SiteLanguageActions.LoadUserLanguageFailure();
            }
            return new SiteLanguageActions.LoadUserLanguageSuccess((data as ProfileModel).language);
          }),
          catchError(() => of(new SiteLanguageActions.SetSiteLanguageFromLocalStorage())),
        ),
      ),
    );

  @Effect()
  LoadUserLanguageSuccess = (): Observable<Action> =>
    this.actions$.pipe(
      ofType<SiteLanguageActions.LoadUserLanguageSuccess>(
        SiteLanguageActions.SiteLanguageActionsTypes.LOAD_USER_LANGUAGE_SUCCESS,
      ),
      map((action) => new SiteLanguageActions.SetSiteLanguage(action.language)),
    );

  @Effect()
  SaveUserLanguage = (): Observable<Action> =>
    this.actions$.pipe(
      ofType<SiteLanguageActions.SaveUserLanguage>(SiteLanguageActions.SiteLanguageActionsTypes.SAVE_USER_LANGUAGE),
      exhaustMap((action) =>
        this.apiLanguageService.apiUpdateUserLanguages(action.language).pipe(
          take(1),
          map(({ error }: CallResponceInterface) => {
            if (error) {
              return new SiteLanguageActions.SaveUserLanguageFailure();
            }
            return new SiteLanguageActions.SaveUserLanguageSuccess(action.language);
          }),
          catchError(() => of(new SiteLanguageActions.SaveUserLanguageFailure())),
        ),
      ),
    );

  @Effect()
  SaveUserLanguageSuccess = (): Observable<Action> =>
    this.actions$.pipe(
      ofType<SiteLanguageActions.SaveUserLanguageSuccess>(
        SiteLanguageActions.SiteLanguageActionsTypes.SAVE_USER_LANGUAGE_SUCCESS,
      ),
      map((action) => new SiteLanguageActions.SetSiteLanguage(action.language)),
    );

  constructor(
    private actions$: Actions,
    private readonly translate: TranslateService,
    private storage: StorageService,
    private profileApiService: ProfileApiService,
    private authService: AuthService,
    private apiLanguageService: ApiLanguageService,
  ) {}
}
