import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ProfileActions } from '@default-application-app/components/profiles/actions';
import { ProfileActionTypes } from '@default-application-app/components/profiles/actions/profile.actions';
import { ApiError } from '@models/api-error.model';
import { ProfileFilter } from '@interfaces/profile-filter-interface';
import { PaginationPageLimitInterface } from '@interfaces/pagination-page-limit.interface';
import { PaginationService } from '@services/pagination/pagination.service';
import { ProfileModel } from '@models/profile-model';

export interface State extends EntityState<ProfileModel> {
  selectedProfileId: string | null;
  loading: boolean;
  saved: boolean;
  errors: ApiError[];
  filter: ProfileFilter;
  pagination: PaginationPageLimitInterface;
}

export const defaultProfileFilter: ProfileFilter = {
  sort: '-created_at',
  page: '1',
  limit: PaginationService.defaultLimit.toString(),
};

export const adapter: EntityAdapter<ProfileModel> = createEntityAdapter<ProfileModel>({
  selectId: (profile: ProfileModel) => profile.uid,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  selectedProfileId: null,
  loading: false,
  saved: false,
  errors: [],
  filter: defaultProfileFilter,
  pagination: PaginationService.defaultPaginationPageLimit,
});

// eslint-disable-next-line @typescript-eslint/default-param-last
export function reducer(state = initialState, action: ProfileActions.ProfileActionsUnion): State {
  switch (action.type) {
    case ProfileActionTypes.LOAD_PROFILES:
      return {
        ...state,
        filter: action.filter ? action.filter : state.filter,
        loading: true,
        errors: [],
      };
    case ProfileActionTypes.LOAD_PROFILES_SUCCESS:
      return {
        ...adapter.setAll(action.profiles, state),
        pagination: { ...(action.pagination || PaginationService.defaultPaginationPageLimit) },
        loading: false,
      };
    case ProfileActionTypes.CREATE_PROFILE:
    case ProfileActionTypes.SAVE_PROFILE:
      return {
        ...state,
        loading: true,
        saved: false,
        errors: [],
      };
    case ProfileActionTypes.CREATE_PROFILE_SUCCESS:
      return {
        ...adapter.addOne(action.profile, state),
        loading: false,
        saved: true,
        errors: [],
      };
    case ProfileActionTypes.SAVE_PROFILE_SUCCESS:
      return {
        ...adapter.updateOne(
          {
            id: action.profile.uid,
            changes: action.profile,
          },
          state,
        ),
        loading: false,
        saved: true,
        errors: [],
      };
    case ProfileActionTypes.LOAD_PROFILES_FAILURE:
    case ProfileActionTypes.CREATE_PROFILE_FAILURE:
    case ProfileActionTypes.SAVE_PROFILE_FAILURE:
      return {
        ...adapter.removeAll(state),
        loading: false,
        saved: false,
        pagination: { ...PaginationService.defaultPaginationPageLimit },
        errors: action.errors,
      };
    case ProfileActionTypes.UNBLOCK_PROFILES:
      return {
        ...state,
        loading: true,
        errors: [],
      };
    case ProfileActionTypes.UNBLOCK_PROFILES_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case ProfileActionTypes.RESET_SAVED_STATE:
      return {
        ...state,
        saved: false,
        errors: [],
      };
    case ProfileActionTypes.TOGGLE_CHECKED:
      return adapter.updateOne(
        {
          id: action.profile.uid,
          changes: { ...action.profile, isChecked: action.checked },
        },
        state,
      );
    default: {
      return state;
    }
  }
}

const { selectAll } = adapter.getSelectors();

export const selectAllProfiles = selectAll;

export const getSelectedId = (state: State) => state.selectedProfileId;

export const getLoading = (state: State) => state.loading;

export const getSaved = (state: State) => state.saved;

export const getError = (state: State) => state.errors;

export const getProfilesFilter = (state: State) => state.filter;

export const getProfilesPagination = (state: State) => state.pagination;
