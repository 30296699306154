import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[trimSymbols]',
})
export class TrimSymbolsDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const maxSymbols: number = 60000;

    if (value.length > maxSymbols) {
      this.el.nativeElement.value = value.substring(0, maxSymbols);
      this.el.nativeElement.dispatchEvent(new Event('input'));
    }
  }
}
