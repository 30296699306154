import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NotificationsServiceWithTranslate } from '@services/translate/notificationsServiceWithTranslate';
import { AuthService } from '@services/auth/auth.service';

@Injectable()
export class RolesGuard implements CanActivate {
  constructor(
    private notificationService: NotificationsServiceWithTranslate,
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const { roles } = route.data;

    if (!roles.includes(this.authService.getUserRole())) {
      this.router.navigate([route.data.redirectTo || 'no-permission']);
      return false;
    }

    return true;
  }
}
