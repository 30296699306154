import { Pipe, PipeTransform } from '@angular/core';
import { COUNTRIES } from '@default-application-app/core/constants/countries';

@Pipe({
  name: 'countries',
})
export class CountriesPipe implements PipeTransform {
  // eslint-disable-next-line consistent-return
  transform(code: string): string {
    if (code.length > 0) {
      const countries = COUNTRIES;
      return countries.find((country) => code === country.code).name;
    }
  }
}
