<ng-container [formGroup]="form">
  <div class="input-header">
    <label *ngIf="label" class="input-header__label">
      {{ label }}
      <div class="input-header__tooltip">
        <app-tooltip *ngIf="tooltipMessage">{{ tooltipMessage }}</app-tooltip>
      </div>
    </label>
  </div>

  <ng-select
    class="simple"
    [ngClass]="{
      'ng-select': isNgSelectClass,
      'native-select': isNativeSelectClass,
      searchable: isSearchableClass,
      'searchable-address': isSearchableAddressClass,
      'select-month': isSelectMonthClass
    }"
    [loading]="loading"
    [title]="title"
    [searchable]="searchable"
    [clearable]="clearable"
    [readonly]="readonly"
    [placeholder]="placeholder"
    [items]="items"
    [multiple]="isMultiple"
    [closeOnSelect]="!isMultiple"
    [bindValue]="bindValue"
    [bindLabel]="bindLabel"
    [groupBy]="groupBy"
    (search)="search.emit($event)"
    [formControlName]="controlName"
    appErrorHandle
    [requiredIf]="requiredIf"
    [typeahead]="typeahead"
    (ngModelChange)="change.emit($event)">
    <ng-template ng-optgroup-tmp let-item="item"> &#8213; </ng-template>

    <ng-container *ngIf="isMultiple && translatePrefix">
      <ng-template ng-label-tmp let-item="item">
        <span class="multiselect-value">{{ translatePrefix + '.' + item.value | translate: getTranslateParams(item) }}{{ isLastElement(item.key) ? '' : ', ' }}</span>
      </ng-template>

      <ng-template ng-option-tmp let-item="item">
        <div class="option-with-select">
          <div class="icon-select-wrapper">
            <app-svg-icon *ngIf="isSelected(item.key)" iconPath="ic_check.svg"></app-svg-icon>
          </div>

          <span>{{ translatePrefix + '.' + item.value | translate: getTranslateParams(item) }}</span>
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="translatePrefix">
      <ng-template ng-label-tmp let-item="item">
        {{ translatePrefix + '.' + item.value | translate: getTranslateParams(item) }}
      </ng-template>

      <ng-template ng-option-tmp let-item="item">
        {{ translatePrefix + '.' + item.value | translate: getTranslateParams(item) }}
      </ng-template>
    </ng-container>

    <ng-container *ngIf="isMultiple">
      <ng-template ng-label-tmp let-item="item">
        <span class="multiselect-value">{{ item.value }}{{ isLastElement(item.key) ? '' : ', ' }}</span>
      </ng-template>

      <ng-template ng-option-tmp let-item="item">
        <div class="option-with-select">
          <div class="icon-select-wrapper">
            <app-svg-icon *ngIf="isSelected(item.key)" iconPath="ic_check.svg"></app-svg-icon>
          </div>

          <span>{{ item.value }}</span>
        </div>
      </ng-template>
    </ng-container>
  </ng-select>
  <ng-content></ng-content>
</ng-container>
