import { RequestNotificationStatus } from '@default-application-app/modules/request-notifications/models/request-notification-status';
import { RequestNotificationType } from '@default-application-app/modules/request-notifications/models/request-notification-type';

export interface RequestNotificationInterface {
  id: number;
  uid: string;
  type: RequestNotificationType;
  entityId: string;
  status: RequestNotificationStatus;
  description: string;
  createdAt: string;
  updatedAt: string;
}

export class RequestNotification implements RequestNotificationInterface {
  public id: number;

  public uid: string;

  public type: RequestNotificationType;

  public entityId: string;

  public status: RequestNotificationStatus;

  public description: string;

  public createdAt: string;

  public updatedAt: string;

  public constructor(params: any) {
    Object.assign(this, params);
  }
}
