import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-aside-menu-group-compact',
  templateUrl: './aside-menu-group-compact.component.html',
  styleUrls: ['./aside-menu-group-compact.component.scss'],
})
export class AsideMenuGroupCompactComponent {
  @Input()
  public iconName: string;

  @Input()
  public title: string;

  @Input()
  public route: string;

  constructor(private router: Router) {}

  public open(): void {
    this.router.navigate([this.route]);
  }
}
