import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@services/auth/auth.service';
import { OptionsPaths } from '@constants/optionsPrefixes';
import { takeUntil } from 'rxjs/operators';
import { CallResponceInterface } from '@interfaces/callResponce.interface';
import { UnsubscribeDestroyHelper } from '@helpers/unsubscribe-destroy.helper';
import { OptionsApiService } from '@services/options/options-api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LegalDocumentsService } from '@services/legal-documents/legal-documents.service';
import { ProfileService } from '@services/profile/profile.service';
import { NgxSpinnerService } from 'ngx-spinner';

const PRIVACY_POLICY: string = 'privacy_policy_text';
const TERMS_AND_CONDITIONS: string = 'terms_and_conditions_text';

@Component({
  selector: 'app-accept-documents-modal',
  templateUrl: './accept-documents-modal.component.html',
  styleUrls: ['./accept-documents-modal.component.scss'],
})
export class AcceptDocumentsModalComponent extends UnsubscribeDestroyHelper {
  public form: FormGroup;

  public privacyPolicy: string;

  public termsAndConditions: string;

  public isShowPrivacy: boolean = false;

  public isShowTerms: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<AcceptDocumentsModalComponent>,
    private authService: AuthService,
    private optionsApiService: OptionsApiService,
    private fb: FormBuilder,
    private legalDocumentsService: LegalDocumentsService,
    private profileService: ProfileService,
    private spinner: NgxSpinnerService,
  ) {
    super();
    this.loadData();
    this.form = this.fb.group({
      privacyConsent: [false, Validators.requiredTrue],
      tacConsent: [false, Validators.requiredTrue],
    });
  }

  public showPrivacy(): void {
    this.isShowPrivacy = !this.isShowPrivacy;
  }

  public showTerms(): void {
    this.isShowTerms = !this.isShowTerms;
  }

  private loadData(): void {
    this.optionsApiService
      .apiLoadOptions(`${OptionsPaths.REGIONAL_LEGAL_DOCS}`, false)
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((resp: CallResponceInterface) => {
        if (!resp.error) {
          const data: Array<{ path: string; value: string; scope: string }> = resp.data as Array<{
            path: string;
            value: string;
            scope: string;
          }>;

          this.privacyPolicy = data.find((item) => item.path.includes(PRIVACY_POLICY)).value;
          this.termsAndConditions = data.find((item) => item.path.includes(TERMS_AND_CONDITIONS)).value;
        }
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public logout(): void {
    this.closeDialog();
    this.authService.logOut();
  }

  public accept(): void {
    this.spinner.show();
    this.legalDocumentsService
      .acceptPrivacyPolicy(this.profileService.profile.uid)
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((resp1) => {
        if (!resp1.error) {
          this.legalDocumentsService
            .acceptTaC(this.profileService.profile.uid)
            .pipe(takeUntil(this.unsubscribeSubject))
            .subscribe((resp2) => {
              if (!resp2.error) {
                this.closeDialog();
              }

              this.spinner.hide();
            });
        } else {
          this.spinner.hide();
        }
      });
  }
}
