import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fillArray',
})
export class FillArrayPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(value: any, args?: any): any {
    return new Array(value).fill(1);
  }
}
