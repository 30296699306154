import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { UnsubscribeDestroyHelper } from '@default-application-app/core/helpers/unsubscribe-destroy.helper';
import { merge, Observable } from 'rxjs';
import { ApiErrorService } from '@default-application-app/core/services/apiError.service';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[scroll-top-error]',
})
export class ScrollFormErrorDirective extends UnsubscribeDestroyHelper implements AfterViewInit {
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private apiErrorService: ApiErrorService,
    private controlContainer: ControlContainer,
  ) {
    super();
  }

  private getFormSubmitPipe(): Observable<any> {
    return (this.controlContainer.formDirective as FormGroupDirective).ngSubmit;
  }

  ngAfterViewInit() {
    merge(this.getFormSubmitPipe(), this.apiErrorService.errorsFormSubject)
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(() => {
        this.seekErrorField();
      });
  }

  private cursorFocus(fields): void {
    if (!fields || fields.length < 1) {
      return;
    }

    const topErrorField = fields[0];

    topErrorField.focus();
    topErrorField.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  }

  private seekErrorField(): void {
    setTimeout(() => {
      const fields = this.elementRef.nativeElement.getElementsByClassName('error-field');
      this.cursorFocus(fields);
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    }, 100);
  }
}
