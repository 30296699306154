import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '@services/token-service';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent {
  constructor(
    private router: Router,
    private tokenService: TokenService,
    private sessionStorage: SessionStorageService,
  ) {
    setTimeout(() => {
      this.tokenService.removeToken();
      this.sessionStorage.removeItem('step');
      router.navigate(['/sign-in/business']);
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    }, 5000);
  }
}
