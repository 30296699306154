<ng-container *ngIf="!isMessageFile">
  <div class="input-header">
    <label *ngIf="label" class="input-header__label">
      {{ label }}

      <span class="input-header__label-optional" *ngIf="isOptional">({{ 'common.labels.optional' | translate }})</span>

      <div class="input-header__tooltip">
        <app-tooltip *ngIf="tooltipMessage" [isRightPosition]="isRightPosition" [isRadio]="true">{{
          tooltipMessage
        }}</app-tooltip>
      </div>
    </label>
  </div>

  <div (onFileDropped)="uploadFiles($event)" [disabled]="disabled" appDragDrop class="drag-and-drop-group">
    <app-drag-and-drop-input-preview
      (deleteFile)="deleteFile($event)"
      [description]="description"
      [descriptionForUploadedFile]="descriptionForUploadedFile"
      [disabled]="disabled"
      [extension]="extension"
      [files]="fileWrappers"
      [isFileName]="isFileName"
      [fileUrl]="fileUrl"
      [maxSize]="maxSize"
      class="drag-and-drop"></app-drag-and-drop-input-preview>

    <label *ngIf="!disabled" class="label">
      <input
        (change)="uploadFiles($event.target['files']); onInputBlur()"
        [accept]="extension"
        *ngIf="!isRemoveFile"
        hidden
        multiple
        type="file" />

      <div class="open-file" *ngIf="isRemoveFile" (click)="openFileInNewWindow()"></div>

      <app-svg-icon
        iconPath="ic_x-circle.svg"
        *ngIf="isRemoveFile"
        (click)="remove($event)"
        class="icon-remove"></app-svg-icon>
    </label>
  </div>

  <div class="bottom-description" *ngIf="bottomDescription">
    {{ bottomDescription }}
  </div>

  <div *ngIf="isBigSizeError && !fileUrl && !fileWrappers[0]?.preview" class="error-size">
    {{ 'errors.max' | translate: { max: maxSizeLabel } }}
  </div>
</ng-container>

<div *ngIf="isMessageFile" class="message">
  <div (onFileDropped)="uploadFiles($event)" [disabled]="disabled" appDragDrop class="drag-and-drop-group">
    <label *ngIf="!disabled" class="label">
      <app-svg-icon iconPath="ic_attach.svg" class="icon-attach"></app-svg-icon>
      <input
        (change)="uploadFiles($event.target['files']); onInputBlur()"
        [accept]="extension"
        hidden
        #fileInput
        multiple
        type="file" />
    </label>
  </div>
</div>
