import en from 'date-fns/locale/en-US';

export const DATEPICKER_RESPONSE_FORMAT = 'YYYY-MM-DD';

export const DATEPICKER_BASE_OPTIONS = {
  format: DATEPICKER_RESPONSE_FORMAT,
  firstCalendarDay: 1,
  formatDays: 'EEEEEE',
  maxDate: new Date(),
  minYear: 1900,
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  minDate: new Date(new Date().getFullYear() - 123, 0),
  inputClass: 'ngx-datepicker-input',
  calendarClass: 'ngx-datepicker-container',
  locale: en,
};
