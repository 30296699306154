import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders as Headers, HttpResponse} from '@angular/common/http';
import { ApiCallerService } from '@services/api-caller.service';
import { ConfigService } from '@default-application-app/config.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StringifyOptions } from 'querystring';

@Injectable()
export class UserGroupTransfersApiService {
  constructor(
    private http: HttpClient,
    private apiCallerService: ApiCallerService,
    private configService: ConfigService,
  ) {}

  /**
   * Load single account
   *
   * @param {number} userId
   * @returns {Observable<any>}
   */

  public apiGetUserGroupTransfers(userId: string): Observable<any> {
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.groupTransfers.getGroupTransfersForUser(userId)),        
      'apiGetUserGroupTransfers',
    );
  }

  public apiGetUserGroupTransfersById(groupId: string): Observable<any> {
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.groupTransfers.getUserGroupTransfersById(groupId)),
      'apiGetUserGroupTransfersById',
    );
  }
  

  public apiCreateGroupTransfer(userId: string) {
    return this.apiCallerService.call(
      () =>
        this.http.post(this.configService.config.api.groupTransfers.createGroupTransfer,
          {userId: userId}
        ),
      'apiCreateGroupTransfer',
    );
  }

  public apiUpdateGroupTransfer(groupId: string, details: {}) {
    return this.apiCallerService.call(
      () =>
        this.http.post(this.configService.config.api.groupTransfers.updateGroupTransfer, {groupId: groupId, details: details}),
      'apiUpdateGroupTransfer',
    );
  }

  public apiGetGroupSubTransfers(groupId: string): Observable<any> {
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.groupTransfers.getGroupSubTransfers(groupId)),
      'apiGetGroupSubTransfers',
    )
  };

  public apiSubmitGroupTransfer(groupId: string, otp: string): Observable<any> {
    const headers = {};
    headers['X-OTP'] = otp;
    const body = {
      groupId: groupId,
      otpValue: otp
    };
    return this.apiCallerService.call(
      () => this.http.post(this.configService.config.api.groupTransfers.submitGroupTransfer, body, { headers }),
      'apiSubmitGroupTransfer'
    );
  }

  public apiUpdateGroupTransferStatus(groupId: string, status: string): Observable<any> {
    const body = {
      groupId: groupId,
      status: status
    };
    return this.apiCallerService.call(
      () => this.http.post(this.configService.config.api.groupTransfers.updateGroupTransferStatus, body),
      'apiUpdateGroupTransferStatus'
    );
  }

  public apiUpdateGroupTotalAmount(groupId: string, totalAmount: number): Observable<any> {
    return this.apiCallerService.call(
      () => this.http.post(this.configService.config.api.groupTransfers.updateGroupTotalAmount, {groupId, totalAmount}),
      'apiUpdateGroupTotalAmount',
    )
  };
  
public apiAddSubTransfer(data: any): Observable<any> {
    return this.apiCallerService.call(
      () => this.http.post(this.configService.config.api.groupTransfers.addSubTransfer, {data}),
      'apiAddSubTransfers',
    )
  }

  public apiDeleteGroupTransfer(groupId: string): Observable<any> {
    return this.apiCallerService.call(
      () => this.http.post(this.configService.config.api.groupTransfers.deleteGroupTransfer,{groupId: groupId}),
      'apiDeleteGroupTransfer',
    );
  }

  public apiDeleteSubGroupTransfer(subtransferId: string): Observable<any> {
    return this.apiCallerService.call(
      () => this.http.post(this.configService.config.api.groupTransfers.deleteSubGroupTransfer,{subtransferId: subtransferId}),
      'apiDeleteSubGroupTransfer',
    );
  }
}
