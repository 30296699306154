import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ErrorCodes } from '@default-application-app/core/constants/errorCodes';
import { AccountInterface } from '@default-application-app/core/interfaces/account-interface';
import { removeErrorFromControl } from '@default-application-app/core/helpers/removeErrorFromControl';

export function sufficientAmountValidatorFn(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const accountFrom: AccountInterface = control.get('accountFrom').value;
    const outgoingAmount: number = parseFloat(control.get('outgoingAmount').value);
    if (
      !Number.isNaN(outgoingAmount) &&
      accountFrom &&
      outgoingAmount &&
      outgoingAmount > accountFrom.availableAmount
    ) {
      control.get('outgoingAmount').setErrors({ [ErrorCodes.INSUFFICIENT_FUNDS]: 'insufficient amount' });
      return { [ErrorCodes.INSUFFICIENT_FUNDS]: 'insufficient amount' };
    }
    removeErrorFromControl(control.get('outgoingAmount'), ErrorCodes.INSUFFICIENT_FUNDS);
    return null;
  };
}
