import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  styleUrls: ['./modal-wrapper.component.scss'],
})
export class ModalWrapperComponent {
  @Input()
  public title: string;

  @Input()
  public isCancelIcon: boolean = false;

  @Input()
  public isCenterIcon: boolean = false;

  @Input()
  public isHideIcon: boolean = false;

  @Input()
  public titleIconName: string = 'plus';

  constructor(private dialog: MatDialog) {}

  public close(): void {
    this.dialog.closeAll();
  }
}
