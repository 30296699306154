export enum RequestNotificationType {
  invoiceIncoming = 'invoice_incoming',
  invoiceOutgoing = 'invoice_outgoing',
  invoiceExpired = 'invoice_expired',
  invoiceCancelled = 'invoice_cancelled',
  transfer = 'transfer',
  transferIncoming = 'transfer_incoming',
  transferOutgoing = 'transfer_outgoing',
  refundRequested = 'refund_requested',
  refundIssued = 'refund_issued',
  moneyRequestIncoming = 'money_request_incoming',
  cashInRequestCreated = 'cash_in_request_created',
  cashInRequestCancelled = 'cash_in_request_cancelled',
  cashOutRequestCreated = 'cash_out_request_created',
  cashOutRequestCancelled = 'cash_in_request_cancelled',
}
