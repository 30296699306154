<button
  [type]="type"
  (click)="onClick()"
  [disabled]="isDisabled"
  class="button"
  [title]="title"
  [class.primary]="appearance === 'primary'"
  [class.primary-outline]="appearance === 'primary-outline'"
  [class.secondary]="appearance === 'secondary'"
  [class.danger]="appearance === 'danger'"
  [class.gray]="appearance === 'gray'"
  [class.danger-outline]="appearance === 'danger-outline'"
  [class.big-font-size]="isBigFontSize"
  [class.row-reverse]="isRowReverse"
  [class.smaller]="size === 'smaller'">
  <div class="icon-wrapper" *ngIf="iconPath">
    <app-svg-icon class="icon" [iconPath]="iconPath"></app-svg-icon>
  </div>

  <span class="btn-text">{{ buttonText }}</span>
  <ng-content></ng-content>
</button>
