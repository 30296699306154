import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  template: ` <ngx-spinner
    [fullScreen]="false"
    [name]="name"
    bdColor="rgba(255,255,255,0.8)"
    size="medium"
    color="#229932"
    type="ball-clip-rotate"></ngx-spinner>`,
})
export class LocalSpinnerComponent {
  public name;

  constructor(private spinner: NgxSpinnerService) {}

  public setName(name: string) {
    this.name = name;
  }

  public show() {
    this.spinner.show(this.name);
  }

  public hide() {
    this.spinner.hide(this.name);
  }
}
