import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { UserPermissionsDirective } from '@default-application-app/core/directives/user-permissions.directive';
import { CommonModule } from '@angular/common';
import { ApplicationPipesModule } from '@default-application-app/modules/application-pipes/application-pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckEmptyTableDirective } from '@default-application-app/core/directives/check-empty-table.directive';
import { RequiredIfDirective } from '@default-application-app/core/directives/requiredIf.directive';
import { ClickOutsideDirective } from '@default-application-app/core/directives/click-outside.directive';
import { DisableControlDirective } from '@default-application-app/core/directives/disableControl.directive';
import { PaginationComponent } from '@default-application-app/core/shared/pagination/pagination.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ItemsPerPageComponent } from '@default-application-app/core/shared/items-per-page/items-per-page.component';
import { OnlyNumberDirective } from '@default-application-app/modules/transfer/directives/only-number.directive';
import { PreventEmptyStringDirective } from '@default-application-app/core/directives/prevent-empty-string.directive';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { DatepickerInZoneDirective } from '@default-application-app/core/directives/datepickerInZone.directive';
import { ClickStopPropagationDirective } from '@default-application-app/core/directives/clickStopPropagation.directive';
import { TextMaskModule } from 'angular2-text-mask';
import { DatepickerModule } from 'ng2-datepicker';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MobileClickHideDirective } from '@default-application-app/core/directives/mobile-click-hide.directive';
import { DragAndDropModule } from '@default-application-app/modules/drag-and-drop/drag-and-drop.module';
import { SortHeaderDirective } from '@default-application-app/core/directives/sortHeader.directive';
import { DateToFromComponent } from '@default-application-app/components/date-to-from/date-to-from.component';
import { QuerySearchComponent } from '@default-application-app/components/query-search/query-search.component';
import { PageSizeComponent } from '@default-application-app/components/page-size/page-size.component';
import { FormPaginationComponent } from '@default-application-app/components/form-pagination/form-pagination.component';
import { RouterModule } from '@angular/router';
import { DateToComponent } from '@default-application-app/components/date-to/date-to.component';
import { ErrorHandleDirective } from '@default-application-app/core/directives/errorHandle.directive';
import { UserSearchFieldComponent } from '@default-application-app/components/user-search/user-search-field.component';
import { ActiveModuleDirective } from '@default-application-app/core/directives/activeModule.directive';
import { OnlyFloatDirective } from '@default-application-app/core/directives/onlyFloat.directive';
import { TranslateModule } from '@ngx-translate/core';
import { ActiveModuleHideBlockDirective } from '@default-application-app/core/directives/active-module-hide-block.directive';
import { ShowPasswordDirective } from '@default-application-app/core/directives/showPassword.directive';
import { TableRowTemplateDirective } from '@default-application-app/core/directives/table-row-template.directive';
import { TableHeaderTemplateDirective } from '@default-application-app/core/directives/table-header-template.directive';
import { TabsTemplateDirective } from '@default-application-app/core/directives/for-customization/tabs-template-directive';
import { ShowOnlyIfRootDirective } from '@default-application-app/core/directives/show-only-if-root.directive';
import { PreviousRouteDirective } from '@default-application-app/core/directives/previous-route.directive';
import { ScrollFormErrorDirective } from '@default-application-app/core/directives/scroll-form-error.directive';
import { PrintComponent } from '@default-application-app/components/print/print-component';
import { HideAutoCompleteDirective } from '@default-application-app/core/directives/hide-auto-complete.directive';
import { ConfirmDeletePopupModule } from '@default-application-app/modules/shared/confirm-delete-popup/confirm-delete-popup.module';
import { ZeroValidatorDirective } from '@default-application-app/core/directives/template-driven-validators/zero-validator.directive';
import { DatePickerButtonsComponent } from '@default-application-app/components/date-picker-buttons/date-picker-buttons.component';
import { DisableAfterClickDirective } from '@default-application-app/core/directives/disable-after-click.directive';
import { LocalSpinnerComponent } from '@default-application-app/components/spinner/local-spinner.component';
import { ViewPermissionsDirective } from '@default-application-app/core/directives/view-permissions.directive';
import { NoPermissionComponent } from '@layouts/error/no-permission/no-permission.component';
import { ToUpperCaseDirective } from '@default-application-app/core/directives/to-upper-case.directive';
import { LogoComponent } from '@default-application-app/modules/shared-components/logo/logo.component';
import { MainContainerComponent } from '@default-application-app/modules/shared-components/main-container/main-container.component';
import { DescriptionComponent } from '@default-application-app/modules/shared-components/desccription/description.component';
import { SuccessComponent } from '@default-application-app/modules/shared-components/success/success.component';
import { IconModule } from '@default-application-app/modules/shared/icon/icon.module';
import { DropdownMenuComponent } from '@default-application-app/components/dropdown-menu/dropdown-menu.component';
import { FilterCurrencySelectComponent } from '@default-application-app/components/filter-currency-select/filter-currency-select.component';
import { InputDateComponent } from '@default-application-app/components/input-date/input-date.component';
import { CodeInputComponent } from '@default-application-app/components/code-input/code-input.component';
import { TopPanelActionIconsComponent } from '@default-application-app/components/top-panel-action-icons/top-panel-action-icons.component';
import { ModalDragAndDropComponent } from '@default-application-app/components/modal-drag-and-drop/modal-drag-and-drop.component';
import { UploadFileToProfileComponent } from '@default-application-app/components/upload-file-to-profile/upload-file-to-profile.component';
import { NgxColorsComponent } from '@default-application-app/components/ngx-colors/ngx-colors.component';
import { NgxColorsModule } from 'ngx-colors';
import { ElementHeightDirective } from '@default-application-app/core/directives/element-height.directive';
import { ComingSoonComponent } from '@default-application-app/modules/shared-components/coming-soon/coming-soon.component';
import { SvgIconModule } from '@default-application-app/modules/components/svg-icon/svg-icon.module';
import { SortSelectComponent } from '@components/sort-select/sort-select.component';
import { TextBoldDirective } from '@default-application-app/core/directives/text-bold.directive';
import { MakeActiveInputDirective } from '@default-application-app/core/directives/make-active-input.directive';
import { SelectComponent } from '@components/select/select.component';
import { InputComponent } from '@components/input/input.component';
import { UserOptionsFieldDisabledDirective } from '@default-application-app/core/directives/user-options-field-disabled.directive';
import { InputPasswordComponent } from '@components/input-password/input-password.component';
import { InputWithCurrencyMaskComponent } from '@components/input-with-currency-mask/input-with-currency-mask.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { PhoneMaskDirective } from '@default-application-app/core/directives/phone-mask.directive';
import { TooltipModule } from '@default-application-app/modules/components/tooltip/tooltip.module';
import { NameSymbolDirective } from '@default-application-app/core/directives/name-symbol.directive';
import { NameWithNumberSymbolDirective } from '@default-application-app/core/directives/name-with-number-symbol.directive';
import { TrimSymbolsDirective } from '@default-application-app/core/directives/trim-symbols.directive';

// @ts-ignore
@NgModule({
  declarations: [
    UserPermissionsDirective,
    NoPermissionComponent,
    ViewPermissionsDirective,
    CheckEmptyTableDirective,
    RequiredIfDirective,
    ClickOutsideDirective,
    DisableControlDirective,
    PaginationComponent,
    ItemsPerPageComponent,
    OnlyNumberDirective,
    DatepickerInZoneDirective,
    PreventEmptyStringDirective,
    ClickStopPropagationDirective,
    PreviousRouteDirective,
    MobileClickHideDirective,
    NameSymbolDirective,
    NameWithNumberSymbolDirective,
    SortHeaderDirective,
    SortSelectComponent,
    SelectComponent,
    DatePickerButtonsComponent,
    DateToFromComponent,
    QuerySearchComponent,
    PageSizeComponent,
    NgxColorsComponent,
    TopPanelActionIconsComponent,
    FormPaginationComponent,
    DateToComponent,
    ErrorHandleDirective,
    UserSearchFieldComponent,
    PrintComponent,
    LocalSpinnerComponent,
    OnlyFloatDirective,
    TrimSymbolsDirective,
    PhoneMaskDirective,
    ShowOnlyIfRootDirective,
    ActiveModuleHideBlockDirective,
    ActiveModuleDirective,
    ShowPasswordDirective,
    HideAutoCompleteDirective,
    TabsTemplateDirective,
    TableRowTemplateDirective,
    TableHeaderTemplateDirective,
    ScrollFormErrorDirective,
    ZeroValidatorDirective,
    DisableAfterClickDirective,
    ToUpperCaseDirective,
    UserOptionsFieldDisabledDirective,
    LogoComponent,
    SuccessComponent,
    MainContainerComponent,
    DescriptionComponent,
    DropdownMenuComponent,
    FilterCurrencySelectComponent,
    InputDateComponent,
    CodeInputComponent,
    ModalDragAndDropComponent,
    UploadFileToProfileComponent,
    ElementHeightDirective,
    ComingSoonComponent,
    TextBoldDirective,
    InputComponent,
    InputWithCurrencyMaskComponent,
    InputPasswordComponent,
    MakeActiveInputDirective,
  ],
  imports: [
    IconModule,
    ConfirmDeletePopupModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgSelectModule,
    ApplicationPipesModule,
    NgxTrimDirectiveModule,
    TextMaskModule,
    DatepickerModule,
    NgxPaginationModule,
    DragAndDropModule,
    NgxSpinnerModule,
    TranslateModule,
    SvgIconModule,
    CurrencyMaskModule,
    TooltipModule,
  ],
  exports: [
    ConfirmDeletePopupModule,
    UserPermissionsDirective,
    NoPermissionComponent,
    ViewPermissionsDirective,
    CheckEmptyTableDirective,
    RequiredIfDirective,
    ClickOutsideDirective,
    DisableControlDirective,
    PaginationComponent,
    ItemsPerPageComponent,
    OnlyNumberDirective,
    PreventEmptyStringDirective,
    DatepickerInZoneDirective,
    ClickStopPropagationDirective,
    PreviousRouteDirective,
    NgxTrimDirectiveModule,
    TextMaskModule,
    DatepickerModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    MobileClickHideDirective,
    NameSymbolDirective,
    NameWithNumberSymbolDirective,
    SortHeaderDirective,
    SortSelectComponent,
    SelectComponent,
    InputComponent,
    InputPasswordComponent,
    InputWithCurrencyMaskComponent,
    UserOptionsFieldDisabledDirective,
    DatePickerButtonsComponent,
    DateToFromComponent,
    QuerySearchComponent,
    PageSizeComponent,
    NgxColorsComponent,
    TopPanelActionIconsComponent,
    FormPaginationComponent,
    DateToComponent,
    ErrorHandleDirective,
    UserSearchFieldComponent,
    PrintComponent,
    LocalSpinnerComponent,
    OnlyFloatDirective,
    TrimSymbolsDirective,
    PhoneMaskDirective,
    ShowOnlyIfRootDirective,
    ActiveModuleHideBlockDirective,
    ActiveModuleDirective,
    TranslateModule,
    ShowPasswordDirective,
    HideAutoCompleteDirective,
    TabsTemplateDirective,
    TableRowTemplateDirective,
    TableHeaderTemplateDirective,
    ScrollFormErrorDirective,
    ZeroValidatorDirective,
    DisableAfterClickDirective,
    ToUpperCaseDirective,
    LogoComponent,
    SuccessComponent,
    MainContainerComponent,
    DescriptionComponent,
    DropdownMenuComponent,
    FilterCurrencySelectComponent,
    InputDateComponent,
    CodeInputComponent,
    ModalDragAndDropComponent,
    UploadFileToProfileComponent,
    NgxColorsModule,
    ElementHeightDirective,
    ComingSoonComponent,
    TextBoldDirective,
    MakeActiveInputDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedComponentsModule {}
