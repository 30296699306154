import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ConfirmDeletePopupComponent } from '@default-application-app/modules/shared/confirm-delete-popup/components/confirm-delete-popup.component';
import { UnsubscribeDestroyHelper } from '@helpers/unsubscribe-destroy.helper';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[ConfirmDeleteDirective]',
})
export class ConfirmDeleteDirective extends UnsubscribeDestroyHelper {
  private textForPopupHeader: string;

  private confirmDeletePopupComponentRef: ComponentRef<ConfirmDeletePopupComponent>;

  @Input() set ConfirmDeleteDirective(itemForDelete: string) {
    this.textForPopupHeader = itemForDelete;
  }

  @Output() confirmedDeleteEvent = new EventEmitter();

  @ViewChild('elem') _elem: ElementRef;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,

    private appRef: ApplicationRef,
    private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {
    super();
  }

  @HostListener('click', ['$event'])
  private onClick() {
    this.showPopup();
  }

  private hidePopup() {
    if (this.confirmDeletePopupComponentRef) {
      this.confirmDeletePopupComponentRef.destroy();
      this.confirmDeletePopupComponentRef = null;
    }
  }

  private showPopup() {
    this.hidePopup();

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ConfirmDeletePopupComponent);
    this.confirmDeletePopupComponentRef = componentFactory.create(this.injector);
    this.appRef.attachView(this.confirmDeletePopupComponentRef.hostView);

    this.confirmDeletePopupComponentRef.instance.headingMessage = this.textForPopupHeader;
    this.subscribeToUserConfirm();

    const componentView = (this.confirmDeletePopupComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0];

    document.body.appendChild(componentView);
  }

  private subscribeToUserConfirm() {
    this.confirmDeletePopupComponentRef.instance.userConfirm
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((confirmed) => {
        if (confirmed) {
          this.confirmedDeleteEvent.emit();
        }

        this.hidePopup();
      });
  }
}
