import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[textBold]',
})
export class TextBoldDirective {
  @HostBinding('style.font-weight')
  get fontWeight(): string {
    return '600';
  }
}
