<div class="content">
  <form class="form container with-title-30" [formGroup]="groupTransferForm">
    <app-page-block-wrapper>
      <div class="table-content">
        <div class="line">
          <div class="elem">
            <app-input (blur)="handleBlur($event)" placeholder="Enter title here" formControlName="groupTransferTitle"
              [isRequiredIf]="true" appErrorHandle label="Group Transfer Title"></app-input>
          </div>
        </div>
        <div class="line">
          <div class="elem">
            <label class="native-label native-label__admin"> {{ 'pages.transfers.sender_account' | translate }}</label>
            <app-account-select-with-currency [placeholderText]="'pages.transfers.select_account' | translate"
              (blur)="handleBlur($event)" [accounts]="accounts" formControlName="accountFrom" appErrorHandle [isPending]="isAccountChangeDisabled()">
            </app-account-select-with-currency>
          </div>
        </div>
        <div class="line">
          <div class="elem full-width">
            <app-textarea [label]="'pages.transfers.description' | translate"
              [placeholder]="'pages.invoices.create_invoice.description_placeholder' | translate" appErrorHandle
              formControlName="description"></app-textarea>
          </div>
        </div>
        <app-add-button (click)="openAddSubTransferDialog()"
        [isDisabled]= "groupTransferForm.invalid"
          [text]="'common.buttons.add_sub_transfer' | translate"></app-add-button>
        <br>
        <br>
        <app-add-button (click)="openUploadSubTransferDialog()"
        [isDisabled]= "groupTransferForm.invalid"
          [text]="'common.buttons.upload_sub_transfer' | translate"></app-add-button>
          
          <p class="native-label native-label__admin error-message" *ngIf="showSubTransfersMessage">Please input a valid CSV file</p>
      </div>
    
    <div class="margin-top" style="overflow-x: auto;">
      <ng-container *ngIf="subTransfers && subTransfers.length">
        <app-page-block-wrapper>
          <div class="data-wrapper">
            <div class="item">
              <div class="data">
                <app-svg-icon class="icon" [iconPath]="'menu/ic_users.svg'"></app-svg-icon>
                <span class="title">
                  {{ 'pages.group_transfers.sub_transfers.total_transfers' | translate }}
                </span>
              </div>
              <div class="data">
                <span>{{ subTransfers.length }}</span>
              </div>
            </div>
            <div class="item">
              <div class="data">
                <app-svg-icon class="icon" [iconPath]="'menu/ic_transfer_requests.svg'"></app-svg-icon>
                <span class="title">
                  {{ 'pages.group_transfers.sub_transfers.total_amount' | translate }}
                </span>
              </div>
              <div class="data">
                <span>{{ getCurrency()}}{{ getTotalAmount()}}</span>
              </div>
            </div>
          </div>
          <table class="table">
            <thead class="table__heading">
              <tr>
                <th>
                  {{ 'pages.group_transfers.sub_transfers.title' | translate }}
                </th>
                <th>
                  {{ 'pages.group_transfers.sub_transfers.amount' | translate }}
                </th>
                <th>
                  {{ 'pages.group_transfers.sub_transfers.recipient_account' | translate }}
                </th>
                <th>
                  {{ 'pages.group_transfers.sub_transfers.recipient_details' | translate }}
                </th>
                <th>
                  {{ 'pages.group_transfers.sub_transfers.description' | translate }}
                </th>
                <th>
                  {{ 'pages.group_transfers.sub_transfers.actions' | translate }}
                </th>
              </tr>
            </thead>
            <tbody [checkEmptyTable]="{ dataExists: subTransfers && subTransfers.length }" class="table__body">
              <ng-container *ngFor="let transaction of subTransfers">
                <tr>
                  <td class="title" [attr.data-title]="'pages.group_transfers.sub_transfers.title' | translate">{{
                    transaction.title }}</td>
                  <td [attr.data-title]="'pages.group_transfers.sub_transfers.amount' | translate"
                    class="amount_minus show">{{
                    transaction.amount }}</td>
                  <td [attr.data-title]="'pages.group_transfers.sub_transfers.recipient_account' | translate">{{
                    transaction.recipient_account }}</td>
                  <td [attr.data-title]="'pages.group_transfers.sub_transfers.recipient_details' | translate">{{
                    transaction.help_text }}</td>
                  <td class="description" [attr.data-title]="'pages.group_transfers.sub_transfers.description' | translate">
                    {{ transaction.description }}
                  </td>
                  <td class=" multiple-icons" [attr.data-title]="'pages.group_transfers.sub_transfers.actions' | translate">
                    <!-- <app-button [iconPath]="'ic_edit_dash.svg'" appearance="secondary" (btnClick)="openEditSubTransferDialog(transaction.id)">
                      {{ 'pages.group_transfers.sub_transfers.edit' | translate }}
                    </app-button> -->
                    <app-button [iconPath]="'ic_cancel.svg'" appearance="secondary" (btnClick)="deleteSubGroupTransfer(transaction.id)">
                      {{ 'pages.group_transfers.sub_transfers.delete' | translate }}
                    </app-button>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </app-page-block-wrapper>
      </ng-container>
    </div>
  </app-page-block-wrapper>
    <div class="button">
      <app-button class="cancel-btn" (btnClick)="onCancel()" buttonText="Cancel">
      </app-button>
      <app-button class="save-btn" [isDisabled]="groupTransferForm.invalid" (btnClick)="onSave()" buttonText="Save">
      </app-button>
      <app-button type="submit" [isDisabled]="isSubmitDisabled()" (btnClick)="redirectToSummaryPage()" buttonText="Submit Group Transfer">
      </app-button>
    </div>
  </form>
</div>
