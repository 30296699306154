<div class="aside__dropdown-btn dropdown-menu">
  <div
    (click)="toggleMenu()"
    [class.noItems]="!items.length"
    [class.opened]="isMenuOpened"
    [ngClass]="icon"
    class="dropdown-menu__row">
    <label class="dropdown-menu__label main_color">{{ 'aside menu.' + main.label | translate }}</label>
  </div>
  <ul [class.opened]="isMenuOpened" class="dropdown-menu__container">
    <li
      *ngFor="let item of items"
      [routerLink]="item.rout"
      class="dropdown-menu__item dropdown-item"
      routerLinkActive="active">
      {{ 'aside menu.' + item.label | translate }}
    </li>
  </ul>
</div>
