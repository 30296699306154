import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@default-application-app/config.service';
import { ApiCallerService } from '@services/api-caller.service';
import { Observable } from 'rxjs';
import { CallResponceInterface } from '@interfaces/callResponce.interface';

@Injectable({
  providedIn: 'root',
})
export class RequestNotificationsApiService {
  public constructor(
    private http: HttpClient,
    private apiCallerService: ApiCallerService,
    private configService: ConfigService,
  ) {}

  public apiLoadRequestNotifications(): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.requestNotifications.notifications),
      'apiLoadRequestNotifications',
    );
  }
}
