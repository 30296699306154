import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { FormatBytes } from '@helpers/format-bytes.helper';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DragAndDropComponent),
    },
  ],
})
export class DragAndDropComponent implements OnInit, ControlValueAccessor {
  @Input() extension: string;

  @Input() maxSize: number;

  @Input() drawPreview: boolean;

  @Input() maxFilesCount = 1;

  @Input() uploaded = true;

  @Input() showRemoveBtn = false;

  @Input() prefix = '';

  @Input() extensionsForView;

  @Input() title = '';

  @Input() description: string;

  @Input() controlName: string;

  @Input() multipleFiles = true;

  @Input() inputAccept: string;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focusout = new EventEmitter();

  @Output() getControlName = new EventEmitter();

  @Output() loaded = new EventEmitter();

  public file: File;

  public fileList: File[];

  public extensionView: string;

  public formatBytes = FormatBytes;

  ngOnInit() {
    this.convertExtension();
  }

  public uploadFile($event: any) {
    const file = $event.target.files[0];
    if (!file.type && file.name.match(/\.csv$/)) {
      this.file = new File([file], file.name, { type: 'text/csv' });
    } else {
      this.file = file;
    }
    this.loaded.emit();
    this.drawAndUpload();
  }

  public dropped(files: NgxFileDropEntry[]): void {
    const droppedFile = files[0];

    if (droppedFile.fileEntry.isFile) {
      (<FileSystemFileEntry>droppedFile.fileEntry).file((file: File) => {
        if (!file.type && (<FileSystemFileEntry>droppedFile.fileEntry).name.match(/\.csv$/)) {
          this.file = new File([file], file.name, { type: 'text/csv' });
        } else {
          this.file = file;
        }

        this.drawAndUpload();
      });
    }
  }

  public resetFile(): void {
    this.file = null;
    this.onChange(null);

    if (this.drawPreview) {
      document.getElementById('gallery').innerHTML = '';
    }
  }

  public onInputBlur() {
    this.onTouched();
    this.focusout.emit();
  }

  public removeFile(): void {
    this.resetFile();
  }

  writeValue(obj: any): void {
    this.file = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private onChange: Function = () => {};

  private onTouched: Function = () => {};

  private convertExtension(): void {
    this.extensionView = this.extension
      .replace(/[a-z]*\//gi, ' ')
      .replace(/plain/g, 'txt')
      .trim();

    if (this.extensionsForView) {
      this.extensionView = this.extensionsForView;
    }

    if (!this.inputAccept) {
      this.inputAccept = this.extension;
    }
  }

  private drawAndUpload(): void {
    this.onChange(this.file);
    this.onTouched();

    if (this.file) {
      const { type } = this.file;

      if (
        this.drawPreview &&
        type !== 'image/jpeg' &&
        type !== 'image/png' &&
        type !== 'image/gif' &&
        type !== 'image/svg+xml'
      ) {
        document.getElementById('gallery').innerHTML = '';
      } else if (this.drawPreview) {
        this.preloadImg();
      }
    }
  }

  private preloadImg(): void {
    const gallery = document.getElementById('gallery');
    gallery.innerHTML = '';
    const reader = new FileReader();
    reader.readAsDataURL(this.file);

    reader.onloadend = () => {
      const img = document.createElement('img');
      img.src = reader.result as string;
      gallery.appendChild(img);
    };
  }
}
