<div class="wrapper">
  <div [class.disabled]="disabled" class="drag-and-drop">
    <div class="header-cub">
      <app-svg-icon *ngIf="!file.preview && !fileUrl" iconPath="drag-and-drop/upload-file.svg"></app-svg-icon>

      <div *ngIf="(file.preview || fileUrl) && !isPdfImage(file.preview ? file.preview : fileUrl)" class="preview">
        <img [src]="file.preview || fileUrl" class="image-preview" />
      </div>

      <div
        *ngIf="(file.preview || fileUrl) && isPdfImage(file.preview ? file.preview : fileUrl)"
        class="preview with-pdf">
        <img [src]="file.preview || fileUrl" class="image-preview" />
      </div>

      <div *ngIf="!file.preview && !fileUrl" class="description">
        {{ description }}
      </div>

      <div *ngIf="file.preview || fileUrl" class="description">
        <span *ngIf="!isFileName">{{ 'common.drag_and_drop.change_document' | translate }}</span>
        <span *ngIf="isFileName">{{ file?.file?.name }}</span>
      </div>

      <div *ngIf="file.preview || fileUrl" class="description">
        {{ descriptionForUploadedFile }}
      </div>
    </div>
  </div>
</div>
