import { Pipe, PipeTransform } from '@angular/core';
import { parseISO } from 'date-fns';

@Pipe({
  name: 'stringToDate',
})
export class StringToDatePipe implements PipeTransform {
  transform(date: string): Date {
    if (date) {
      return parseISO(date);
    }

    return null;
  }
}
