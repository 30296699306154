<div class="body">
  <aside class="body__aside">
    <app-aside-menu></app-aside-menu>
  </aside>

  <div class="body-content">
    <header class="body-content__header">
      <app-header></app-header>
    </header>

    <main class="body-content__main">
      <ng-scrollbar visibility="hover">
        <div class="content-wrapper" [ngClass]="{ rtl: isRtl }">
          <router-outlet></router-outlet>
        </div>
      </ng-scrollbar>
    </main>
  </div>
</div>
