import { Directive, HostListener, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[makeActiveInput]',
})
export class MakeActiveInputDirective implements OnInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  public ngOnInit(): void {
    this.checkInputValueAndSetClass();
  }

  @HostListener('change')
  private onChange(): void {
    this.checkInputValueAndSetClass();
  }

  @HostListener('keyup')
  private onKeyUp(): void {
    this.checkInputValueAndSetClass();
  }

  private checkInputValueAndSetClass(): void {
    if (this.el.nativeElement.value) {
      this.renderer.addClass(this.el.nativeElement, 'filled');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'filled');
    }
  }
}
