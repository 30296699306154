import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { CurrenciesFilter } from '@resolvers/currency/currencies.resolver';
import { CurrenciesService } from '@services/currencies/currencies.service';
import { Currency } from '@models/currency-model';
import { FilterOptions } from '@default-application-app/core/shared/filter-options';
import { Injectable } from '@angular/core';

@Injectable()
export class UserCurrenciesResolver implements Resolve<Currency[]> {
  constructor(private currenciesService: CurrenciesService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Currency[]> {
    const filters: FilterOptions = new FilterOptions();
    filters.setSort('type');
    switch (route.data.currenciesFilters) {
      case CurrenciesFilter.Active:
        filters.addFilter('active', ['true']);
        break;
      case CurrenciesFilter.ActiveCrypto:
        filters.addFilter('active', ['true']);
        filters.addFilter('type', ['crypto']);
        break;
    }

    return this.currenciesService.getAllUserCurrencies(filters).pipe(first());
  }
}
