import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CallResponceInterface } from '@interfaces/callResponce.interface';
import { HttpClient } from '@angular/common/http';
import { ApiCallerService } from '@services/api-caller.service';
import { ConfigService } from '@default-application-app/config.service';
import { TokenService } from '@services/token-service';

@Injectable({
  providedIn: 'root',
})
export class LegalDocumentsApiService {
  constructor(
    private http: HttpClient,
    private apiCallerService: ApiCallerService,
    private configService: ConfigService,
    private tokenService: TokenService,
  ) {}

  public apiAcceptTaC(uid: string): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () => this.http.post(this.configService.config.api.legalDocuments.tac(uid), { tacConsent: true }),
      'apiAcceptTaC',
    );
  }

  public apiAcceptPrivacyPolicy(uid: string): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () => this.http.post(this.configService.config.api.legalDocuments.privacyPolicy(uid), { privacyConsent: true }),
      'apiAcceptPrivacyPolicy',
    );
  }
}
