import { Pipe, PipeTransform } from '@angular/core';
import { AsYouType, CountryCode } from 'libphonenumber-js';

@Pipe({
  name: 'phone-without-country-code',
})
export class PhoneWithoutCountryCodePipe implements PipeTransform {
  transform(phoneValue: number | string, countryCode: string): string {
    const ayt = new AsYouType(countryCode as CountryCode);
    try {
      ayt.input(`${phoneValue}`);
      const positionShift = 2;
      const position = ayt.getNumber().countryCallingCode.length + positionShift;
      return ayt.getNumber().format('INTERNATIONAL').slice(position);
    } catch (error) {
      return String(phoneValue);
    }
  }
}
