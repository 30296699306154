import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  RequestNotification,
  RequestNotificationInterface,
} from '@default-application-app/modules/request-notifications/models/request-notification';
import { NotificationGroup } from '@default-application-app/modules/user-request-notifications/models/notification-group';
import { RequestNotificationType } from '@default-application-app/modules/request-notifications/models/request-notification-type';
import * as moment from 'moment-timezone';
import { NotificationGroupType } from '@default-application-app/modules/request-notifications/enums/notification-group-type.enum';
import { RequestNotificationsApiService } from './request-notifications-api.service';

const DATE_FORMAT = 'DD/MM/YYYY';

@Injectable({
  providedIn: 'root',
})
export class RequestNotificationsService {
  public constructor(protected apiService: RequestNotificationsApiService) {}

  public loadRequestNotifications(): Observable<RequestNotification[]> {
    return this.apiService.apiLoadRequestNotifications().pipe(
      map(({ data, error }: { data: RequestNotificationInterface[]; error: boolean; links: any }) => {
        if (error) {
          return [];
        }

        return data
          .map((item: RequestNotificationInterface) => new RequestNotification(item))
          .sort((a: RequestNotificationInterface, b: RequestNotificationInterface) => b.id - a.id);
      }),
    );
  }

  public loadGroupsRequestNotifications(): Observable<NotificationGroup[]> {
    return this.loadRequestNotifications().pipe(
      map((notifications: RequestNotification[]) => this.prepareNotificationGroups(notifications)),
    );
  }

  private prepareNotificationGroups(notifications: RequestNotification[]): NotificationGroup[] {
    const namedGroups: NotificationGroup[] = [];
    const unnamedGroups: NotificationGroup[] = [];

    notifications.forEach((notification: RequestNotification) => {
      switch (notification.type) {
        case RequestNotificationType.cashInRequestCreated:
        case RequestNotificationType.cashOutRequestCreated:
        case RequestNotificationType.transferIncoming:
        case RequestNotificationType.transferOutgoing:
        case RequestNotificationType.moneyRequestIncoming:
        case RequestNotificationType.invoiceIncoming:
        case RequestNotificationType.invoiceOutgoing:
        case RequestNotificationType.refundRequested: {
          // pending
          const group = this.getNotificationsGroupByKey(namedGroups, NotificationGroupType.pending);
          group.notifications.push(notification);
          break;
        }
        default: {
          // today
          if (moment(notification.updatedAt).isSame(moment(), 'day')) {
            const group = this.getNotificationsGroupByKey(namedGroups, NotificationGroupType.today);
            group.notifications.push(notification);
            break;
          }
          // yesterday
          if (moment(notification.updatedAt).isSame(moment().subtract(1, 'day'), 'day')) {
            const group = this.getNotificationsGroupByKey(namedGroups, NotificationGroupType.yesterday);
            group.notifications.push(notification);
            break;
          }

          const dateTitle = moment(notification.updatedAt).local().format(DATE_FORMAT);
          const group = this.getNotificationsGroupByKey(unnamedGroups, dateTitle);
          group.notifications.push(notification);
          break;
        }
      }
    });

    namedGroups.sort((a: NotificationGroup, b: NotificationGroup) => a.key.localeCompare(b.key));
    unnamedGroups.sort(
      (a: NotificationGroup, b: NotificationGroup) =>
        moment(b.key, DATE_FORMAT).valueOf() - moment(a.key, DATE_FORMAT).valueOf(),
    );

    return [...namedGroups, ...unnamedGroups];
  }

  private getNotificationsGroupByKey(groups: NotificationGroup[], type: string): NotificationGroup {
    let group = groups.find((value: NotificationGroup) => value.key === type);

    if (!group) {
      group = {
        key: type,
        notifications: [],
      };
      groups.push(group);
    }

    return group;
  }
}
