import { Component, HostBinding, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DatepickerOptions, DatepickerComponent } from 'ng2-datepicker';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data.min';
import { DATEPICKER_BASE_OPTIONS, DATEPICKER_RESPONSE_FORMAT } from '@constants/datepickerBaseOptions';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { OptionsValuesNames } from '@constants/optionsPrefixes';
import { take, takeUntil } from 'rxjs/operators';
import { AppOptionsService } from '@services/appOptions.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-date-to-from',
  templateUrl: './date-to-from.component.html',
  styleUrls: ['./date-to-from.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DateToFromComponent implements OnInit, OnDestroy {
  @ViewChild('dateFrom', { static: true }) dateFromElement: DatepickerComponent;

  @ViewChild('dateTo', { static: true }) dateToElement: DatepickerComponent;

  @Input() form: UntypedFormGroup;

  @Input() public dateFromAdditionalOptions: DatepickerOptions;

  @Input() dateElement: DatepickerComponent;

  @Input() public dateToAdditionalOptions: DatepickerOptions;

  @HostBinding('class.two-datepicker') twoDatepickerClass: boolean = true;

  public from: string;

  public to: string;

  public dateFromOptions: DatepickerOptions;

  public dateToOptions: DatepickerOptions;

  private unsubscribeSubject: Subject<void> = new Subject<void>();

  constructor(protected route: ActivatedRoute, protected appOptionService: AppOptionsService) {
    this.createDatepickerOptions();
  }

  @Input()
  public set placeholderDateTo(placeholder: string) {
    if (placeholder) {
      this.dateToOptions = {
        ...this.dateToOptions,
        placeholder,
      };
    }
  }

  @Input()
  public set placeholderDateFrom(placeholder: string) {
    if (placeholder) {
      this.dateFromOptions = {
        ...this.dateFromOptions,
        placeholder,
      };
    }
  }

  public ngOnInit(): void {
    this.appOptionService
      .getOptionsValuePipe(OptionsValuesNames.DEFAULT_DATE_FORMAT)
      .pipe(take(1))
      .subscribe((format: string) => {
        format = format.replace('DD', 'dd').replace('YYYY', 'yyyy');

        this.dateToOptions = {
          ...this.dateToOptions,
          format,
          ...this.dateToAdditionalOptions,
          placeholder: '',
        };

        this.dateFromOptions = {
          ...this.dateFromOptions,
          format,
          ...this.dateFromAdditionalOptions,
          placeholder: '',
        };
      });

    this.form.addControl('dateFrom', new UntypedFormControl(''));
    this.form.addControl('dateTo', new UntypedFormControl(''));

    this.from = this.sanitizeValue(this.form.get('dateFrom').value);
    this.to = this.sanitizeValue(this.form.get('dateTo').value);

    this.form
      .get('dateFrom')
      .valueChanges.pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((value: string) => {
        value = this.sanitizeValue(value);

        if (value) {
          if (value !== this.from) {
            this.dateToOptions = {
              ...this.dateToOptions,
              minDate: moment.tz(value, Intl.DateTimeFormat().resolvedOptions().timeZone).toDate(),
            };
          }
        } else {
          if (this.dateToOptions.minDate) {
            delete this.dateToOptions.minDate;
          }

          this.dateToOptions = {
            ...this.dateToOptions,
          };
        }

        this.from = value;
      });

    this.form
      .get('dateTo')
      .valueChanges.pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((value: string) => {
        value = this.sanitizeValue(value);

        if (value) {
          if (value !== this.to) {
            this.dateFromOptions = {
              ...this.dateFromOptions,
              maxDate: moment.tz(value, Intl.DateTimeFormat().resolvedOptions().timeZone).toDate(),
            };
          }
        } else {
          this.dateFromOptions = {
            ...this.dateFromOptions,
            maxDate: new Date(),
          };
        }

        this.to = value;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

  public setDateFrom(dateFrom: Date): void {
    const date: string = moment(dateFrom).format(DATEPICKER_RESPONSE_FORMAT);
    this.form.patchValue({ dateFrom: date });
  }

  public setDateTo(dateTo: Date): void {
    const date = moment(dateTo).format(DATEPICKER_RESPONSE_FORMAT);
    this.form.patchValue({ dateTo: date });
  }

  private createDatepickerOptions(): void {
    this.dateFromOptions = { placeholder: '01/01/2000', ...DATEPICKER_BASE_OPTIONS };

    this.dateToOptions = { placeholder: moment(new Date()).format('dd/MM/yyyy'), ...DATEPICKER_BASE_OPTIONS };
  }

  public dateFromReset() {
    this.form.get('dateFrom').setValue(null);
    this.dateFromElement.displayValue = null;
    this.dateFromElement.innerValue = new Date();
  }

  public dateToReset() {
    this.form.get('dateTo').setValue(null);
    this.dateToElement.displayValue = null;
    this.dateToElement.innerValue = new Date();
  }

  private sanitizeValue(value: string): string {
    if (value && !this.isValidateDate(value)) {
      return '';
    }

    return value;
  }

  private isValidateDate(date: string): boolean {
    return moment(date, DATEPICKER_RESPONSE_FORMAT, true).isValid();
  }
}
