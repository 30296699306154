import { Injectable } from '@angular/core';
import { ProfileFilter } from '@default-application-app/core/interfaces/profile-filter-interface';
import { Observable, Subject } from 'rxjs';
import { PaginationService } from '@default-application-app/core/services/pagination/pagination.service';
import { ProfileApiService } from '@default-application-app/core/services/profile/profile-api.service';
import { ProfileModel } from '@default-application-app/core/models/profile-model';
import { PaginationPageLimitInterface } from '@default-application-app/core/interfaces/pagination-page-limit.interface';
import { map, switchMap, take } from 'rxjs/operators';
import { CallResponceInterface } from '@default-application-app/core/interfaces/callResponce.interface';
import * as FileSaver from 'file-saver';
import { FileDownloadModel } from '@default-application-app/core/models/file-download.model';
import { QueryFieldInterface } from '@default-application-app/core/interfaces/sort-bar/queryField.interface';
import { DateToFromFieldInterface } from '@default-application-app/core/interfaces/sort-bar/dateToFromField.interface';
import { SortFieldInterface } from '@default-application-app/core/interfaces/sort-bar/sortField.interface';
import { PaginationFieldInterface } from '@default-application-app/core/interfaces/sort-bar/paginationField.interface';
import { queryParamsStringify } from '@default-application-app/core/helpers/queryParamsHelpers';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data.min';
import { NoteInterface, NoteModel } from '@default-application-app/modules/profiles/interfaces/note.interface';

export type UserProfilesRequestFilter = QueryFieldInterface &
  DateToFromFieldInterface &
  SortFieldInterface &
  PaginationFieldInterface & {
    status?: string;
    userGroupId?: string;
    roleName?: string;
  };

export type AdminProfilesRequestFilter = QueryFieldInterface &
  DateToFromFieldInterface &
  SortFieldInterface &
  PaginationFieldInterface & {
    status: string;
    classId: string;
    roleName: string;
  };

export type NotesRequestFilter = PaginationFieldInterface & SortFieldInterface;

export type SortAndPaginationFilter = SortFieldInterface & PaginationFieldInterface;

@Injectable()
export class ProfileService {
  public loadUsersProfilesSubject$: Subject<UserProfilesRequestFilter> = new Subject<UserProfilesRequestFilter>();

  public onLoadUsersProfiles$: Observable<{ profiles: ProfileModel[]; pagination: PaginationPageLimitInterface }> =
    this.loadUsersProfilesSubject$.asObservable().pipe(
      map((query: UserProfilesRequestFilter) => ProfileService.transformUserRequestParams(query)),
      switchMap((params: { [key: string]: string }) => this.apiService.apiLoadUserProfiles(params)),
      map(({ data, error }: CallResponceInterface) => {
        if (error) {
          return { profiles: [], pagination: PaginationService.defaultPaginationPageLimit };
        }
        const profiles = (<{ items: any[] }>data).items.map((item) => new ProfileModel(item));

        const pagination = PaginationService.buildPaginationPageLimit(<any>data);
        return { profiles, pagination };
      }),
    );

  public loadAdminsProfilesSubject$: Subject<AdminProfilesRequestFilter> = new Subject<AdminProfilesRequestFilter>();

  public onLoadAdminsProfiles$: Observable<{ profiles: ProfileModel[]; pagination: PaginationPageLimitInterface }> =
    this.loadAdminsProfilesSubject$.asObservable().pipe(
      map((query: AdminProfilesRequestFilter) => ProfileService.transformAdminRequestParams(query)),
      switchMap((params: { [key: string]: string }) => this.apiService.apiLoadAdminProfiles(params)),
      map(({ data, error }: CallResponceInterface) => {
        if (error) {
          return { profiles: [], pagination: PaginationService.defaultPaginationPageLimit };
        }
        const profiles = (<{ items: any[] }>data).items.map((item) => new ProfileModel(item));

        const pagination = PaginationService.buildPaginationPageLimit(<any>data);
        return { profiles, pagination };
      }),
    );

  private onFilterChangeSubject$: Subject<ProfileFilter> = new Subject<ProfileFilter>();

  private profileData: ProfileModel;

  public onFilterChange$: Observable<ProfileFilter> = this.onFilterChangeSubject$.asObservable();

  public constructor(private apiService: ProfileApiService) {}

  public static transformToSortPaginationFilter(queryParams: SortAndPaginationFilter): ProfileFilter {
    return {
      sort: queryParams.sort,
      page: queryParams.page,
      limit: queryParams.size,
      filter: { isBlocked: true },
    };
  }

  public get profile(): ProfileModel {
    return this.profileData;
  }

  public resetProfile(): void {
    this.profileData = null;
  }

  private static transformUserRequestParams(
    queryParams: UserProfilesRequestFilter,
    forCsv = false,
  ): { [key: string]: string } {
    const params = {
      sort: queryParams.sort,
      filter: {},
    };

    if (queryParams?.roleName) {
      // @ts-ignore
      params.filter.roleName = queryParams?.roleName;
    }

    if (!forCsv) {
      // @ts-ignore
      params.page = queryParams.page;
      // @ts-ignore
      params.limit = queryParams.size;
    }

    if (queryParams.query) {
      // @ts-ignore
      params.filter.query = queryParams.query;
    }
    if (queryParams.userGroupId) {
      // @ts-ignore
      params.filter.userGroupId = queryParams.userGroupId;
    }
    if (queryParams.dateTo) {
      // @ts-ignore
      params.filter.dateTo = moment(queryParams.dateTo).endOf('day').utc().format();
    }
    if (queryParams.dateFrom) {
      // @ts-ignore
      params.filter.dateFrom = moment(queryParams.dateFrom).startOf('day').utc().format();
    }

    if (queryParams.status) {
      // @ts-ignore
      params.filter.status = queryParams.status;
    }
    return queryParamsStringify(params);
  }

  private static transformAdminRequestParams(
    queryParams: AdminProfilesRequestFilter,
    forCsv = false,
  ): { [key: string]: string } {
    const params = {
      sort: queryParams.sort,
      filter: { roleName: ['admin,iban_admin,root'] },
    };

    if (!forCsv) {
      // @ts-ignore
      params.page = queryParams.page;
      // @ts-ignore
      params.limit = queryParams.size;
    }

    if (queryParams.query) {
      // @ts-ignore
      params.filter.query = queryParams.query;
    }

    if (queryParams.dateTo) {
      // @ts-ignore
      params.filter.dateTo = moment(queryParams.dateTo).endOf('day').utc().format();
    }

    if (queryParams.dateFrom) {
      // @ts-ignore
      params.filter.dateFrom = moment(queryParams.dateFrom).startOf('day').utc().format();
    }

    if (queryParams.status) {
      // @ts-ignore
      params.filter.status = queryParams.status;
    }

    if (queryParams.classId) {
      // @ts-ignore
      params.filter.classId = queryParams.classId;
    }

    return queryParamsStringify(params);
  }

  public loadUserProfile(id: string): Observable<ProfileModel> {
    return this.apiService
      .apiLoadProfile(id)
      .pipe(map(({ data }: { data: {}; error: boolean }): ProfileModel => new ProfileModel(data)));
  }

  public loadUserProfiles(
    params: UserProfilesRequestFilter,
  ): Observable<{ profiles: ProfileModel[]; pagination: PaginationPageLimitInterface }> {
    return this.apiService.apiLoadUserProfiles(ProfileService.transformUserRequestParams(params)).pipe(
      map(({ data, error }: CallResponceInterface) => {
        if (error) {
          return { profiles: [], pagination: PaginationService.defaultPaginationPageLimit };
        }
        const profiles = (<{ items: any[] }>data).items.map((item) => new ProfileModel(item));

        const pagination = PaginationService.buildPaginationPageLimit(<any>data);
        return { profiles, pagination };
      }),
    );
  }

  public loadMyProfile(): Observable<ProfileModel> {
    return this.apiService.apiLoadMyProfile().pipe(
      map(({ data }: { data: {}; error: boolean }): ProfileModel => {
        this.profileData = new ProfileModel(data);
        return new ProfileModel(data);
      }),
    );
  }

  public loadUserNotes(
    uid: string,
    params: NotesRequestFilter,
  ): Observable<{ notes: NoteInterface[]; pagination: PaginationPageLimitInterface }> {
    return this.apiService.apiGetNotes(uid, ProfileService.transformNotesParams(params)).pipe(
      map(({ data, error }: CallResponceInterface) => {
        if (error) {
          return { notes: [], pagination: PaginationService.defaultPaginationPageLimit };
        }
        const notes = (<{ items: any[] }>data).items.map((item) => new NoteModel(item));

        const pagination = PaginationService.buildPaginationPageLimit(<any>data);
        return { notes, pagination };
      }),
    );
  }

  public createUserNote(body): Observable<NoteInterface[]> {
    return this.apiService
      .apiCreateNote(body)
      .pipe(map(({ data }: { data: { items: NoteInterface[] }; error: boolean }): NoteInterface[] => data.items));
  }

  public loadLimitedMyProfile(): Observable<ProfileModel> {
    return this.apiService
      .apiLoadLimitedMyProfile()
      .pipe(map(({ data }: { data: {}; error: boolean }): ProfileModel => new ProfileModel(data)));
  }

  public exportUserProfilesToCsv(query: UserProfilesRequestFilter) {
    this.apiService
      .apiExportUserProfilesToCsv(ProfileService.transformUserRequestParams(query, true))
      .pipe(take(1))
      .subscribe((data: FileDownloadModel) => {
        FileSaver.saveAs(data.blob, data.filename);
      });
  }

  public exportAdminProfilesToCsv(query: AdminProfilesRequestFilter) {
    this.apiService
      .apiExportAdminProfilesToCsv(ProfileService.transformAdminRequestParams(query))
      .pipe(take(1))
      .subscribe((data: FileDownloadModel) => {
        FileSaver.saveAs(data.blob, data.filename);
      });
  }

  public static transformNotesParams(queryParams: NotesRequestFilter): { [key: string]: string } {
    const params = {};

    // @ts-ignore
    params.page = queryParams.page;
    // @ts-ignore
    params.limit = queryParams.size;

    return queryParamsStringify(params);
  }

  public updateEmail(email: string, otp: string, uid: string): Observable<CallResponceInterface> {
    return this.apiService.apiUpdateEmail(email, otp, uid);
  }

  public updatePhone(phone: string, otp: string, uid: string): Observable<CallResponceInterface> {
    return this.apiService.apiUpdatePhone(phone, otp, uid);
  }

  public sendSmsToPhone(phone: string): Observable<CallResponceInterface> {
    return this.apiService.apiSendSmsToPhone(phone);
  }

  public sendSmsToEmail(email: string): Observable<CallResponceInterface> {
    return this.apiService.apiSendSmsToEmail(email);
  }

  public updateProfile(profile: ProfileModel): Observable<any> {
    return this.apiService.apiUpdateProfilePatch(profile);
  }
}
