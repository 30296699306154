<app-modal-wrapper
  [title]="'pages.group_transfers.add_sub_transfer' | translate"
  [titleIconName]="'representative'">
  <form [formGroup]="addTransferForm" >
    <div class="modal-content">
        <div class="line">
        <div class="elem test">
            <app-input
            placeholder= "Enter title here"
            [isRequiredIf]="true"
            appErrorHandle
            formControlName="title"
            [label]="'pages.group_transfers.sub_transfer_title' | translate"></app-input>
      
        </div>
    </div>
        <div class="line row">
            <div class="elem-row">
              <app-input
                formControlName="accountNumberTo"
                onlyNumber
                [isRequiredIf]="true"
                (keyup)="calculateFee()"
                (change)="onChangeAccount()"
                [appErrorHandle]="[errorCodes.ACCOUNT_NOT_FOUND, errorCodes.TRANSFER_TO_SELF_ACCOUNT]"
                [formControlName]="'accountNumberTo'"
                [label]="'pages.transfers.recipient_account' | translate"></app-input>
                <div *ngIf="recipientDetails"
                class="recipientInfo">
                  {{recipientDetails}}
                </div>
            </div>

            <div class="elem-row">
              <app-input-amount
                formControlName="outgoingAmount"
                placeholder="0.00"
                isRequiredIf="true"
                (keyup)="calculateFee()"
                [appErrorHandle]="[errorCodes.UNCOVERED_TRANSFER_FEE]"
                [isDisabled]="
                  !addTransferForm.get('accountNumberTo').valid ||
                  !addTransferForm.value?.accountFrom ||
                  !addTransferForm.value?.accountNumberTo
                "
                [fee]="
                  details
                    ? typeFeeText(this.details)
                      ? typeFeeText(this.details)
                      : '0.00 ' + (accountFrom?.type?.currencyCode || defaultCurrencyCode)
                    : '0.00 ' + (accountFrom?.type?.currencyCode || defaultCurrencyCode)
                "
                [currencyCode]="accountFrom?.type?.currencyCode || defaultCurrencyCode"
                [label]="'pages.transfers.amount' | translate"></app-input-amount>
          
            </div>
          </div>
          <div class="line">
            <div class="elem full-width">
              <app-textarea [label]="'pages.transfers.description' | translate"
                [placeholder]="'pages.invoices.create_invoice.description_placeholder' | translate" appErrorHandle
                formControlName="description"></app-textarea>
            </div>
          </div>
    </div>

    <div class="footer">
      <app-button
        class="button"
        [appearance]="'primary-outline'"
        (btnClick)="closeDialog()"
        [buttonText]="'common.buttons.cancel' | translate"></app-button>

      <app-button
        class="button"
        [type]="'submit'"
        (btnClick)="addSubTransfer()"
        [isDisabled]="addTransferForm.invalid"
        [buttonText]="'common.buttons.save' | translate"></app-button>
    </div>
</form>
</app-modal-wrapper>