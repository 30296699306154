import { IUser } from '@default-application-app/core/interfaces/user-interface';
import { getHumanId } from '@default-application-app/core/helpers/get-human-user-id.helper';

/** @deprecated */
export class UserModel {
  status: number;

  data: User[];
}

export class User implements IUser {
  uid: string;

  id?: string;

  email: string;

  firstName: string;

  lastName: string;

  middleName: string;

  roleName: string;

  phoneNumber: string;

  status: string;

  userGroupId: number;

  createdAt: string;

  updatedAt: string;

  companyName: string;

  isCorporate: boolean;

  countryOfCitizenshipISO2: string;

  countryOfResidenceISO2: string;

  dateOfBirthDay: number;

  dateOfBirthMonth: number;

  dateOfBirthYear: number;

  documentPersonalId: string;

  documentType: {
    String: string;
    Valid: boolean;
  };

  paAddress: string;

  paAddress2ndLine: string;

  paCity: string;

  paCountryIso2: string;

  paStateProvRegion: string;

  paZipPostalCode: string;

  maAddress: string;

  maAddress2ndLine: string;

  maCity: string;

  maCountryISO2: string;

  maStateProvRegion: string;

  maZipPostalCode: string;

  user_field_values: any;

  attributes: {};

  userGroup: {
    id: string;
    name: string;
    description: string;
    createdAt: string;
    updatedAt: string;
  };

  cancellation_reason: string;

  label: string;

  ROLE_ADMIN = 'admin';

  ROLE_ROOT = 'root';

  public constructor(params: any) {
    (<any>Object).assign(this, params);
    if (this?.attributes && this.attributes['legal_business_name']) {
      this.label = this.attributes['legal_business_name'];
    } else {
      this.label = `${this.firstName} ${this.lastName}`;
    }
  }

  public isAdmin(): boolean {
    return this.roleName === this.ROLE_ADMIN || this.roleName === this.ROLE_ROOT;
  }

  public getFullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  public humanId(): string {
    return getHumanId(this);
  }
}
