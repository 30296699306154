import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserPermissions } from '@constants/userPermissions';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as FromApp from '@default-application-app/reducers/app.reducer';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeDestroyHelper } from '@helpers/unsubscribe-destroy.helper';
import { ArabicLanguage } from '@constants/languages';

export interface IAsideMenuGroup {
  iconName: string;
  title: string;
  route?: string;
  isOpened?: boolean;
  subItems?: IAsideMenuSubItem[];
  label?: number;
  permission?: UserPermissions;
  isActive?: boolean;
  isHidden?: boolean;
}

export interface IAsideMenuSubItem {
  title: string;
  route: string;
  permissions?: UserPermissions[];
}

@Component({
  selector: 'app-aside-menu-group',
  templateUrl: './aside-menu-group.component.html',
  styleUrls: ['./aside-menu-group.component.scss'],
})
export class AsideMenuGroupComponent extends UnsubscribeDestroyHelper {
  @Input()
  public iconName: string;

  @Input()
  public title: string;

  @Input()
  public route: string;

  @Input()
  public subItems: IAsideMenuSubItem[];

  @Input()
  public isActive: boolean;

  @Output()
  public linkClick: EventEmitter<void> = new EventEmitter<void>();

  public userPermissions = UserPermissions;

  public isRtl: boolean = false;

  constructor(private router: Router, private store: Store) {
    super();
    this.subscribeOnLanguage();
  }

  private subscribeOnLanguage(): void {
    this.store
      .pipe(select(FromApp.getSiteLanguage))
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((currentLanguage) => {
        this.isRtl = currentLanguage === ArabicLanguage;
      });
  }

  public open(event, subItem?: IAsideMenuSubItem): void {
    if (this.subItems) {
      event.stopPropagation();
      if (!subItem) {
        this.isActive = !this.isActive;
      }
    } else {
      this.router.navigate([this.route]);
      this.linkClick.emit();
    }
  }
}
