<div class="select-container input-field">
  <ng-select
    #NgSelectComponent
    (change)="emitChange($event)"
    [bindValue]="bindValue"
    [clearable]="isClearable"
    [dropdownPosition]="'bottom'"
    [items]="_accounts"
    [placeholder]="placeholderText || ('pages.accounts.accounts.select_account' | translate)"
    [searchable]="false"
    class="simple app-select">
    <!--BODY TEMPLATE-->
    <ng-template let-item="item" ng-label-tmp>
      <div class="preview">
        <div class="preview__image" *ngIf="item?.type?.currencyCode">
          <app-svg-icon
            class="flag-icon"
            iconPath="country-currency/{{ item?.type?.currencyCode | lowercase }}.svg"></app-svg-icon>
        </div>

        <div>
          <div class="select-value">{{ item?.type?.currencyCode }} {{ item.number }}</div>

          <div class="bottom-hint" *ngIf="item?.type?.currencyCode">
            {{ item.availableAmount | customCurrency: item.type.currencyCode | async }} {{ item.type.currencyCode }}
            {{ 'common.labels.available' | translate }}
          </div>
        </div>
      </div>
    </ng-template>

    <!--DROPDOWN TEMPLATE-->
    <ng-template let-item="item" ng-option-tmp>
      <span class="select-value">{{ item.number }}</span>

      <span class="bottom-hint">
        {{ item.type.currencyCode }} {{ item.availableAmount | customCurrency: item.type.currencyCode | async }}
      </span>
    </ng-template>
  </ng-select>
</div>
