import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { KycService } from '@services/kyc/kyc.service';
import { KycTierModel } from '@models/kyc/kyc-tiers.model';

@Injectable()
export class KycTiersResolver implements Resolve<KycTierModel[]> {
  constructor(private kycService: KycService) {}

  public resolve(): Observable<KycTierModel[]> {
    return this.kycService.kycTiers();
  }
}
