import { ChangeDetectorRef, Component, Inject, OnInit, Output} from '@angular/core';
import { FormGroup, UntypedFormBuilder ,ValidatorFn,Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VALIDATION_PROPERTY } from '@constants/validation-property';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import {
  IAdminTbuRequest,
    IAmountDetails,
    IIncomingAmount,
    ITbuRequest,
  } from '@interfaces/transfer-request-interface';
import { TransferService, TransferTypes } from '@services/transfer/transfer.service';
import { ApiError } from '@models/api-error.model';
import { ErrorCodes } from '@default-application-app/core/constants/errorCodes';
import { AmountDetailsMixin } from '../../../transfer/components/shared/amountDetails.mixin';
import { AccountInterface } from '@interfaces/account-interface';
import { sufficientAmountValidatorFn } from '@validators/sufficientAmount.validator';
import { accountNumberValidator } from '@validators/account-number.validator';
import { numberValidator } from '@validators/number-validator';
import { ActivatedRoute } from '@angular/router';
import { UserGroupTransfersService } from '../../services/user-group-transfers.services';
import { UserGroupTransfersApiService } from '../../services/user-group-transfers-api.services'; 
import { CreateGroupTransfer } from '../../pages/create-group-transfer/create-group-transfer.component';

enum CurrencyMinAmountValidationLimit {
  LBP = '500000',
  default = '5',
}

const LBP_CURRENCY_CODE = 'LBP';

@Component({
  selector: 'app-upload-sub-transfer-csv',
  templateUrl: './upload-sub-transfer-csv.component.html',
  styleUrls: ['./upload-sub-transfer-csv.component.scss'],
})
export class UploadSubbTransferComponent extends AmountDetailsMixin(class {}) implements OnInit{

    public uploadTransferForm: FormGroup;
    public incomingCurrencyCode: string = null;
    public groupTitle: string;
    public selectedFile: File | null = null;
    protected totalOutgoingAmount: number;
    protected onInitDetails: Subject<any> = new Subject<any>();
    protected unsubscribeSubject = new Subject();
    public readonly defaultCurrencyCode = 'LBP';
    private minimumAmount: CurrencyMinAmountValidationLimit = CurrencyMinAmountValidationLimit.LBP;
    public errorCodes = ErrorCodes;
    public recipientDetails: string;
    public get accountFrom(): AccountInterface {
        return this.uploadTransferForm.get('accountFrom').value;
      }

  constructor(
    private dialogRef: MatDialogRef<UploadSubbTransferComponent>,
    protected fb: UntypedFormBuilder,
    protected transferService: TransferService,
    private activatedRoute: ActivatedRoute,
    private UserGroupTransfersService: UserGroupTransfersService,
    protected changeDetector: ChangeDetectorRef,


    @Inject(MAT_DIALOG_DATA)
    data: {
        accountFrom: AccountInterface,
        groupTitle: string,
    },
  ) {
      super();
      this.groupTitle = data.groupTitle;
    this.createForm()
    this.subscribeToLoadIncomingAmount();
    this.uploadTransferForm.patchValue({accountFrom: data.accountFrom})
    this.uploadTransferForm.setValidators([sufficientAmountValidatorFn()]);
    this.onChangeAccountFrom();
    
  }

  public ngOnInit() {
    this.uploadTransferForm.get('accountNumberTo').setValidators([accountNumberValidator(), numberValidator()]);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  createForm() {
    this.uploadTransferForm = this.fb.group({
      title: [null, Validators.required],
      accountNumberTo: ['', Validators.required],
      outgoingAmount: [null, [Validators.required]],
      accountFrom: [null],
      description: [null],
      helpText: [null],
      incomingAmount: [''],
    });
  }

  private initDetails(data: any): void {
    this.uploadTransferForm.get('incomingAmount').setValue((<IIncomingAmount>data).incomingAmount);
    this.details = (<IIncomingAmount>data).details || null;
    this.recipientDetails = this.transformRecipientInfo(data.recipient);
    this.uploadTransferForm.get('helpText').setValue(this.recipientDetails);
    this.incomingCurrencyCode = (<IIncomingAmount>data).incomingCurrencyCode || null;
    this.totalOutgoingAmount = data?.totalOutgoingAmount ? Math.abs(Number(data?.totalOutgoingAmount)) : null;
    this.onInitDetails.next();
  }

  private subscribeToLoadIncomingAmount() {
    this.transferService.onLoadIncomingAmount$
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(({ data, error }: { data: IIncomingAmount | ApiError[]; error: boolean }) => {
        if (!error) {
          this.initDetails(data);
        } else {
          this.details = null;
        }
        this.changeDetector.detectChanges();
      });
  }

  protected calculateFee(): void {
    this.details = null;
    if (this.uploadTransferForm.get('accountNumberTo').valid && this.uploadTransferForm.get('outgoingAmount').valid) {
      this.evaluateRequest();
    }
  }

  public onChangeAccount(): void {
    this.uploadTransferForm.get('accountNumberTo').updateValueAndValidity();
  }
  protected evaluateRequest(): void {
    this.transferService.loadIncomingAmount.next({
      type: TransferTypes.USER_TBU,
      data: { body: this.getRequestData() },
    });
  }


  public onChangeAccountFrom(): void {
    this.uploadTransferForm
      .get('accountFrom')
      .valueChanges.pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((data: AccountInterface) => {
        this.updateAmountControlValidators(data.type.currencyCode);
      });
  }

  protected getRequestData(): IAdminTbuRequest | ITbuRequest {
    const { accountFrom, accountTo, userFrom, userTo, userName, accountNumberTo, ...data } = this.uploadTransferForm.value;
    return {
      ...data,
      accountIdFrom: accountFrom.id,
      accountNumberTo: accountNumberTo || accountTo?.number,
    };
  }

  public uploadSubTransfer(): void {
    if (!this.selectedFile) {
      console.warn('No file selected');
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const { accountFrom, accountTo, userFrom, userTo, userName, accountNumberTo, ...data } = this.uploadTransferForm.value;
      const base64File = reader.result?.toString().split(',')[1]; // Extract base64 string
      const payload = {
        fileName: this.selectedFile.name,
        fileData: base64File,
        groupId: this.activatedRoute.snapshot.queryParamMap.get('groupId'),
        groupTitle: this.groupTitle,
        accountIdFrom: accountFrom.number,
      };
  
      this.UserGroupTransfersService.uploadSubTransfer(payload).subscribe(() => {
        this.dialogRef.close();
      }, (error) => {
        console.error('Error uploading file:', error);
      });
    };
  
    reader.readAsDataURL(this.selectedFile); // Read file as base64
  }

  private getAmountValidators(): ValidatorFn[] {
    return [Validators.required, Validators.min(Number(this.minimumAmount))];
  }

  private updateAmountControlValidators(currencyCode: string): void {
    this.minimumAmount =
      currencyCode === LBP_CURRENCY_CODE
        ? CurrencyMinAmountValidationLimit.LBP
        : CurrencyMinAmountValidationLimit.default;

    this.uploadTransferForm.controls.outgoingAmount.clearValidators();
    this.uploadTransferForm.controls.outgoingAmount.addValidators(this.getAmountValidators());
    this.uploadTransferForm.controls.outgoingAmount.updateValueAndValidity();
  }

  transformRecipientInfo(data: { firstName: string, lastName: string, phoneNumber: string }): string {
    let { firstName, lastName, phoneNumber } = data;
    let transformedFirstName = firstName[0] + '.';
    let transformedPhoneNumber = phoneNumber.slice(0, 6) + "XXX" + phoneNumber.slice(9);
    let output = `${transformedFirstName} ${lastName} ${transformedPhoneNumber}`;

    return output;
  }

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0] as File;
  }
  

  public triggerFileInput(): void {
    const fileInput = document.getElementById('csvUpload') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }
  
}
