import { Injectable } from '@angular/core';
import { Notification, NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NotificationsServiceWithTranslate {
  constructor(protected notificationService: NotificationsService, protected translateService: TranslateService) {}

  public success(prefix: string, title?: any, content?: any, override?: any): Notification {
    return this.notificationService.success(
      this.translateService.instant(this.prefix(prefix) + title),
      this.translateService.instant(this.prefix(prefix) + content),
      this.translateService.instant(this.prefix(prefix) + override),
    );
  }

  public alert(prefix: string, title?: any, content?: any, override?: any): Notification {
    return this.notificationService.alert(
      this.translateService.instant(this.prefix(prefix) + title),
      this.translateService.instant(this.prefix(prefix) + content),
      this.translateService.instant(this.prefix(prefix) + override),
    );
  }

  public error(prefix: string, title?: any, content?: any, override?: any): Notification {
    return this.notificationService.error(
      this.translateService.instant(this.prefix(prefix) + title),
      this.translateService.instant(this.prefix(prefix) + content),
      this.translateService.instant(this.prefix(prefix) + override),
    );
  }

  public errorV2(content: any): Notification {
    return this.notificationService.error(
      this.translateService.instant('common.notifications.error'),
      this.translateService.instant(content),
    );
  }

  public errorWithParams(
    prefix: string,
    title?: any,
    titleParams?: Object,
    content?: any,
    contentParams?: Object,
    override?: any,
  ): Notification {
    return this.notificationService.error(
      this.translateService.instant(this.prefix(prefix) + title, titleParams),
      this.translateService.instant(this.prefix(prefix) + content, contentParams),
      this.translateService.instant(this.prefix(prefix) + override),
    );
  }

  public warn(prefix: string, title?: any, content?: any, override?: any): Notification {
    return this.notificationService.error(
      this.translateService.instant(this.prefix(prefix) + title),
      this.translateService.instant(this.prefix(prefix) + content),
      this.translateService.instant(this.prefix(prefix) + override),
    );
  }

  public remove(): void {
    this.notificationService.remove();
  }

  private prefix(prefix): string {
    if (!prefix) {
      return '';
    }
    return `${prefix}.`;
  }
}
