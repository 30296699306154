<div class="close-icon-wrapper" *ngIf="isCancelIcon">
  <app-svg-icon (click)="close()" class="close-icon" iconPath="auth/close.svg"></app-svg-icon>
</div>

<div class="icon-wrapper" *ngIf="!isHideIcon" [ngClass]="{ center: isCenterIcon }">
  <app-svg-icon class="icon" [iconPath]="'modals/header/' + titleIconName + '.svg'"></app-svg-icon>
</div>

<div class="title" [ngClass]="{ center: isCenterIcon }">{{ title }}</div>

<ng-content></ng-content>
