import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, mergeMap, map, take, withLatestFrom } from 'rxjs/operators';
import { ErrorHandlerService } from '@services/error-handler.service';
import { OptionActions } from '@default-application-app/components/profiles/actions';
import { ApiError } from '@models/api-error.model';
import { CallResponceInterface } from '@interfaces/callResponce.interface';
import { StoreOptionsModel } from '@models/option.model';
import * as FromProfiles from '@default-application-app/components/profiles/reducers';
import { OptionsApiService } from '@services/options/options-api.service';
import { AuthService } from '@services/auth/auth.service';
import { NotificationsServiceWithTranslate } from '@services/translate/notificationsServiceWithTranslate';

@Injectable()
export class OptionEffects {
  @Effect()
  loadOption$ = (): Observable<Action> =>
    this.actions$.pipe(
      ofType<OptionActions.LoadOptions>(OptionActions.OptionActionsTypes.LOAD_OPTIONS),
      withLatestFrom(this.store.pipe(select(FromProfiles.getCurrentOptionsPath))),
      mergeMap(
        ([{ actionData }, loadActionPath]: [{ actionData?: { path: string; publicPath?: boolean } }, string]) => {
          const loadingPath: string = (actionData && actionData.path) || loadActionPath;
          return this.apiService.apiLoadOptions(loadingPath, !this.authService.isAuthenticated()).pipe(
            take(1),
            map(({ data, error }: CallResponceInterface) => {
              if (error) {
                return new OptionActions.LoadOptionsFailure(<ApiError[]>data);
              }
              return new OptionActions.LoadOptionsSuccess(
                new StoreOptionsModel({ path: loadingPath, options: <any[]>data }),
              );
            }),
            catchError((err) => of(new OptionActions.LoadOptionsFailure(ErrorHandlerService.generateApiErrors(err)))),
          );
        },
      ),
    );

  @Effect()
  updateOption$ = (): Observable<Action> =>
    this.actions$.pipe(
      ofType<OptionActions.UpdateOptions>(OptionActions.OptionActionsTypes.UPDATE_OPTION),
      map((action) => action.options),
      exhaustMap((options: StoreOptionsModel) =>
        this.apiService.apiUpdateOptions(options).pipe(
          take(1),
          map(({ data, error }: CallResponceInterface) => {
            if (error) {
              this.notificationService.error('common.notifications', 'options', 'something_went_wrong');
              return new OptionActions.UpdateOptionsFailure(<ApiError[]>data);
            }
            this.notificationService.success('common.notifications', 'options', 'successfully_updated');
            return new OptionActions.UpdateOptionsSuccess(
              new StoreOptionsModel({ path: options.path, options: <any[]>data }),
            );
          }),
          catchError((err) => of(new OptionActions.UpdateOptionsFailure([err]))),
        ),
      ),
    );

  constructor(
    private actions$: Actions,
    private apiService: OptionsApiService,
    private store: Store<FromProfiles.OptionsState>,
    private notificationService: NotificationsServiceWithTranslate,
    private authService: AuthService,
  ) {}
}
