class User {
  public id: string;

  public username: string;

  public email: string;

  public firstName: string;

  public lastName: string;

  constructor(params: any) {
    Object.assign(this, params);
  }

  public humanId(): string {
    const fullName = `${this.firstName.trim()} ${this.lastName.trim()}`.trim();
    if (fullName) {
      return `${fullName}`;
    }

    return this.email;
  }
}

interface TransactionDetailsInterface {
  amountType: string;
  creationReason: string;
  delegateId: string;
  delegateType: string;
  requestCreatedFor: string;
  revenueCashAgentAccountId: number;
  revenueWalletAccountId: number;
  sourceAccountId: number;
  sourceAccountNumber: string;
  sourceOfFundsDocID: string;
  totalAmount: number;
  transferFeeParamsAgent: {
    base: string;
    max: string;
    min: string;
    percent: string;
  };
  transferFeeParamsWallet: {
    base: string;
    max: string;
    min: string;
    percent: string;
  };
}

export class TransferRequest {
  public id: number;

  public userId: string;

  public status: string;

  public subject: string;

  public baseCurrencyCode: string;

  public amount: string;

  public createdAt: string;

  public statusChangedAt: string;

  public user: User;

  public details: TransactionDetailsInterface;

  public constructor(params: any) {
    Object.assign(this, params);
    this.user = new User(params.user);
    this.details = params['-'];
  }
}
