import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@default-application-app/config.service';
import { CallResponceInterface } from '@interfaces/callResponce.interface';
import { DelegateInterface } from '@interfaces/delegate-interface';
import { ApiCallerService } from '../api-caller.service';

@Injectable()
export class DelegateApiService {
  constructor(
    private http: HttpClient,
    private apiCallerService: ApiCallerService,
    private configService: ConfigService,
  ) {}

  public apiDeleteDelegateById(id: number): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () => this.http.delete(this.configService.config.api.delegate.delegateById(id)),
      'apiDeleteDelegateById',
    );
  }

  public apiCreateDelegate(data: DelegateInterface): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () => this.http.post(this.configService.config.api.delegate.delegates, { data }),
      'apiCreateDelegate',
    );
  }

  public apiLoadDelegates(): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.delegate.delegates),
      'apiLoadDelegates',
    );
  }

  public apiLoadAdminDelegatesForUser(id: string): Observable<CallResponceInterface> {
    return this.apiCallerService.call(
      () => this.http.get(this.configService.config.api.delegate.adminDelegates(id)),
      'apiLoadAdminDelegatesForUser',
    );
  }
}
