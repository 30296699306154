import { EDropDownItem } from '@default-application-app/modules/components/enums';

export const DROP_DOWN_MENU_CONFIG = {
  print: {
    icon: 'ic_print.svg',
    title: 'common.buttons.print',
    key: EDropDownItem.Print,
  },
  download: {
    icon: 'ic_download.svg',
    title: 'common.buttons.download_csv',
    key: EDropDownItem.Download,
  },
  import: {
    icon: 'ic_import.svg',
    title: 'common.buttons.import_csv',
    key: EDropDownItem.Import,
  },
  view: {
    icon: 'ic_eye_on.svg',
    title: 'common.buttons.view',
    key: EDropDownItem.View,
  },
  edit: {
    icon: 'ic_edit.svg',
    title: 'common.buttons.edit',
    key: EDropDownItem.Edit,
  },
};
