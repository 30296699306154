import { Action } from '@ngrx/store';
import {
  userPermissionsAdapter,
  reducer,
  State,
  initialUserPermissionState,
} from '@default-application-app/actions/permission.actions';

// eslint-disable-next-line @typescript-eslint/default-param-last
export function userPermissionsReducer(state: State = initialUserPermissionState, action: Action): State {
  return reducer(state, action);
}

const { selectEntities } = userPermissionsAdapter.getSelectors();

export const selectUserPermissionsEntitiesSelector = selectEntities;

export const getUserPermissionsLoading = (state: State) => state.loading;
