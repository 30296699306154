/**
 * @license Copyright iKantam LLC. All Rights Reserved.
 *
 *   Use of this source code is governed by an CC BY-NC-ND 4.0 license that can be found in the LICENSE file at
 *   https://creativecommons.org/licenses/by-nc-nd/4.0
 */
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { Transaction } from '@models/transaction';
import { PaginationService } from '@services/pagination/pagination.service';
import { PaginationPageLimitInterface } from '@interfaces/pagination-page-limit.interface';
import * as FileSaver from 'file-saver';
import { QueryFieldInterface } from '@interfaces/sort-bar/queryField.interface';
import { DateToFromFieldInterface } from '@interfaces/sort-bar/dateToFromField.interface';
import { SortFieldInterface } from '@interfaces/sort-bar/sortField.interface';
import { PaginationFieldInterface } from '@interfaces/sort-bar/paginationField.interface';
import { queryParamsStringify } from '@helpers/queryParamsHelpers';
import { map, switchMap, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserRequestModel } from '@models/userRequest.model';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data.min';
import { CallResponceInterface } from '@interfaces/callResponce.interface';
import { ViewTransactionModel } from '@models/transactions/view-transaction.model';
import { TransactionApiService } from './transaction-api.service';

export type TransactionRequestFilter = QueryFieldInterface &
  DateToFromFieldInterface &
  SortFieldInterface &
  PaginationFieldInterface & { accountId?: string } & { status?: string } & { subject?: string } & {
    isCancelledByAdmin?: boolean;
  } & { isCancelledBySystem?: boolean } & { purpose?: string };

export class TransactionsData {
  constructor(public transactions: Transaction[], public pagination: PaginationPageLimitInterface) {
    this.transactions = transactions;
    this.pagination = pagination;
  }
}

export class UserRequestsData {
  constructor(public transactions: UserRequestModel[], public pagination: PaginationPageLimitInterface) {}
}

@Injectable()
export class TransactionService {
  public loadPendingTransactionsSubject$: Subject<TransactionRequestFilter> = new Subject<TransactionRequestFilter>();

  public onLoadPendingTransactions$: Observable<TransactionsData> = this.loadPendingTransactionsSubject$
    .asObservable()
    .pipe(
      map((query: TransactionRequestFilter) => TransactionService.transformParams(query)),
      // tap(() => this.spinner.show()),
      switchMap((params: { [key: string]: string }) => this.transactionsApiService.apiLoadPendingTransactions(params)),
      // tap(() => this.spinner.hide()),
      map(({ data, error, links }: { data: any[]; error: boolean; links: any }) => {
        if (error) {
          return null;
        }
        const requests = data.map((item) => new Transaction(item));

        const pagination = PaginationService.buildPaginationSizeNumber(links);
        return new TransactionsData(requests, pagination);
      }),
    );

  /** @type {Subject<Transaction[]>} */
  public loadTransactionsSubject$: Subject<TransactionRequestFilter> = new Subject<TransactionRequestFilter>();

  /** @type {Observable<Transaction[]>} */
  public onLoadTransactions$: Observable<TransactionsData> = this.loadTransactionsSubject$.asObservable().pipe(
    map((query: TransactionRequestFilter) => TransactionService.transformParams(query)),
    tap(() => this.spinner.show()),
    switchMap((params: { [key: string]: string }) => this.transactionsApiService.apiLoadTransactions(params)),
    tap(() => this.spinner.hide()),
    map(({ data, error, links }: { data: any[]; error: boolean; links: any }) => {
      if (error) {
        return null;
      }
      const requests = data.map((item) => new Transaction(item));

      const pagination = PaginationService.buildPaginationSizeNumber(links);
      return new TransactionsData(requests, pagination);
    }),
  );

  public loadRequestsSubject$: Subject<TransactionRequestFilter> = new Subject<TransactionRequestFilter>();

  /** @type {Observable<Transaction[]>} */
  public onLoadRequests$: Observable<UserRequestsData> = this.loadRequestsSubject$.asObservable().pipe(
    map((query: TransactionRequestFilter) => TransactionService.transformParamsForRequest(query)),
    tap(() => this.spinner.show()),
    switchMap((params: { [key: string]: string }) =>
      this.transactionsApiService.apiLoadRequests(params).pipe(
        tap(
          () => this.spinner.hide(),
          () => this.spinner.hide(),
        ),
        map(({ data, error, links }: { data: any[]; error: boolean; links: any }) => {
          if (error) {
            return new UserRequestsData([], PaginationService.defaultPaginationPageLimit);
          }
          const requests = data.map((item) => new UserRequestModel(item, parseInt(params['filter[accountId]'], 10)));

          const pagination = PaginationService.buildPaginationSizeNumber(links);
          return new UserRequestsData(requests, pagination);
        }),
      ),
    ),
  );

  public constructor(private transactionsApiService: TransactionApiService, private spinner: NgxSpinnerService) {}

  private static transformParams(queryParams: TransactionRequestFilter): { [key: string]: string } {
    const params = {
      page: { number: queryParams.page, size: queryParams.size },
    };
    const filter = {};
    if (queryParams.sort) {
      // @ts-ignore
      params.sort = queryParams.sort;
    }

    if (queryParams.accountId) {
      // @ts-ignore
      filter.accountId = queryParams.accountId;
    }

    if (queryParams.query) {
      // @ts-ignore
      filter.id = queryParams.query;
    }

    if (queryParams.status) {
      // @ts-ignore
      filter['status'] = queryParams.status;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (queryParams?.hasOwnProperty('isCancelledByAdmin')) {
      filter['isCancelledByAdmin'] = queryParams.isCancelledByAdmin;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (queryParams?.hasOwnProperty('isCancelledBySystem')) {
      filter['isCancelledBySystem'] = queryParams.isCancelledBySystem;
    }

    if (queryParams.subject) {
      // @ts-ignore
      filter['subject'] = queryParams.subject;
    }

    if (queryParams.purpose) {
      // @ts-ignore
      filter['purpose:nin'] = queryParams.purpose;
    }

    if (queryParams.dateTo) {
      // @ts-ignore
      filter.statusChangedAtTo = moment(queryParams.dateTo).endOf('day').utc().format();
    }
    if (queryParams.dateFrom) {
      // @ts-ignore
      filter.statusChangedAtFrom = moment(queryParams.dateFrom).startOf('day').utc().format();
    }
    if (Object.keys(filter).length) {
      // @ts-ignore
      params.filter = filter;
    }
    return queryParamsStringify(params, false);
  }

  private static transformParamsForRequest(queryParams: TransactionRequestFilter): { [key: string]: string } {
    const params = {
      page: { number: queryParams.page, size: queryParams.size },
      include: ['balanceSnapshots', 'balanceSnapshots.balanceType', 'balanceDifference'],
    };
    const filter = {};
    if (queryParams.sort) {
      // @ts-ignore
      params.sort = queryParams.sort;
    }

    if (queryParams.accountId) {
      // @ts-ignore
      filter.accountId = queryParams.accountId;
    }

    if (queryParams.query) {
      // @ts-ignore
      filter.id = queryParams.query;
    }
    if (queryParams.dateTo) {
      filter['statusChangedAt:lte'] = moment(queryParams.dateTo).endOf('day').utc().format();
    }
    if (queryParams.dateFrom) {
      filter['statusChangedAt:gte'] = moment(queryParams.dateFrom).startOf('day').utc().format();
    }
    if (Object.keys(filter).length) {
      // @ts-ignore
      params.filter = filter;
    }
    return queryParamsStringify(params, false);
  }

  /**
   * Load accounts list
   *
   * @returns {Observable<AccountInterface[]>}
   */
  public loadTransactionsCsv(params: TransactionRequestFilter) {
    this.transactionsApiService
      .apiLoadTransactionsCsv(TransactionService.transformParams(params))
      .subscribe((blob: Blob) => {
        FileSaver.saveAs(blob, `${new Date().toISOString()}.csv`);
      });
  }

  public getUserTransaction(id: string): Observable<ViewTransactionModel | null> {
    const params = { include: 'sender,recipient,requestData,invoice,refund' };
    this.spinner.show();
    return this.transactionsApiService.apiGetUserTransaction(id, params).pipe(
      map((data: CallResponceInterface) => {
        this.spinner.hide();
        if (data.error) {
          return null;
        }
        return data.data as ViewTransactionModel;
      }),
    );
  }

  public loadRequests(filter: TransactionRequestFilter): Observable<UserRequestsData> {
    return this.transactionsApiService.apiLoadRequests(TransactionService.transformParams(filter)).pipe(
      map(({ data, error, links }: { data: any[]; error: boolean; links: any }) => {
        if (error) {
          return new UserRequestsData([], PaginationService.defaultPaginationPageLimit);
        }
        const requests = data.map(
          (item) =>
            new UserRequestModel(item, parseInt(TransactionService.transformParams(filter)['filter[accountId]'], 10)),
        );

        const pagination = PaginationService.buildPaginationSizeNumber(links);
        return new UserRequestsData(requests, pagination);
      }),
    );
  }

  public loadTransactionHistory(
    filter: TransactionRequestFilter,
  ): Observable<{ transactions: ViewTransactionModel[]; pagination: PaginationPageLimitInterface }> {
    return this.transactionsApiService.apiLoadTransactionsHistory(TransactionService.transformParams(filter)).pipe(
      map(({ data, error, links }: { data: any[]; error: boolean; links: any }) => {
        if (error) {
          return null;
        }
        const transactions: ViewTransactionModel[] = data.map((item) => new ViewTransactionModel(item));

        const pagination: PaginationPageLimitInterface = PaginationService.buildPaginationSizeNumber(links);
        return { transactions, pagination };
      }),
    );
  }

  public loadSubTransactionsByGroupId(
    groupId: string,
  ): Observable<{ transactions: ViewTransactionModel[];}> {
    return this.transactionsApiService.apiLoadSubTransactionsByGroupId(groupId).pipe(
      map(({ data, error, links }: { data: any[]; error: boolean; links: any }) => {
        if (error) {
          return null;
        }
        const transactions: ViewTransactionModel[] = data.map((item) => new ViewTransactionModel(item));
        return { transactions};
      }),
    );
  }
}
